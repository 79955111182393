/* eslint-disable jsdoc/no-undefined-types */
import { isEmpty, isNil } from "lodash";
import React from "react";
import { ValidationRule, ValidationError } from "../utils/validation";
import { MaxNumberValue, MinNumberValue, MinMaxNumberValue, RequiredNumber } from "../utils/validation/rules";
import { formatCurrency, formatNumber } from "../../../iserver365-globalization-utility/src";

const currencyDecimalPlaces = 2;

interface GetValidationRuleProps {
    validationRules: ValidationRule<Value>[];
    isRequired: boolean;
    min?: number;
    max?: number;
}

export type Value = number | null | undefined;

/**
 * Calculates the string display value for the number field component.
 * @param {number | undefined} currentValue The current value.
 * @param {boolean} readOnly A value indicating whether read mode is active.
 * @param {boolean} isCurrency A value indicating whether the component is used to display currency values.
 * @param {string | undefined} suffix Suffix for the value.
 * @param {number | undefined} decimalPlaces Decimal places to use when formatting the value.
 * @returns {string} The formatted string value.
 */
export function getDisplayValue(
    currentValue: number | null | undefined,
    readOnly: boolean,
    isCurrency: boolean,
    suffix: string | undefined,
    decimalPlaces: number | undefined
): string {
    if (currentValue === undefined || currentValue === null) {
        return "";
    }

    if (!readOnly) {
        return String(currentValue);
    }

    return isCurrency && suffix !== undefined
        ? formatCurrency(currentValue, suffix, undefined, currencyDecimalPlaces)
        : formatNumber(currentValue, decimalPlaces);
}

/**
 * Calculates the suffix to display in the number field component.
 * @param {string | undefined} suffix Suffix for the value.
 * @param {boolean} readOnly A value indicating whether read mode is active.
 * @param {boolean} isCurrency A value indicating whether the component is used to display currency values.
 * @returns {string} The suffix.
 */
export function getSuffix(suffix: string | undefined, readOnly: boolean, isCurrency: boolean): string | undefined {
    return readOnly && isCurrency ? undefined : suffix;
}

/**
 * @param {GetValidationRuleProps} params Parameter object
 * @returns {ValidationRule[]} Validation rules to apply to {@link NumberField}
 */
export function getValidationRules({
    validationRules,
    isRequired,
    min,
    max,
}: GetValidationRuleProps): ValidationRule<Value>[] {
    const builtinRules = [];
    if (isRequired) {
        builtinRules.push(new RequiredNumber());
    }
    if (!isNil(min) && !isNil(max)) {
        builtinRules.push(new MinMaxNumberValue({ min, max }));
    }
    if (!isNil(min)) {
        builtinRules.push(new MinNumberValue({ min }));
    }
    if (!isNil(max)) {
        builtinRules.push(new MaxNumberValue({ max }));
    }
    return [...builtinRules, ...validationRules];
}

/**
 * @param {object} params Parameter object
 * @param {ValidationError[]} params.validationErrors List of current validation errors for the {@link NumberField} component
 * @param {boolean} params.readOnly Whether or not the {@link NumberField} component is currently readOnly
 * @returns {boolean} Whether or not to display a validation error
 */
export function getDisplayValidationError({
    validationErrors,
    readOnly,
}: {
    validationErrors: ValidationError[];
    readOnly: boolean;
}): boolean {
    return !isEmpty(validationErrors) && !readOnly;
}

/**
 * @param {string} value String which needs to be formatted
 * @param {number} fixed Amount of numbers after comma
 * @returns {string | null} Formatted fixed string
 */
export const toFixed = (value: string, fixed: number): string | null => {
    // We need to validate and truncate incoming value with decimal.
    // For doing this we were using `toFixed()` function, but it does not work for value like `1.23e+21`
    // It only allows `e+20`, so we needed to add custom RegExp to parse our numbers.
    const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${fixed}})?`);
    const matchArray = `${value}`.match(regex);

    if (matchArray !== null) {
        return matchArray[0].replace(/\.$/, "");
    }

    return null;
};

/**
 * @param {string} value String which needs to be formatted
 * @param {number} decimalPlaces Amount of symbols after comma
 * @returns {string} Formatted fixed string
 */
export const getFixedStringValue = (value: string | undefined, decimalPlaces: number): string => {
    if (isNil(value)) {
        return "";
    }

    const fixedString = toFixed(value, decimalPlaces);
    if (isNil(fixedString)) {
        return "";
    }

    return fixedString;
};

/**
 * @param {string} stringValue String which needs to be checked if is no longer that maxLength
 * @param {number} maxLength Maximal length of the string
 * @returns {string} Trimmed fixed string
 */
export function checkMaxLength(stringValue: string, maxLength: number): string {
    const indexOfSeparator = stringValue.indexOf(".");
    if (indexOfSeparator === -1) {
        if (stringValue.length > maxLength) {
            return stringValue.slice(0, maxLength);
        }
    } else if (stringValue.length > maxLength + 1) {
        return stringValue.slice(0, maxLength + 1);
    }
    return stringValue;
}

/**
 * We need to block scientific notation for all number inputs - without this 'e' can be input/paste to the NumberFiled,
 * because is supported by default for number type
 */

/**
 * @param {event} e onKeyDown event
 * @returns {false | void} false if keyboard key is not 'e'
 */
export const blockInvalidChar = (e: React.KeyboardEvent): false | void =>
    ["e", "E"].includes(e.key) && e.preventDefault();

/**
 * @param {React.ClipboardEvent<HTMLTextAreaElement>} e Pasting event
 */
export const pasteHandlerToBlockE = (e: React.ClipboardEvent<HTMLTextAreaElement>): false | void => {
    e.clipboardData.getData("text/plain").match(/[eE]/) !== null && e.preventDefault();
};
