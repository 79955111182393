import { isEmpty, isNil, trim } from "lodash";
import { ValidationRule, ValidationRuleProps } from "../../";

type Value = string | undefined;

/**
 * RequiredStringError validation error class
 */
export class RequiredStringError extends Error {
    message = "Field is required";
}

/**
 * RequiredString validation rule class
 */
export class RequiredString extends ValidationRule<Value> {
    static Error = RequiredStringError;
    constructor(props: ValidationRuleProps<Value> = {}) {
        super(props);
    }
    validate(value: Value): RequiredStringError | null {
        if (isNil(value) || isEmpty(trim(value))) {
            return new RequiredStringError();
        }
        return null;
    }
}
