import { wrap } from "lodash";
import React from "react";
import classNames from "classnames";

import { stopPropagation } from "../../../utils/events";
import { keyDownA11y } from "../../../utils/accessibility";
import { LinkTargetOption } from "../../../types";
import { TopLevelOptionProps } from "../types";
import {
    getDisableAdd,
    handleLinkTargetInputChange,
    handleLinkTitleInputChange,
    handleLinkTargetOptionInputChange,
    handleModalAdd,
} from "./LinkOption.service";

export interface LinkOptionModalProps
    extends Pick<TopLevelOptionProps, "config" | "translations" | "doCollapse" | "onChange"> {
    linkTitle?: string;
    linkTarget?: string;
    linkTargetOption: LinkTargetOption;
    setLinkTitle: (linkTitle: string) => void;
    setLinkTarget: (linkTarget: string) => void;
    setLinkTargetOption: (linkTargetOption: LinkTargetOption) => void;
}

/**
 * @param {LinkOptionModalProps} props {@link LinkOptionModal} props
 * @returns {JSX.Element} The react element.
 */
export function LinkOptionModal({
    config: { popupClassName },
    translations,
    linkTitle = "",
    linkTarget = "",
    linkTargetOption,
    onChange,
    setLinkTitle,
    setLinkTarget,
    setLinkTargetOption,
    doCollapse,
}: LinkOptionModalProps): JSX.Element {
    const disableAdd = getDisableAdd({ linkTitle, linkTarget });
    return (
        <div role="dialog" title="Link Dialog">
            <div
                className={classNames("rdw-link-modal", popupClassName)}
                onClick={stopPropagation}
                onKeyPress={keyDownA11y({ handler: stopPropagation })}
                role="presentation"
            >
                <label className="rdw-link-modal-label" htmlFor="linkTitle">
                    <div>{translations["components.controls.link.linkTitle"]}</div>
                    <input
                        id="linkTitle"
                        className="rdw-link-modal-input"
                        onChange={wrap({ setLinkTitle }, handleLinkTitleInputChange)}
                        onBlur={wrap({ setLinkTitle }, handleLinkTitleInputChange)}
                        name="linkTitle"
                        value={linkTitle}
                    />
                </label>
                <label className="rdw-link-modal-label" htmlFor="linkTarget">
                    <div>{translations["components.controls.link.linkTarget"]}</div>
                    <input
                        id="linkTarget"
                        className="rdw-link-modal-input"
                        onChange={wrap({ setLinkTarget }, handleLinkTargetInputChange)}
                        onBlur={wrap({ setLinkTarget }, handleLinkTargetInputChange)}
                        name="linkTarget"
                        value={linkTarget}
                    />
                </label>
                <label className="rdw-link-modal-target-option" htmlFor="openLinkInNewWindow">
                    <input
                        id="openLinkInNewWindow"
                        type="checkbox"
                        defaultChecked={linkTargetOption === "_blank"}
                        value="_blank"
                        onChange={wrap({ setLinkTargetOption }, handleLinkTargetOptionInputChange)}
                    />
                    <span>{translations["components.controls.link.linkTargetOption"]}</span>
                </label>
                <span className="rdw-link-modal-buttonsection">
                    <button
                        className="rdw-link-modal-btn"
                        onClick={wrap({ linkTitle, linkTarget, linkTargetOption, onChange }, handleModalAdd)}
                        disabled={disableAdd}
                    >
                        {translations["generic.add"]}
                    </button>
                    <button className="rdw-link-modal-btn" onClick={doCollapse}>
                        {translations["generic.cancel"]}
                    </button>
                </span>
            </div>
        </div>
    );
}
