import React from "react";
import { Callout, Stack, Shimmer, ShimmerElementType } from "@fluentui/react";
import { useId, useBoolean } from "@fluentui/react-hooks";
import { IChoiceDataEntry, Choice, getChoiceModels } from "../Choice";
import { TextField } from "../TextField";
import { Tags } from "../Tags/Tags";
import { isNil } from "lodash";
import { useChoicePickerStyles } from "./ChoicePicker.styles";
import { useChoicePicker } from "./useChoicePicker";
import { FieldLabel } from "../FieldLabel";
import { ILabeledFieldProps } from "../types";

const TEXT_FIELD_HEIGHT = 32;

export interface ChoicePickerProps extends ILabeledFieldProps {
    isLoading: boolean;
    choices: IChoiceDataEntry[];
    selectedChoices: string[];
    readOnly?: boolean;
    disabled?: boolean;
    placeholder?: string;
    allowMultipleValues?: boolean;
    /** Specifies whether dropdown with choices should have exact same width as parent text field. */
    dropdownWidthSameAsInput?: boolean;
    onChange: (selectedKeys: string[]) => void;
}

/**
 * Provides user an ability to select from
 * predefined hierarchical choices.
 * @param {ChoicePickerProps} props - component props
 * @returns {JSX.Element} React element
 */
export function ChoicePicker({
    isLoading,
    choices,
    selectedChoices,
    label,
    dropdownWidthSameAsInput = false,
    readOnly = false,
    disabled = false,
    placeholder = "-",
    allowMultipleValues = true,
    onChange,
    onRenderLabel,
    id,
}: ChoicePickerProps): JSX.Element {
    const CHOICE_INPUT_ID = useId("choice");
    const [isCalloutVisible, { toggle: toggleCallout, setFalse: hideCallout }] = useBoolean(false);
    const { containerTokens, textField: textFieldStyles } = useChoicePickerStyles();
    const { calloutWidth, displayValues, choicesState, textInputRef, handleChoiceChange } = useChoicePicker({
        choices,
        selectedChoices,
        allowMultipleValues,
        dropdownWidthSameAsInput,
        onChange,
    });

    if (readOnly) {
        return (
            <Stack tokens={containerTokens}>
                {!isNil(onRenderLabel) ? onRenderLabel() : <FieldLabel label={label} />}
                <Tags items={displayValues.tags} />
                {displayValues.tags.length === 0 && !isNil(placeholder) && placeholder}
            </Stack>
        );
    }

    return (
        <>
            <Shimmer
                isDataLoaded={!isLoading}
                shimmerElements={[{ type: ShimmerElementType.line, height: TEXT_FIELD_HEIGHT }]}
            >
                <TextField
                    elementRef={textInputRef}
                    ariaLabel="Values"
                    readOnly
                    borderless={false}
                    styles={textFieldStyles}
                    id={id ?? CHOICE_INPUT_ID}
                    iconProps={{ iconName: isCalloutVisible ? "ChevronUp" : "ChevronDown" }}
                    value={displayValues.text.join(", ")}
                    onClick={isLoading ? undefined : toggleCallout}
                    label={label}
                    disabled={disabled}
                    onRenderLabel={onRenderLabel}
                />
            </Shimmer>
            {isCalloutVisible && !isLoading ? (
                <Callout
                    role="dialog"
                    alignTargetEdge
                    isBeakVisible={false}
                    calloutMaxHeight={250}
                    calloutWidth={calloutWidth}
                    calloutMaxWidth={calloutWidth}
                    target={`#${id ?? CHOICE_INPUT_ID}`}
                    onDismiss={hideCallout}
                >
                    {getChoiceModels(choicesState, true, false).map((choiceTreeModel) => (
                        <Choice
                            editMode
                            partialSelectionEnabled
                            key={choiceTreeModel.key}
                            choiceTreeModel={choiceTreeModel}
                            hideChoiceCount={!allowMultipleValues}
                            onChoiceChange={handleChoiceChange}
                        />
                    ))}
                </Callout>
            ) : null}
        </>
    );
}
