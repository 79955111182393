import React, { useCallback, useMemo } from "react";
import { IDropdownOption } from "@fluentui/react";
import { SearchableDropdownBaseProps } from "./types/SearchableDropdownBaseProps";
import { useDropdown } from "./hooks/useDropdown";
import { SearchableDropdownBase } from "./SearchableDropdownBase";

/**
 * The props of the SearchableDropdown component.
 */
export interface PreloadedSearchableDropdownProps extends SearchableDropdownBaseProps {
    /** The dropdown options.*/
    options: IDropdownOption[];
}

/**
 * SearchableDropdown is a component which displays the selected item from options. It is built on top of Fluent UI Dropdown
 * It has following additional features:
 * - filter options by text;
 * @param {PreloadedSearchableDropdownProps} props The component props
 * @returns {JSX.Element} return react component
 */
export function PreloadedSearchableDropdown(props: PreloadedSearchableDropdownProps): JSX.Element {
    const { searchText, setSearchText, selectedValue, setSelectedValue, validationError } = useDropdown(props);

    const onFieldChangeHandler = useCallback(
        (event: React.FormEvent<HTMLDivElement>, newValue?: IDropdownOption) => {
            setSelectedValue(newValue?.key as string, event);
        },
        [setSelectedValue]
    );

    const filteredOptions = useMemo(() => {
        return props.options.map((option) => {
            return option.text.toLowerCase().includes(searchText.toLowerCase()) ? option : { ...option, hidden: true };
        });
    }, [props.options, searchText]);

    return (
        <SearchableDropdownBase
            {...props}
            selectedKey={selectedValue}
            options={filteredOptions}
            errorMessage={validationError}
            setSearchText={setSearchText}
            onChange={onFieldChangeHandler}
        />
    );
}
