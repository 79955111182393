import { isEmpty } from "lodash";
import { ChangeEvent } from "react";

import { OptionProps } from "../types";
import { LinkTargetOption } from "../../../types";

interface SignalExpandShowModalParams {
    link: OptionProps;
    setShowModal: (showModal: boolean) => void;
    setLinkTarget: (target: string) => void;
    setLinkTargetOption: (targetOption: LinkTargetOption) => void;
    setLinkTitle: (title: string) => void;
    linkTargetOption: LinkTargetOption;
    linkTarget?: string;
    selectionText?: string;
    onExpandEvent: () => void;
}

interface GetDisableAddParams {
    linkTitle?: string;
    linkTarget?: string;
}

interface HandleLinkTitleInputChangeParams {
    setLinkTitle: (target: string) => void;
}

interface HandleLinkTargetInputChange {
    setLinkTarget: (target: string) => void;
}

interface HandleLinkTargetOptionInputChange {
    setLinkTargetOption: (targetOption: LinkTargetOption) => void;
}

interface HandleModalAddParams {
    linkTitle?: string;
    linkTarget?: string;
    linkTargetOption: LinkTargetOption;
    onChange(value: "link", linkTitle: string, linkTarget: string, linkTargetOption: LinkTargetOption): void;
}

/**
 * @param {SignalExpandShowModalParams} params {@link signalExpandShowModal} params.
 * @param {string} [_] Value of the input - unused in this case.
 */
export function signalExpandShowModal(
    {
        link,
        setShowModal,
        setLinkTarget,
        setLinkTargetOption,
        setLinkTitle,
        linkTargetOption,
        linkTarget,
        selectionText,
        onExpandEvent,
    }: SignalExpandShowModalParams,
    _?: string
): void {
    onExpandEvent();
    setShowModal(true);
    setLinkTarget(link.target ?? linkTarget ?? "");
    setLinkTargetOption(link.targetOption ?? linkTargetOption);
    setLinkTitle(link.title ?? selectionText ?? "");
}

/**
 * @param {GetDisableAddParams} params {@link getDisableAdd} params.
 * @returns {boolean} Whether or not to disable the add button.
 */
export function getDisableAdd({ linkTitle, linkTarget }: GetDisableAddParams): boolean {
    return isEmpty(linkTarget) || isEmpty(linkTitle);
}

/**
 * @param {HandleLinkTitleInputChangeParams} params {@link handleLinkTitleInputChange} params.
 * @param {ChangeEvent<HTMLInputElement>} event Input change event.
 * @returns {undefined}
 */
export function handleLinkTitleInputChange(
    { setLinkTitle }: HandleLinkTitleInputChangeParams,
    event: ChangeEvent<HTMLInputElement>
): void {
    setLinkTitle(event.target.value);
}

/**
 * @param {HandleLinkTargetInputChange} params {@link handleLinkTargetInputChange} params.
 * @param {ChangeEvent<HTMLInputElement>} event Input change event.
 * @returns {undefined}
 */
export function handleLinkTargetInputChange(
    { setLinkTarget }: HandleLinkTargetInputChange,
    event: ChangeEvent<HTMLInputElement>
): void {
    setLinkTarget(event.target.value);
}

/**
 * @param {HandleLinkTargetOptionInputChange} params {@link handleLinkTargetOptionInputChange} params.
 * @param {ChangeEvent<HTMLInputElement>} event Input change event.
 * @returns {undefined}
 */
export function handleLinkTargetOptionInputChange(
    { setLinkTargetOption }: HandleLinkTargetOptionInputChange,
    event: ChangeEvent<HTMLInputElement>
): void {
    setLinkTargetOption(event.target.checked ? LinkTargetOption._blank : LinkTargetOption._self);
}

/**
 * @param {HandleModalAddParams} params {@link handleModalAdd} params.
 * @returns {undefined}
 */
export function handleModalAdd({ linkTitle, linkTarget, linkTargetOption, onChange }: HandleModalAddParams): void {
    onChange("link", linkTitle ?? "", linkTarget ?? "", linkTargetOption);
}
