/* eslint-disable jsdoc/no-undefined-types */
import React from "react";
import { systemColors } from "../SystemColor";
import { Icon } from "./types";

export type SVGIconProps = {
    icon: Icon;
    width?: string;
    height?: string;
};

/**
 * Cell for the list view which outputs an svg icon. The icon type and color is determined
 * by {@link icon}.
 * @param {SVGIconProps} param The cell props.
 * @returns {React.FC<SVGIconProps>} The react component.
 */
export const SVGIcon: React.FC<SVGIconProps> = ({ icon, height = "1rem", width = "1rem" }: SVGIconProps) => {
    return (
        <svg
            style={{
                backgroundColor: systemColors[icon.color],
                flexShrink: 0,
                width,
                height,
            }}
        >
            <use fill="white" viewBox="0 0 500 500" xlinkHref={`#${icon.icon}`} />
        </svg>
    );
};
