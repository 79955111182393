/**
 * Indicates if the app is rendered in iframe.
 * @returns {boolean} value indicating if the app is rendered in iframe.
 */
function inIframe(): boolean {
    try {
        return window.self !== window.top;
    } catch (e) {
        // Some browsers does not allow to access top property from iframe.
        // When the exception occurs the app is rendered in iframe.
        return true;
    }
}

export { inIframe };
