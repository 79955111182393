import React from "react";
import { BreadcrumbItemRenderer } from "./BreadcrumbItemRenderer";
import { Breadcrumb as BreadcrumbRoot, IBreadcrumbProps } from "@fluentui/react";
import { BreadcrumbItemDefinition } from "./types";

type BreadcrumbProps = IBreadcrumbProps & {
    items: BreadcrumbItemDefinition[];
};

/**
 * Renders a Breadcrumb component.
 * This component is a wrapper around FluentUI Breadcrumb component, that helps to render customized BreadcrumbItem components.
 * @param {BreadcrumbProps} props - The props for the Breadcrumb component.
 * @returns {JSX.Element} The rendered Breadcrumb component.
 */
export function Breadcrumb(props: BreadcrumbProps): JSX.Element {
    const itemRenderer = (item: BreadcrumbItemDefinition | undefined) => {
        return <BreadcrumbItemRenderer {...(item as BreadcrumbItemDefinition)} onRenderItem={props.onRenderItem} />;
    };
    return <BreadcrumbRoot {...props} onRenderItem={(item) => itemRenderer(item as BreadcrumbItemDefinition)} />;
}

export type { BreadcrumbProps };
