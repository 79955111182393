import { IButtonStyles, IStackStyles, ITextStyles, useTheme } from "@fluentui/react";

export interface RatingStyles {
    stackStyles: Partial<IStackStyles>;
    buttonStyles: Partial<IButtonStyles>;
    textStyles: Partial<ITextStyles>;
}

/** @returns {RatingStyles} Rating styles. */
export function useStyles(): RatingStyles {
    const { spacing, palette } = useTheme();

    return {
        stackStyles: {
            root: {
                paddingTop: spacing.m,
                paddingBottom: spacing.s2,
            },
        },
        buttonStyles: {
            root: {
                // by default DefaultButton has 80px min width, so we need to override it
                minWidth: spacing.l1,
                width: "100%",
                fontWeight: 600,
            },
        },
        textStyles: {
            root: {
                color: palette.neutralSecondaryAlt,
            },
        },
    };
}
