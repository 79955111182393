/**
 * Resolves custom props.
 * @param {string} propertyName The property to resolve.
 * @returns {Record<string, string>} The MFE props.
 */
export function resolveMFECSSProp(propertyName: string): string {
    const computedStyles = getComputedStyle(document.documentElement);

    return computedStyles.getPropertyValue(propertyName);
}
