import React from "react";
import { WizardProvider } from "./Wizard.context";
import { useControllableValue } from "@fluentui/react-hooks";
import { WizardStep, WizardStepProps } from "./WizardStep";

/**
 * The {@link WizardProps} Props.
 */
export interface WizardProps {
    /** The wizard's steps configuration. */
    steps: Array<WizardStepProps>;
    /**
     * The step index to select.
     * Use this value when you want to manually control the state of Wizard active in parent component.
     * Wizard will behave as controlled component.
     * Use this value together with `onStepChange` callback.
     */
    stepIndex?: number;
    /**
     * The default value for step index.
     * Use this value when you don't want manually to control the state of Wizard active step.
     * Wizard will behave as uncontrolled component.
     */
    defaultStepIndex?: number;
    /** Callback invoked when step index changed. */
    onStepChange?: (event: unknown, stepIndex: number | undefined) => void;
}

/**
 * Wizard component renders the WizardStep with passed props in steps wrapped by WizardContext.
 * @param {WizardProps} props The Wizard component properties.
 * @returns {JSX.Element} The Wizard component.
 */
export function Wizard(props: WizardProps): JSX.Element {
    const { steps, stepIndex, defaultStepIndex, onStepChange } = props;
    const [activeStepIndex = 0, setActiveStepIndex] = useControllableValue(stepIndex, defaultStepIndex, onStepChange);

    const handlePrev = () => {
        if (activeStepIndex === 0) {
            return;
        }
        setActiveStepIndex((index) => (index ?? 1) - 1);
    };

    const handleNext = () => {
        if (activeStepIndex === steps.length - 1) {
            return;
        }
        setActiveStepIndex((index) => (index ?? 0) + 1);
    };

    return (
        <WizardProvider prev={handlePrev} next={handleNext}>
            <WizardStep {...steps[activeStepIndex]} />
        </WizardProvider>
    );
}
