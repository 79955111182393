import React from "react";
import { Text } from "@fluentui/react";
import { WithLinkWrapperProps, withLinkWrapper } from "../LinkWrapper/withLinkWrapper";
import { useTagsStyles } from "./Tags.styles";
import { TagItemDefinition } from "./types/TagItemDefinition";
import { isNil, merge } from "lodash";

type TagItemBaseProps = Omit<TagItemDefinition, "key" | "link">;

function TagItemBase({ displayValue, startIcon, styles }: TagItemBaseProps): JSX.Element {
    const { tagItem } = useTagsStyles();
    const style = merge(tagItem, styles);
    return (
        <Text styles={style}>
            {!isNil(startIcon) && startIcon()}
            {displayValue}
        </Text>
    );
}

export const TagItem = withLinkWrapper(TagItemBase);
export type TagItemProps = WithLinkWrapperProps<TagItemBaseProps>;
