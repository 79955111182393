import React, { useContext } from "react";

const WizardContext = React.createContext<WizardContextType>({} as WizardContextType);

/** WizardContextProps for Context */
interface WizardContextProps extends WizardContextType {
    /** The children passed into WizardProvider */
    children?: React.ReactNode;
}

/**
 * The react context provider for Wizard.
 * @param {WizardContextProps} props The props for the component.
 * @returns {JSX.Element} The react component.
 */
const WizardProvider = ({ children, prev, next }: WizardContextProps): JSX.Element => {
    return <WizardContext.Provider value={{ prev, next }}>{children}</WizardContext.Provider>;
};

/**
 * useWizardContext hook.
 * @returns {WizardContextType} WizardContextType.
 */
const useWizardContext = (): WizardContextType => {
    return useContext(WizardContext);
};

/**
 * The {@link WizardContextType} type.
 */
export type WizardContextType = {
    /** callback to invoke when moving to the prev step */
    prev: () => void;
    /** callback to invoke when moving to the next step */
    next: () => void;
};

export { useWizardContext, WizardProvider };
