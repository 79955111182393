import { IStackStyles } from "@fluentui/react";
import { CSSProperties } from "react";

interface ListViewStyles {
    /** Container styles */
    container: IStackStyles;
    hoverCard: CSSProperties;
}

/**
 * Hook that returns the styles object used for the ListView.
 * @returns {ListViewStyles} The styles object.
 */
function useListViewStyles(): ListViewStyles {
    return {
        container: {
            root: {
                height: "100%",
            },
        },
        hoverCard: {
            maxWidth: "750px",
            display: "flex",
            justifyContent: "center",
            border: "1px solid",
            padding: "5px",
            fontSize: "12px",
            whiteSpace: "pre-line",
        },
    };
}

export { useListViewStyles };
