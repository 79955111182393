import { useEffect, useMemo, useState } from "react";
import { RelationshipTypePickerDefinition } from "../types/RelationshipTypePickerDefinition";
import { RelationshipType } from "../types/RelationshipType";
import { isNil } from "lodash";
import { IDropdownOption } from "@fluentui/react";

type UseRelationshipTypePickerDataProps = {
    /** Initially selected RelationshipType. */
    relationshipType: RelationshipType | undefined;
    /** Relationship's source Metamodel Item id. */
    sourceMetamodelItemId: string | undefined;
    /** Relationship's target Metamodel Item id. */
    targetMetamodelItemId: string | undefined;
    /** Flag that indicates whether RelationshipType can be edited. */
    isEditable: boolean;
    /** Available relationship types retriever. */
    relationshipTypesRetriever?: (
        sourceMetamodelItemId: string | undefined,
        targetMetamodelItemId: string | undefined
    ) => RelationshipType[];
    /** Event executed when new RelationshipType has been selected. */
    onRelationshipTypeSelected?: (relationshipTypeId: string) => void;
};

type UseRelationshipTypePickerDataOutput = RelationshipTypePickerDefinition;

/**
 * Hook for retrieving relationship type picker data.
 * @param {UseRelationshipTypePickerDataProps} props - The props.
 * @returns {UseRelationshipTypePickerDataOutput} The relationship type picker data.
 */
export const useRelationshipTypePickerData = ({
    relationshipType,
    sourceMetamodelItemId,
    targetMetamodelItemId,
    isEditable,
    relationshipTypesRetriever,
    onRelationshipTypeSelected,
}: UseRelationshipTypePickerDataProps): UseRelationshipTypePickerDataOutput => {
    const [relationshipTypeState, setRelationshipTypeState] = useState<{ relationshipTypeId: string | undefined }>({
        relationshipTypeId: relationshipType?.id,
    });

    useEffect(() => {
        setRelationshipTypeState({ relationshipTypeId: relationshipType?.id });
    }, [relationshipType, isEditable]);

    const changeRelationshipType = (relationshipTypeId: string | undefined): void => {
        if (!isNil(onRelationshipTypeSelected) && !isNil(relationshipTypeId)) {
            onRelationshipTypeSelected(relationshipTypeId);
        }
        setRelationshipTypeState({ relationshipTypeId });
    };

    const availableRelationshipTypes = useMemo(() => {
        if (isNil(relationshipTypesRetriever)) {
            return [];
        }

        return relationshipTypesRetriever(sourceMetamodelItemId, targetMetamodelItemId);
    }, [relationshipTypesRetriever, sourceMetamodelItemId, targetMetamodelItemId]);

    const relationshipTypesDropdownOptions: IDropdownOption[] = useMemo(() => {
        return availableRelationshipTypes.map(
            (relationshipType): IDropdownOption => ({
                key: relationshipType.id,
                text: relationshipType.directionName,
            })
        );
    }, [availableRelationshipTypes]);

    const selectedRelationshipType =
        availableRelationshipTypes.find((r) => r.id === relationshipTypeState.relationshipTypeId) ?? relationshipType;

    return {
        relationshipTypeId: relationshipTypeState.relationshipTypeId,
        availableRelationshipTypes: relationshipTypesDropdownOptions,
        color: selectedRelationshipType?.color ?? "california",
        direction: selectedRelationshipType?.direction,
        isEditable,
        changeRelationshipType,
    };
};

export type { UseRelationshipTypePickerDataProps, UseRelationshipTypePickerDataOutput };
