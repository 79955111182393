import { Toggle, Stack, StackItem, TooltipHost, Icon } from "@fluentui/react";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import React, { useCallback, useState } from "react";
import cx from "classnames";

import { useStyles } from "./Choice.styles";
import { DEFAULT_OFFSET } from "./Choice.constants";
import { getLeafDataEntries, getNestedOffsetStepWithChildren, selectedChoices, totalChoices } from "./Choice.utils";
import { ExpandCollapse } from "../ExpandCollapse";
import { IChoiceDataEntry } from "./IChoiceDataEntry";
import { IChoiceTreeModel } from "./IChoiceTreeModel";

/**
 * The props for the {@link Choice} component.
 */
export interface IChoiceValueProps<T extends IChoiceDataEntry> {
    choiceTreeModel: IChoiceTreeModel<T>;
    nestedLevelOffset?: number;
    editMode?: boolean;
    expanded?: boolean;
    onChoiceChange(leafChoicesToUpdate: T[], newSelectionValue: boolean): void;
    hideChoiceCount?: boolean;
    partialSelectionEnabled?: boolean;
    eventTrackingLabel?: string;
    childrenIndependentFromParent?: boolean;
}

/**
 * This component accepts choiceTreeModel, displays Choice tree.
 * When user changes choice, it calls onChoiceChange specifying which leaf choices data entries changed
 * and what is their new selected value.
 *
 * Logical assumption is that only leaf choice data entries dictate selection state of parent entries in order to avoid
 * functional dependency in data.
 * @param {IChoiceValueProps<IChoiceDataEntry>} props The component props.
 * @returns {JSX.Element} The react element.
 */
export function Choice({
    editMode = false,
    expanded = false,
    choiceTreeModel,
    nestedLevelOffset = DEFAULT_OFFSET,
    onChoiceChange,
    hideChoiceCount = false,
    partialSelectionEnabled = false,
    eventTrackingLabel,
    childrenIndependentFromParent = false,
}: IChoiceValueProps<IChoiceDataEntry>): JSX.Element | null {
    const [isExpanded, setIsExpanded] = useState(expanded);
    const {
        choiceItem,
        choiceTotalCounter,
        choiceChildWrapper,
        choiceDescription,
        choiceToggleContainer,
        choiceToggle,
        choiceIconReadOnly,
        partiallySelectedChoiceItem,
    } = useStyles();

    const appInsights = useAppInsightsContext();

    const onChoiceSelection = useCallback(() => {
        const allAffectedLeafDataEntries = childrenIndependentFromParent
            ? [choiceTreeModel.dataEntry]
            : getLeafDataEntries(choiceTreeModel);

        const newSelectionValue = !choiceTreeModel.selected;

        onChoiceChange(allAffectedLeafDataEntries, newSelectionValue);

        if (eventTrackingLabel !== null && eventTrackingLabel !== undefined) {
            appInsights.trackEvent({ name: eventTrackingLabel });
        }
    }, [appInsights, choiceTreeModel, eventTrackingLabel, onChoiceChange, childrenIndependentFromParent]);

    if (!editMode && !choiceTreeModel.selected) {
        return null;
    }

    const hasChildren = choiceTreeModel.children.length > 0;
    const hasDescription =
        choiceTreeModel.description !== undefined &&
        choiceTreeModel.description !== null &&
        choiceTreeModel.description !== "";

    const actions = (
        <Stack data-test-id={choiceTreeModel.name} horizontal verticalAlign="center">
            <ExpandCollapse isExpanded={isExpanded} setIsExpanded={setIsExpanded} hasChildren={hasChildren} />
            <StackItem className={choiceToggleContainer}>
                <Toggle
                    inlineLabel
                    label={<span title={choiceTreeModel.name}>{choiceTreeModel.name}</span>}
                    data-selected={choiceTreeModel.selected}
                    data-test-id={`choice-icon-${choiceTreeModel.key}`}
                    checked={choiceTreeModel.selected}
                    disabled={choiceTreeModel.disabled}
                    className={cx(choiceToggle, {
                        [choiceIconReadOnly]: !editMode,
                        [partiallySelectedChoiceItem]:
                            choiceTreeModel.partialSelected === true && partialSelectionEnabled,
                    })}
                    onClick={onChoiceSelection}
                />
            </StackItem>

            {hasDescription && (
                <StackItem className={choiceDescription}>
                    <TooltipHost content={choiceTreeModel.description}>
                        <Icon iconName="Info" aria-label={choiceTreeModel.description} />
                    </TooltipHost>
                </StackItem>
            )}

            {hasChildren && !hideChoiceCount && (
                <StackItem>
                    <span className={choiceTotalCounter}>
                        {`${selectedChoices(choiceTreeModel.children)}/${totalChoices(choiceTreeModel.children)}`}
                    </span>
                </StackItem>
            )}
        </Stack>
    );

    return (
        <>
            <Stack
                data-test-id={`choice-id-${choiceTreeModel.key}`}
                style={{ paddingLeft: `${nestedLevelOffset}rem` }}
                className={choiceItem}
            >
                {actions}
            </Stack>

            {isExpanded && (
                <div className={choiceChildWrapper} data-test-id="childChoices">
                    {choiceTreeModel.children.map((childChoice) => (
                        <Choice
                            key={childChoice.key}
                            editMode={editMode}
                            nestedLevelOffset={getNestedOffsetStepWithChildren(nestedLevelOffset, hasChildren)}
                            choiceTreeModel={childChoice}
                            onChoiceChange={onChoiceChange}
                            hideChoiceCount={hideChoiceCount}
                            partialSelectionEnabled={partialSelectionEnabled}
                        />
                    ))}
                </div>
            )}
        </>
    );
}
