import { IStackStyles, ITextStyles, useTheme } from "@fluentui/react";

export interface EmptyContentStyle {
    container: Partial<IStackStyles>;
    title: Partial<ITextStyles>;
    description: Partial<ITextStyles>;
}

/** @returns {EmptyContentStyle} EmptyContent component styles. */
export function useStyles(): EmptyContentStyle {
    const { spacing, fonts } = useTheme();

    return {
        container: {
            root: {
                height: "70%",
            },
        },
        title: {
            root: {
                fontSize: fonts.xLarge.fontSize,
                fontWeight: fonts.xLarge.fontWeight,
            },
        },
        description: {
            root: {
                marginBottom: spacing.s1,
                width: "60%",
                textAlign: "center",
            },
        },
    };
}
