import React from "react";
import { Stack, Text } from "@fluentui/react";
import { useNotFoundStyles } from "./NotFound.styles";

export type NotFoundProps = {
    /** The title. */
    title: string;

    /** The subtitle. */
    subtitle: string;

    /** The context. */
    content: string;

    /** Optional child component. */
    children?: React.ReactNode;
};

/**
 * Renders the NotFound component.
 *@param {NotFoundProps} props The component props.
 * @returns {JSX.Element} The React component.
 */
export function NotFound({ title, subtitle, content, children }: NotFoundProps): JSX.Element {
    const styles = useNotFoundStyles();

    return (
        <Stack styles={styles.container}>
            <Stack horizontalAlign="center" verticalAlign="center" styles={styles.header} disableShrink>
                <Text as="h1" styles={styles.subTitle}>
                    {subtitle}
                </Text>
                <Text as="h1" styles={styles.title}>
                    {title}
                </Text>
                <Text styles={styles.content}>{content}</Text>
                {children}
            </Stack>
        </Stack>
    );
}
