import { noop } from "lodash";
import React, { useCallback } from "react";
import {
    Stack,
    IStackStyles,
    IButtonStyles,
    ITextFieldProps,
    TextField as TextInput,
    IRenderFunction,
} from "@fluentui/react";
import { useControllableValue } from "@fluentui/react-hooks";
import { useValidation, ComponentValidationProps } from "../utils/validation";
import { getValidationRules, getDisplayValidationError } from "./TextField.service";
import { ILabeledFieldProps } from "../types";
import { getDefaultStylesFunction } from "./TextField.styles";

/**
 * The props for the {@link TextFieldBase: TextField} component.
 * Same props as in @fluentui/react also supported
 */
interface TextFieldProps extends ITextFieldProps, ILabeledFieldProps, ComponentValidationProps<string | undefined> {
    /** Styles for text field's Stack wrapper. */
    textFieldWrapperStyles?: IStackStyles;
    /** Styles for clear button */
    clearIconStyles?: IButtonStyles;
    /** Whether the value should be validated based on string requirements */
    validateOnChange?: boolean;
    /** Default value, overrides fluent ui's TextField component default prop, so that component will be controlled */
    initialValue?: string;
    /** Minimum allowed value */
    min?: number;
    /** Maximum allowed value */
    max?: number;
    /** Disallowed characters */
    invalidChars?: string[];
    /** Minimum allowed words length */
    minWords?: number;
    /** A rendering function that allows rendering custom label. */
    onRenderLabel?: IRenderFunction<ITextFieldProps>;
}

/**
 * Renders a TextField component consisting of a value and a label.
 * @param {TextFieldProps} textFieldProps The component props.
 * @returns {JSX.Element} The react element.
 */
export function TextField(textFieldProps: React.PropsWithChildren<TextFieldProps>): JSX.Element {
    const {
        min,
        max,
        invalidChars,
        minWords,
        value,
        styles,
        onChange,
        initialValue,
        readOnly = false,
        validationRules = [],
        textFieldWrapperStyles,
        required: isRequired = false,
        onValidationStateChange = noop,
        validationErrorMessageProvider = (error) => error.message,
        borderless,
        onRenderLabel,
    } = textFieldProps;

    const [valueState, setValueState] = useControllableValue(value, initialValue, onChange);

    const onFieldChange = useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) =>
            setValueState(newValue, event),
        [setValueState]
    );

    const {
        validationErrors,
        validationErrorMessages: [validationErrorToDisplay],
    } = useValidation({
        value: valueState,
        rules: getValidationRules({ validationRules, isRequired, min, max, invalidChars, minWords }),
        onValidationStateChange,
        validationErrorMessageProvider,
    });

    const displayValidationError = getDisplayValidationError({
        validationErrors,
        readOnly,
        isDefaultValue: value === initialValue,
    });

    const textInputProps = {
        ...textFieldProps,
        children: undefined,
    };

    return (
        <Stack styles={{ ...textFieldWrapperStyles }}>
            {textFieldProps.children}
            <TextInput
                autoComplete="off"
                styles={styles ?? getDefaultStylesFunction(readOnly)}
                errorMessage={displayValidationError ? validationErrorToDisplay : undefined}
                {...textInputProps}
                value={valueState}
                onChange={onFieldChange}
                borderless={borderless !== undefined ? borderless : readOnly}
                onRenderLabel={onRenderLabel}
            />
        </Stack>
    );
}

export type { TextFieldProps };
