import { SettingsData, SettingsDataResponse } from "./types";

/**
 * Maps settings and flattens features to a single entity.
 * @param {SettingsDataResponse | undefined} settings iServer365 settings.
 * @returns {SettingsData | undefined} Mapped settings.
 */
export function mapSettings(settings: SettingsDataResponse | undefined): SettingsData | undefined {
    if (settings === undefined) {
        return undefined;
    }

    const features = Object.assign({}, ...settings.features.map((f) => ({ [f.name]: f.value })));

    return { ...settings, features };
}
