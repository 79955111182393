import { wrap } from "lodash";
import React from "react";

import { CurrentStyle } from "../types";
import { Option } from "../Option";

interface HandleClickParams {
    currentStyle: keyof typeof CurrentStyle;
    onChange(value: keyof typeof CurrentStyle, color: string | undefined): void;
}

interface MapColorOptionsParams {
    colors: string[];
    currentSelectedColor?: string;
    currentStyle: keyof typeof CurrentStyle;
    onChange(value: keyof typeof CurrentStyle, color: string | undefined): void;
}

/**
 * @param {HandleClickParams} params {@link handleClick} params.
 * @param {string} color Color that was clicked.
 * @returns {undefined}
 */
export function handleClick({ currentStyle, onChange }: HandleClickParams, color: string): void {
    onChange(currentStyle, color);
}

/**
 * @param {MapColorOptionsParams} params {@link mapColorOptions} params.
 * @returns {JSX.Element[]} The {@link Option} components to render.
 */
export function mapColorOptions({
    colors,
    currentSelectedColor,
    currentStyle,
    onChange,
}: MapColorOptionsParams): JSX.Element[] {
    return colors.map((c, index) => (
        <Option
            disabled={false}
            value={c}
            key={index}
            className="rdw-colorpicker-option"
            activeClassName="rdw-colorpicker-option-active"
            active={currentSelectedColor === c}
            onClick={wrap(currentStyle, onChange)}
        >
            <span style={{ backgroundColor: c }} className="rdw-colorpicker-cube" />
        </Option>
    ));
}
