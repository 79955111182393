import React from "react";
import { Icon } from "@fluentui/react";
import { Icon as IconType, SVGIcon } from "../../SVGIcon";
import { useBreadcrumbIconStyles } from "./BreadcrumbIcon.styles";
import { isIconType } from "./utils";
import { isString } from "lodash";

type BreadcrumbIconProps = {
    icon: IconType | string;
};

/**
 * Renders the icon for the BreadcrumbItem.
 * @param {BreadcrumbIconProps} props - The props for the BreadcrumbIcon component.
 * @returns {JSX.Element} The rendered JSX element.
 */
function BreadcrumbIcon({ icon }: BreadcrumbIconProps): JSX.Element {
    const { textIcon } = useBreadcrumbIconStyles();

    if (isIconType(icon)) {
        return <SVGIcon icon={icon} />;
    }

    if (isString(icon)) {
        return <Icon styles={textIcon} iconName={icon} />;
    }

    return <></>;
}

export { BreadcrumbIcon };
export type { BreadcrumbIconProps };
