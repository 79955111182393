import { AppError } from "single-spa";
import { navigateToAppErrorUrl } from "./eventHandlers";
import { MFENames } from "./mfeNames";

/**
 * Handles errors during execution of the MFE's lifecycle or activity functions.
 * @param {AppError} error Application error.
 */
export function handleMFEError({ appOrParcelName }: AppError): void {
    const isTopLevelMFE = appOrParcelName === MFENames.Topbar || appOrParcelName === MFENames.Sidebar;

    if (isTopLevelMFE) {
        navigateToAppErrorUrl();
    }
}
