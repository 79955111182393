import { v4 as newGUID } from "uuid";
import { HyperLinkFieldValue } from "./Hyperlink.interface";

function createEmptyRow() {
    return { displayValue: "", value: "", key: newGUID(), isDisplayValueValid: true, isValueValid: true };
}

/**
 * Delete a value entry from values
 * @param {number} index The index of the item to be deleted.
 * @param {HyperLinkFieldValue[]} values The values.
 * @returns {HyperLinkFieldValue[]} The updated values
 */
function deleteFromValues(index: number, values: HyperLinkFieldValue[]): HyperLinkFieldValue[] {
    const newValues = [...values];
    newValues.splice(index, 1);

    // if last element has been removed, let's put an empty one
    // to make the user experience better.
    if (newValues.length === 0) {
        newValues.push(createEmptyRow());
    }

    return newValues;
}

/**
 * Add a new empty value in the values
 * @param {HyperLinkFieldValue[]} values The values.
 * @returns {HyperLinkFieldValue[]} The updated values
 */
function addNewValue(values: HyperLinkFieldValue[]): HyperLinkFieldValue[] {
    const newValues = [...values];
    newValues.push(createEmptyRow());
    return newValues;
}

/**
 * Get values with a GUID key property.
 * @param {HyperLinkFieldValue[]} values The values.
 * @returns {HyperLinkFieldValue[]} The updated values
 */
function getKeyedValues(values: HyperLinkFieldValue[]): HyperLinkFieldValue[] {
    {
        return values.map((item) => {
            return {
                isDisplayValueValid: true,
                isValueValid: true,
                ...item,
                key: newGUID(),
            };
        });
    }
}

/**
 * Get single or multiple values.
 * @param {HyperLinkFieldValue[]} values The values.
 * @param {boolean} allowMultipleValue Is single or multiple values
 * @returns {HyperLinkFieldValue[]} The updated values
 */
function getSingleOrMultipleValues(values: HyperLinkFieldValue[], allowMultipleValue: boolean): HyperLinkFieldValue[] {
    const newValues = values.length === 0 ? addNewValue(values) : values;
    return allowMultipleValue ? newValues : newValues.slice(0, 1);
}

/**
 * Get values that can be shown in read only mode.
 * @param {HyperLinkFieldValue[]} values The values.
 * @returns {HyperLinkFieldValue[]} The values that can be shown in read only mode
 */
function getReadOnlyValues(values: HyperLinkFieldValue[]): HyperLinkFieldValue[] {
    return values.filter((item) => item.value !== undefined && item.value.length > 0);
}

/**
 * Get values without the key.
 * @param {HyperLinkFieldValue[]} values The values.
 * @returns {HyperLinkFieldValue[]} The values that can be shown in read only mode
 */
function getValuesWithoutKey(values: HyperLinkFieldValue[]): HyperLinkFieldValue[] {
    return values.map((item) => {
        return {
            displayValue: item.displayValue,
            isDisplayValueValid: item.isDisplayValueValid,
            isValueValid: item.isValueValid,
            value: item.value,
        };
    });
}

/**
 * Get value to display.
 * @param {string | undefined} displayValue The display value.
 * @param {string | undefined} value The value.
 * @param {boolean} displayValueRequired A value indicating whether displayValue is required.
 * @returns {string} The value to display
 */
function getValueToDisplay(
    displayValue: string | undefined,
    value: string | undefined,
    displayValueRequired: boolean
): string {
    return displayValueRequired ? displayValue ?? "" : value ?? "";
}
export {
    addNewValue,
    deleteFromValues,
    getKeyedValues,
    getSingleOrMultipleValues,
    getReadOnlyValues,
    getValuesWithoutKey,
    getValueToDisplay,
};
