import { TextField } from "@fluentui/react";
import React from "react";
import { DateTimeListItemField } from "../types";
import { CellProps } from "./CellProps";

/**
 * Cell for the list view which outputs data in a date-format, with support
 * via the {@link options: CellOptions} for formatting of the date.
 * @param {CellProps<DateTimeListItemField>} param The cell props.
 * @returns {React.FC<CellProps<DateTimeListItemField>>} The react component.
 */
export const DateTimeCell: React.FC<CellProps<DateTimeListItemField>> = ({
    editMode,
    field,
    onStopEditing,
    onValueChange,
    options,
}: CellProps<DateTimeListItemField>) => {
    const formattedValue = options.formatting.dateTime(field.text);

    if (editMode) {
        return (
            <TextField
                componentRef={(input) => {
                    input?.focus();
                }}
                value={formattedValue}
                onChange={(_, newValue) => onValueChange({ ...field, text: newValue ?? "" })}
                onBlur={onStopEditing}
                ariaLabel="Edit"
            />
        );
    }

    return <>{formattedValue}</>;
};
