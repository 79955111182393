import { listenEvent } from "iserver365-infrastructure-utility";
import { CustomProps, mountRootParcel, Parcel } from "single-spa";
import { getIframe, getErrorPageContainer } from "./iframeHelper";
import { resolveMFECSSProp } from "./mfeCSSPropsResolver";
import { EventTypes as AuthorizationEventTypes } from "iserver365-authorization-utility";
import { isNil } from "lodash";
import { ParcelDefinitions } from "./parcelDefinitions";

const HEADER_HEIGHT_CSS_VARIABLE = "--header-height";

const Status = {
    Mounted: "MOUNTED",
};

const ActiveParcels: Record<string, Parcel> = {};

function getParcelProperties(useIframe: boolean, data: unknown) {
    return {
        domElement: useIframe ? getIframe() : getErrorPageContainer(),
        marginTop: resolveMFECSSProp(HEADER_HEIGHT_CSS_VARIABLE),
        data,
    };
}

function clearParcel(parcelDefinition: { actionName: string; unmountEventName: string }, parcel: Parcel): void {
    if (parcel.getStatus() !== Status.Mounted) {
        return;
    }

    const removeForbiddenErrorListener = listenEvent(AuthorizationEventTypes.forbiddenError, async () => {
        await parcel.unmount();
        removeForbiddenErrorListener();
    });

    if (isNil(parcelDefinition.unmountEventName)) {
        return;
    }

    const removeListener = listenEvent(parcelDefinition.unmountEventName, async () => {
        await parcel.unmount();
        removeListener();
    });
}

/**
 * Mounts parcels by action name.
 * @param {string} action The name of action to resolve.
 * @param {object} data The data to be passed into mounted component.
 * @returns {Promise<void>} data parcel.
 */
export async function mountParcelAsync(action: string, data: unknown): Promise<void> {
    const parcelDefinition = ParcelDefinitions.filter((x) => x.actionName === action)[0];

    if (isNil(parcelDefinition)) {
        console.warn(`Could not load '${action}' parcel config.`);
        return;
    }

    const activeParcel = ActiveParcels[action];

    const parcelProps = getParcelProperties(parcelDefinition.useIframe, data);

    if (
        activeParcel !== undefined &&
        activeParcel.getStatus() === Status.Mounted &&
        activeParcel.update !== undefined
    ) {
        await activeParcel.update(parcelProps);
        return;
    }

    const parcelConfig = await parcelDefinition.import();

    if (parcelConfig === null) {
        return;
    }

    const MFEParcel = mountRootParcel<CustomProps>(parcelConfig, parcelProps);

    MFEParcel.mountPromise
        .then(() => {
            ActiveParcels[parcelDefinition.actionName] = MFEParcel;

            clearParcel(parcelDefinition, MFEParcel);
        })
        .catch(MFEParcel.unmount);
}
