import React, { createContext } from "react";
import { EventTypes } from "./types";
import { MessageCategory, listenEvent, raiseEvent } from "iserver365-infrastructure-utility";
import { Stack } from "@fluentui/react";
import { SomethingWentWrong, SomethingWentWrongProps } from "iserver365-component-library";
import { trackEvent } from "iserver365-amplitude";

export const ErrorBoundaryContext = createContext({
    /**
     * Set the ErrorBoundary error.
     * @param {string} error The error message.
     * @returns {void} void.
     */
    setError: (error: string) => {
        // eslint-disable-next-line no-console
        console.log(error);
    },
});

export interface CustomErrorEventConfig extends SomethingWentWrongProps {
    /** Error event type */
    eventType: string;
}

export interface IServer365ErrorBoundaryProps {
    /** Custom error event configurations */
    customErrorEventConfig?: CustomErrorEventConfig;
}

/**
 * Captures unhandled errors and displays a toast within the children scope.
 * Allows optionally listening for custom error event and displays error message when it occurs.
 */
export class IServer365ErrorBoundary extends React.Component<IServer365ErrorBoundaryProps, { hasError: boolean }> {
    constructor(props: IServer365ErrorBoundaryProps) {
        super(props);

        this.state = { hasError: false };
    }

    componentDidCatch(error: Error): void {
        raiseEvent(EventTypes.BffResponseMessage, {
            messageCategory: MessageCategory.Error,
            messageString: error.message,
        });
    }

    componentDidMount(): void {
        const eventType = this.props.customErrorEventConfig?.eventType;
        if (eventType !== undefined) {
            listenEvent(eventType, () => this.setState({ hasError: true }));
        }
    }

    render(): JSX.Element {
        return (
            <>
                {this.state.hasError && this.props.customErrorEventConfig !== undefined ? (
                    <Stack data-test-id="errorContainer" verticalFill verticalAlign="center">
                        <SomethingWentWrong
                            {...this.props.customErrorEventConfig}
                            onRefresh={() => {
                                this.props.customErrorEventConfig?.onRefresh?.();
                                this.setState({ hasError: false });
                            }}
                            onTrackEvent={() =>
                                trackEvent("Error Occurred", undefined, {
                                    Name: this.props.customErrorEventConfig?.headerText,
                                    Description: this.props.customErrorEventConfig?.contentText,
                                })
                            }
                        />
                    </Stack>
                ) : (
                    this.props.children
                )}
            </>
        );
    }
}
