import { useCallback, useEffect, useState } from "react";
import { raiseEvent } from "iserver365-infrastructure-utility";
import { EventTypes } from "../events";

function raiseNavigatorUnsavedChanges(value: boolean, stateChangeWithinTheSameGroup = undefined) {
    raiseEvent(EventTypes.setUnsavedChangesEventType, { value, stateChangeWithinTheSameGroup });
}

export interface UseUnsavedChangesData {
    hasUnsavedChanges: boolean;
    setHasUnsavedChanges: (value: boolean, stateChangeWithinTheSameGroup?: boolean) => void;
}

/**
 * A React hook that specifies whether unsaved changes are present.
 * @returns {UseUnsavedChangesData} Object with `hasUnsavedChanges` value and callback `setHasUnsavedChanges` to change it.
 */
export function useUnsavedChanges(): UseUnsavedChangesData {
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

    /** Synchronize `hasUnsavedChanges` and global navigator state. */
    const setHasUnsavedChangesCallback = useCallback((value: boolean, stateChangeWithinTheSameGroup = undefined) => {
        raiseNavigatorUnsavedChanges(value, stateChangeWithinTheSameGroup);
        setHasUnsavedChanges(value);
    }, []);

    /**
     * Set global navigator to state in which all changes are saved.
     * Executes once on unmount.
     */
    useEffect(() => () => raiseNavigatorUnsavedChanges(false), []);

    return {
        hasUnsavedChanges,
        setHasUnsavedChanges: setHasUnsavedChangesCallback,
    };
}
