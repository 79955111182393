import { LabelStyles } from "../LabelWrapper/types";
import React from "react";
import { ITextStyles, useTheme } from "@fluentui/react";

export type UseFieldLabelStyleOutput = {
    fieldLabel?: LabelStyles;
    asterisk?: React.CSSProperties;
    tagItem?: ITextStyles;
    tagContainerStyles?: React.CSSProperties;
};

/**
 * Styles for the FieldLabel component.
 * @returns {UseFieldLabelStyleOutput} The styles for the FieldLabel component.
 */
export const useFieldLabelStyle = (): UseFieldLabelStyleOutput => {
    const { spacing, palette, fonts, semanticColors } = useTheme();
    return {
        fieldLabel: {
            root: {
                display: "flex",
                alignItems: "center",
            },
        },
        asterisk: {
            color: semanticColors.errorText,
            marginLeft: spacing.s1,
        },
        tagItem: {
            root: {
                fontSize: fonts.xSmall.fontSize,
                fontWeight: fonts.xSmall.fontWeight,
                background: palette.neutralLighter,
                paddingTop: "2px",
                paddingBottom: "2px",
                paddingRight: "4px",
                paddingLeft: "4px",
                borderRadius: "2px",
                display: "flex",
                alignItems: "center",
                [":hover"]: {
                    textDecoration: "none",
                },
            },
        },
        tagContainerStyles: {
            paddingLeft: spacing.s1,
        },
    };
};
