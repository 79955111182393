import { useState, useEffect } from "react";
import { usePrevious } from "./usePrevious";

type CompareFn<T> = (prev?: T, current?: T) => boolean;

const defaultCompareFn: CompareFn<unknown> = (prev, next) => prev !== next;

/**
 * Determines whether the value has changed.
 * @template T The type of the value being passed.
 * @param {T} value The input value.
 * @param {CompareFn<T>} compareFn The function to compare previous and next values.
 * @param {boolean} stopTrackingOnFirstChange The flag idicating whether to stop tracking of value changes after hasChanged marked as true.
 * @returns {boolean} True if value has changed.
 */
export function useHasChanged<T>(
    value?: T,
    compareFn: CompareFn<T> = defaultCompareFn,
    stopTrackingOnFirstChange = true
): boolean {
    const prevValue = usePrevious(value, value);
    const [hasChanged, setHasChanged] = useState(false);

    useEffect(() => {
        if (hasChanged && stopTrackingOnFirstChange) {
            return;
        }

        setHasChanged(compareFn(prevValue, value));

        // Track only value changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return hasChanged;
}
