import { IDropdownStyles, IShimmerElement, IStackStyles, ShimmerElementType, useTheme } from "@fluentui/react";

type UseRelationshipPickerStyles = {
    container: IStackStyles;
    shimmerContainer: IStackStyles;
    shimmerElements: IShimmerElement[];
    relationshipDropdown: Partial<IDropdownStyles>;
    relationshipContainer: IStackStyles;
    relationshipInnerContainer: IStackStyles;
};

/**
 * Returns the styles for the RelationshipPicker component.
 * @param {boolean} isEditable - Flag that indicates whether the component is in edit mode.
 * @returns {UseRelationshipPickerStyles} The styles object.
 */
export const useRelationshipPickerStyles = (isEditable: boolean): UseRelationshipPickerStyles => {
    const { spacing, fonts, palette } = useTheme();
    return {
        container: {
            root: { alignItems: "center", justifyContent: "center" },
        },
        shimmerContainer: {
            root: {
                width: 844,
            },
        },
        shimmerElements: [
            { type: ShimmerElementType.line, height: 136, width: 297 },
            { type: ShimmerElementType.gap, width: spacing.m },
            { type: ShimmerElementType.line, height: 24, verticalAlign: "center", width: 250 },
            { type: ShimmerElementType.gap, width: spacing.m },
            { type: ShimmerElementType.line, height: 136, width: 297 },
        ],
        relationshipDropdown: {
            root: {
                paddingLeft: `calc(${spacing.s2} * 2)`,
                paddingRight: `calc(${spacing.s2} * 2)`,
                paddingBottom: spacing.s2,
                paddingTop: 0,
                width: "100%",
                boxSizing: "border-box",
            },
            label: {
                ...fonts.small,
                color: palette.neutralPrimary,
                fontWeight: 400,
            },
        },
        relationshipContainer: {
            root: {
                marginTop: spacing.l1,
                justifyContent: "center",
                alignItems: "center",
            },
        },
        relationshipInnerContainer: {
            root: {
                height: isEditable ? "116px" : "40px",
                alignItems: "center",
            },
        },
    };
};
