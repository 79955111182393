/* eslint-disable jsdoc/no-undefined-types */
/** Event types that this MFE is listening to */
export const EventTypes = {
    /**
     * BFF Operation Response Message event.
     *
     * This event accepts the data as {@link Message} type.
     *
     */
    BffResponseMessage: "bff-response-message",

    /**
     * Global error event
     *
     * This event raises when error occurs during communication with the server in top level MFEs such as Topbar or Sidebar
     */
    GlobalError: "global-error",
};
