/**
 * ValidationMessage enumeration for list of messageCodes:
 *  - required
 *  - valueOutOfRangeLowerOnly
 *  - valueOutOfRangeUpperOnly
 *  - valueOutOfRange
 *  - wrongUrlFormat
 *  - oneEntryNeedsToBeValid
 */
enum ValidationMessage {
    required = "The value is required.",
    valueOutOfRangeLowerOnly = "valueOutOfRangeLowerOnly",
    valueOutOfRangeUpperOnly = "valueOutOfRangeUpperOnly",
    valueOutOfRange = "valueOutOfRange",
    wrongUrlFormat = "Enter a valid URL.",
    oneEntryNeedsToBeValid = "One entry needs to be valid.",
}

/**
 * Defines a validation result.
 */
type ValidationResult = {
    /** Indicates if validation was successful. */
    isValid: boolean;

    /** Validation message code (in case validation fails). */
    validationMessage?: string;
};

/**
 * Describes a component which value could be required.
 */
interface RequiredValue {
    /** A value indicating whether the value is required. */
    isRequired?: boolean;
}

/**
 * Describes a component which value length can be bounded.
 */
interface BoundableLength {
    /** Minimum length. When not specified the value will not be validated. */
    minLength?: number;

    /** Maximum length. When not specified the value will not be validated. */
    maxLength?: number;
}

/**
 * Describes a component which value can be bounded.
 */
interface BoundableValue {
    /** Minimum value. When not specified the value will not be validated. */
    min?: number;

    /** Maximum value. When not specified the value will not be validated. */
    max?: number;
}

/**
 * Checks if the value is required.
 * @deprecated Use `src/utils/validation/` instead
 * @param {string} value - The current value.
 * @param {RequiredValue} options - The field options including whether the value is required.
 * @param {(messageCode: string, params?: Record<string, string>) => string} messageProvider - The validation error message provider.
 * @returns {ValidationResult} - The validation result.
 */
function validateRequiredValue(
    value: string | number | undefined | null,
    options: RequiredValue,
    messageProvider: (messageCode: string, params?: Record<string, string>) => string
): ValidationResult {
    const isValid = options?.isRequired === true ? Boolean(value) || value === 0 : true;

    return {
        isValid,
        validationMessage: !isValid ? messageProvider(ValidationMessage.required) : "",
    };
}

/**
 * Checks if the length of the value is within the minimum and maximum values.
 * @param {number} value - The current value length.
 * @param {BoundableLength} options - The field options including minimum and maximum lengths.
 * @param {(messageCode: string, params?: Record<string, string>) => string} messageProvider - The validation error message provider.
 * @returns {ValidationResult} - The validation result.
 */
function validateBoundableLength(
    value: number,
    options: BoundableLength,
    messageProvider: (messageCode: string, params?: Record<string, string>) => string
): ValidationResult {
    return validateBoundableValue(
        value,
        {
            min: options?.minLength,
            max: options.maxLength,
        },
        messageProvider
    );
}

/**
 * Checks if a numeric value is within the minimum and maximum values.
 * @param {number} value - The current value.
 * @param {BoundableValue} options - The field options including minimum and maximum values.
 * @param {(messageCode: string, params?: Record<string, string>) => string} messageProvider - The validation error message provider.
 * @returns {ValidationResult} - The validation result.
 */
function validateBoundableValue(
    value: number,
    options: BoundableValue,
    messageProvider: (messageCode: string, params?: Record<string, string>) => string
): ValidationResult {
    const hasMinRangeValidation = Boolean(options?.min);
    const hasMaxRangeValidation = Boolean(options?.max);

    const passesMinRangeValidation = !hasMinRangeValidation || value > (options?.min ?? 0);
    const passesMaxRangeValidation = !hasMaxRangeValidation || value < (options?.max ?? Number.MAX_VALUE);

    if (passesMinRangeValidation && passesMaxRangeValidation) {
        return {
            isValid: true,
        };
    }

    const validationErrorParams: Record<string, string> = {
        min: options?.min?.toString() ?? "",
        max: options?.max?.toString() ?? "",
    };

    if (hasMinRangeValidation && hasMaxRangeValidation) {
        return {
            isValid: false,
            validationMessage: messageProvider(ValidationMessage.valueOutOfRange, validationErrorParams),
        };
    }

    const errorMessage = hasMinRangeValidation
        ? ValidationMessage.valueOutOfRangeLowerOnly
        : ValidationMessage.valueOutOfRangeUpperOnly;

    return {
        isValid: false,
        validationMessage: messageProvider(errorMessage, validationErrorParams),
    };
}

export type { ValidationResult, RequiredValue, BoundableValue, BoundableLength };
export { ValidationMessage, validateRequiredValue, validateBoundableValue, validateBoundableLength };
