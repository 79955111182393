import { useTheme } from "@fluentui/react";
import { SidebarPanelStyles } from "./SidebarPanelStyles";

/**
 *  Returns styles for the Sidebar panel component.
 * @param {string} marginTop The margin top of the panel.
 * @returns {SidebarPanelStyles} The styles object.
 */
export function useStyles(marginTop: string): SidebarPanelStyles {
    const { spacing, palette, fonts } = useTheme();
    return {
        panel: {
            root: {
                marginTop,
                overflow: "hidden",
            },
            overlay: {
                cursor: "default",
            },
            scrollableContent: {
                display: "flex",
                flexDirection: "column",
                height: "100%",
            },
            commands: {
                paddingTop: 0,
            },
            navigation: {
                padding: 0,
                height: "auto",
            },
            content: {
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                overflowY: "auto",
            },
            footer: {
                borderTop: `1px solid ${palette.neutralLight}`,
                background: palette.white,
            },
        },
        header: {
            root: {
                padding: `${spacing.m} ${spacing.l1}`,
                width: "100%",
            },
        },
        headerText: {
            root: {
                fontSize: fonts.xLarge.fontSize,
                fontWeight: fonts.xLarge.fontWeight,
            },
        },
        cross: {
            root: {
                color: palette.neutralDark,
            },
            iconHovered: {
                color: palette.neutralDark,
            },
        },
    };
}
