import { makeStyles } from "@fluentui/react";

const useCardStyles = makeStyles((theme) => ({
    headerStyle: {
        display: "flex",
        alignItems: "center",
        marginBottom: theme.spacing.s2,
    },
    titleTextStyle: {
        fontFamily: theme.fonts.medium.fontFamily,
        fontSize: theme.fonts.medium.fontSize,
        fontWeight: 600,
        color: theme.palette.neutralPrimary,
        width: "100%",
    },
    iconContainer: {
        display: "flex",
        background: theme.palette.themePrimary,
        fontSize: theme.fonts.mediumPlus.fontSize,
        fontWeight: theme.fonts.mediumPlus.fontWeight,
        color: theme.palette.white,
        borderRadius: theme.effects.roundedCorner2,
        padding: theme.spacing.s1,
        marginRight: theme.spacing.s1,
    },
}));

export { useCardStyles };
