import React from "react";
import { Stack, StackItem, merge } from "@fluentui/react";
import { GroupSectionStyles, useGroupSectionStyles } from "./GroupSection.styles";
import { ExpandCollapse } from "../ExpandCollapse";

export type GroupSectionProps = {
    groupName: string;
    isExpanded: boolean;
    setIsExpanded: (expanded: boolean) => void;
    customStyles?: GroupSectionStyles;
};

/**
 *Renders the group section.
 * @param {GroupSectionProps} props The component props.
 * @returns {JSX.Element} The React component.
 */
export function GroupSection({
    groupName,
    isExpanded = false,
    setIsExpanded,
    customStyles,
    children,
}: React.PropsWithChildren<GroupSectionProps>): JSX.Element {
    const defaultStyles = useGroupSectionStyles();
    const styles = merge(defaultStyles, customStyles);

    return (
        <Stack horizontal styles={styles.groupContainer} tokens={styles.tokens}>
            <StackItem styles={styles.groupItem}>
                <ExpandCollapse
                    isExpanded={isExpanded}
                    text={groupName}
                    hasChildren={true}
                    setIsExpanded={setIsExpanded}
                    styles={styles.expandCollapseButton}
                />
            </StackItem>
            {isExpanded && children}
        </Stack>
    );
}
