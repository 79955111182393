import { makeStyles } from "@fluentui/react";

export const useNotificationCardHeaderStyles = makeStyles(({ palette, spacing, effects, fonts }) => ({
    notificationCardHeader: {
        width: "100%",
    },
    statusContainer: {
        paddingTop: spacing.s2,
        fontWeight: fonts.mega.fontWeight,

        i: {
            borderRadius: effects.roundedCorner2,
            color: palette.white,
            fontSize: fonts.small.fontSize,
            height: spacing.m,
            lineHeight: spacing.m,
            marginRight: spacing.s2,
            textAlign: "center",
            width: spacing.m,
        },
    },
    notificationTypeContainer: {
        color: palette.neutralSecondary,
        ...fonts.small,
    },
    detailsContainer: {
        ...fonts.small,
        color: palette.neutralDark,
        padding: spacing.s2,
    },
}));
