import { Link, TextField } from "@fluentui/react";
import React from "react";
import { useNavigationContext } from "../Navigation.context";
import { StateListItemField } from "../types";
import { CellProps } from "./CellProps";
import { isNil } from "lodash";
import { MouseButton } from "../../types";

type MouseEvent = React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLElement>;
/**
 * Cell for the list view which outputs a state - which is a link to page.
 * @param {CellProps<StateListItemField>} param The cell props.
 * @returns {React.FC<CellProps<StateListItemField>>} The react component.
 */
export const StateCell: React.FC<CellProps<StateListItemField>> = ({
    editMode,
    field,
    onStopEditing,
    onValueChange,
}: CellProps<StateListItemField>) => {
    const { navigateToUrl } = useNavigationContext();
    const handleLeftMouseButtonClick = (event: MouseEvent) => {
        event.preventDefault();
        const link = field.link;

        if (!isNil(link.onClick)) {
            link.onClick();
        } else if (link.blank === true) {
            window.open(link.href);
        } else {
            navigateToUrl(link.href);
        }
    };

    const handleMouseWheelClick = (event: MouseEvent) => {
        const link = field.link;

        if (!isNil(link.href)) {
            event.preventDefault();
            window.open(link.href);
        }
    };

    const handleMouseDown = (e: MouseEvent) => {
        if (e.button === MouseButton.Left) {
            handleLeftMouseButtonClick(e);
        } else if (e.button === MouseButton.Wheel) {
            handleMouseWheelClick(e);
        }
    };

    if (editMode) {
        return (
            <TextField
                componentRef={(input) => {
                    input?.focus();
                }}
                value={field.text ?? ""}
                onChange={(_, newValue) => onValueChange({ ...field, text: newValue ?? "" })}
                onBlur={onStopEditing}
                ariaLabel="Edit"
            />
        );
    }

    return <Link onMouseDown={handleMouseDown}>{field.text}</Link>;
};
