import { IButtonStyles, useTheme, ITextStyles, IStackStyles } from "@fluentui/react";

interface ShowMoreTextStyles {
    showButton: IButtonStyles;
    text: ITextStyles;
    stack: IStackStyles;
}

interface ShowMoreTextProps {
    isAllTextShown: boolean;
}

/**
 * Hook that returns the styles object used for the ListView.
 * @param {ShowMoreTextProps} props The styles component props.
 * @returns {ShowMoreTextStyles} The styles object.
 */
export function useStyles({ isAllTextShown }: ShowMoreTextProps): ShowMoreTextStyles {
    const { fonts, spacing } = useTheme();

    return {
        showButton: {
            root: {
                padding: isAllTextShown ? `${spacing.m} 0 0 0` : "0",
                height: "auto",
                fontSize: fonts.mediumPlus.fontSize,
            },
            label: {
                margin: 0,
            },
            textContainer: {
                whiteSpace: "nowrap",
            },
        },
        text: {
            root: {
                fontSize: fonts.mediumPlus.fontSize,
                wordBreak: "break-all",
            },
        },
        stack: {
            root: {
                minWidth: "0",
            },
        },
    };
}
