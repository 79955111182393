import React from "react";

/**
 * The props for the {@link withAppInsightsTagTrackerProps} component.
 */
export interface withAppInsightsTagTrackerProps {
    /**
     * @param {JSX.Element} component
     */
    component: JSX.Element;
    /**
     * @param {string} id
     */
    id: string;
}

/**
 * This is a withAppInsightsTagTracker component.
 * @param {withAppInsightsTagTrackerProps} props The component props.
 * @returns {JSX.Element} The react element.
 */
export function withAppInsightsTagTracker({ component, id }: withAppInsightsTagTrackerProps): JSX.Element {
    return <div data-is365-id={id}>{component}</div>;
}
