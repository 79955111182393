/* eslint-disable jsdoc/no-undefined-types */
import React from "react";
import { BreadcrumbTitle } from "./BreadcrumbTitle";
import { BreadcrumbIcon } from "./BreadcrumbIcon";
import { Stack, StackItem } from "@fluentui/react";
import { useBreadcrumbItemStyles } from "./BreadcrumbItem.styles";
import { BreadcrumbItemDefinition } from "../types";
import { isEmpty, isNil } from "lodash";
import { withLinkWrapper } from "../../LinkWrapper/withLinkWrapper";

/**
 * Renders a breadcrumb item.
 * This component is meant to be used inside the {@link Breadcrumb} component. It allows to render an icon next to Breadcrumb text.
 * @param {BreadcrumbItemDefinition} props - The breadcrumb item definition.
 * @returns {JSX.Element} The JSX element representing the breadcrumb item.
 */
function BreadcrumbItemBase(props: BreadcrumbItemDefinition): JSX.Element {
    const { text, icon, isCurrentItem, as } = props;
    const { container, iconContainer, textContainer } = useBreadcrumbItemStyles({ icon });

    return (
        <Stack horizontal tokens={{ childrenGap: 10 }} styles={container}>
            {!isNil(icon) && (
                <StackItem styles={iconContainer}>
                    <BreadcrumbIcon icon={icon} />
                </StackItem>
            )}
            {!isEmpty(text) && (
                <StackItem styles={textContainer}>
                    <BreadcrumbTitle text={text} isCurrentItem={isCurrentItem} as={as} />
                </StackItem>
            )}
        </Stack>
    );
}

export const BreadcrumbItem = withLinkWrapper(BreadcrumbItemBase);
