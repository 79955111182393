import React from "react";
import { Label, merge } from "@fluentui/react";
import { TagItemDefinition, Tags } from "../Tags";
import { UseFieldLabelStyleOutput, useFieldLabelStyle } from "./FieldLabel.styles";

export type FieldLabelProps = {
    label?: string;
    id?: string;
    htmlFor?: string;
    tags?: TagItemDefinition[];
    showAsterisk?: boolean;
    customStyles?: UseFieldLabelStyleOutput;
};

/**
 * Renders a label with a given tag.
 * @param {FieldLabelProps} props - The label props.
 * @returns {JSX.Element} The JSX element representing the label.
 */
export const FieldLabel = ({
    label,
    id,
    htmlFor,
    showAsterisk = false,
    tags = [],
    customStyles,
}: FieldLabelProps): JSX.Element => {
    const defaultStyles = useFieldLabelStyle();
    const styles = merge(defaultStyles, customStyles);

    return (
        <Label htmlFor={htmlFor} styles={styles.fieldLabel} id={id}>
            {label}
            {showAsterisk && <span style={styles.asterisk}> *</span>}
            {tags.length > 0 && (
                <div style={styles.tagContainerStyles}>
                    {<Tags items={tags.map((tag) => ({ ...tag, styles: defaultStyles.tagItem }))} />}
                </div>
            )}
        </Label>
    );
};
