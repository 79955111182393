import React from "react";
import { LinkTargetOption } from "../types";
import { isEmpty } from "lodash";
import { ILinkStyles, Link } from "@fluentui/react";

export type WithLinkWrapperProps<TProps> = TProps & {
    href?: string;
    target?: LinkTargetOption;
    linkStyles?: ILinkStyles;
    onClick?: (event: React.MouseEvent<HTMLElement>, item?: TProps) => void;
};

/**
 * Higher-order component that wraps a component with link functionality.
 * @template TProps - The props type of the component.
 * @param {React.FC<TProps>} Component - The component to be wrapped.
 * @returns {React.FC<WithLinkWrapperProps<TProps>>} - The wrapped component.
 */
export const withLinkWrapper =
    <TProps,>(Component: React.FC<TProps>) =>
    (props: WithLinkWrapperProps<TProps>): JSX.Element => {
        const { href, target, linkStyles, onClick } = props;

        const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
            if (onClick !== undefined) {
                onClick(e, props);
            }
        };

        if (!isEmpty(href)) {
            return (
                <Link href={props.href} target={target} styles={linkStyles} onClick={handleOnClick}>
                    <Component {...props} />
                </Link>
            );
        }

        return <Component {...props} />;
    };
