import { validateRequiredValue, ValidationMessage, ValidationResult } from "../validation.service";

function isUrlValid(url: string | undefined): url is string {
    const urlPattern = new RegExp(
        "^(https?:\\/\\/)?" + // validate protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR IP (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
            "(\\#[-a-z\\d_]*)?$",
        "i"
    ); // validate fragment locator
    return urlPattern.test(url ?? "");
}

/**
 * Validates Hyperlink value based on provided settings.
 * @param {string | undefined} value The value to validate.
 * @param {(messageCode: string, params?: Record<string, string>) => string} messageProvider Message formatter to format messages based on message code.
 * @param {boolean} required Is the value required
 * @returns {ValidationResult} Result if the value is valid.
 */
function validateUrl(
    value: string | undefined,
    messageProvider: (messageCode: string, params?: Record<string, string>) => string,
    required: boolean
): ValidationResult {
    if (!required && (value === undefined || value?.length === 0)) {
        return {
            isValid: true,
            validationMessage: "",
        };
    }

    const requiredValidation = validateRequiredValue(value ?? "", { isRequired: required }, messageProvider);

    if (!requiredValidation.isValid) {
        return requiredValidation;
    }

    const isValid = isUrlValid(value);

    return {
        isValid,
        validationMessage: !isValid ? messageProvider(ValidationMessage.wrongUrlFormat) : "",
    };
}
/**
 * Get Display Value error message on provided settings.
 * @param {string | undefined} displayValue The display value to validate.
 * @param {boolean} displayValueRequired A value indicating whether displayValue is required.
 * @param {(messageCode: string, params?: Record<string, string>) => string} messageProvider Message provider
 * @returns {string} error message.
 */
function getDisplayValueErrorMessage(
    displayValue: string | undefined,
    displayValueRequired: boolean,
    messageProvider: (messageCode: string, params?: Record<string, string>) => string
): string {
    if (!displayValueRequired) {
        return "";
    }

    const validation = validateRequiredValue(displayValue, { isRequired: displayValueRequired }, messageProvider);

    return validation.validationMessage ?? "";
}

/**
 * Get Value error message on provided settings.
 * @param {string | undefined} value The value to validate.
 * @param {string | undefined} displayValueRequired A value indicating whether displayValue is required.
 * @param {string | undefined} required A value indicating whether it is required.
 * @param {(messageCode: string, params?: Record<string, string>) => string} messageProvider Message provider
 * @returns {string} error message.
 */
function getValueErrorMessage(
    value: string | undefined,
    displayValueRequired: boolean,
    required: boolean,
    messageProvider: (messageCode: string, params?: Record<string, string>) => string
): string {
    const isRequired = displayValueRequired || required;
    const validation = validateUrl(value, messageProvider, isRequired);

    return validation.validationMessage ?? "";
}

export { getValueErrorMessage, getDisplayValueErrorMessage };
