import React from "react";

/**
 * Contact Support Icon.
 * @returns {JSX.Element} Contact Support Icon.
 */
const ContactSupportIcon = (): JSX.Element => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3002_25533)">
                <path
                    d="M6.24874 9.08832H2.30357C1.03138 9.08832 0 10.1197 0 11.3919V22.0268L2.44858 19.5782H14.7147C15.9869 19.5782 17.0183 18.5468 17.0183 17.2747V9.08832H6.2496H6.24874Z"
                    fill="#939BC1"
                />
                <path
                    d="M7.52372 1.84702H21.4202C22.8452 1.84702 24 3.00182 24 4.42678V16.3339L21.2588 13.5927H7.52458C6.09962 13.5927 4.94482 12.4379 4.94482 11.013V4.42591C4.94482 3.00096 6.09962 1.84616 7.52458 1.84616L7.52372 1.84702Z"
                    fill="url(#paint0_linear_3002_25533)"
                />
                <path
                    d="M17.2076 4.4238H11.7371C11.5058 4.4238 11.3184 4.61127 11.3184 4.84257C11.3184 5.07386 11.5058 5.26134 11.7371 5.26134H17.2076C17.4389 5.26134 17.6263 5.07386 17.6263 4.84257C17.6263 4.61127 17.4389 4.4238 17.2076 4.4238Z"
                    fill="white"
                />
                <path
                    d="M14.539 5.89157H11.7371C11.5058 5.89157 11.3184 6.07905 11.3184 6.31034C11.3184 6.5416 11.5058 6.72911 11.7371 6.72911H14.539C14.7703 6.72911 14.9578 6.5416 14.9578 6.31034C14.9578 6.07905 14.7703 5.89157 14.539 5.89157Z"
                    fill="white"
                />
                <path
                    d="M17.2076 8.79733H11.7371C11.5058 8.79733 11.3184 8.98481 11.3184 9.2161C11.3184 9.44736 11.5058 9.63487 11.7371 9.63487H17.2076C17.4389 9.63487 17.6263 9.44736 17.6263 9.2161C17.6263 8.98481 17.4389 8.79733 17.2076 8.79733Z"
                    fill="white"
                />
                <path
                    d="M14.539 10.2636H11.7371C11.5058 10.2636 11.3184 10.4511 11.3184 10.6824C11.3184 10.9137 11.5058 11.1012 11.7371 11.1012H14.539C14.7703 11.1012 14.9578 10.9137 14.9578 10.6824C14.9578 10.4511 14.7703 10.2636 14.539 10.2636Z"
                    fill="white"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_3002_25533"
                    x1="14.2879"
                    y1="16.2232"
                    x2="14.2879"
                    y2="1.68881"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#151E47" />
                    <stop offset="1" stopColor="#1B355C" />
                </linearGradient>
                <clipPath id="clip0_3002_25533">
                    <rect width="24" height="20.3077" fill="white" transform="translate(0 1.84616)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export { ContactSupportIcon };
