import { startsWith } from "lodash";

/**
 * Returns redirect url for `workspaces` when the feature is disabled.
 * @param {URL} url Source url.
 * @returns {string | undefined} Redirect url.
 */
export function getDisabledWorkspacesRedirectUrl(url: URL): string | undefined {
    if (startsWith(url.pathname, "/workspaces")) {
        return "/";
    }

    return undefined;
}
