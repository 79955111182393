const publicViewsPathname = "/visualizations/all";
const myViewsPathname = "/visualizations/my";
const savedLandscapeViewPathname = "/visualizations/landscape/view";
const newListViewPathname = "/visualizations/listview/content";
const legacyAppAllViewsPathName = "/views/all";
const legacyAppPublicViewsPathname = "/views/public";
const legacyAppMyViewsPathname = "/views/personal";
const legacyAppSavedLandscapeViewPathname = "/landscape/view";
const legacyAppNewListViewPathname = "/catalog/new/content";

function getPublicViewsUrl(): string {
    return publicViewsPathname;
}

function getMyViewsUrl(): string {
    return myViewsPathname;
}

function getSavedLandscapeViewUrl(url: URL): string | undefined {
    const pathSegments = url.pathname.split("/");
    const viewId = pathSegments[pathSegments.length - 1];
    if (viewId === undefined) {
        return undefined;
    }

    return `${savedLandscapeViewPathname}/${viewId}`;
}

function getSavedListViewUrl(url: URL): string | undefined {
    const viewId = getViewId(url, true);
    if (viewId === undefined) {
        return undefined;
    }

    return `${savedListViewPathname}/${viewId}`;
}

function getLegacyAppNewListViewUrl(url: URL): string {
    const objectTypeId = url?.searchParams.get("objectTypeId");
    return `${legacyAppNewListViewPathname}?objectTypeId=${objectTypeId}&page=1&pageSize=50&mode=List`;
}

function getViewId(url: URL, legacyUrl: boolean): string | undefined {
    const pathSegments = url.pathname.split("/");
    return pathSegments[pathSegments.length - (legacyUrl ? 2 : 1)];
}

function getLegacyAppPublicViewsUrl(): string {
    return legacyAppPublicViewsPathname;
}

function getLegacyAppMyViewsUrl(): string {
    return legacyAppMyViewsPathname;
}

function getLegacyAppSavedListViewUrl(url: URL): string {
    const viewId = getViewId(url, false);
    return `${legacyAppSavedListViewPathname}${viewId}/content?page=1&pageSize=50&mode=List`;
}

function startsWith(source: string, target: string): boolean {
    return source.toLowerCase().startsWith(target.toLowerCase());
}

export const legacyAppSavedListViewPathname = "/catalog/view/";
export const savedListViewPathname = "/visualizations/listview";

/**
 * Returns url from `Views` mfe to corresponding url in legacy app.
 * @param {URL} url Source url.
 * @returns {string | undefined} Redirect url.
 */
export function getViewsToLegacyAppRedirect(url: URL): string | undefined {
    if (startsWith(url.pathname, publicViewsPathname)) {
        return getLegacyAppPublicViewsUrl();
    }

    if (startsWith(url.pathname, myViewsPathname)) {
        return getLegacyAppMyViewsUrl();
    }

    if (startsWith(url.pathname, newListViewPathname)) {
        return getLegacyAppNewListViewUrl(url);
    }

    return undefined;
}

/**
 * Returns url from legacy app to corresponding url in `Views` mfe.
 * @param {URL} url The source url.
 * @returns {string | undefined} Redirect url.
 */
export function getLegacyAppToViewsRedirect(url: URL): string | undefined {
    if (startsWith(url.pathname, legacyAppAllViewsPathName)) {
        return getPublicViewsUrl();
    }

    if (startsWith(url.pathname, legacyAppPublicViewsPathname)) {
        return getPublicViewsUrl();
    }

    if (startsWith(url.pathname, legacyAppMyViewsPathname)) {
        return getMyViewsUrl();
    }

    return undefined;
}

/**
 * Returns url from legacy app to landscape view url in `Views` mfe.
 * @param {URL} url The source url.
 * @returns {string | undefined} Redirect url.
 */
export function getLegacyAppToLandscapeViewRedirect(url: URL): string | undefined {
    if (startsWith(url.pathname, legacyAppSavedLandscapeViewPathname)) {
        return getSavedLandscapeViewUrl(url);
    }

    return undefined;
}

/**
 * Returns url from legacy app to list view url in `Views` mfe.
 * @param {URL} url The source url.
 * @returns {string | undefined} Redirect url.
 */
export function getRedirectToBetaListView(url: URL): string | undefined {
    if (startsWith(url.pathname, legacyAppSavedListViewPathname)) {
        return getSavedListViewUrl(url);
    }

    return undefined;
}

/**
 * Returns url from legacy app to list view url in `Views` mfe.
 * @param {URL} url The source url.
 * @returns {string | undefined} Redirect url.
 */
export function getRedirectToLegacyListView(url: URL): string | undefined {
    if (startsWith(url.pathname, savedListViewPathname)) {
        return getLegacyAppSavedListViewUrl(url);
    }

    return undefined;
}
