import { IStackTokens, ITextFieldStyles, useTheme } from "@fluentui/react";

type UseChoicePickerStylesOutput = {
    containerTokens: IStackTokens;
    textField: Partial<ITextFieldStyles>;
};

/**
 * Returns the styles for the ChoicePicker component.
 * @returns {UseChoicePickerStylesOutput} The styles object.
 */
export const useChoicePickerStyles = (): UseChoicePickerStylesOutput => {
    const { spacing } = useTheme();
    return {
        containerTokens: {
            childrenGap: spacing.s1,
        },
        textField: {
            root: {
                paddingLeft: 0,
            },
        },
    };
};
