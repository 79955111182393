import React from "react";
import { ComboBox } from "@fluentui/react";
import { Condition, CategoryOption, FilterCategoryHandler, CategoryType } from "../QuickFilter.interfaces";
import { CategoryFilter } from "../useQuickFilterReducer";

export type FilterCategoryProps = {
    /** List of conditions */
    conditions: Condition[];

    /** Field of current row */
    category: CategoryFilter;

    /** Handler for current category */
    categoryHandler: FilterCategoryHandler;
};

/**
 * Category component
 * @param {FilterCategoryProps} props for category
 * @returns {JSX.Element} return React element
 */
export function FilterCategory({ conditions, category, categoryHandler }: FilterCategoryProps): JSX.Element {
    return (
        <ComboBox
            allowFreeform
            autoComplete="on"
            ariaLabel="Category"
            selectedKey={category.value}
            options={OptionsCreator(conditions)}
            onChange={(event, option) => {
                const categoryOption = option as CategoryOption;
                const value = categoryOption !== undefined ? categoryOption.key : null;
                const category = categoryOption !== undefined ? categoryOption.category : null;
                categoryHandler(value, category);
            }}
        />
    );

    function OptionsCreator(conditions: Condition[]) {
        const comboBoxOptions = [
            {
                key: "Related",
                text: "Related",
                itemType: 2,
            },
            {
                key: CategoryType.Relationship.toString(),
                category: CategoryType.Relationship,
                text: "Related to",
            },
            {
                key: "divider",
                text: "",
                itemType: 1,
            },
            {
                key: "Attributes",
                text: "Attributes",
                itemType: 2,
            },
        ];

        return comboBoxOptions.concat(
            conditions.map((c) => ({
                key: c.id,
                category: c.category,
                text: c.name,
            }))
        );
    }
}
