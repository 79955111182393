import { IButtonStyles, IStackStyles, ITextFieldStyles, useTheme } from "@fluentui/react";

interface SearchInputStyles {
    searchButton: Partial<IButtonStyles>;
    searchInput: Partial<ITextFieldStyles>;
    searchInputContainer: Partial<IStackStyles>;
}

/**
 *  Returns styles for the SearchInput component.
 * @returns {SearchInputStyles} The styles object.
 */
export const useStyles = (): SearchInputStyles => {
    const { palette } = useTheme();

    return {
        searchButton: {
            root: {
                minWidth: "unset",
                padding: "none",
            },
        },
        searchInput: {
            fieldGroup: {
                border: "none",
                ":focus": {
                    border: "none",
                },
                ":hover": {
                    border: "none",
                },
                ":after": {
                    border: "none",
                },
            },
        },
        searchInputContainer: {
            root: {
                border: "1px solid",
                borderColor: palette.neutralLight,
                borderRadius: "2px",
                height: "fit-content",
            },
        },
    };
};
