import { ApolloClient, createHttpLink, from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getAccessToken } from "iserver365-authentication-utility";
import { authorizationGuardLink } from "iserver365-authorization-utility";
import { EventTypes, getErrorHandlingLink, messageHandlerLink } from "iserver365-infrastructure-utility";
import { settings } from "../settings";
import { cache } from "./cache";

const httpLink = createHttpLink({
    uri: settings.bffUrl,
});

const authLink = setContext(async (_, { headers }) => {
    const token = await getAccessToken();
    return {
        headers: {
            ...headers,
            authorization: `Bearer ${token}`,
        },
    };
});

const errorHandlingLink = getErrorHandlingLink(EventTypes.GlobalError);

const apolloClient = new ApolloClient({
    name: "settingsClient",
    link: from([errorHandlingLink, messageHandlerLink, authLink, authorizationGuardLink, httpLink]),
    cache,
});

export { apolloClient };
