/**
 * Utility function that converts the {@link hex} color string with the {@link opacity} into
 * it's rgba equivalent value.
 * @param {string} hex The color string in hex.
 * @param {number} opacity The opacity to use for the rgba color.
 * @returns {string} The color string in rgba.
 */
function convertHexToRGBA(hex: string, opacity: number): string {
    if (hex === "" || opacity < 0) {
        return "rgba(0,0,0,0)";
    }

    const tempHex = hex.replace("#", "");
    const r = parseInt(tempHex.substring(0, 2), 16);
    const g = parseInt(tempHex.substring(2, 4), 16);
    const b = parseInt(tempHex.substring(4, 6), 16);

    return `rgba(${r},${g},${b},${opacity / 100})`;
}

export { convertHexToRGBA };
