import { IShimmerStyles } from "@fluentui/react";

export interface ShimmeredWidgetStyles {
    shimmer: IShimmerStyles;
}

/**
 * Returns styles for the ShimmeredWidget component.
 * @returns {ShimmeredWidgetStyles} ShimmeredWidget component styles.
 */
export function useStyles(): ShimmeredWidgetStyles {
    return {
        shimmer: {
            root: {
                width: "100%",
                height: "100%",
            },
            shimmerWrapper: {
                height: "100%",
            },
        },
    };
}
