import { makeStyles } from "@fluentui/react";

export const useStyles = makeStyles((theme) => ({
    choiceItem: {
        paddingRight: theme.spacing.m,

        "& .ms-Label": {
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            marginLeft: theme.spacing.s1,
            paddingTop: 0,
            paddingBottom: 0,
            ...theme.fonts.medium,
        },
        ":hover": {
            backgroundColor: theme.palette.neutralLighter,
        },
        ":hover .ms-Toggle:not(.is-disabled) .ms-Label": {
            color: theme.palette.themePrimary,
        },
    },
    partiallySelectedChoiceItem: {
        opacity: 0.5,
    },
    choiceBorder: {
        width: "1px",
        height: "100%",
        borderLeft: `1px dashed ${theme.palette.neutralQuaternary}`,
        position: "absolute",
        top: 0,
        bottom: 0,
        zIndex: 999,
    },
    choiceChildWrapper: {
        position: "relative",
    },
    choiceTotalCounter: {
        padding: theme.spacing.s2,
        borderRadius: theme.spacing.s2,
        backgroundColor: theme.palette.neutralQuaternary,
        color: theme.palette.black,
        marginLeft: theme.spacing.s2,
    },
    choiceDescription: {
        cursor: "default",
        paddingLeft: theme.spacing.s2,
    },
    choiceToggleContainer: {
        overflow: "hidden",
    },
    choiceToggle: {
        width: "100%",
        margin: "0",
        height: theme.spacing.l2,
        paddingTop: theme.spacing.s2,
        paddingBottom: theme.spacing.s2,

        "&:not(.is-disabled):hover .ms-Label": {
            cursor: "pointer",
        },
        "&:not(.is-disabled):hover .ms-Toggle-innerContainer": {
            cursor: "pointer",
        },
    },
    choiceIconReadOnly: {
        selectors: {
            ":hover": {
                background: "none",
                color: theme.semanticColors.primaryButtonBackground,
            },
            ":active": {
                background: "none",
                color: theme.semanticColors.primaryButtonBackground,
            },
        },
    },
}));
