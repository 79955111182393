import { raiseEvent } from "./events";
import { EventTypes, MessageCategory } from "./types";

/**
 * Raises the event with BFF success response message.
 * @param {string} messageString The message.
 */
export function raiseBffResponseSuccessMessage(messageString: string): void {
    raiseEvent(EventTypes.BffResponseMessage, {
        messageCategory: MessageCategory.Success,
        messageString,
    });
}
