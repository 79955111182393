import React, { useMemo } from "react";
import { isEmpty, isNil, merge, noop } from "lodash";
import { Text, Stack, IStackProps, ActionButton } from "@fluentui/react";
import { DisabledContext } from "./context";
import { useControllableValue } from "@fluentui/react-hooks";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { useValidation, ComponentValidationProps } from "../utils/validation";
import { RichTextFieldStyles, useStyles } from "./RichTextField.styles";
import {
    getDisplayValidationError,
    htmlToRichTextState,
    getValidationRules,
    fieldValueHasChanged,
    RichTextFieldState,
    Value,
} from "./RichTextField.service";
import { toolbarOptions } from "./toolbar";
import { useHasChanged } from "../utils/hooks";
import { HTMLContent } from "../HTMLContent";
import { stripHtmlTags } from "./RichTextField.utils";
import { ILabeledFieldProps } from "../types";
import { FieldLabel } from "../FieldLabel";
import { getSupportedLocale } from "../../../iserver365-globalization-utility/src";

type OnChange = (state: RichTextFieldState) => void;
interface RichTextFieldProps extends ILabeledFieldProps, ComponentValidationProps<Value> {
    /**
        Accessibility label, attached to the editor element
     */
    ariaLabel?: string;
    /**
        Sets the default html value of the editor. The value is parsed as HTML and converted into the
        underlying state object. For uncontrolled mode.
     */
    defaultHtmlValue?: string;
    /**
        If `value` is provided, component will behave as a controlled input.
     */
    value?: RichTextFieldState | null;
    /**
        Input placeholder when no value is provided.
     */
    placeholder?: string;
    /**
        Function called when the value of the input changes
     */
    onChange?: OnChange;
    /**
        Disables the input
     */
    disabled?: boolean;
    /**
     * The component styles
     */
    styles?: Partial<RichTextFieldStyles>;
    /**
        Requires a value to be input.  Input will be invalid if value is missing.
        NOTE: Validation is performed on text after all HTML tags and line breaks are removed
     */
    isRequired?: boolean;
    /**
        Requires a value of minimum length to be  input.  Input will be invalid if value does not have required length.
        NOTE: Validation is performed on text after all HTML tags and line breaks are removed
     */
    minLength?: number;
    /**
        Requires a value of maximum length to be  input.  Input will be invalid if value exceeds maximum length.
        NOTE: Validation is performed on text after all HTML tags and line breaks are removed
     */
    maxLength?: number;

    /**
     * Whether the button to clear all text is visible
     */
    hasClearAllAction?: boolean;

    /**
     * The label of button to clear all text
     */
    clearAllBtnLabel?: string;

    /**
        Attached to the editor wrapper.  This is used to generate consistent IDs for snapshot testing. You probably don't need to use this prop as a consumer of RichText.
     */
    wrapperId?: number;
    /**
        Props of the Stack container of the input
     */
    containerProps?: IStackProps;
    /** Enable readOnly mode */
    readOnly?: boolean;
}

/**
 * Renders a field component displaying a rich text editor in edit mode (default), or text with
 * HTML formatting applied in readOnly mode.
 * In edit mode, it shows an editing area with
 * "what-you-see-is-what-you-get" (WYSIWYG) support, with actions which can be triggered from
 * a toolbar.
 *
 * The toolbar actions support capabilities such as
 *
 * - Bold text
 * - underlining text
 * - italicizing text
 * - aligning text
 * - unordered and ordered lists
 * - inserting links
 * @param {RichTextFieldProps} props The component props.
 * @returns {JSX.Element} the react element.
 */
function RichTextField({
    ariaLabel = "Rich Text Editor",
    defaultHtmlValue: initialHtmlValue,
    value,
    label = "",
    placeholder = "",
    onChange = noop,
    onValidationStateChange = noop,
    disabled = false,
    isRequired = false,
    minLength,
    maxLength,
    validationRules = [],
    validationErrorMessageProvider = (error) => error.message,
    wrapperId,
    hasClearAllAction = false,
    clearAllBtnLabel,
    containerProps,
    styles,
    readOnly = false,
    onRenderLabel,
}: RichTextFieldProps): JSX.Element {
    const [richTextState, setRichTextStateState] = useControllableValue(
        value,
        htmlToRichTextState({ html: initialHtmlValue, moveSelectionToEnd: true }),
        // state should always be available
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        (_, s) => onChange(s!)
    );
    const currentLocale = getSupportedLocale();

    const { validationErrors, validationErrorMessages } = useValidation({
        value: richTextState?.toHtml(),
        rules: getValidationRules({ validationRules, isRequired, minLength, maxLength }),
        onValidationStateChange,
        validationErrorMessageProvider,
    });

    const hasChanged = useHasChanged(richTextState, fieldValueHasChanged);
    const validationErrorToDisplay: string | undefined = validationErrorMessages[0];
    const displayValidationError = getDisplayValidationError({
        validationErrors,
        disabled,
        hasChanged,
    });

    const defaultStyles = useStyles({ displayValidationError, disabled, readOnly });
    const mergedStyles = useMemo(() => merge(defaultStyles, styles), [defaultStyles, styles]);

    const clearAllText = () => setRichTextStateState(htmlToRichTextState());

    const labelComponent = () => {
        return !isNil(onRenderLabel) ? (
            onRenderLabel()
        ) : (
            <FieldLabel
                label={label}
                showAsterisk={isRequired && !disabled}
                customStyles={{ fieldLabel: mergedStyles.label }}
            />
        );
    };

    return readOnly ? (
        <>
            {labelComponent()}
            {!isNil(value) && !isEmpty(stripHtmlTags(value?.toHtml())) ? (
                <HTMLContent value={value.toHtml()}></HTMLContent>
            ) : (
                placeholder !== undefined && <Text styles={mergedStyles.placeholder}>{placeholder}</Text>
            )}
        </>
    ) : (
        <DisabledContext.Provider value={disabled}>
            <Stack styles={mergedStyles.richTextFieldWrapper}>
                <Stack horizontalAlign="space-between" {...containerProps}>
                    <Stack styles={mergedStyles.labelContainer}>
                        {labelComponent()}
                        {hasClearAllAction && (
                            <ActionButton
                                onClick={clearAllText}
                                styles={mergedStyles.clearAllBtn}
                                iconProps={{ iconName: "Delete" }}
                            >
                                {clearAllBtnLabel}
                            </ActionButton>
                        )}
                    </Stack>
                    <Stack styles={mergedStyles.editorContainer}>
                        <Editor
                            handlePastedText={() =>
                                false
                            } /* The `Editor` component has known opened issue on GitHub when pasting formatted values, https://github.com/jpuri/react-draft-wysiwyg/issues/498 the HTML that is generated is wrong, suggested to add `handlePastedText` setting. */
                            ariaLabel={
                                !disabled ? ariaLabel : " "
                            } /* NOTE: using an empty string here, as we don't want to set aria-label if the input is disabled.  Setting undefined or '' results in using the draft-js default value, instead of nothing. */
                            readOnly={disabled}
                            placeholder={placeholder}
                            editorState={richTextState?.editorState}
                            wrapperStyle={mergedStyles.wrapper}
                            editorStyle={mergedStyles.editor}
                            toolbarStyle={mergedStyles.toolbar}
                            onEditorStateChange={(state) => {
                                const newState = new RichTextFieldState(state);
                                setRichTextStateState(newState);
                            }}
                            toolbar={toolbarOptions}
                            wrapperId={wrapperId}
                            localization={{ locale: currentLocale }}
                        />
                    </Stack>
                </Stack>
                {displayValidationError && <p style={mergedStyles.error}>{validationErrorToDisplay}</p>}
            </Stack>
        </DisabledContext.Provider>
    );
}

export { RichTextField };
export type { OnChange, RichTextFieldProps };
