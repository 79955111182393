// import { raiseEvent } from "iserver365-infrastructure-utility";
import { merge, cloneDeep } from "lodash";
import { commonTranslation } from "./translation";

// import { EventTypes } from "./eventTypes";

type CommonTranslationType = typeof commonTranslation;
type Translation = Record<string, string>;

const defaultLocale = "en";

const supportedTranslationsLocales = ["en", "fr"];

// Temporary commented it. Uncomment it after add another method such as getLocale

// function setLocale(locale: string): void {
//     raiseEvent(EventTypes.localeChanged, { detail: { locale } });
// }

/**
 * Gets the current locale value being used.
 * This is currently using the default implementation of browser locale (with "en" as a fallback).
 * @returns {string} The locale string.
 */
function getCurrentLocale(): string {
    const isLanguageExist = Boolean(window?.navigator?.language);

    return isLanguageExist ? window.navigator.language.toLocaleLowerCase() : defaultLocale.toLocaleLowerCase();
}

/**
 * Returns locale if it is has supported translations, otherwise default one.
 * @returns {string} Supported locale.
 */
function getSupportedLocale(): string {
    const currentLocale = getCurrentLocale();
    return supportedTranslationsLocales.includes(currentLocale) ? currentLocale : defaultLocale;
}

/**
 * Creates a localized string object by merging the default translation files with the additional translation
 * specified by {@link additionalTranslation}.
 * @typedef TTranslation The type for the {@link additionalTranslation}.
 * @param {TTranslation} additionalTranslation The additional translation files to use.
 * @returns {CommonTranslationType & TTranslation} The combined default translation files with the {@link additionalTranslation}.
 */
function initLocalizedStrings<TTranslation = Record<string, Translation>>(
    additionalTranslation?: TTranslation
): CommonTranslationType & TTranslation {
    // clone deep the common translation as lodash mutates the first argument
    return merge(cloneDeep(commonTranslation), additionalTranslation);
}

export { initLocalizedStrings, getCurrentLocale, getSupportedLocale };
