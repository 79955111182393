import { Configuration, LogLevel, BrowserAuthOptions } from "@azure/msal-browser";
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import { trackMessage } from "./appInsights";
import { AuthModuleConfiguration } from "./authModule";

const defaultConfig = {
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
                if (containsPii) {
                    return;
                }

                switch (level) {
                    case LogLevel.Error:
                        trackMessage(message, SeverityLevel.Error);
                        return;
                    case LogLevel.Info:
                        trackMessage(message, SeverityLevel.Information);
                        return;
                    case LogLevel.Warning:
                        trackMessage(message, SeverityLevel.Warning);
                        return;
                }
            },
        },
    },
};

function createAuthOptions(authConfiguration: Record<string, string>): BrowserAuthOptions {
    return {
        clientId: authConfiguration.clientId,
        authority: `https://${authConfiguration.domain}/${authConfiguration.tenant}`,
    };
}

/**
 * Returns the {@link Configuration} for authenticating users using the @param challengeUrl to extract the tenant and client id.
 * @param {string} challengeUrl - The challenge url to use for which to extract the tenant and client id for msal.
 * @returns {Promise<AuthModuleConfiguration>} {@link AuthModuleConfiguration}
 */
export async function createConfiguration(challengeUrl: string): Promise<AuthModuleConfiguration> {
    const res = await fetch(challengeUrl, { headers: { Authorization: "Bearer" } });
    const wwwAuthHeader = res.headers.get("www-authenticate");

    if (wwwAuthHeader === null) {
        throw Error("Could not create auth configuration: No www header");
    }

    const regex =
        /(?:client_id="(?<clientId>.*?)")\s?,\s?(?:authorization_uri="(?:http(?:s)?:\/\/(?<domain>.*?)\/(?<tenant>.*?)\/(?<rest>.*?))")\s?,?\s?(?:sharepoint_scope="(?<sharepointScope>.*?)")?/gi;
    const authConfiguration = regex.exec(wwwAuthHeader)?.groups;

    if (authConfiguration === undefined) {
        throw Error("Could not create auth configuration: Invalid www header");
    }
    const msalConfiguration: Configuration = { ...defaultConfig, auth: createAuthOptions(authConfiguration) };

    return {
        msal: msalConfiguration,
        sharepointScope: authConfiguration.sharepointScope,
    };
}
