import { navigateToUrl } from "iserver365-navigator/api";

import { hideIframeContainer, initializeIframe, isInitialized, showIframeContainer } from "./iframeHelper";
import {
    addEventHandlers,
    initializeEvents,
    sendEventUnmountParcelLegacyError,
    sendLegacyCurrentVisibility,
    startNavigationChanges,
    stopNavigationChanges,
} from "./eventHandlers";
import { trackException } from "./appInsights";

/**
 * Bootstraps the legacy MFE. We need this lifecycle event for proper initialization
 * @returns {Promise} A promise for the bootstrap operation.
 */
function bootstrap(): Promise<void> {
    return Promise.resolve();
}

/**
 * Mounts the legacy MFE by adding event handlers and showing the iframe.
 * @returns {Promise} A promise for the mount operation.
 */
function mount(): Promise<void> {
    try {
        if (!isInitialized()) {
            initializeIframe();
        }
        showIframeContainer();
        sendLegacyCurrentVisibility(true);
    } catch (e) {
        trackException(e as Error);
        return Promise.reject();
    }

    startNavigationChanges();

    return Promise.resolve();
}

/**
 * Unmount the legacy MFE by removing event handlers and hiding the iframe.
 * @returns {Promise} A promise for the unmount operation.
 */
async function unmount(): Promise<void> {
    sendEventUnmountParcelLegacyError();
    sendLegacyCurrentVisibility(false);

    hideIframeContainer();

    stopNavigationChanges();

    return Promise.resolve();
}

export const legacyApp = {
    bootstrap,
    mount,
    unmount,
};

/**
 * Init legacy app iframe and events
 *
 */
export function initLegacyApp(): void {
    initializeIframe();
    initializeEvents(navigateToUrl);
    addEventHandlers();
}

export { sendEventToIframe } from "./iframeHelper";
