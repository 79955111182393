import React from "react";
import { Icon, Text } from "@fluentui/react";
import { useCardStyles } from "./Card.styles";
import { CardHeaderProps } from "./types";

/**
 * Renders the header of the card with optional icon and customized text.
 * @param {CardHeaderProps} props The component props.
 * @returns {JSX.Element} The React component.
 */
export function CardHeader(props: CardHeaderProps): JSX.Element {
    const classes = useCardStyles();

    return (
        <header className={classes.headerStyle}>
            {props.iconName !== undefined && (
                <div className={classes.iconContainer}>
                    <Icon iconName={props.iconName} />
                </div>
            )}
            <Text className={classes.titleTextStyle}>
                {props.onRenderHeaderTitle !== undefined ? props.onRenderHeaderTitle(props.title) : props.title}
            </Text>
        </header>
    );
}
