import createDOMPurify from "dompurify";

const allowedTargets = ["_self", "_blank"];
const sanitizer = createDOMPurify();

sanitizer.addHook("uponSanitizeAttribute", function (node, data) {
    const isLinkWithTarget =
        node.tagName === "A" && data.attrName === "target" && allowedTargets.includes(data.attrValue);
    if (!isLinkWithTarget) {
        return;
    }

    data.forceKeepAttr = true;

    if (data.attrValue === "_blank") {
        node.setAttribute("rel", "noopener");
    }
});

export { sanitizer };
