import { useModelItemPickerState } from "./useModelItemPickerState";
import { useModelItemPickerData } from "./useModelItemPickerData";
import { useRelationshipTypePickerData } from "./useRelationshipTypePickerData";
import { ModelItemPickerDefinition } from "../types/ModelItemPickerDefinition";
import { RelationshipTypePickerDefinition } from "../types/RelationshipTypePickerDefinition";
import { ModelItem } from "../types/ModelItem";
import { RelationshipType } from "../types/RelationshipType";
import { ModelItemType } from "../types/ModelItemType";
import { isNil } from "lodash";
import { Model } from "../types/Model";

type UseRelationshipPickerOutput = {
    /** Source ModelItem picker state. */
    sourceModelItemPicker: ModelItemPickerDefinition;
    /** Target ModelItem picker state. */
    targetModelItemPicker: ModelItemPickerDefinition;
    /** RelationshipType picker state. */
    relationshipTypePicker: RelationshipTypePickerDefinition;
    /** handler for Clear all action */
    clearPickers: () => void;
    /** Indicates if clear all option should be available. */
    displayClearAllOption: boolean;
};

type UseRelationshipPickerProps = {
    /** Model of the source object. It will be overridden when 'sourceItem.model' property is provided. */
    sourceModel: Model | undefined;
    /** Model of the target object. It will be overridden when 'targetItem.model' property is provided. */
    targetModel: Model | undefined;
    /** Initially selected source ModelItem. If no ModelItem is preset, value should be undefined. */
    sourceModelItem: ModelItem | undefined;
    /** Initially selected target ModelItem. If no ModelItem is preset, value should be undefined. */
    targetModelItem: ModelItem | undefined;
    /** Initially selected RelationshipType. If no RelationshipType is present, value should be undefined. */
    relationshipType: RelationshipType | undefined;
    /** Flag indicating whether the relationship source can be edited. */
    isSourceEditable?: boolean;
    /** Flag indicating whether the relationship target can be edited. */
    isTargetEditable?: boolean;
    /** Available Metamodel Items retriever. */
    metamodelItemsRetriever?: (
        relatedMetamodelItemId: string | undefined,
        relationshipTypeId: string | undefined,
        isSource: boolean
    ) => ModelItemType[];
    /** Available relationship types retriever. */
    relationshipTypesRetriever?: (
        sourceObjectTypeId: string | undefined,
        targetObjectTypeId: string | undefined
    ) => RelationshipType[];
    /** Available Model Items retriever. */
    modelItemsRetriever?: (searchText: string, metamodelItemId: string, modelId: string) => Promise<ModelItem[]>;
    /** Event executed when new source ModelItem has been selected. */
    onSourceModelItemSelected?: (modelItemId: string, metamodelItemId: string) => void;
    /** Event executed when new target ModelItem has been selected. */
    onTargetModelItemSelected?: (modelItemId: string, metamodelItemId: string) => void;
    /** Event executed when new RelationshipType has been selected. */
    onRelationshipTypeSelected?: (relationshipTypeId: string) => void;
};

/**
 * Hook for managing state of RelationshipPicker.
 * @param {UseRelationshipPickerProps} props - The props.
 * @returns {UseRelationshipPickerOutput} The relationship picker state object.
 */
export const useRelationshipPicker = ({
    sourceModel,
    targetModel,
    relationshipType,
    sourceModelItem,
    targetModelItem,
    isSourceEditable = false,
    isTargetEditable = false,
    metamodelItemsRetriever,
    relationshipTypesRetriever,
    modelItemsRetriever,
    onSourceModelItemSelected: onSourceObjectSelected,
    onTargetModelItemSelected: onTargetObjectSelected,
    onRelationshipTypeSelected,
}: UseRelationshipPickerProps): UseRelationshipPickerOutput => {
    const sourceModelItemState = useModelItemPickerState({
        model: sourceModel,
        modelItem: sourceModelItem,
        isEditable: isSourceEditable,
        onModelItemSelected: onSourceObjectSelected,
    });

    const targetModelItemState = useModelItemPickerState({
        model: targetModel,
        modelItem: targetModelItem,
        isEditable: isTargetEditable,
        onModelItemSelected: onTargetObjectSelected,
    });

    const isRelationshipTypeEditable =
        sourceModelItemState.isEditable || targetModelItemState.isEditable || isNil(relationshipType);

    const relationshipTypeState = useRelationshipTypePickerData({
        relationshipType,
        sourceMetamodelItemId: sourceModelItemState.selectedMetamodelItemId,
        targetMetamodelItemId: targetModelItemState.selectedMetamodelItemId,
        isEditable: isRelationshipTypeEditable,
        relationshipTypesRetriever,
        onRelationshipTypeSelected,
    });

    const sourceModelItemData = useModelItemPickerData({
        metamodelItemId: sourceModelItemState.selectedMetamodelItemId,
        relatedMetamodelItemId: targetModelItemState.selectedMetamodelItemId,
        relationshipTypeId: relationshipTypeState.relationshipTypeId,
        metamodelItemsRetriever,
        modelItemsRetriever,
        isSource: true,
    });

    const targetModelItemData = useModelItemPickerData({
        metamodelItemId: targetModelItemState.selectedMetamodelItemId,
        relatedMetamodelItemId: sourceModelItemState.selectedMetamodelItemId,
        relationshipTypeId: relationshipTypeState.relationshipTypeId,
        metamodelItemsRetriever,
        modelItemsRetriever,
        isSource: false,
    });

    const displayClearAllOption =
        (!isNil(sourceModelItemState.selectedMetamodelItemId) && sourceModelItemState.isEditable) ||
        (!isNil(targetModelItemState.selectedMetamodelItemId) && targetModelItemState.isEditable) ||
        (!isNil(relationshipTypeState.relationshipTypeId) &&
            (sourceModelItemState.isEditable || targetModelItemState.isEditable));

    const clearPickers = () => {
        if (sourceModelItemState.isEditable) {
            sourceModelItemState.changeMetamodelItem(undefined);
        }
        if (targetModelItemState.isEditable) {
            targetModelItemState.changeMetamodelItem(undefined);
        }
        if (isRelationshipTypeEditable) {
            relationshipTypeState.changeRelationshipType(undefined);
        }
    };

    return {
        sourceModelItemPicker: {
            ...sourceModelItemState,
            icon: sourceModelItemData.icon,
            availableMetamodelItems: sourceModelItemData.metamodelItemsDropdownOptions,
            getAvailableModelItems: sourceModelItemData.modelItemsDropdownOptions,
        },
        targetModelItemPicker: {
            ...targetModelItemState,
            icon: targetModelItemData.icon,
            availableMetamodelItems: targetModelItemData.metamodelItemsDropdownOptions,
            getAvailableModelItems: targetModelItemData.modelItemsDropdownOptions,
        },
        relationshipTypePicker: relationshipTypeState,
        displayClearAllOption,
        clearPickers,
    };
};

export type { UseRelationshipPickerProps, UseRelationshipPickerOutput };
