import React from "react";
import { IDropdownOption } from "@fluentui/react";
import { PreloadedSearchableDropdown } from "./PreloadedSearchableDropdown";
import { SearchableDropdownBaseProps } from "./types/SearchableDropdownBaseProps";
import { LazySearchableDropdown } from "./LazySearchableDropdown";

/**
 * The props of the {@link SearchableDropdown} component.
 */
export interface SearchableDropdownProps extends SearchableDropdownBaseProps {
    /** List of options, or callback to retrieve them on search. */
    options: IDropdownOption[] | ((searchText: string) => Promise<IDropdownOption[]>);
    /** render method for rendering non-header options */
    renderNormalOption?: (props?: IDropdownOption) => JSX.Element | null;
}

/**
 * SearchableDropdown is a component which displays the selected item from options. It is built on top of Fluent UI Dropdown
 * It has following additional features:
 * - filter options by text;
 * @param {SearchableDropdownProps} props The component props
 * @returns {JSX.Element} return react component
 */
export function SearchableDropdown(props: SearchableDropdownProps): JSX.Element {
    return typeof props.options === "function" ? (
        <LazySearchableDropdown {...props} getOptions={props.options} />
    ) : (
        <PreloadedSearchableDropdown {...props} options={props.options}></PreloadedSearchableDropdown>
    );
}
