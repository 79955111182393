import { ApolloLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { raiseEvent } from "iserver365-infrastructure-utility";
import { logError, communicationErrorCodes } from "./errorHandling.utils";

/**
 * Returns apollo link that tracks communication errors with the api and raises specified event type.
 * @param {string} eventType Event type.
 * @returns {ApolloLink} Apollo link.
 */
const getErrorHandlingLink = (eventType: string): ApolloLink => {
    return onError(({ networkError, graphQLErrors }): void => {
        const hasGraphQLCommunicationError = graphQLErrors?.some((error) =>
            communicationErrorCodes.includes(error.extensions?.code)
        );
        if (networkError === undefined && hasGraphQLCommunicationError !== true) {
            return;
        }

        if (networkError !== undefined) {
            logError(eventType, networkError);
        }

        if (hasGraphQLCommunicationError === true) {
            graphQLErrors?.forEach((graphQLError) => logError(eventType, graphQLError));
        }

        raiseEvent(eventType);
    });
};

export { getErrorHandlingLink };
