import React from "react";
import { Icon, PrimaryButton, Stack, Text } from "@fluentui/react";
import { useSomethingWentWrongStyles } from "./SomethingWentWrong.styles";

export interface SomethingWentWrongProps {
    /** Refresh button text */
    refreshBtnText: string;

    /** Header text */
    headerText: string;

    /** Content text */
    contentText: string;

    /** On refresh button click callback */
    onRefresh?: () => void;

    /** Callback to track Amplitude Event */
    onTrackEvent?: () => void;
}

/**
 * This component display an error page commonly used in scenarios involving non-recoverable errors. E.g. failed network calls.
 * Can be used within an error boundary or as a standalone error page.
 * @param {SomethingWentWrongProps} props The component props.
 * @returns {JSX.Element} The react element.
 */
export function SomethingWentWrong(props: SomethingWentWrongProps): JSX.Element {
    const styles = useSomethingWentWrongStyles();

    if (props.onTrackEvent !== null && props.onTrackEvent !== undefined) {
        props.onTrackEvent();
    }

    return (
        <Stack horizontalAlign="center" verticalAlign="center" styles={styles.header} disableShrink>
            <Icon styles={styles.alertIcon} iconName="Error" />
            <Text as="h1" styles={styles.title}>
                {props.headerText}
            </Text>
            <Text styles={styles.content}>{props.contentText}</Text>
            <PrimaryButton
                data-is365-id="Web:SomethingWentWrong:Refresh"
                data-test-id="refreshButton"
                iconProps={{ iconName: "Refresh" }}
                text={props.refreshBtnText}
                onClick={props.onRefresh}
            />
        </Stack>
    );
}
