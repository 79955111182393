import React, { useCallback, useEffect, useMemo, useState } from "react";
import { IDropdownOption } from "@fluentui/react";
import { isEmpty } from "lodash";
import { useDropdown } from "./hooks/useDropdown";
import { SearchableDropdownBaseProps } from "./types/SearchableDropdownBaseProps";
import { SearchableDropdownBase } from "./SearchableDropdownBase";

/**
 * The props of the SearchableDropdown component.
 */
export interface LazySearchableDropdownProps extends SearchableDropdownBaseProps {
    /** Callback to retrieve options on search. */
    getOptions: (searchText: string) => Promise<IDropdownOption[]>;
}

/**
 * SearchableDropdown is a component which displays the selected item from options. It is built on top of Fluent UI Dropdown
 * It has following additional features:
 * - filter options by text;
 * @param {LazySearchableDropdownProps} props The component props
 * @returns {JSX.Element} return react component
 */
export function LazySearchableDropdown(props: LazySearchableDropdownProps): JSX.Element {
    const { selectedKey, getOptions } = props;

    const { searchText, setSearchText, selectedValue, setSelectedValue, validationError } = useDropdown(props);
    const [options, setOptions] = useState<IDropdownOption[]>([]);
    const [selectedKeyOption, setSelectedKeyOption] = useState<IDropdownOption>();

    const onFieldChangeHandler = useCallback(
        (event: React.FormEvent<HTMLDivElement>, newValue?: IDropdownOption) => {
            setSelectedValue(newValue?.key as string, event);
            setSelectedKeyOption(options.filter((x) => x.key === newValue?.key)[0] ?? undefined);
        },
        [setSelectedValue, setSelectedKeyOption, options]
    );

    // This is needed so that the callback is not executed every time the user types.
    useEffect(() => {
        const timeoutHandler = setTimeout(async () => {
            setOptions(isEmpty(searchText) ? [] : await getOptions(searchText));
        }, 300);

        return () => clearTimeout(timeoutHandler);
    }, [searchText, getOptions]);

    useEffect(() => {
        if (selectedKey === undefined) {
            setSelectedKeyOption(undefined);
        }
    }, [selectedKey]);

    const filteredOptions = useMemo(() => {
        // We need to include the selected option as "hidden" when looking for other objects,
        // otherwise the value is cleared from the dropdown input.
        return options.some((x) => x.key === selectedValue)
            ? options
            : selectedKeyOption !== undefined
            ? options.concat([
                  {
                      ...selectedKeyOption,
                      hidden: true,
                  },
              ])
            : options;
    }, [options, selectedValue, selectedKeyOption]);

    return (
        <SearchableDropdownBase
            {...props}
            selectedKey={selectedValue}
            setSearchText={setSearchText}
            options={filteredOptions}
            errorMessage={validationError}
            onChange={onFieldChangeHandler}
        />
    );
}
