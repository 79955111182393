import { SeverityLevel } from "@microsoft/applicationinsights-common";
import { appInsights } from "iserver365-logging-utility";

/**
 * Tracks an exception in AppInsights.
 * @param {Error} error The exception.
 */
function trackException(error: Error): void {
    appInsights.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
    });
}

/**
 * Tracks a message in AppInsights.
 * @param {string} message The message.
 * @param {SeverityLevel} severityLevel The severity level (error, info, warning, etc.).
 */
function trackMessage(message: string, severityLevel: SeverityLevel): void {
    appInsights.trackException({
        exception: {
            name: "",
            message,
        },
        severityLevel,
    });
}

export { trackException, trackMessage };
