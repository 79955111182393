import React from "react";
import { ILinkStyles, Stack, StackItem } from "@fluentui/react";
import { useTagsStyles } from "./Tags.styles";
import { TagItemDefinition } from "./types/TagItemDefinition";
import { TagItem } from "./TagItem";

type TagsProps = {
    items: TagItemDefinition[];
    linkStyles?: ILinkStyles;
};

/**
 * Renders a list of tags.
 * @param {TagsProps} props - The component props.
 * @returns {JSX.Element} The rendered component.
 */
function Tags({ items: values, linkStyles }: TagsProps): JSX.Element {
    const { containerTokens } = useTagsStyles();

    return (
        <Stack horizontal wrap tokens={containerTokens}>
            {values.map(({ key, link, ...restProps }) => (
                <StackItem key={key}>
                    <TagItem href={link?.url} target={link?.target} linkStyles={linkStyles} {...restProps} />
                </StackItem>
            ))}
        </Stack>
    );
}

export { Tags };
export type { TagsProps };
