import React from "react";
import { FilterValuesErrorHandler } from "../QuickFilter.interfaces";
import { ValuesFilter, ValuesDateTime } from "../useQuickFilterReducer";
import { IPersonaProps, ITag } from "@fluentui/react";

type Value = string | string[] | IPersonaProps[] | ValuesDateTime | ValuesDateTime[] | number | null;

/**
 * Function for retrieve string value
 * @param {Value} value from state
 * @returns {string} return value
 */
export function getValueString(value: Value): string {
    return value !== null && (typeof value === "string" || typeof value === "number") ? String(value) : "";
}

/**
 * Function for retrieve number value
 * @param {Value} value from state
 * @returns {number | undefined} return value
 */
export function getValueNumber(value: Value): number | undefined {
    return value !== null && (typeof value === "string" || typeof value === "number") ? Number(value) : undefined;
}

/**
 * Function for retrieve Date object from ISO string
 * @param {Value} value from state
 * @returns {Date | undefined} return value
 */
export function getValueDate(value: Value): Date | undefined {
    return value !== null && (typeof value === "string" || typeof value === "number") ? new Date(value) : undefined;
}

/**
 * Function which convert event to string
 * @param {React.FormEvent<HTMLInputElement | HTMLTextAreaElement>} event received from input
 * @returns {string | undefined} return value
 */
export function convertEventToString(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>): string | null {
    const target = event.target as HTMLInputElement;
    return target !== undefined ? String(target.value) : null;
}

/**
 * Function which convert event to number
 * @param {React.FormEvent<HTMLInputElement | HTMLTextAreaElement>} event received from input
 * @returns {number | undefined} return value
 */
export function convertEventToNumber(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>): number | null {
    const target = event.target as HTMLInputElement;
    return target !== undefined ? Number(target.value) : null;
}

/**
 * Function which handle error
 * @param {ValuesFilter} values Filter values
 * @param {Error[]} error Error
 * @param {FilterValuesErrorHandler} valuesErrorHandler Error handler
 * @returns {void} return void
 */
export function handleError(values: ValuesFilter, error: Error[], valuesErrorHandler: FilterValuesErrorHandler): void {
    if (error[0] !== undefined && values.error !== error[0].message) {
        valuesErrorHandler(error[0].message);
    }
}

/**
 * Function which convert person to key
 * @param {IPersonaProps[]} persons Person
 * @returns {string[]} return string
 */
export function convertPersonsToKeys(persons: IPersonaProps[]): string[] {
    if (persons === null || persons.length === 0) {
        return [];
    }
    return persons.map((person) => person.key as string);
}

/**
 * Function which convert objects to key
 * @param {ITag[]} objects Objects
 * @returns {string[]} return string
 */
export function convertObjectsToKeys(objects: ITag[]): string[] {
    if (objects === null || objects.length === 0) {
        return [];
    }
    return objects.map((person) => person.key as string);
}
