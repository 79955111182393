/* eslint-disable jsdoc/no-undefined-types */
import { isEmpty, isNil } from "lodash";
import { ValidationRule, ValidationError } from "../utils/validation";
import {
    MaxStringLength,
    MinStringLength,
    MinMaxStringLength,
    RequiredString,
    InvalidCharacters,
    MinWordsLength,
} from "../utils/validation/rules";

interface GetValidationRuleProps {
    validationRules: ValidationRule<Value>[];
    isRequired: boolean;
    min?: number;
    max?: number;
    invalidChars?: string[];
    minWords?: number;
}

export type Value = string | undefined;

/**
 * @param {GetValidationRuleProps} params Parameter object
 * @returns {ValidationRule[]} Validation rules to apply to {@link NumberField}
 */
export function getValidationRules({
    validationRules,
    isRequired,
    min,
    max,
    invalidChars,
    minWords,
}: GetValidationRuleProps): ValidationRule<Value>[] {
    const builtinRules = [];
    if (isRequired) {
        builtinRules.push(new RequiredString());
    }
    if (!isNil(min) && !isNil(max)) {
        builtinRules.push(new MinMaxStringLength({ minLength: min, maxLength: max }));
    }
    if (!isNil(min)) {
        builtinRules.push(new MinStringLength({ minLength: min }));
    }
    if (!isNil(max)) {
        builtinRules.push(new MaxStringLength({ maxLength: max }));
    }
    if (!isNil(invalidChars)) {
        builtinRules.push(new InvalidCharacters({ invalidChars }));
    }
    if (!isNil(minWords)) {
        builtinRules.push(new MinWordsLength({ minWordsLength: minWords }));
    }

    return [...builtinRules, ...validationRules];
}

/**
 * @param {object} params Parameter object
 * @param {ValidationError[]} params.validationErrors List of current validation errors for the {@link TextField} component
 * @param {boolean} params.readOnly Whether or not the {@link TextField} component is currently readOnly
 * @param {boolean} params.isDefaultValue Whether or not the {@link TextField} component value is equals to default
 * @returns {boolean} Whether or not to display a validation error
 */
export function getDisplayValidationError({
    validationErrors,
    readOnly,
    isDefaultValue,
}: {
    validationErrors: ValidationError[];
    readOnly: boolean;
    isDefaultValue: boolean;
}): boolean {
    return !isEmpty(validationErrors) && !readOnly && !isDefaultValue;
}
