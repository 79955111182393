import { IStackStyles, useTheme } from "@fluentui/react";

interface UseStyles {
    /** Container styles */
    container: IStackStyles;
}

/**
 * Hook that returns the styles object used for ToastStack.
 * @returns {UseStyles} The styles object.
 */
export function useStyles(): UseStyles {
    const theme = useTheme();
    return {
        container: {
            root: {
                pointerEvents: "all",
                width: 540,
                marginBottom: theme.spacing.s2,
                marginLeft: theme.spacing.s2,
                marginRight: theme.spacing.s2,
                boxShadow: theme.effects.elevation8,
            },
        },
    };
}
