/* eslint-disable @typescript-eslint/no-non-null-assertion */

/**
 * Returns availability of features.
 * Prevents tokens to be renamed during minification in production mode.
 */
export const getFeatures = (function () {
    const features = {
        enableViews: process.env.enableViews!,
    };

    return () => features;
})();
