import React from "react";
import { isEmpty, isNil } from "lodash";
import { ILabeledFieldProps } from "../types";
import { useId } from "@fluentui/react-hooks";
import { LabelWrapperStyles } from "./types";
import { FieldLabel } from "../FieldLabel/FieldLabel";

type LabelWrapperProps = {
    label?: string;
    styles?: LabelWrapperStyles;
};

/**
 * Higher-order component that adds a label to the wrapped component.
 * @template TProps - The props type of the wrapped component.
 * @param {React.ComponentType<TProps>} Component - The component to be wrapped.
 * @returns {React.ComponentType<TProps & LabelWrapperProps>} - The wrapped component with a label.
 */
export const withLabel =
    <TProps extends ILabeledFieldProps>(Component: React.ComponentType<TProps>) =>
    (props: TProps & LabelWrapperProps): JSX.Element => {
        const componentId = useId("labeledComponent");
        const componentProps: TProps = {
            ...props,
            label: undefined,
        };

        if (!isNil(props.label) && !isEmpty(props.label)) {
            return (
                <>
                    <Component id={componentId} {...componentProps}>
                        <FieldLabel
                            label={props.label}
                            htmlFor={componentId}
                            styles={{ fieldLabel: props.styles?.subComponentStyles?.label }}
                            {...props}
                        />
                    </Component>
                </>
            );
        }
        return <Component {...componentProps} />;
    };

export type { LabelWrapperProps };
