import { wrap } from "lodash";
import React from "react";
import classNames from "classnames";

import { stopPropagation } from "../../../utils/events";
import { keyDownA11y } from "../../../utils/accessibility";
import { CurrentStyle, TopLevelOptionProps } from "../types";
import { mapColorOptions } from "./ColorPickerOption.service";

export interface ColorPickerOptionModalProps extends TopLevelOptionProps {
    currentStyle: keyof typeof CurrentStyle;
    setCurrentStyle: (currentStyle: keyof typeof CurrentStyle) => void;
}

/**
 * @param {ColorPickerOptionModalProps} props {@link ColorPickerOptionModal} props
 * @returns {JSX.Element} The react element.
 */
export function ColorPickerOptionModal({
    currentStyle,
    setCurrentStyle,
    config: { popupClassName, colors = [] },
    currentState: { color, bgColor },
    translations,
    onChange,
}: ColorPickerOptionModalProps): JSX.Element {
    const currentSelectedColor = currentStyle === "color" ? color : bgColor;
    return (
        <div role="dialog" title="Color Picker Dialog">
            <div
                className={classNames("rdw-colorpicker-modal", popupClassName)}
                onClick={stopPropagation}
                onKeyPress={keyDownA11y({ handler: stopPropagation })}
                role="presentation"
            >
                <span className="rdw-colorpicker-modal-header" role="tabpanel">
                    <span
                        role="tab"
                        tabIndex={0}
                        className={classNames("rdw-colorpicker-modal-style-label", {
                            "rdw-colorpicker-modal-style-label-active": currentStyle === "color",
                        })}
                        onClick={wrap("color", setCurrentStyle)}
                        onKeyPress={keyDownA11y({ handler: wrap("color", setCurrentStyle) })}
                    >
                        {translations["components.controls.colorpicker.text"]}
                    </span>
                    <span
                        role="tab"
                        tabIndex={0}
                        className={classNames("rdw-colorpicker-modal-style-label", {
                            "rdw-colorpicker-modal-style-label-active": currentStyle === "bgcolor",
                        })}
                        onClick={wrap("bgcolor", setCurrentStyle)}
                        onKeyPress={keyDownA11y({ handler: wrap("bgcolor", setCurrentStyle) })}
                    >
                        {translations["components.controls.colorpicker.background"]}
                    </span>
                </span>
                <span className="rdw-colorpicker-modal-options">
                    {mapColorOptions({ colors, currentSelectedColor, currentStyle, onChange })}
                </span>
            </div>
        </div>
    );
}
