import { Link, Stack, TextField } from "@fluentui/react";
import React from "react";
import { useNavigationContext } from "../Navigation.context";
import { Hyperlink, HyperlinkListItemField } from "../types";
import { CellProps } from "./CellProps";
import { useStyles } from "./HyperlinkCell.styles";

/**
 * Cell for the list view which outputs a state - which is a link to page.
 * @param {CellProps<HyperlinkListItemField>} param The cell props.
 * @returns {React.FC<CellProps<HyperlinkListItemField>>} The react component.
 */
export const HyperlinkCell: React.FC<CellProps<HyperlinkListItemField>> = ({
    editMode,
    field,
    onStopEditing,
    onValueChange,
}: CellProps<HyperlinkListItemField>) => {
    const styles = useStyles();
    const { navigateToUrl } = useNavigationContext();
    const handleLinkClick = (
        event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLElement>,
        link: Hyperlink
    ) => {
        event.preventDefault();

        if (link.blank === true) {
            window.open(link.url);
        } else {
            navigateToUrl(link.url);
        }
    };

    if (editMode) {
        return (
            <TextField
                componentRef={(input) => {
                    input?.focus();
                }}
                value={field.text ?? ""}
                onChange={(_, newValue) => onValueChange({ ...field, text: newValue ?? "" })}
                onBlur={onStopEditing}
                ariaLabel="Edit"
            />
        );
    }

    return (
        <Stack horizontal disableShrink>
            {field.hyperlinks.map((l, i) => (
                <Stack horizontal disableShrink styles={styles.container} key={`c${i}`}>
                    <Link href={l.url} styles={styles.link} key={i} onClick={(e) => handleLinkClick(e, l)}>
                        {l.text ?? l.url}
                    </Link>
                    {i < field.hyperlinks.length - 1 ? <>,</> : <></>}
                </Stack>
            ))}
        </Stack>
    );
};
