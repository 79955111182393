import { IStackItemStyles, useTheme } from "@fluentui/react";
import { SystemColorKey, systemColors } from "iserver365-styleguide";
import { CSSProperties } from "react";

type UseItemConnectorStyles = {
    rightArrow: CSSProperties;
    leftArrow: CSSProperties;
    connector: IStackItemStyles;
    title: IStackItemStyles;
};
/**
 * Hook that provides the styles for the Arrow component.
 * @param {SystemColorKey} accentColor - The accent color for the styles.
 * @returns {UseItemConnectorStyles} The Arrow styles.
 */
export const useArrowStyles = (accentColor: SystemColorKey): UseItemConnectorStyles => {
    const { spacing } = useTheme();

    const arrowBase: CSSProperties = {
        position: "absolute",
        bottom: "-9px",
        borderTop: "8px solid transparent",
        borderBottom: "8px solid transparent",
    };
    return {
        rightArrow: {
            ...arrowBase,
            right: "-1px",
            borderLeft: `15px solid ${systemColors[accentColor]}`,
        },
        leftArrow: {
            ...arrowBase,
            left: "-1px",
            borderRight: `15px solid ${systemColors[accentColor]}`,
        },
        connector: {
            root: {
                position: "relative",
                borderBottom: "2px solid",
                borderColor: systemColors[accentColor],
                width: "250px",
                textAlign: "center",
                paddingBottom: spacing.s2,
            },
        },
        title: {
            root: {
                fontWeight: 600,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
            },
        },
    };
};
