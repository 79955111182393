import { trackEvent } from "iserver365-amplitude";

/**
 * Initialize Amplitude SDK and track initial message.
 */
async function initializeAmplitude(): Promise<void> {
    const { initAmplitude } = await import("iserver365-amplitude");
    const { getCurrentUser } = await import("iserver365-users-and-roles-utility");
    const { getSettings } = await import("iserver365-settings-utility");

    try {
        const { data } = await getCurrentUser();
        const settings = await getSettings();

        initAmplitude(data?.user, settings);
        initAmplitudeGlobalEventListener();
    } catch {
        // Nothing we can do if initialization failed, just continue without Amplitude
    }
}

function initAmplitudeGlobalEventListener(): void {
    document.addEventListener(`click`, (e) => {
        const targetElement = e.target;
        if (targetElement instanceof HTMLElement) {
            const amplitudeEventName = getAmplitudeEventName(targetElement);
            if (amplitudeEventName !== null) {
                trackEvent(amplitudeEventName);
            }
        }
    });
}

function getAmplitudeEventName(element: HTMLElement): string | null {
    const amplitudeEventNameAttribute = "data-amplitude-event-name";

    const amplitudeEventName = element.getAttribute(amplitudeEventNameAttribute);

    if (amplitudeEventName !== null) {
        return amplitudeEventName;
    }

    if (element.parentElement === null) {
        return null;
    }

    return getAmplitudeEventName(element.parentElement);
}

export { initializeAmplitude };
