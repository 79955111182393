import { IComboBoxOption, ITag } from "@fluentui/react";

export enum CategoryType {
    None,
    AttributeChoice,
    AttributeCurrency,
    AttributeDateTime,
    AttributeHyperlink,
    AttributeNumber,
    AttributePersonOrGroup,
    AttributeText,
    AttributeTrueFalse,
    AttributeUniqueIdentifier,
    AttributeMilestone,
    Object,
    Relationship,
    ModelItem,
    IsApproved,
    IsPendingReview,
    Approvers,
    LeadObject,
    MemberObject,
}

export const enum OperatorValueType {
    None,
    Contains,
    DoesNotContain,
    DoesNotEndWith,
    DoesNotStartWith,
    EndsWith,
    Equal,
    GreaterThan,
    GreaterThanOrEqualTo,
    LessThan,
    LessThanOrEqualTo,
    NotEqual,
    NotNull,
    Null,
    Similar,
    StartsWith,
    Before,
    BeforeOrIn,
    After,
    AfterOrIn,
    IsOfType,
    IsNotOfType,
    IsValid,
    IsNotValid,
    IsRelatedTo,
    IsNotRelatedTo,
    HasRelationship,
    DoesNotHaveRelationship,
    IsContainedIn,
    IsNotContainedIn,
    IsLinkedTo,
    IsNotLinkedTo,
    IsLead,
    IsMember,
    Relates,
    DoesNotRelate,
    HasSingleLead,
    HasMultipleLeads,
    HasSingleMember,
    HasMultipleMembers,
    IsInDocument,
    IsNotInDocument,
    IsTrue,
    IsFalse,
    IsInImport,
    IsNotInAnyDocument,
    HasNoRelationships,
}

export interface CategoryOption extends IComboBoxOption {
    category: CategoryType;
}

export interface Choice {
    key: string;
    name: string;
    children?: Choice[] | null;
    description?: string;
    selected: boolean;
    parentId: string | null;
    disabled: boolean;
}

export type Condition = {
    id: string;
    category: CategoryType;
    name: string;
};

export type FilterValuesType = number | string | string[] | ITag[] | null;

export type FilterCategoryHandler = (value: number | string | null, category: CategoryType | null) => void;

export type FilterOperatorHandler = (value: OperatorValueType) => void;

export type FilterValuesHandler = (value: FilterValuesType) => void;

export type FilterValuesErrorHandler = (error: string | null) => void;
