import { IChoiceDataEntry } from "./IChoiceDataEntry";
import { IChoiceTreeModel } from "./IChoiceTreeModel";
import { NESTED_STEP, EXPANDABLE_ICON_WIDTH, NESTED_CHILD_OFFSET } from "./Choice.constants";

/**
 * Maps the {@link IChoiceDataEntry} items to {@link IChoiceTreeModel<T>}.
 * @typedef T The choice data entry type.
 * @param {T} dataEntry The choice data entry.
 * @param {boolean} isPartialSelectionEnabled A flag indicating whether we need to show parents with some selected children as selected too.
 * @param {boolean} childrenIndependentFromParent A flag indicating whether the child selection is correlated with parent selection.
 * @returns {IChoiceTreeModel<T>} The mapped choice tree models.
 */
function getChoiceModel<T extends IChoiceDataEntry>(
    dataEntry: T,
    isPartialSelectionEnabled: boolean,
    childrenIndependentFromParent = false
): IChoiceTreeModel<T> {
    const children =
        (dataEntry.children?.map((parentChoice) => getChoiceModel(parentChoice, isPartialSelectionEnabled)) as
            | IChoiceTreeModel<T>[]
            | undefined) ?? [];

    let selected: boolean;
    let partialSelected: boolean | undefined = undefined;

    if (children.length === 0 || childrenIndependentFromParent) {
        selected = dataEntry.selected;
    } else if (isPartialSelectionEnabled) {
        const hasSomeSelectedNodes = (choice: IChoiceDataEntry): boolean => {
            return choice.children !== undefined && choice.children.some((c) => c.selected || hasSomeSelectedNodes(c));
        };

        const hasAllSelectedNodes = (choice: IChoiceDataEntry): boolean => {
            return (
                choice.children !== undefined &&
                choice.children.length > 0 &&
                choice.children.every((c) => c.selected || hasAllSelectedNodes(c))
            );
        };

        selected = hasSomeSelectedNodes(dataEntry);
        partialSelected = selected && !hasAllSelectedNodes(dataEntry);
    } else {
        const numberOfChildren = children.length;
        const numberOfSelectedChildren = children.filter((c) => c.selected).length;

        selected = numberOfSelectedChildren === numberOfChildren;
    }

    return {
        // `name` for top-level choices, `value` for children choices
        name: dataEntry.name ?? dataEntry.value,
        key: dataEntry.key,
        selected,
        children,
        description: dataEntry.description,
        dataEntry,
        partialSelected,
        disabled: dataEntry.disabled,
    };
}

/**
 * Maps the {@link IChoiceDataEntry} items in {@link allChoices} to an array of {@link IChoiceTreeModel}.
 * @typedef T The choice data entry type.
 * @param {Array<T>} allChoices The choice data entries to map.
 * @param {boolean} isPartialSelectionEnabled A flag indicating whether we need to show parents with some selected children as selected too.
 * @param {boolean} childrenIndependentFromParent A flag indicating whether we child selection is correlated with parent selection.
 * @returns {Array<IChoiceTreeModel<T>>} The mapped choice tree models.
 */
export function getChoiceModels<T extends IChoiceDataEntry>(
    allChoices: T[],
    isPartialSelectionEnabled: boolean,
    childrenIndependentFromParent: boolean
): IChoiceTreeModel<T>[] {
    return allChoices.map((choice) => getChoiceModel(choice, isPartialSelectionEnabled, childrenIndependentFromParent));
}

/**
 * Recursively iterates the child choices of {@link choiceModel} to produce a flattened array of all of it's leaf data entry elements.
 * @param {IChoiceTreeModel<T>} choiceModel The choice model to produce the leaf data entry elements from.
 * @returns {Array<T>} A flattened array of all the leaf data entry elements of {@link choiceModel}.
 * @example
 * const choiceModel = {
 *  dataEntry: "1",
 *  children: [
 *    {
 *      dataEntry: "1.1",
 *      children: [
 *          {
 *              dataEntry: "1.1.1",
 *              children: []
 *          }
 *      ]
 *    },
 *    {
 *      dataEntry: "1.2",
 *      children: []
 *    }
 *  ]
 * };
 *
 * const leafDataEntries = getLeafDataEntries(choiceModel);
 *
 * console.log(leafDataEntries);
 * // ["1.1.1", "1.2"]
 */
export function getLeafDataEntries<T>(choiceModel: IChoiceTreeModel<T>): T[] {
    if (choiceModel.children.length === 0) {
        return [choiceModel.dataEntry];
    }

    return choiceModel.children.reduce((memo, child) => memo.concat(getLeafDataEntries(child)), [] as T[]);
}

/**
 * Gets the offset for the {@link number} which is used for left indenting actions section in the Choice component with child nodes.
 * @param {number} offset The offset of the choice.
 * @param {boolean} hasChildren The boolean value defining does Choice has children.
 * @returns {number} The offset value to use.
 */
export function getActionsOffset(offset: number, hasChildren: boolean): number {
    return hasChildren ? offset - EXPANDABLE_ICON_WIDTH : offset;
}

/**
 * Gets the offset for the {@link number} which is used for left indenting choices in the Choice component with child nodes.
 * @param {number} offset The offset of the choice.
 * @param {boolean} hasChildren The boolean value defining does Choice has children.
 * @returns {number} The offset value to use.
 */
export function getNestedOffsetStepWithChildren(offset: number, hasChildren: boolean): number {
    return hasChildren ? getNestedOffsetStep(offset) + NESTED_CHILD_OFFSET : getNestedOffsetStep(offset);
}

/**
 * Gets the offset for the {@link number} which is used for left indenting child choices in the Choice component.
 * @param {number} offset The offset of the choice.
 * @returns {number} The offset value to use.
 */
export function getNestedOffsetStep(offset: number): number {
    return offset + NESTED_STEP;
}

/**
 * Gets the offset for the {@link number} which is used for left indenting the choice in an expanded state in the Choice component.
 * @param {number} offset The offset of the choice.
 * @returns {number} The offset value to use.
 */
export function getSeparatorOffsetStep(offset: number): number {
    return getNestedOffsetStep(offset) - EXPANDABLE_ICON_WIDTH;
}

/**
 * Returns the total top-level choices in the {@link arr}.
 * @param {IChoiceTreeModel<T>[]} arr The choice tree model array.
 * @returns {number} The total top-level choices.
 */
export function totalChoices<T>(arr: IChoiceTreeModel<T>[]): number {
    return arr.length;
}

/**
 * Returns the total, top-level selected choices in the {@link arr}.
 * @param {IChoiceTreeModel<T>[]} arr The choice tree model array.
 * @returns {number} The total, selected top-level choices.
 */
export function selectedChoices<T>(arr: IChoiceTreeModel<T>[]): number {
    return arr.reduce((count, choice) => count + (choice.selected ? 1 : 0), 0);
}

/**
 * Converts flat choices to the tree model
 * @param {IChoiceDataEntry[]} choices The choice data entry.
 * @returns {IChoiceDataEntry[]} The mapped choice tree models.
 */
export const convertFlatChoicesToTreeModel = (choices: IChoiceDataEntry[]): IChoiceDataEntry[] => {
    const mapChoices = (choices: IChoiceDataEntry[], id: null | string): IChoiceDataEntry[] =>
        choices
            .filter((choice) => choice.parentId === id)
            .map((choice) => ({ ...choice, children: mapChoices(choices, choice.key) }));

    return mapChoices(choices, null);
};
