import { ITextFieldStyleProps, ITextFieldStyles, makeStyles } from "@fluentui/react";
export const useHyperlinkFieldStyles = makeStyles((theme) => {
    const { spacing } = theme;

    return {
        childrenGap: {
            gap: spacing.m,
        },
        hyperlinkColumn: {
            flex: "1 1 0",
        },
    };
});

/**
 * Returns the styles for the Textfield component combined with the theme.
 * @returns {Partial<ITextFieldStyles>} The styles for the Number component.
 */
export function getTextFieldStylesFunction(): (props: ITextFieldStyleProps) => Partial<ITextFieldStyles> {
    return (): Partial<ITextFieldStyles> => {
        return {
            root: { width: "100%" },
        };
    };
}
