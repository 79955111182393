import React, { useEffect, useState } from "react";
import { PrimaryButton, Stack, TextField } from "@fluentui/react";
import { useStyles } from "./SearchInput.styles";

type SearchInputProps = {
    onClick: (value: string) => void;
    onCancel: () => void;
    searchPlaceholder?: string;
};

/**
 * Search input component - to search for items in the list.
 * @param {SearchInputProps} props The component props.
 * @returns {JSX.Element} The React component.
 */
export const SearchInput = (props: SearchInputProps): JSX.Element => {
    const styles = useStyles();
    const [searchText, setSearchText] = useState("");
    const [isInputNotEmpty, setIsInputNotEmpty] = useState(false);

    const handleInputChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setSearchText(newValue ?? "");
    };

    useEffect(() => {
        setIsInputNotEmpty(searchText.trim() !== "");
    }, [searchText]);

    return (
        <Stack styles={styles.searchInputContainer} horizontal>
            <TextField
                ariaLabel="Search input"
                type="text"
                placeholder={props.searchPlaceholder}
                value={searchText}
                onChange={handleInputChange}
                onBlur={() => {
                    if (searchText === "") {
                        props.onCancel();
                    }
                }}
                styles={styles.searchInput}
            />
            <PrimaryButton
                ariaLabel="Search button"
                iconProps={{ iconName: "Search" }}
                disabled={!isInputNotEmpty}
                styles={styles.searchButton}
                onClick={() => props.onClick(searchText)}
            />
        </Stack>
    );
};
