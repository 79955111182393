import React from "react";
import { Label, Stack, TextField } from "@fluentui/react";
import { getTextFieldStylesFunction, useHyperlinkFieldStyles } from "./HyperlinkField.styles";
import { getDisplayValueErrorMessage, getValueErrorMessage } from "./HyperlinkField.validation";
import { HyperlinkFieldLabels } from "./HyperlinkFieldLabels.interface";
import { mergeWith, noop } from "lodash";

/**
 * {@link Hyperlink: HyperlinkField} component props.
 */
interface HyperlinkFieldProps {
    /** The link Name label of the input. */
    linkNameLabel?: string;
    /** The link label of the input. */
    linkLabel?: string;
    /** The display value of the input. */
    displayValue?: string | undefined;
    /** The value of the input. */
    value: string | undefined;
    /** A value indicating whether value is required. */
    valueRequired?: boolean;
    /** Require Display Value for the input. */
    displayValueRequired?: boolean;
    /** Callback to run on display value and on url value change */
    onFieldChange?: (value: string, displayValue: string, isDisplayValueValid: boolean, isValueValid: boolean) => void;
    /** Labels of the component */
    labels?: HyperlinkFieldLabels;
    /** Formatter for messages of the input, input is a messageCode and output is the message. */
    messageProvider?: (messageCode: string, params?: Record<string, string>) => string;
    /** Indicated whether we are showing display value. */
    showDisplayValue?: boolean;
    /** Indicated whether fields are disabled. */
    disabled?: boolean;
}

/**
 * Hyperlink Input.
 * @param {HyperlinkFieldProps} props Input props.
 * @returns {JSX.Element} Hyperlink Input.
 */
function HyperlinkField(props: HyperlinkFieldProps): JSX.Element {
    const styles = useHyperlinkFieldStyles();
    const textFieldStyle = getTextFieldStylesFunction();

    const {
        linkNameLabel = "",
        linkLabel = "",
        displayValue = "",
        value = "",
        displayValueRequired = false,
        valueRequired = false,
        onFieldChange = noop,
        labels,
        messageProvider,
        disabled = false,
        showDisplayValue = false,
    } = props;

    const defaultLabels = {
        displayValuePlaceholder: "Put Text Here",
        valuePlaceholder: "URL",
    };

    const messageProviderOrDefault = messageProvider ?? ((message) => message);
    const labelsOrDefault = mergeWith(defaultLabels, labels);

    const shouldValidate = displayValue?.length > 0 || value?.length > 0;

    return (
        <Stack>
            <Stack horizontal className={styles.childrenGap}>
                {(displayValueRequired || showDisplayValue) && linkNameLabel?.length > 0 && (
                    <Stack className={styles.hyperlinkColumn}>
                        <Label>{linkNameLabel}</Label>
                    </Stack>
                )}
                {linkLabel?.length > 0 && (
                    <Stack className={styles.hyperlinkColumn}>
                        <Label>{linkLabel}</Label>
                    </Stack>
                )}
            </Stack>
            <Stack horizontal className={styles.childrenGap}>
                {(displayValueRequired || showDisplayValue) && (
                    <Stack className={styles.hyperlinkColumn}>
                        <TextField
                            disabled={disabled}
                            defaultValue={displayValue}
                            placeholder={labelsOrDefault.displayValuePlaceholder}
                            errorMessage={
                                shouldValidate
                                    ? getDisplayValueErrorMessage(
                                          displayValue,
                                          displayValueRequired,
                                          messageProviderOrDefault
                                      )
                                    : ""
                            }
                            styles={textFieldStyle}
                            onChange={(_, newValue) => {
                                const isValidDisplayValueValid =
                                    getDisplayValueErrorMessage(
                                        newValue,
                                        displayValueRequired,
                                        messageProviderOrDefault
                                    ).length === 0;

                                const isValueValid =
                                    getValueErrorMessage(
                                        value,
                                        displayValueRequired,
                                        valueRequired,
                                        messageProviderOrDefault
                                    ).length === 0;

                                onFieldChange(value, newValue, isValidDisplayValueValid, isValueValid);
                            }}
                        />
                    </Stack>
                )}
                <Stack className={styles.hyperlinkColumn} horizontal>
                    <TextField
                        disabled={disabled}
                        defaultValue={value}
                        iconProps={{ iconName: "Link12" }}
                        placeholder={labelsOrDefault.valuePlaceholder}
                        errorMessage={
                            shouldValidate
                                ? getValueErrorMessage(
                                      value,
                                      displayValueRequired,
                                      valueRequired,
                                      messageProviderOrDefault
                                  )
                                : ""
                        }
                        styles={textFieldStyle}
                        onChange={(_, newValue) => {
                            const isValueValid =
                                getValueErrorMessage(
                                    newValue,
                                    displayValueRequired,
                                    valueRequired,
                                    messageProviderOrDefault
                                ).length === 0;

                            const isDisplayValueValid =
                                getDisplayValueErrorMessage(
                                    displayValue,
                                    displayValueRequired,
                                    messageProviderOrDefault
                                ).length === 0;

                            onFieldChange(newValue, displayValue, isValueValid, isDisplayValueValid);
                        }}
                    />
                </Stack>
            </Stack>
        </Stack>
    );
}

export type { HyperlinkFieldProps };
export { HyperlinkField };
