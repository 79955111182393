import { isNil } from "lodash";
import { ValidationError, ValidationRule, ValidationRuleProps } from "../..";

export type Value = number | null | undefined;

export interface MinNumberValueErrorProps {
    /**
        Minimum number value required for the input.
     */
    min: number;
    /**
        Actual number value received for the input.
     */
    actual: number;
}

export interface MinNumberValueProps extends ValidationRuleProps<Value> {
    /**
        Minimum number value required for the input.
     */
    min: number;
}

/**
 * MinNumberValueError validation error class
 */
export class MinNumberValueError implements ValidationError {
    constructor(props: MinNumberValueErrorProps) {
        this.name = "MinNumberValueError";
        this.min = props.min;
        this.actual = props.actual;
        this.message = `This value must be higher than or equal to ${props.min}.`;
    }
    name: string;
    min: number;
    actual: number;
    message: string;
}

/**
 * MinNumberValue validation rule class
 */
export class MinNumberValue extends ValidationRule<Value> {
    static Error = MinNumberValueError;
    constructor(props: MinNumberValueProps) {
        super(props);
        this.min = props.min;
    }

    min: number;

    validate(value: Value): MinNumberValueError | null {
        if (!isNil(value) && value < this.min) {
            return new MinNumberValueError({ min: this.min, actual: value });
        }

        return null;
    }
}
