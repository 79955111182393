import React from "react";

/**
 * Solutions Hub SVG Icon.
 * @returns {JSX.Element} Solutions Hub SVG Icon.
 */
const SolutionsHubIcon = (): JSX.Element => {
    return (
        <svg width="32" height="32" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.6165 23.5509L12.301 11.1551L15.0345 9.76246C15.0424 10.2209 15.1529 10.6715 15.3605 11.0816C15.6364 11.6264 16.0715 12.0753 16.6115 12.3724C16.8708 12.5103 17.1497 12.6105 17.4399 12.6699C19.0512 12.8099 20.2688 12.1007 20.9007 11.096C21.1194 10.7475 21.6254 10.7343 21.8036 11.0842L21.8165 11.1095L21.8573 11.1674L23.9507 15.2763C23.9921 15.3601 24.0079 15.4543 23.9964 15.5479C23.9849 15.6415 23.9465 15.7305 23.8857 15.8045C23.821 15.8192 23.7581 15.8414 23.6984 15.8707L19.0145 18.2478L19.1566 18.4268C19.1436 18.5087 19.1536 18.5921 19.1853 18.6678C19.2171 18.7436 19.2695 18.8089 19.3368 18.8568C19.4067 18.906 19.4739 18.9587 19.5382 19.0148C19.5612 19.0785 19.5821 19.1478 19.6064 19.2284C19.6155 19.2588 19.6252 19.2907 19.6356 19.3245L19.6477 19.3636L19.6735 19.3957C19.7656 19.5212 19.8479 19.6534 19.9199 19.7912C20.1866 20.318 20.3053 20.9696 20.179 21.7586C20.1594 21.8816 20.117 22.0006 20.054 22.1096C19.9845 22.2279 19.9092 22.3331 19.8343 22.4377C19.8289 22.4452 19.8236 22.4526 19.8182 22.4601C19.5081 22.9097 19.0962 23.2833 18.6165 23.5509Z"
                fill="#023336"
            />
            <path
                d="M0.00146484 17.4215L12.3008 11.1552L18.6163 23.551C18.5935 23.5638 18.5705 23.5762 18.5474 23.5885C18.4323 23.6461 18.3138 23.6964 18.1925 23.7391C16.8166 24.2424 15.288 23.7143 14.6493 22.4607C14.5815 22.3151 14.5274 22.1635 14.4876 22.0077L14.4755 21.9687L14.4527 21.9359C14.3638 21.811 14.2839 21.6802 14.2135 21.5442L14.2091 21.451C14.2106 21.3805 14.1955 21.311 14.165 21.2479C14.1345 21.1848 14.0894 21.1299 14.0333 21.0873L13.9586 20.8171L9.34392 23.159C9.26775 23.1984 9.19862 23.25 9.13947 23.3117C9.04628 23.3177 8.95385 23.2983 8.87233 23.2557C8.7908 23.2132 8.72333 23.1491 8.67732 23.0705L8.57876 22.9431L6.60548 19.0243C6.55824 18.9622 6.49673 18.9121 6.42578 18.8778C6.35483 18.8436 6.27637 18.8262 6.19654 18.8269C6.11672 18.8276 6.03771 18.8465 5.9657 18.8821C5.8937 18.9177 5.83066 18.969 5.78153 19.0319C5.47429 19.4405 5.07529 19.7746 4.61675 20.0074C3.0086 20.8242 1.07658 20.2378 0.34208 18.6928C0.125533 18.2882 0.0154424 17.8548 0.00146484 17.4215Z"
                fill="#0C6E75"
            />
            <path
                d="M0.00152798 17.4215L12.3009 11.1552L6.94622 0.64526C6.31509 0.954606 5.73135 1.47809 5.286 2.24252C5.20743 2.3787 5.15407 2.52704 5.12845 2.6805C4.8872 4.15886 5.4408 5.26408 6.32756 5.88124C6.41782 5.94519 6.48197 6.03845 6.5091 6.14514C6.53623 6.25182 6.52465 6.36535 6.47635 6.4664L2.00184 8.72431C1.88201 8.78274 1.78872 8.88392 1.74151 9.00667C1.6943 9.12941 1.69684 9.2642 1.74859 9.38279L1.793 9.53943L3.7841 13.4475C3.8062 13.5223 3.8102 13.6015 3.79577 13.6789C3.78135 13.7563 3.74889 13.8298 3.70097 13.8937C3.65305 13.9575 3.59098 14.0098 3.51963 14.0466C3.44828 14.0834 3.36961 14.1036 3.28983 14.1056C2.77922 14.1132 2.27457 14.2378 1.81605 14.4695C0.637906 15.063 -0.0365212 16.2418 0.00152798 17.4215Z"
                fill="#92D5DA"
            />
            <path
                d="M19.1647 5.86937L19.1765 5.89265C19.3548 6.24248 19.0429 6.6435 18.6287 6.60655C17.4448 6.52279 16.1517 7.08361 15.3109 8.46448C15.1877 8.7341 15.1033 9.01849 15.0602 9.30929C15.0404 9.46044 15.0318 9.6119 15.0344 9.76252L12.3009 11.1552L6.94629 0.645253C8.52035 -0.126146 10.3892 0.434343 11.1414 1.91073C11.3572 2.33438 11.4635 2.80584 11.451 3.28434C11.4506 3.3997 11.4929 3.51026 11.5701 3.59536C11.6473 3.68045 11.754 3.73425 11.8705 3.74669L16.3724 1.47481C16.4328 1.44324 16.4987 1.42353 16.5663 1.41681C16.6339 1.41009 16.7018 1.41648 16.7662 1.43562C16.8306 1.45477 16.8903 1.48628 16.9417 1.52838C16.9932 1.57047 17.0355 1.62232 17.0661 1.68095L19.1404 5.79797L19.1647 5.86937Z"
                fill="#1F878E"
            />
        </svg>
    );
};

export { SolutionsHubIcon };
