/* eslint-disable jsdoc/no-undefined-types */
import React, { useRef, RefObject } from "react";
import { DefaultButton, Callout, DirectionalHint } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";

import { CALLOUT_WIDTH, CALLOUT_HEIGHT } from "./CollapsibleContent.constants";

export interface CollapsibleContentProps {
    /** Current state of collapsed parent */
    isCollapsed: boolean;
    /** Button text */
    buttonText?: string;
    /** children all what we pass into collapse component */
    children: JSX.Element;
    /** Callback for render custom button */
    onRenderButton?: (
        /** This ref for callout */
        buttonRef: RefObject<HTMLElement>,
        /** This flag is needed for apply styles if callout is open */
        checked: boolean,
        /** Function for close / open callout */
        toggleIsCalloutVisible: () => void
    ) => JSX.Element;
}

/**
 * This component renders content with an option to collapse it
 * and render a button that will show the content in the callout instead
 * or just render content in extended way
 *
 * You could render your custom button through callback {@link onRenderButton}
 *
 * Could be used in such component as
 * - list where your need additional menu for an item
 * @param {CollapsibleContentProps} props The component props.
 * @returns {JSX.Element} The react component.
 */
export function CollapsibleContent(props: CollapsibleContentProps): JSX.Element {
    const { isCollapsed, children, buttonText = "Open Content", onRenderButton } = props;

    const buttonRef = useRef(null);

    const [isCalloutVisible, { toggle: toggleIsCalloutVisible, setFalse: closeCallout }] = useBoolean(false);

    const renderButton = () => {
        if (typeof onRenderButton === "function") {
            return onRenderButton(buttonRef, isCalloutVisible, toggleIsCalloutVisible);
        }

        return <DefaultButton elementRef={buttonRef} text={buttonText} onClick={toggleIsCalloutVisible} />;
    };

    if (isCollapsed) {
        return (
            <>
                {renderButton()}

                {isCalloutVisible && (
                    <Callout
                        target={buttonRef}
                        calloutMaxWidth={CALLOUT_WIDTH}
                        calloutMaxHeight={CALLOUT_HEIGHT}
                        isBeakVisible={false}
                        directionalHint={DirectionalHint.rightTopEdge}
                        onDismiss={closeCallout}
                        setInitialFocus
                    >
                        {children}
                    </Callout>
                )}
            </>
        );
    }

    return children;
}
