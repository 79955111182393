import { ILinkStyles, IStackStyles, useTheme } from "@fluentui/react";
import { systemColors } from "../../SystemColor";
import { Icon } from "../../SVGIcon";
import { isIconType } from "./utils";

type UseBreadcrumbItemStylesOutput = {
    container: IStackStyles;
    iconContainer: IStackStyles;
    textContainer: IStackStyles;
    link: ILinkStyles;
};

type UseBreadcrumbItemStylesProps = {
    icon?: Icon | string;
};

/**
 * Hook for styling the BreadcrumbItem component.
 * @param {UseBreadcrumbItemStylesProps} props - The props for the BreadcrumbItem component.
 * @returns {UseBreadcrumbItemStylesOutput} The styles for the BreadcrumbItem component.
 */
export const useBreadcrumbItemStyles = ({ icon }: UseBreadcrumbItemStylesProps): UseBreadcrumbItemStylesOutput => {
    const { spacing } = useTheme();
    return {
        container: {
            root: {
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: spacing.s2,
                paddingRight: spacing.s2,
                overflow: "hidden",
            },
        },
        iconContainer: {
            root: {
                flexShrink: 0,
                ...(isIconType(icon)
                    ? {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "1.5rem",
                          width: "1.5rem",
                          borderRadius: "2px",
                          backgroundColor: icon.color !== undefined ? systemColors[icon.color] : undefined,
                      }
                    : {}),
            },
        },
        textContainer: {
            root: {
                overflow: "hidden",
            },
        },
        link: {
            root: {
                overflow: "hidden",
            },
        },
    };
};
