import { makeStyles } from "@fluentui/react";

export const useNotificationCardStyles = makeStyles(({ palette, fonts, spacing }) => ({
    progressBar: {
        backgroundColor: palette.blue,
    },
    itemProgress: {
        padding: `${spacing.s2} 0px`,
    },
    titleContainer: {
        fontWeight: fonts.xLarge.fontWeight,
        color: palette.neutralDark,
    },
}));
