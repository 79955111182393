import { useCallback, useMemo } from "react";
import { ModelItem } from "../types/ModelItem";
import { ModelItemType } from "../types/ModelItemType";
import { isNil } from "lodash";
import { IDropdownOption } from "@fluentui/react";
import { SystemColorKey } from "iserver365-component-library/src/SystemColor";

type UseModelItemPickerProps = {
    /** Selected MetamodelItem id. */
    metamodelItemId: string | undefined;
    /** Related MetamodelItem id. */
    relatedMetamodelItemId: string | undefined;
    /** Relationship Type id */
    relationshipTypeId: string | undefined;
    /** Indicates whether the current picker is the source of the relationship. */
    isSource: boolean;
    /** Available Metamodel Items retriever. */
    metamodelItemsRetriever?: (
        relatedMetamodelItemId: string | undefined,
        relationshipTypeId: string | undefined,
        isSource: boolean
    ) => ModelItemType[];
    /** Available Model Items retriever. */
    modelItemsRetriever?: (searchText: string, metamodelItemId: string, modelId: string) => Promise<ModelItem[]>;
};

type UseModelItemPickerDataOutput = {
    modelItemsDropdownOptions: (searchText: string) => Promise<IDropdownOption[]>;
    metamodelItemsDropdownOptions: IDropdownOption[];
    icon: { name: string; color: SystemColorKey };
};

/**
 * Hook for retrieving data for the model item picker.
 * @param {UseModelItemPickerProps} props - The props.
 * @returns {UseModelItemPickerDataOutput} The data for the model item picker.
 */
export const useModelItemPickerData = ({
    metamodelItemId,
    relatedMetamodelItemId,
    relationshipTypeId,
    metamodelItemsRetriever,
    modelItemsRetriever,
    isSource,
}: UseModelItemPickerProps): UseModelItemPickerDataOutput => {
    const availableObjectTypes = useMemo(() => {
        if (isNil(metamodelItemsRetriever)) {
            return [];
        }

        return metamodelItemsRetriever(relatedMetamodelItemId, relationshipTypeId, isSource);
    }, [metamodelItemsRetriever, relatedMetamodelItemId, relationshipTypeId, isSource]);

    const modelItemsDropdownOptions = useCallback(
        async (searchText: string) => {
            if (isNil(modelItemsRetriever) || isNil(metamodelItemId) || isNil(searchText)) {
                return [];
            }

            const modelItems = await modelItemsRetriever(searchText, metamodelItemId, "");

            return modelItems.map(
                (object): IDropdownOption => ({
                    key: object.id,
                    text: object.name,
                })
            );
        },
        [metamodelItemId, modelItemsRetriever]
    );

    const metamodelItemsDropdownOptions: IDropdownOption[] = useMemo(() => {
        return availableObjectTypes.map(
            (objectType): IDropdownOption => ({
                key: objectType.id,
                text: objectType.name,
            })
        );
    }, [availableObjectTypes]);

    const selectedMetamodelItem = availableObjectTypes.find((o) => o.id === metamodelItemId);

    const icon = !isNil(selectedMetamodelItem)
        ? {
              name: selectedMetamodelItem.icon,
              color: selectedMetamodelItem.color,
          }
        : {
              name: "",
              color: "silverChalice" as SystemColorKey,
          };

    return {
        modelItemsDropdownOptions,
        metamodelItemsDropdownOptions,
        icon,
    };
};

export type { UseModelItemPickerProps, UseModelItemPickerDataOutput };
