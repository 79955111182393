import { isNil } from "lodash";
import { ValidationError, ValidationRule, ValidationRuleProps } from "../../types";

type Value = Date | undefined;

export interface MinDateErrorProps extends ValidationRuleProps<Value> {
    /** Minimum Date allowed for the input. */
    minDate: Date;
}

/**
 * MinDateError validation error class.
 */
export class MinDateError implements ValidationError {
    constructor(props: MinDateErrorProps) {
        this.name = "MinDateError";
        this.minDate = props.minDate;
        this.message = `The date must be after ${props.minDate.toDateString()}.`;
    }
    name: string;
    minDate: Date;
    message: string;
}

/**
 * MinDate validation rule class.
 */
export class MinDate extends ValidationRule<Value> {
    static Error = MinDateError;
    constructor(props: MinDateErrorProps) {
        super(props);
        this.minDate = props.minDate;
    }

    minDate: Date;

    validate(value: Value): MinDateError | null {
        if (isNil(value)) {
            return null;
        }

        if (value < this.minDate) {
            return new MinDateError({ minDate: this.minDate });
        }

        return null;
    }
}
