import { ApolloError, NetworkError } from "@apollo/client/errors";
import { GraphQLError } from "graphql";

/**
 * Logs proper expanded error message.
 * @param {string} eventType Event type.
 * @param {GraphQLError | NetworkError} error error object
 */
function logError(eventType: string, error: GraphQLError | NetworkError | ApolloError): void {
    console.error("%s: %o", eventType, error);
}

const communicationErrorCodes = [
    "INTERNAL_SERVER_ERROR",
    "GRAPHQL_VALIDATION_FAILED",
    "GRAPHQL_PARSE_FAILED",
    "PERSISTED_QUERY_NOT_SUPPORTED",
    "PERSISTED_QUERY_NOT_FOUND",
];

export { logError, communicationErrorCodes };
