import React from "react";
import { ComboBox } from "@fluentui/react";
import { FilterOperatorHandler } from "../QuickFilter.interfaces";
import { OperatorFilter } from "../useQuickFilterReducer";
import { getOperatorOptions } from "./utilsFilterOperator";

export type FilterOperatorProps = {
    /** Field of current row */
    operator: OperatorFilter;

    /** Handler for current operator */
    operatorHandler: FilterOperatorHandler;
};

/**
 * FilterOperator component
 * @param {FilterOperatorProps} props for category
 * @returns {JSX.Element} return React element
 */
export function FilterOperator({ operator, operatorHandler }: FilterOperatorProps): JSX.Element {
    return (
        <ComboBox
            allowFreeform
            autoComplete="on"
            ariaLabel="Operator"
            disabled={operator.type === null}
            selectedKey={operator.value}
            options={getOperatorOptions(operator)}
            onChange={(event, option) => {
                if (option === undefined) {
                    return;
                }

                operatorHandler(Number(option.key));
            }}
        />
    );
}
