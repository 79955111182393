import React from "react";
import { TextField } from "../TextField";

/**
 * The props for the {@link ChoiceField} component.
 */
export interface ChoiceFieldProps {
    /** Enable readOnly mode */
    readOnly?: boolean;
    /** Text for component label */
    label?: string;
    /** Specifies the placeholder to display in readOnly mode, displayed when no value provided. */
    readOnlyPlaceholder?: string;
    /** Values of the component */
    values?: string[];
}

/**
 * Renders the choice selection component (in current state, supports only readOnly mode).
 * In readOnly mode displays the selected choice values.
 * @param {ChoiceFieldProps} props The component props.
 * @returns {JSX.Element} The react element.
 */
export function ChoiceField({
    readOnly = false,
    label,
    readOnlyPlaceholder = "-",
    values,
}: ChoiceFieldProps): JSX.Element {
    const displayValue = values?.join(", ");
    //TODO: redesign component to provide all structure for choice selection

    return readOnly ? (
        <TextField readOnly placeholder={readOnlyPlaceholder} value={displayValue} label={label} />
    ) : (
        <></>
    );
}
