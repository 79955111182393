import { IToggleStyles } from "@fluentui/react";
import React from "react";

export interface BetaToggleStyles {
    shimmerStyle?: React.CSSProperties;
    betaToggleDivStyle?: React.CSSProperties;
    toggleStyles?: Partial<IToggleStyles>;
    betaTextStyle?: React.CSSProperties;
    iTextStyle?: React.CSSProperties;
}

/**
 * Returns default styles for the Beta Toggle component.
 * @returns {string} {@link BetaToggleStyles} component default styles.
 */
export function useStyles(): BetaToggleStyles {
    return {
        shimmerStyle: {
            marginLeft: "auto",
        },
        betaToggleDivStyle: {
            display: "flex",
            alignItems: "center",
        },
        toggleStyles: {
            root: {
                marginBottom: "0px",
            },
            label: {
                fontFamily: "Segoe UI",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "20px",
                marginLeft: "8px",
                textAlign: "center",
                wordBreak: "keep-all",
                textWrap: "nowrap",
            },
        },
        betaTextStyle: {
            background: "#FF8469",
            borderRadius: "25px",
            display: "inline-block",
            fontFamily: "Segoe UI",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "20px",
            textAlign: "center",
            margin: "8px",
            padding: "2px 12px 4px 12px",
        },
        iTextStyle: {
            fontSize: "large",
            verticalAlign: "sub",
        },
    };
}
