import React from "react";
import { ActionButton, ProgressIndicator, Text } from "@fluentui/react";
import { Card } from "../Card";
import { NotificationCardHeader } from "./NotificationCardHeader";
import { NotificationStatus, NotificationCardSharedProps } from "./types";
import { useNotificationCardStyles } from "./NotificationCard.styles";
import { useStatusInfo } from "./useStatusInfo";
import { CustomAction } from "iserver365-topbar/src/types/CustomAction";

/**
 * The props for the {@link NotificationCard} component.
 */
export interface NotificationCardProps extends NotificationCardSharedProps {
    notificationId: number;
    /** The notification status to control component state. */
    status: NotificationStatus;
    /** The title of notification. */
    title: string;
    /** The description of notification. */
    description?: string;
    /**
     * Percentage of the operation's completeness, numerically between 0 and 100.
     * If this is not set the progress bar will be hidden.
     */
    progress?: number;
    /** * The custom action object to reader an additional link. */
    customAction?: CustomAction;

    setIsCalloutVisible: (isCalloutVisible: boolean, notificationId: number) => void;
    isOpen: boolean;
}

/**
 * NotificationCard component renders cards in `Info`, `Success`, `Pending`, `Warning`, and `Error` statuses.
 * It can display a progress bar by specifying the completion percentage in the `progress` property (0 - 100).
 * Delete Notification button is driven by `onDelete()` callback function, and is hidden when `onDelete()` is not defined.
 * We can also specify additional Messages and Start/End dates.
 * In order to render custom actions inside the component we need to populate customAction object.
 * @param {NotificationCardProps} props The component props.
 * @returns {JSX.Element} The React component.
 */
export function NotificationCard(props: NotificationCardProps): JSX.Element {
    const {
        status,
        statusText,
        notificationType,
        title,
        description,
        progress,
        labels,
        startDate,
        endDate,
        messages,
        customAction,
        deleteButtonProps,
        setIsCalloutVisible,
        notificationId,
        isOpen,
    } = props;
    const { progressBar, itemProgress, titleContainer } = useNotificationCardStyles();
    const statusInfo = useStatusInfo(status, progress);

    return (
        <Card
            data-test-id="notification-card"
            title={title}
            onRenderHeaderTitle={() => (
                <NotificationCardHeader
                    statusColor={statusInfo.color}
                    statusIcon={statusInfo.icon}
                    statusText={statusText}
                    notificationType={notificationType}
                    startDate={startDate}
                    endDate={endDate}
                    messages={messages}
                    labels={labels}
                    deleteButtonProps={deleteButtonProps}
                    setIsCalloutVisible={setIsCalloutVisible}
                    isOpen={isOpen}
                    notificationId={notificationId}
                />
            )}
        >
            {progress !== undefined && (
                <ProgressIndicator
                    styles={{ progressBar, itemProgress }}
                    ariaValueText={`${labels.ariaLabelForProgressIndicator} ${progress}`}
                    percentComplete={progress / 100}
                />
            )}

            <Text
                data-test-id="notification-card-title"
                variant="medium"
                block
                nowrap
                className={titleContainer}
                title={title}
            >
                {title}
            </Text>

            {description !== undefined && (
                <Text variant="medium" block nowrap title={description}>
                    {description}
                </Text>
            )}

            {customAction !== null && customAction !== undefined && (
                <ActionButton
                    href={customAction.url}
                    target="_blank"
                    iconProps={{ iconName: "View" }}
                    aria-label={customAction.text}
                >
                    {customAction.text}
                </ActionButton>
            )}
        </Card>
    );
}
