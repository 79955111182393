export enum EventTypes {
    legacyAppIsVisibleEventType = "legacyAppIsVisible",
    legacyAppNavigationEventType = "legacyAppNavigation",
    legacyAppContextChangeEventType = "legacyAppContextChange",
    refreshLegacyViewsEventType = "refreshLegacyViews",
    roleChangeEventType = "roleChange",
    mscContextChangeEventType = "mscContextChanged",
    setUnsavedChangesEventType = "setUnsavedChanges",
    confirmUnsavedChangesEventType = "confirmUnsavedChanges",
    confirmUnsavedChangesResponseEventType = "confirmUnsavedChangesResponse",
    resetUnsavedChangesEventType = "resetUnsavedChanges",
    displayNotificationEventType = "displayNotification",
    legacyAppAccessDeniedEventType = "legacyAppAccessDeniedEventType",
    eventReceivedEventType = "eventReceived",
    modalOpenedEventType = "modalOpened",
    openNewWebAppFlyOut = "openNewWebAppFlyOut",
    refreshLegacyAppListView = "refreshLegacyAppListView",
    onObjectMerged = "onObjectMerged",
    legacyAppError = "legacyAppError",
    unmountParcelError = "unmountParcelError",
    closeSidePanel = "closeSidePanel",
    onObjectsEdited = "onObjectsEdited",
    onLegacyReloaded = "legacyReloaded",
    mergeObjectsSidePanelClosed = "mergeObjectsSidePanelClosed",
    editObjectsSidePanelClosed = "editObjectsSidePanelClosed",
    modelItemDetailsSidePanelClosed = "modelItemDetailsSidePanelClosed",
    mergeModelsSidePanelClosed = "mergeModelsSidePanelClosed",
}
