import React from "react";
import { PrimaryButton, DefaultButton, Dialog, DialogFooter, DialogType } from "@fluentui/react";
import { useStyles } from "./DialogBox.styles";
import { getEventName } from "../utils";

/**
 * The props for the {@link DialogBox} component.
 */
export interface DialogBoxProps {
    isOpen: boolean;
    title: string;
    subText: string;
    closeButtonLabel?: string;
    minWidth: number;
    onConfirm: () => void;
    onDismiss: () => void;
    confirmButtonTitle: string;
    dismissButtonTitle: string;
    eventName?: string;
    disabledButtons?: boolean;
}

/**
 * Renders the basic structure of the DialogBox component.
 * @param {DialogBoxProps} props The component props.
 * @returns {JSX.Element} The React component.
 */
export function DialogBox(props: DialogBoxProps): JSX.Element {
    const styles = useStyles();
    const closeButtonLabel = props.closeButtonLabel === undefined ? "Close" : props.closeButtonLabel;

    const contentProps = {
        type: DialogType.normal,
        title: props.title,
        subText: props.subText,
        // Replace default close button to track 'Dismiss' action
        showCloseButton: false,
        topButtonsProps: [
            {
                iconProps: { iconName: "Cancel" },
                ariaLabel: closeButtonLabel,
                title: closeButtonLabel,
                onClick: props.onDismiss,
                styles: styles.closeButton,
                "data-is365-id": getEventName(props.eventName, "Dismiss"),
            },
        ],
    };

    return (
        <Dialog
            hidden={!props.isOpen}
            onDismiss={props.onDismiss}
            dialogContentProps={contentProps}
            modalProps={{ isBlocking: true, layerProps: { eventBubblingEnabled: true } }}
            minWidth={props.minWidth}
            styles={styles.dialog}
        >
            <DialogFooter>
                <PrimaryButton
                    onClick={props.onConfirm}
                    text={props.confirmButtonTitle}
                    data-is365-id={getEventName(props.eventName, "Confirm")}
                    disabled={props.disabledButtons}
                />
                <DefaultButton
                    onClick={props.onDismiss}
                    text={props.dismissButtonTitle}
                    data-is365-id={getEventName(props.eventName, "Dismiss")}
                    disabled={props.disabledButtons}
                />
            </DialogFooter>
        </Dialog>
    );
}
