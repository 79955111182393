import { CSSProperties, useMemo } from "react";
import { useTheme, ITextStyles, IButtonStyles, IStackStyles } from "@fluentui/react";

interface RichTextFieldProps {
    displayValidationError: boolean;
    disabled: boolean;
    readOnly: boolean;
}

export interface RichTextFieldStyles {
    /** Rich Text field container styles */
    richTextFieldWrapper: IStackStyles;

    /** Editor Container styles */
    editorContainer: IStackStyles;

    /** Wrapper styles */
    wrapper: CSSProperties;

    /** Editor styles */
    editor: CSSProperties;

    /** Label styles */
    label: ITextStyles;

    /** Label container styles */
    labelContainer: IStackStyles;

    /** Toolbar Styles for scrollable pane */
    toolbar: CSSProperties;

    /** Styles of button to clear all text */
    clearAllBtn: IButtonStyles;

    /** Asterisk Styles */
    asterisk: CSSProperties;

    /** error Styles */
    error: CSSProperties;

    /** Placeholder styles for readOnly mode. */
    placeholder: ITextStyles;
}

/**
 * Hook that returns the styles object used for the ListView.
 * @param {RichTextFieldProps} props The styles component props.
 * @returns {RichTextFieldStyles} The styles object.
 */
export function useStyles({ displayValidationError, disabled, readOnly }: RichTextFieldProps): RichTextFieldStyles {
    const theme = useTheme();

    return useMemo(
        () => ({
            richTextFieldWrapper: {
                root: {
                    display: "block",
                },
            },
            editorContainer: {
                root: {
                    borderRadius: theme.effects.roundedCorner2,
                    border: displayValidationError
                        ? `1px solid ${theme.semanticColors.errorText}`
                        : `1px solid ${theme.palette.neutralSecondaryAlt}`,
                },
            },
            wrapper: {
                display: "flex",
                flexDirection: "column",
                cursor: disabled ? "not-allowed" : "text",
                backgroundColor: disabled ? theme.palette.neutralLighter : "inherit",
                flex: 1,
            },
            editor: {
                paddingLeft: theme.spacing.s2,
                paddingRight: theme.spacing.s2,
                backgroundColor: disabled ? theme.palette.neutralLighter : "inherit",
                opacity: disabled ? 0.5 : 1,
                overflow: "hidden",
                flex: 1,
            },
            toolbar: {
                backgroundColor: disabled ? theme.palette.neutralLighter : theme.palette.neutralLighterAlt,
                border: "none",
            },
            label: {
                root: {
                    display: "block",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    paddingTop: theme.spacing.s1,
                    paddingBottom: theme.spacing.s1,
                    color: theme.semanticColors.inputText,
                    opacity: disabled ? 0.5 : 1,
                    fontWeight: theme.fonts.xLarge.fontWeight,
                },
            },
            labelContainer: {
                root: {
                    flexFlow: "row nowrap",
                    alignItems: "flex-end",
                    justifyContent: "space-between",
                },
            },
            clearAllBtn: {
                root: {
                    paddingBottom: theme.spacing.s1,
                    height: "auto",
                },
                flexContainer: {
                    alignItems: "flex-end",
                },
                label: {
                    paddingBottom: "2px",
                },
            },
            asterisk: {
                color: theme.semanticColors.errorText,
                padding: theme.spacing.s1,
            },
            error: {
                fontSize: 12,
                color: theme.semanticColors.errorText,
            },
            placeholder: {
                root: {
                    paddingLeft: readOnly ? 0 : theme.spacing.s2,
                },
            },
        }),
        [theme, displayValidationError, disabled, readOnly]
    );
}
