import React from "react";
import { CardProps } from "./types";
import { CardHeader } from "./CardHeader";

/**
 * Renders the basic structure of the card with custom component inside.
 * @param {CardProps} props The component props.
 * @returns {JSX.Element} The React component.
 */
export function Card(props: CardProps): JSX.Element {
    return (
        <article>
            <CardHeader {...props} />
            {props.children}
        </article>
    );
}
