import { ITextFieldStyleProps, ITextFieldStyles } from "@fluentui/react";

/**
 * Returns the default styles for the Text field.
 * @param {boolean} readOnly Is component in read only mode.
 * @returns {Partial<ITextFieldStyles>} The default styles for the Text field.
 */
export function getDefaultStylesFunction(
    readOnly: boolean
): (props: ITextFieldStyleProps) => Partial<ITextFieldStyles> {
    return (_: ITextFieldStyleProps): Partial<ITextFieldStyles> => {
        return {
            fieldGroup: readOnly && {
                backgroundColor: "inherit",
                input: {
                    padding: 0,
                },
            },
        };
    };
}
