import React from "react";
import { merge, Stack, StackItem } from "@fluentui/react";
import { BadgeStyles, useBadgeStyles } from "./Badge.styles";

export type BadgeProps = {
    /** Children is wrapped react component */
    children: JSX.Element;
    /** Name for badge aria-label */
    ariaLabel?: string;
    /** Content for badge item */
    badgeContent: string;
    /** Custom badge styles */
    styles?: BadgeStyles;
};

/**
 * The badge component shows a small badge to the top-right of it's content.
 *
 * This is commonly used to associate a number with it's content, e.g. an icon,
 * to convey the number of items or a number of action points for the content.
 *
 * Examples:
 * - The number of emails in an inbox
 * - The number of unread notifications
 * - The number selected / total items etc.
 * @param {BadgeProps} props The component props
 * @returns {JSX.Element} return react component
 */
export function Badge(props: BadgeProps): JSX.Element {
    const { children, ariaLabel, badgeContent, styles: customStyles } = props;

    const defaultStyles = useBadgeStyles();
    const styles = merge(defaultStyles, customStyles);

    return (
        <Stack styles={styles.badgeContainerStyles}>
            {children}
            <StackItem aria-label={ariaLabel} styles={styles.badgeStyles}>
                {badgeContent}
            </StackItem>
        </Stack>
    );
}
