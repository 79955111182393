import { CategoryType } from "./QuickFilter.interfaces";
import { Filter as Condition, State as Filter, ValuesType } from "./useQuickFilterReducer";

/**
 * Verifies whether the condition is valid.
 * @param {Condition} condition - condition to validate
 * @returns {boolean} true means valid
 */
export function isConditionValid(condition: Condition): boolean {
    const { category, operator, values } = condition;

    // a form with errors is invalid
    const someErrorsExist = [category, operator, values].some(({ error }) => error !== null);
    if (someErrorsExist) {
        return false;
    }

    // a form without values in the 'category' and the 'operator' fields is invalid
    if (category.value === null || (operator.value === null && category.type !== CategoryType.Relationship)) {
        return false;
    }

    // a form with hidden 'values' field is valid
    if (values.type === ValuesType.Hidden) {
        return true;
    }

    // a form with an empty array or an empty string as a value is invalid
    if (Array.isArray(values.value) || typeof values.value === "string") {
        return values.value.length > 0;
    }

    // a form without a value in the 'values' field is invalid
    return values.value !== null;
}

/**
 * Verifies whether two filters are equal
 * using custom comparison logic:
 * it checks whether every condition from each filter
 * is present in the other filter.
 * @param {Filter} filterA - first filter to compare
 * @param {Filter} filterB - second filter to compare
 * @returns {boolean} true means equal
 */
export function areFiltersEqual(filterA: Filter, filterB: Filter): boolean {
    if (filterA.length !== filterB.length) {
        return false;
    }

    const filerAConditionMissedInTheFilterB = filterA.find((condition) => !isConditionInFilter(condition, filterB));
    const filterBHasAllFilterAConditions = filerAConditionMissedInTheFilterB === undefined;

    const filerBConditionMissedInTheFilterA = filterB.find((condition) => !isConditionInFilter(condition, filterA));
    const filterAHasAllFilterBConditions = filerBConditionMissedInTheFilterA === undefined;

    return filterBHasAllFilterAConditions && filterAHasAllFilterBConditions;
}

/**
 * Verifies whether the condition is present in the filter.
 * @param {Condition} conditionA - condition to find
 * @param {Filter} filter - filter, where to look
 * @returns {boolean} true means the condition is present in the filter
 */
export function isConditionInFilter(conditionA: Condition, filter: Filter): boolean {
    return filter.find((conditionB) => areConditionsEqual(conditionA, conditionB)) !== undefined;
}

/**
 * Verifies whether two conditions are equal
 * using custom comparison logic.
 * @param {Condition} conditionA - first condition to compare
 * @param {Condition} conditionB - second condition to compare
 * @returns {boolean} true means equal
 */
export function areConditionsEqual(conditionA: Condition, conditionB: Condition): boolean {
    if (conditionA.category.value !== conditionB.category.value) {
        return false;
    }

    if (conditionA.operator.value !== conditionB.operator.value) {
        return false;
    }

    if (conditionA.values.type !== conditionB.values.type) {
        return false;
    }

    if (Array.isArray(conditionA.values.value) && Array.isArray(conditionB.values.value)) {
        const valueA = conditionA.values.value;
        const valueB = conditionB.values.value;

        return JSON.stringify([...valueA].sort()) === JSON.stringify([...valueB].sort());
    }

    return conditionA.values.value === conditionB.values.value;
}
