import React from "react";
import {
    Link,
    Stack,
    StackItem,
    Text,
    TooltipHost,
    TooltipOverflowMode,
    mergeStyleSets,
    useTheme,
} from "@fluentui/react";
import { useTicketStyles } from "./Ticket.styles";
import { SVGIcon } from "../SVGIcon";
import { SystemColorKey } from "../SystemColor";
import { isNil } from "lodash";
import { SearchableDropdown } from "../SearchableDropdown";
import { SearchableDropdownProps } from "../SearchableDropdown/SearchableDropdown";

export type TicketProps = {
    /** The label to be displayed above the ticket. */
    label: string;
    /** The title of the ticket. */
    title: string;
    /** The subtitle of the ticket. */
    subTitle: string;
    /** The description of the ticket. */
    description: string;
    /** The system icon to be displayed on the ticket. */
    icon: {
        name: string;
        color: SystemColorKey;
    };
    /** The props that are passed to the title picker when edit mode enabled. */
    titlePicker?: SearchableDropdownProps;
    /** The props that are passed to the subTitle picker when edit mode enabled. */
    subTitlePicker?: SearchableDropdownProps;
    /** Flag that indicates whether components is in read only mode. */
    readOnly?: boolean;
    /** Flag that indicates component content is loading. */
    loading?: boolean;
    /** The callback to be called when the title is clicked. */
    onTitleClick?: () => void;
};

/**
 * Renders a Ticket component that can be used to label entity.
 * @param {TicketProps} props - The props.
 * @returns {JSX.Element} The rendered Ticket component.
 */
export const Ticket = ({
    label,
    title,
    subTitle,
    description,
    icon,
    readOnly = true,
    titlePicker,
    subTitlePicker,
    onTitleClick,
}: TicketProps): JSX.Element => {
    const { spacing } = useTheme();
    const styles = useTicketStyles(icon.color);

    return (
        <Stack tokens={{ childrenGap: spacing.s2 }} horizontalAlign="start">
            <Text styles={styles.label}>{label}</Text>
            <Stack horizontal styles={styles.container}>
                <StackItem styles={styles.iconContainer}>
                    <SVGIcon icon={{ icon: icon.name, color: icon.color }} height={spacing.m} width={spacing.m} />
                </StackItem>
                <Stack styles={styles.contentContainer}>
                    <StackItem styles={styles.subTitleContainer}>
                        {!readOnly && !isNil(subTitlePicker) ? (
                            <SearchableDropdown
                                {...subTitlePicker}
                                styles={mergeStyleSets(subTitlePicker.styles, styles.subTitleDropdownPicker)}
                            />
                        ) : (
                            <TooltipHost overflowMode={TooltipOverflowMode.Parent} content={subTitle}>
                                <Text styles={styles.subTitle}>{subTitle}</Text>
                            </TooltipHost>
                        )}
                    </StackItem>
                    <StackItem>
                        {!readOnly && !isNil(titlePicker) ? (
                            <SearchableDropdown {...titlePicker} />
                        ) : (
                            <TooltipHost overflowMode={TooltipOverflowMode.Parent} content={title}>
                                {!isNil(onTitleClick) ? (
                                    <Link onClick={onTitleClick} as="div" styles={styles.title}>
                                        {title}
                                    </Link>
                                ) : (
                                    <Text styles={styles.title}>{title}</Text>
                                )}
                            </TooltipHost>
                        )}
                    </StackItem>
                    <StackItem styles={styles.description}>
                        <TooltipHost overflowMode={TooltipOverflowMode.Parent} content={description}>
                            {description}
                        </TooltipHost>
                    </StackItem>
                </Stack>
            </Stack>
        </Stack>
    );
};
