import { MFENames } from "./mfeNames";
import { getFeatures } from "iserver365-mfe-utility";
import { SingleSpaCustomEventDetail, navigateToUrl as navigate, unloadApplication } from "single-spa";
import {
    getViewsToLegacyAppRedirect,
    getLegacyAppToViewsRedirect,
    getLegacyAppToLandscapeViewRedirect,
    getRedirectToBetaListView,
    legacyAppSavedListViewPathname,
    savedListViewPathname,
    getRedirectToLegacyListView,
} from "iserver365-views/redirects";
import { confirmNavigation, shouldShowUnsavedChangesConfirmationDialog, navigateToUrl } from "iserver365-navigator/api";
import { EventTypes as NavigatorEventTypes } from "iserver365-navigator-utility";
import { EventTypes as AuthorizationEventTypes } from "iserver365-authorization-utility";
import { EventTypes as InfrastructureEventTypes, listenEvent, raiseEvent } from "iserver365-infrastructure-utility";
import { SettingsData, EventTypes as SettingsEventTypes, getSettings } from "iserver365-settings-utility";
import { EventTypes } from "./types/EventTypes";
import { sendEventToIframe } from "iserver365-legacy-app/app";
import { getDisabledWorkspacesRedirectUrl } from "iserver365-content-management/redirects";
import { getDisabledDrawRedirectUrl } from "iserver365-draw/redirects";

type RedirectInformation = {
    isLegacyModellingContentSectionUrl: boolean;
    isLegacyConfigureSectionUrl: boolean;
    needsRedirectToBetaListView: boolean;
    needsRedirectToLegacyListView: boolean;
    needsRedirectToDisabledWorkspaces: boolean;
    needsRedirectToDisabledDraw: boolean;
};

/**
 * Take changes from https://dev.azure.com/Orbus/Orbus/_git/iserver365-webapp/pullrequest/5502
 * when 117632 is merged back into dev
 */
const legacyAppUrlRegex = /(?:[a-z]{2}-[a-z]{2}\/\/?)?(modellingcontent|configure)(?:#?!?\/?)/i;
const urlRetrievers = [
    getUrlFromViewsToLegacyApp,
    getUrlFromLegacyAppToViews,
    getOverriddenLandscapeViewUrl,
    getOverriddenListViewUrl,
    getDisabledWorkspacesUrl,
    getDisabledDrawUrl,
];
const configureSection = "configure";

const errorPath = "/error";
let unsavedChangesHandled = false;

function getUrlFromViewsToLegacyApp(url: URL): string | undefined {
    const features = getFeatures();
    if (features.enableViews === "False") {
        return getViewsToLegacyAppRedirect(url);
    }

    return undefined;
}

function getUrlFromLegacyAppToViews(url: URL): string | undefined {
    const features = getFeatures();
    if (features.enableViews === "True") {
        return getLegacyAppToViewsRedirect(url);
    }

    return undefined;
}

function getOverriddenLandscapeViewUrl(url: URL): string | undefined {
    return getLegacyAppToLandscapeViewRedirect(url);
}

function getOverriddenListViewUrl(url: URL, urlInformation: RedirectInformation): string | undefined {
    if (urlInformation.needsRedirectToBetaListView) {
        return getRedirectToBetaListView(url);
    }

    if (urlInformation.needsRedirectToLegacyListView) {
        return getRedirectToLegacyListView(url);
    }

    return undefined;
}

function getDisabledWorkspacesUrl(url: URL, urlInformation: RedirectInformation): string | undefined {
    if (urlInformation.needsRedirectToDisabledWorkspaces) {
        return getDisabledWorkspacesRedirectUrl(url);
    }

    return undefined;
}

function getDisabledDrawUrl(url: URL, urlInformation: RedirectInformation): string | undefined {
    if (urlInformation.needsRedirectToDisabledDraw) {
        return getDisabledDrawRedirectUrl(url);
    }

    return undefined;
}

function getRedirectInformation(url: string, settings: SettingsData | undefined): RedirectInformation {
    const redirectInformation = {
        isLegacyModellingContentSectionUrl: false,
        isLegacyConfigureSectionUrl: false,
        needsRedirectToBetaListView: false,
        needsRedirectToLegacyListView: false,
        needsRedirectToDisabledWorkspaces: false,
        needsRedirectToDisabledDraw: false,
    };

    redirectInformation.needsRedirectToBetaListView = shouldRedirectToBetaListView();

    redirectInformation.needsRedirectToLegacyListView = shouldRedirectToLegacyListView();

    redirectInformation.needsRedirectToDisabledWorkspaces = settings?.features.workspaces === false;

    redirectInformation.needsRedirectToDisabledDraw = settings?.features?.onlineDiagramming !== true;

    const match = legacyAppUrlRegex.exec(url);

    if (match === null || match.length !== 2) {
        return redirectInformation;
    }

    if (match[1].toLowerCase() === configureSection) {
        redirectInformation.isLegacyConfigureSectionUrl = true;
    }

    redirectInformation.isLegacyModellingContentSectionUrl = !redirectInformation.isLegacyConfigureSectionUrl;

    return redirectInformation;

    function shouldRedirectToLegacyListView() {
        return (
            settings?.features.betaListView === false ||
            localStorage.getItem("UseBetaListView") === null ||
            (localStorage.getItem("UseBetaListView") === "false" && url.includes(savedListViewPathname))
        );
    }

    function shouldRedirectToBetaListView() {
        return (
            settings?.features.betaListView === true &&
            localStorage.getItem("UseBetaListView") === "true" &&
            url.includes(legacyAppSavedListViewPathname) &&
            url.includes("mode=List")
        );
    }
}

function adaptLegacyUrl(url: string): URL {
    return new URL(url.replace(legacyAppUrlRegex, ""));
}

function getOverriddenUrl(url: URL, urlInformation: RedirectInformation): string | undefined {
    let overriddenUrl: string | undefined = undefined;

    for (const urlRetriever of urlRetrievers) {
        overriddenUrl = urlRetriever(url, urlInformation);

        if (overriddenUrl !== undefined) {
            break;
        }
    }

    return overriddenUrl;
}

/**
 * Unloads view mode MFEs.
 */
function unloadViewModeMFEs(): void {
    unloadApplication(MFENames.Topbar);
    unloadApplication(MFENames.Sidebar);
    unloadApplication(MFENames.Dashboard);
}

/**
 * Add the event handlers for the single spa events.
 */
export function addEventHandlers(): void {
    listenEvent(AuthorizationEventTypes.forbiddenError, navigateToNoAccessUrl);
    listenEvent(InfrastructureEventTypes.GlobalError, navigateToAppErrorUrl);
    listenEvent(EventTypes.singleSpaBeforeRoutingEvent, handleBeforeRoutingEvent);
    listenEvent(SettingsEventTypes.viewModeChanged, unloadViewModeMFEs);
}

/**
 * Redirects to the legacy app when some MFE are disabled.
 * @param {SingleSpaCustomEventDetail} event Contains information about event.
 */
export async function handleBeforeRoutingEvent({
    newUrl,
    cancelNavigation,
}: SingleSpaCustomEventDetail): Promise<void> {
    if (shouldShowUnsavedChangesConfirmationDialog(newUrl) && !unsavedChangesHandled) {
        await handleUnsavedChanges();
    }

    const redirectInformation = getRedirectInformation(newUrl, await getSettings());

    // If it is a legacy configure section URL, override current location href.
    if (redirectInformation.isLegacyConfigureSectionUrl) {
        window.location.href = `${new URL(newUrl).origin}/${configureSection}`;
        return;
    }

    // Adapt URL if it is a legacy modelling content section URL.
    const url = redirectInformation.isLegacyModellingContentSectionUrl ? adaptLegacyUrl(newUrl) : new URL(newUrl);
    let overriddenUrl = getOverriddenUrl(url, redirectInformation);

    // After applying the URL retrievers, there are the following options:
    // - Overridden url has a value => replace current history state with the overridden URL.
    // - Overridden url doesn't have a value, but original URL was a legacy modelling
    //   section URL (i.e. /recycle-bin) => replace current history state with the adapted URL.
    // - Overridden url doesn't have a value, and the original URL was already a new
    //   app URL => do nothing.
    if (redirectInformation.isLegacyModellingContentSectionUrl) {
        overriddenUrl = overriddenUrl ?? url.toString();
    }

    if (overriddenUrl === undefined) {
        return;
    }

    cancelNavigation?.();
    window.history.replaceState(window.history.state, "", overriddenUrl);

    async function handleUnsavedChanges() {
        unsavedChangesHandled = true;
        cancelNavigation?.();
        const value = await confirmNavigation();
        if (value) {
            raiseEvent(NavigatorEventTypes.setUnsavedChangesEventType, { value: false });
            sendEventToIframe("navigateToInitialPage");
            navigate(newUrl);

            // We need to set unsavedChangesHandled to false
            // because singleSpaBeforeRoutingEvent is fired multiple times
            // and we want to avoid multiple confirmations and navigation calls
            unsavedChangesHandled = false;
        }
    }
}

/**
 * Navigates to application no access url.
 */
export function navigateToNoAccessUrl(): void {
    navigateToUrl("/no-access");
}

/**
 * Navigates to application error url.
 */
export function navigateToAppErrorUrl(): void {
    const isErrorUrlActive = window.location.pathname === errorPath;
    if (!isErrorUrlActive) {
        navigate(`${errorPath}?refreshUrl=${encodeURIComponent(window.location.pathname + window.location.search)}`);
    }
}
