import { isEmpty } from "lodash";
import { IChoiceDataEntry } from "../Choice";
import { TagItemDefinition } from "../Tags";

/**
 * Finds the names of selected choices.
 * @param {IChoiceDataEntry[]} choices - The array of choices.
 * @returns {string[]} An array of selected choice names.
 */
export const findSelectedChoicesNames = (choices: IChoiceDataEntry[]): string[] => {
    return choices.flatMap((choice) => {
        if (choice.selected) {
            return [choice.name];
        }

        return findSelectedChoicesNames(choice.children ?? []);
    });
};

/**
 * Returns an array of TagItemDefinition based on the selected choices.
 * @param {string[]} selectedChoices - The array of selected choices.
 * @returns {TagItemDefinition[]} An array of TagItemDefinition.
 */
export const getDisplayValueTags = (selectedChoices: string[]): TagItemDefinition[] =>
    selectedChoices
        .map((choice, i): TagItemDefinition | undefined => {
            return {
                key: `${choice}_${i}`,
                displayValue: choice,
            };
        })
        .filter((choice) => !isEmpty(choice?.displayValue)) as TagItemDefinition[];

/**
 * Calculates the selected choices based on the leaf choices to update.
 * @param {string[]} selectedChoices - The array of selected choices.
 * @param {IChoiceDataEntry[]} leafChoicesToUpdate - The leaf choices to update.
 * @param {boolean} isSelected - The new selected state.
 * @param {boolean} allowMultipleValues - Flag indicating whether multiple values are allowed.
 * @returns {string[]} The updated array of selected choices.
 */
export const calculateSelectedChoices = (
    selectedChoices: string[],
    leafChoicesToUpdate: IChoiceDataEntry[],
    isSelected: boolean,
    allowMultipleValues: boolean
): string[] => {
    const keysToUpdate = leafChoicesToUpdate.map((leafChoice) => leafChoice.key);

    return isSelected
        ? allowMultipleValues
            ? selectedChoices.concat(keysToUpdate)
            : keysToUpdate
        : selectedChoices.filter((choice) => !keysToUpdate.includes(choice));
};

/**
 * Updates the state of a single choice and its children.
 * @param {IChoiceDataEntry} choice - The choice to update.
 * @param {string[]} keysToUpdate - The keys of the choices to update.
 * @param {boolean} isSelected - The new selected state.
 * @returns {IChoiceDataEntry} The updated choice.
 */
export const updateSelected = (
    choice: IChoiceDataEntry,
    keysToUpdate: string[],
    isSelected: boolean
): IChoiceDataEntry => {
    return {
        ...choice,
        selected: keysToUpdate.includes(choice.key) ? isSelected : choice.selected,
        children: choice.children?.map((child) => updateSelected(child, keysToUpdate, isSelected)),
    };
};

/**
 * Updates the selected state of multiple choices.
 * @param {IChoiceDataEntry[]} choices - The array of choices.
 * @param {string[]} keysToUpdate - The keys of the choices to update.
 * @param {boolean} isSelected - The new selected state.
 * @returns {IChoiceDataEntry[]} The updated choices.
 */
export const updateSelectedChoicesValue = (
    choices: IChoiceDataEntry[],
    keysToUpdate: string[],
    isSelected: boolean
): IChoiceDataEntry[] => {
    return choices.map((choice) => updateSelected(choice, keysToUpdate, isSelected));
};
