import { get, isNil, noop, wrap } from "lodash";
import React, { useState } from "react";
import classNames from "classnames";
import { useDisabledContext } from "../../context";
import { LinkTargetOption } from "../../../types";
import { TopLevelOptionProps, OptionKeys } from "../types";
import { Option } from "../Option";

import { signalExpandShowModal } from "./LinkOption.service";
import { LinkOptionModal } from "./LinkOption.modal";

/**
 * Adapted from: https://github.com/jpuri/react-draft-wysiwyg/blob/705f518fa516f2839394d706e8b11bfd99734abd/src/controls/Link/Component/index.js
 * @param {TopLevelOptionProps} props The component props
 * @returns {JSX.Element} The react element.
 */
export function LinkOption({
    currentState,
    expanded = false,
    config,
    translations,
    onExpandEvent = noop,
    doCollapse,
    onChange,
}: TopLevelOptionProps): JSX.Element {
    const { className, options, link, unlink } = config;
    const [showModal, setShowModal] = useState(false);
    const [linkTarget, setLinkTarget] = useState(currentState.link?.target ?? "");

    const [linkTargetOption, setLinkTargetOption] = useState<LinkTargetOption>(
        get(link, "targetOption", LinkTargetOption._blank)
    );
    const [linkTitle, setLinkTitle] = useState(link?.title ?? currentState.selectionText ?? "");
    const disabled = useDisabledContext();

    let linkContent = null;
    let unlinkContent = null;
    if (options.includes(OptionKeys.link) && !isNil(link)) {
        linkContent = (
            <Option
                active={false}
                disabled={disabled}
                value="unordered-list-item"
                className={classNames(link.className)}
                icon={link.icon}
                onClick={wrap(
                    {
                        link,
                        setShowModal,
                        setLinkTarget,
                        setLinkTargetOption,
                        setLinkTitle,
                        linkTargetOption,
                        linkTarget: currentState.link?.target,
                        selectionText: currentState.selectionText,
                        onExpandEvent,
                    },
                    signalExpandShowModal
                )}
                aria-haspopup="true"
                aria-expanded={showModal}
                title={link.title ?? translations["components.controls.link.link"]}
            />
        );
    }

    if (options.includes(OptionKeys.unlink) && !isNil(unlink)) {
        unlinkContent = (
            <Option
                active={false}
                disabled={disabled || isNil(currentState.link)}
                value="ordered-list-item"
                className={classNames(unlink.className)}
                icon={unlink.icon}
                onClick={wrap<"unlink", string | undefined, ReturnType<typeof onChange>>("unlink", onChange)}
                title={unlink.title ?? translations["components.controls.link.unlink"]}
            />
        );
    }

    return (
        <div className={classNames("rdw-link-wrapper", className)} aria-label="Link Control">
            {linkContent}
            {unlinkContent}
            {expanded && showModal ? (
                <LinkOptionModal
                    config={config}
                    doCollapse={doCollapse}
                    linkTitle={linkTitle}
                    linkTarget={linkTarget}
                    linkTargetOption={linkTargetOption}
                    onChange={onChange}
                    setLinkTitle={setLinkTitle}
                    setLinkTarget={setLinkTarget}
                    setLinkTargetOption={setLinkTargetOption}
                    translations={translations}
                />
            ) : undefined}
        </div>
    );
}
