import { defaultColors, defaultSpacing } from "./theme";
import { createTheme, Theme } from "@fluentui/react";

const defaultTheme = createTheme({
    palette: defaultColors,
    spacing: defaultSpacing,
});

/**
 * Retrieves the current fluent {@link Theme} being applied for iserver365.
 * @returns {Theme} The current theme.
 */
function getCurrentTheme(): Theme {
    return defaultTheme;
}

export { getCurrentTheme };
