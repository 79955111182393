import React from "react";
import { IconButton, Stack, StackItem, Text, Shimmer, ITextStyles, IStackStyles } from "@fluentui/react";
import { usePageTitleStyles } from "./PageTitle.styles";
import { useHistory } from "react-router";
import { SystemColorKey } from "../SystemColor";
import { merge } from "lodash";
import { SVGIcon } from "../SVGIcon";

type BackButtonProps = {
    ariaLabel?: string;
    "data-is365-id"?: string;
    onClick?: () => void;
} & Record<string, string | (() => void)>;

/**
 * The props for the {@link PageTitle} component.
 */
export interface PageTitleProps {
    /** Page title */
    title: string;

    /** Page subtitle */
    subtitle?: string;

    /** Page tags */
    tags?: string[] | null;

    /** Icon name */
    iconName?: string;

    /** Icon color */
    iconColor?: SystemColorKey;

    /** Show back button */
    showBackButton?: boolean;

    /** Additional element for actions associated to the page. */
    actionElement?: JSX.Element;

    /** Additional props for back button */
    backButtonProps?: BackButtonProps;

    /** Whether to render loading state or data from props */
    loading?: boolean;

    /** If specified, displays title in one line, and truncates it, if title does not fit the screen. */
    singleLineTitle?: boolean;

    /** Additional title container styles */
    shimmerStyles?: IStackStyles;

    /** Additional title styles*/
    titleStyle?: ITextStyles;

    /** Additional subtitle styles*/
    subtitleStyle?: ITextStyles;

    /** Additional header styles*/
    headerStyle?: ITextStyles;

    /** Additional tag styles*/
    tagStyle?: IStackStyles;
}

/**
 * This component displays title of the page.
 * Also allows optionally specify icon and button which navigates to previous location.
 * @param {PageTitleProps} props The component props.
 * @returns {JSX.Element} The react element.
 */
export function PageTitle({
    title,
    subtitle,
    tags,
    iconName,
    iconColor = "default",
    showBackButton = false,
    actionElement,
    backButtonProps = {},
    loading = false,
    singleLineTitle = false,
    shimmerStyles,
    titleStyle,
    subtitleStyle,
    headerStyle,
    tagStyle,
}: PageTitleProps): JSX.Element {
    const styles = usePageTitleStyles({
        iconColor,
        backButtonVisible: showBackButton,
        truncateTitle: singleLineTitle,
    });
    const history = useHistory();

    return (
        <Stack as="header" verticalAlign="center" styles={merge(styles.header, headerStyle)} horizontal>
            {showBackButton && (
                <IconButton
                    styles={styles.backButton}
                    iconProps={{ iconName: "ChevronLeft" }}
                    onClick={() => history.goBack()}
                    {...backButtonProps}
                />
            )}

            <Shimmer width="20vw" isDataLoaded={!loading} styles={merge(styles.shimmer, shimmerStyles)}>
                <Stack verticalAlign="center" horizontal>
                    {iconName !== undefined && (
                        <Stack horizontalAlign="center" verticalAlign="center" styles={styles.iconContainer}>
                            <SVGIcon icon={{ icon: iconName, color: iconColor }} width="1.5rem" height="1.5rem" />
                        </Stack>
                    )}
                    <Stack verticalAlign="center" styles={styles.titleContainer}>
                        <Text className="oa-primary-title" as="h1" styles={merge(styles.title, titleStyle)}>
                            {title}
                        </Text>
                        <Text as="h2" styles={merge(styles.subtitle, subtitleStyle)}>
                            {subtitle}
                        </Text>
                    </Stack>
                    {tags !== undefined && tags !== null ? (
                        <Stack horizontal wrap>
                            {tags.map((tag) => (
                                <StackItem key={tag} styles={merge(styles.tag, tagStyle)}>
                                    {tag}
                                </StackItem>
                            ))}
                        </Stack>
                    ) : null}
                </Stack>
            </Shimmer>

            {actionElement}
        </Stack>
    );
}
