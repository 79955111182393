import React from "react";
import classNames from "classnames";
import { useDisabledContext } from "../../context";
import { TopLevelOptionProps } from "../types";
import { getOptions } from "./TopLevelOption.service";

export type { TopLevelOptionProps } from "../types";

/**
 * @param {TopLevelOptionProps} props The component props.
 * @returns {JSX.Element} The react element.
 */
export function TopLevelOption({
    onChange,
    currentState,
    translations,
    config: { className, component, dropdownClassName, inDropdown, options, ...config },
}: TopLevelOptionProps): JSX.Element {
    const disabled = useDisabledContext();

    return (
        <div className={classNames("rdw-inline-wrapper", className)} aria-label={"Inline Control"}>
            {getOptions({ config, currentState, disabled, onChange, styles: options, translations })}
        </div>
    );
}
