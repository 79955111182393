import { listenEvent, raiseEvent } from "iserver365-infrastructure-utility";
import { initLocalizedStrings, getCurrentLocale } from "iserver365-globalization-utility";
import { merge } from "lodash";
import { navigateToUrl as navigate, checkActivityFunctions } from "single-spa";
import { EventTypes } from "iserver365-navigator-utility";
import { NavigationStates } from "./types/navigationStates";
import LocalizedStrings from "react-localization";
import { sendEventToIframe } from "iserver365-legacy-app/app";

let navigationRouteStates: NavigationStates = {};

const translation = initLocalizedStrings();
let unsavedChanges = false;
let stateChangeWithinTheSameGroup = false;
const legacyAppNewViewUrls = ["/matrix/create", "/traceability/create", "/catalog/new"];

type NavigationKeyValuePair = {
    key: string;
    value: string | { exact?: boolean; url: string; features?: Record<string, boolean> };
};

function calculateCurrentNavigationKeyValuePair(): NavigationKeyValuePair | undefined {
    for (const key in navigationRouteStates) {
        const values = navigationRouteStates[key];
        const value = values.find((value) => {
            if (typeof value === "string") {
                return window.location.pathname.startsWith(value);
            }

            return value.exact === true
                ? window.location.pathname === value.url
                : window.location.pathname.startsWith(value.url);
        });

        if (value !== undefined) {
            return { key, value };
        }
    }

    return undefined;
}

function isURLFromLegacyApp(url: string) {
    return checkActivityFunctions(new URL(url, window.location.href) as unknown as Location).includes(
        "@iserver365/legacy-app"
    );
}

function isUrlFromLegacyNewView(url: string) {
    return legacyAppNewViewUrls.some((element) => url.includes(element));
}

/**
 * Gets the navigation item key by parsing URL.
 * @returns {string} The navigation item key.
 */
export function calculateCurrentNavigationState(): string | undefined {
    const pair = calculateCurrentNavigationKeyValuePair();

    return pair?.key;
}

/**
 * Gets the navigation features.
 * @returns {string} The navigation features.
 */
export function calculateCurrentNavigationFeatures(): Record<string, boolean> | undefined {
    const pair = calculateCurrentNavigationKeyValuePair();
    const value = pair?.value;

    if (value === undefined || typeof value === "string") {
        return undefined;
    }

    return value.features;
}

/**
 * Registers navigator app events to control the unload behavior and listen for setUnsavedChanges
 */
export function registerEvents(): void {
    window.onbeforeunload = (): string | undefined => {
        if (unsavedChanges) {
            const currentLocale = getCurrentLocale();
            const strings = new LocalizedStrings(translation);
            strings.setLanguage(currentLocale);

            return strings.unsavedChangesMessage;
        }

        return undefined;
    };

    listenEvent(
        EventTypes.setUnsavedChangesEventType,
        (data: { value: boolean; stateChangeWithinTheSameGroup: boolean }) => {
            unsavedChanges = data.value;
            stateChangeWithinTheSameGroup = data.stateChangeWithinTheSameGroup;
        }
    );
}

/**
 * Shows a confirmation dialog for a route change and returns a value if the response is positive.
 * @returns {Promise<boolean>} A promise value indicating if the confirmation is positive or not.
 */
export function confirmNavigation(): Promise<boolean> {
    raiseEvent(EventTypes.displayUnsavedNavigationDialogEventType, null);

    return new Promise<boolean>((resolver) => {
        const removeListener = listenEvent(EventTypes.navigationConfirmedEventType, (data: { value: boolean }) => {
            removeListener();
            resolver(data.value);
        });
    });
}

/**
 * Navigates the app to a url. If there are any unsaved changes
 * @param {string} url to navigate to.
 * @returns {Promise<boolean>} A promise value indicating if the navigation confirmation is positive or not.
 */
export async function navigateToUrl(url: string): Promise<boolean> {
    if (shouldShowUnsavedChangesConfirmationDialog(url)) {
        const value = await confirmNavigation();
        if (value) {
            raiseEvent(EventTypes.setUnsavedChangesEventType, { value: false, stateChangeWithinTheSameGroup: false });
            if (!isURLFromLegacyApp(url) && isURLFromLegacyApp(window.location.href)) {
                sendEventToIframe("navigateToInitialPage");
            }
            navigate(url);
        }
        return value;
    }
    // If the user is leaving new view page, we want it to be refreshed when he comes back
    else if (isUrlFromLegacyNewView(window.location.href) && !isURLFromLegacyApp(url)) {
        sendEventToIframe("navigateToInitialPage");
    }
    navigate(url);
    return true;
}
/**
 * Checks if the confirmation dialog should be shown.
 * @param {string} url to navigate to.
 * @returns {boolean} A value indicating if the confirmation dialog should be shown.
 */
export function shouldShowUnsavedChangesConfirmationDialog(url: string): boolean {
    const ignoredUrls = ["no-access"];
    return (
        unsavedChanges &&
        ignoredUrls.every((ignoredUrl) => !url.toLocaleLowerCase().includes(ignoredUrl)) &&
        // disabling tslint rule because stateChangeWithinTheSameGroup can be undefined
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-boolean-literal-compare
        (stateChangeWithinTheSameGroup === false || !isURLFromLegacyApp(url))
    );
}

/**
 * Registers navigation states that will be used by NavigationChanged event type to notify what is the current state based on url.
 * @param {Record<string, Array<string | { exact: boolean; url: string }>>} states States to register.
 */
export function registerStates(states: NavigationStates): void {
    navigationRouteStates = merge(navigationRouteStates, states);
}
