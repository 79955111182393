import React from "react";
import classNames from "classnames";
import { useDisabledContext } from "../../context";
import { Option } from "../Option";
import { TopLevelOptionProps } from "../types";

/**
 * Adapted from: https://github.com/jpuri/react-draft-wysiwyg/blob/705f518fa516f2839394d706e8b11bfd99734abd/src/controls/Remove/Component/index.js
 * @param {TopLevelOptionProps} props The component props.
 * @returns {JSX.Element} The react element.
 */
export function RemoveOption({
    config: { className, icon, title },
    onChange,
    translations,
}: TopLevelOptionProps): JSX.Element {
    const disabled = useDisabledContext();

    return (
        <div className="rdw-remove-wrapper" aria-label="Remove Control">
            <Option
                active={false}
                disabled={disabled}
                icon={icon}
                className={classNames(className)}
                onClick={onChange}
                title={title ?? translations["components.controls.remove.remove"]}
            >
                {icon}
            </Option>
        </div>
    );
}
