import React, { useContext } from "react";
import { initLocalizedStrings, getCurrentLocale } from "../../iserver365-globalization-utility/src";
import LocalizedStrings from "react-localization";
import { translationMFE } from "./translation";

const translation = initLocalizedStrings(translationMFE);

const currentLocale = getCurrentLocale();

const strings = new LocalizedStrings(translation);
strings.setLanguage(currentLocale);
interface LocalizationProps {
    children: React.ReactNode;
}

const LocalizationContext = React.createContext(strings);

// use default locale for translation types
export type Translation = typeof translation["en"] & {
    formatString: (key: string, ...args: string[]) => string;
};
export type TranslationKey = keyof Translation;

/**
 * The react context provider for localization support for the MFE.
 * @param {LocalizationProps} props The props for the component.
 * @returns {JSX.Element} The react component.
 */
export const LocalizationProvider = ({ children }: LocalizationProps): JSX.Element => {
    return <LocalizationContext.Provider value={strings}>{children}</LocalizationContext.Provider>;
};

/**
 * useLocalization hook.
 * @returns {Translation} Localized strings.
 */
export const useLocalization = (): Translation => {
    return useContext(LocalizationContext) as Translation;
};
