import { ApolloLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { raiseEvent } from "iserver365-infrastructure-utility";
import { logError, communicationErrorCodes } from "./errorHandling.utils";

/**
 * Returns apollo link that tracks communication errors when doing silent reload through web sockets or http and raises specified event type.
 * @param {string} eventType Event type.
 * @returns {ApolloLink} Apollo link.
 */
const getSilentReloadErrorHandlingLink = (eventType: string): ApolloLink => {
    return onError(({ graphQLErrors, networkError }): void => {
        if (networkError !== undefined) {
            logError(eventType, networkError);
            return;
        }

        const hasGraphQLCommunicationError = graphQLErrors?.some((error) =>
            communicationErrorCodes.includes(error.extensions?.code)
        );
        if (hasGraphQLCommunicationError === true) {
            graphQLErrors?.forEach((graphQLError) => logError(eventType, graphQLError));
        }

        raiseEvent(eventType);
    });
};

export { getSilentReloadErrorHandlingLink };
