import React from "react";

import { FontIcon } from "@fluentui/react";
import { useStyles } from "./Icon.styles";

interface IconProps {
    iconName: string;
}

/**
 * @param {IconProps} props {@link Icon} props.
 * @returns {JSX.Element} The react element.
 */
export function Icon({ iconName }: IconProps): JSX.Element {
    const styles = useStyles();
    return <FontIcon iconName={iconName} className={styles.fontIcon} />;
}
