import React, { useEffect, useMemo, useState } from "react";
import { isEqual, isNil, mergeWith } from "lodash";
import { HyperlinkField } from "../HyperlinkField";
import {
    IconButton,
    Stack,
    IStackStyles,
    ILabelStyles,
    Text,
    FontIcon,
    CommandBarButton,
    StackItem,
} from "@fluentui/react";
import { HyperlinkLabels, HyperLinkFieldValue } from "./Hyperlink.interface";
import { useHyperlinkStyles } from "./Hyperlink.styles";
import {
    addNewValue,
    deleteFromValues,
    getKeyedValues,
    getReadOnlyValues,
    getSingleOrMultipleValues,
    getValuesWithoutKey,
    getValueToDisplay,
} from "./Hyperlink.utils";
import { isAnyUrlValid, isAllValuesValid } from "./Hyperlink.validation";
import { v4 as newGUID } from "uuid";
import { useLocalization } from "../Localization.context";
import { Tags } from "../Tags";
import { TagItemDefinition } from "../Tags/types/TagItemDefinition";
import { ILabeledFieldProps, LinkTargetOption } from "../types";
import { FieldLabel } from "../FieldLabel";

/**
 * {@link Hyperlink} component props.
 */
export interface HyperlinkProps extends ILabeledFieldProps {
    /** The value of the input. */
    values?: HyperLinkFieldValue[];
    /** Require a Display Value for the input. */
    displayValueRequired?: boolean;
    /** Allow Multiple Value for the input. */
    allowMultipleValue?: boolean;
    /** A value indicating whether it is required. */
    required?: boolean;
    /** Should the input be displayed in readOnly mode. */
    readOnly?: boolean;
    /** Formatter for messages of the input, input is a messageCode and output is the message. */
    messageProvider?: (messageCode: string, params?: Record<string, string>) => string;
    /** Change event on the values */
    onChange?: (values: HyperLinkFieldValue[], validationErrors: Error[]) => void;
    /** Labels of the component */
    labels?: HyperlinkLabels;
    /** Indicates if we need to display default labels */
    showDefaultLabels?: boolean;
    /** Indicates if we need to display default value */
    showDisplayValue?: boolean;
    /** Styles for hyper link fields container */
    fieldsContainerStyles?: IStackStyles;
    /** Styles for labels */
    labelStyles?: ILabelStyles;
    /** Styles for hyperlink container  */
    containerStyles?: IStackStyles;
    /** Styles for label container */
    labelContainerStyles?: IStackStyles;
    /** Indicates if hyperlink should be disabled */
    disabled?: boolean;
    /** Specifies where to open the hyperlink  */
    linkTarget?: LinkTargetOption;
    /** Specifies placeholder to display in readOnly mode, displayed when no value provided. */
    readOnlyPlaceholder?: string;
}

/**
 * Hyperlink gives a way to enter and edit trusted hyperlink values.
 *
 * ### Features ###
 * - Ability to use the component in **Read** or **Edit** mode
 * - Ability to allow **Single** or **Multiple values**
 * - Ability to assign a **Display value** (i.e DisplayValue) alongside to a hyperlink value which will be used in read mode to display a friendly name of the link
 * - Ability to make value **Required**, in that case, it makes URL value required on each entry,
 * and if Multiple values, at least one entry  needs to be valid
 * - Validation that checks the validity of Hyperlink value
 * @param {HyperlinkProps} props Input props.
 * @returns {JSX.Element} Hyperlink Input.
 */
// For reused components we probably might have more props
// eslint-disable-next-line sonarjs/cognitive-complexity
export function Hyperlink(props: HyperlinkProps): JSX.Element {
    const {
        label,
        values,
        displayValueRequired = false,
        required = false,
        readOnly = false,
        messageProvider,
        labels,
        onChange,
        allowMultipleValue = false,
        showDefaultLabels = true,
        showDisplayValue = false,
        fieldsContainerStyles,
        labelStyles,
        containerStyles,
        labelContainerStyles,
        disabled,
        linkTarget = LinkTargetOption._blank,
        readOnlyPlaceholder,
        onRenderLabel,
    } = props;

    const messageProviderOrDefault = messageProvider ?? ((message) => message);
    const onChangeOrDefault = onChange ?? ((values) => values);
    const styles = useHyperlinkStyles();
    const translation = useLocalization();

    const defaultLabels = {
        displayValueHeaderLabel: "Link Name",
        valueHeaderLabel: "Link",
        displayValuePlaceholder: "Enter name",
        valuePlaceholder: "URL",
        deleteTitle: "Delete",
        addLinks: "Add",
    };

    const defaultValues = useMemo(
        () => [
            {
                displayValue: "",
                value: "",
                isValueValid: true,
                isDisplayValueValid: true,
            },
        ],
        []
    );

    const labelsOrDefault = mergeWith(defaultLabels, labels);

    const keyedValues = useMemo(() => getKeyedValues(values ?? defaultValues), [values, defaultValues]);
    const singleOrMultipleValues = useMemo(
        () => getSingleOrMultipleValues(keyedValues, allowMultipleValue),
        [keyedValues, allowMultipleValue]
    );

    const [currentValues, setHyperlinkValues] = useState(singleOrMultipleValues);

    const validationResult = isAnyUrlValid(currentValues, messageProviderOrDefault, required, displayValueRequired);
    const [currentValidation, setValidation] = useState(validationResult);
    const [isValuesValid, setIsValuesValid] = useState(isAllValuesValid(currentValues));

    useEffect(() => {
        // update state when new values was changed in parent
        if (!isEqual(getValuesWithoutKey(currentValues), getValuesWithoutKey(singleOrMultipleValues))) {
            setHyperlinkValues(singleOrMultipleValues);
        }
    }, [currentValues, singleOrMultipleValues]);

    const valuesToDisplay = useMemo(
        () =>
            getReadOnlyValues(currentValues).map(
                (item): TagItemDefinition => ({
                    key: newGUID(),
                    displayValue: getValueToDisplay(item.displayValue, item.value, displayValueRequired),
                    link: {
                        url: item.value ?? "",
                        target: linkTarget,
                    },
                    startIcon: () => <FontIcon iconName="Link" style={styles.fontIcon} />,
                })
            ),
        [currentValues, displayValueRequired, styles.fontIcon, linkTarget]
    );

    const readOnlyComponent = () => {
        return (
            <>
                <Tags items={valuesToDisplay} linkStyles={styles.link} />
                {valuesToDisplay.length === 0 && readOnlyPlaceholder && <Text>{readOnlyPlaceholder}</Text>}
            </>
        );
    };

    const getValidationMessage = (isValuesValid: boolean) => {
        return !isValuesValid
            ? [
                  {
                      name: "HyperlinkAttributeValidationError",
                      message: translation.hyperlinkNotValidMessage,
                  },
              ]
            : [];
    };

    const editComponent = () => {
        const onInputChange = (values: HyperLinkFieldValue[]) => {
            const valuesWithoutKey = getValuesWithoutKey(values);
            const validationResult = isAnyUrlValid(values, messageProviderOrDefault, required, displayValueRequired);

            const isValuesValid = isAllValuesValid(values);

            setIsValuesValid(isValuesValid);
            onChangeOrDefault(valuesWithoutKey, getValidationMessage(isValuesValid));
            setValidation(validationResult);
            setHyperlinkValues(values);
        };

        return (
            <Stack styles={fieldsContainerStyles}>
                <Stack style={styles.hyperlinksContainer}>
                    {currentValues.map((item, index) => (
                        <div key={item.key} style={styles.hyperlinkRow}>
                            <Stack grow={1} horizontal tokens={styles.hyperlinkRowContainerTokens}>
                                <StackItem grow={1}>
                                    <HyperlinkField
                                        disabled={disabled}
                                        linkNameLabel={
                                            index === 0 && showDefaultLabels
                                                ? labelsOrDefault.displayValueHeaderLabel
                                                : null
                                        }
                                        linkLabel={
                                            index === 0 && showDefaultLabels ? labelsOrDefault.valueHeaderLabel : null
                                        }
                                        displayValue={item.displayValue}
                                        value={item.value}
                                        displayValueRequired={displayValueRequired}
                                        valueRequired={required || showDisplayValue}
                                        showDisplayValue={showDisplayValue}
                                        onFieldChange={(
                                            newValue,
                                            newDisplayValue,
                                            isDisplayValueValid,
                                            isValueValid
                                        ) => {
                                            const newValues = [...currentValues];
                                            newValues[index].value = newValue;
                                            newValues[index].displayValue = newDisplayValue;
                                            newValues[index].isDisplayValueValid = isDisplayValueValid;
                                            newValues[index].isValueValid = isValueValid;
                                            onInputChange(newValues);
                                        }}
                                        messageProvider={messageProviderOrDefault}
                                        labels={labelsOrDefault}
                                    />
                                </StackItem>
                                <IconButton
                                    disabled={disabled}
                                    ariaLabel={labelsOrDefault.deleteTitle}
                                    iconProps={{ iconName: "Delete" }}
                                    title={labelsOrDefault.deleteTitle}
                                    style={index === 0 && showDefaultLabels ? styles.deleteButton : {}}
                                    onClick={() => {
                                        const newValues = deleteFromValues(index, currentValues);
                                        const valuesWithoutKey =
                                            currentValues.length === 1 ? [] : getValuesWithoutKey(newValues);

                                        setIsValuesValid(isAllValuesValid(newValues));
                                        setHyperlinkValues(newValues);
                                        onChangeOrDefault(valuesWithoutKey, getValidationMessage(isValuesValid));
                                    }}
                                />
                            </Stack>
                        </div>
                    ))}
                </Stack>
                {!currentValidation.isValid && (
                    <span style={styles.textError}>{currentValidation.validationMessage}</span>
                )}
                {allowMultipleValue && (
                    <Stack horizontal style={styles.addButtonContainer}>
                        <CommandBarButton
                            disabled={disabled}
                            iconProps={{ iconName: "Add" }}
                            onClick={() => {
                                const newValues = addNewValue(currentValues);
                                const valuesWithoutKey = getValuesWithoutKey(newValues);

                                setHyperlinkValues(newValues);
                                onChangeOrDefault(valuesWithoutKey, getValidationMessage(isValuesValid));
                            }}
                        >
                            {labelsOrDefault.addLinks}
                        </CommandBarButton>
                    </Stack>
                )}
            </Stack>
        );
    };

    return (
        <Stack styles={containerStyles}>
            <Stack styles={labelContainerStyles}>
                {!isNil(onRenderLabel) ? (
                    onRenderLabel()
                ) : (
                    <FieldLabel
                        label={label}
                        customStyles={{ fieldLabel: labelStyles }}
                        showAsterisk={!readOnly && required}
                    />
                )}
            </Stack>
            {readOnly ? readOnlyComponent() : editComponent()}
        </Stack>
    );
}
