import { createContext, useContext } from "react";

const DisabledContext = createContext<boolean>(false);

/**
 * The react context type for the read only mode.
 * @returns {boolean} the context type.
 */
function useDisabledContext(): boolean {
    return useContext(DisabledContext);
}

export { DisabledContext, useDisabledContext };
