import { isNil } from "lodash";
import { ValidationError, ValidationRule, ValidationRuleProps } from "../../types";

type Value = Date | undefined;

export interface MinMaxDateErrorProps extends ValidationRuleProps<Value> {
    /** Maximum Date allowed for the input. */
    maxDate: Date;
    /** Minimum Date allowed for the input. */
    minDate: Date;
}

/**
 * MinMaxDateError validation error class.
 */
export class MinMaxDateError implements ValidationError {
    constructor(props: MinMaxDateErrorProps) {
        this.name = "MinMaxDateError";
        this.minDate = props.minDate;
        this.maxDate = props.maxDate;
        this.message = `The date must be between ${props.minDate.toDateString()} and ${props.maxDate.toDateString()}.`;
    }
    name: string;
    minDate: Date;
    maxDate: Date;
    message: string;
}

/**
 * MinMaxDate validation rule class.
 */
export class MinMaxDate extends ValidationRule<Value> {
    static Error = MinMaxDateError;
    constructor(props: MinMaxDateErrorProps) {
        super(props);
        this.maxDate = props.maxDate;
        this.minDate = props.minDate;
    }

    maxDate: Date;
    minDate: Date;

    validate(value: Value): MinMaxDateError | null {
        if (isNil(value)) {
            return null;
        }

        if (value > this.maxDate || value < this.minDate) {
            return new MinMaxDateError({ maxDate: this.maxDate, minDate: this.minDate });
        }

        return null;
    }
}
