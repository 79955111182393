import { KeyboardEventHandler } from "react";

interface KeyDownA11yParams {
    handler: KeyboardEventHandler;
}

/**
 * Source: https://stackoverflow.com/a/68009016
 * @param {KeyDownA11yParams} params {@link keyDownA11y} params.
 * @returns {KeyboardEventHandler} Handler for {@link KeyboardEvent}.
 */
export function keyDownA11y({ handler }: KeyDownA11yParams): KeyboardEventHandler {
    return function onKeyDown(event): void {
        if (["keydown", "keypress"].includes(event.type) && ["Enter", " "].includes(event.key)) {
            handler(event);
        }
    };
}
