export const states = {
    ["dashboards"]: [
        {
            exact: true,
            url: "/dashboards",
            features: { hiddenMsc: true },
        },
        {
            url: "/dashboards/",
            features: { hiddenMsc: true },
        },
    ],
};
