import { noop } from "lodash";
import React, { useMemo } from "react";
import { useControllableValue } from "@fluentui/react-hooks";

import { ComponentValidationProps, useValidation } from "../utils/validation";
import { getDisplayValidationError, getValidationRules, OnChangeHandler, Value } from "./UniqueIdentifierField.service";
import { TextField } from "../TextField";
import { ITextFieldProps } from "@fluentui/react";
import { ILabeledFieldProps } from "../types";

/**
 * {@link UniqueIdentifierField} component props.
 */
export interface UniqueIdentifierFieldProps
    extends ILabeledFieldProps,
        ComponentValidationProps<Value>,
        Pick<ITextFieldProps, "styles" | "disabled"> {
    /** The value of the input. Use to control the value in controlled mode */
    value?: string;
    /** The initial value of the input. Use to set initial value in uncontrolled mode. */
    defaultValue?: string;
    /** Is value required for the input. */
    required?: boolean;
    /** Should the input be displayed in readOnly mode. */
    readOnly?: boolean;
    /** Specifies a custom placeholder to display when no value provided. */
    placeholder?: string;
    /** Input value change event. */
    onChange?: OnChangeHandler;
}

/**
 * Unique identifier input field component.
 *
 * Gives the ability for users to enter a guid into an input field component, with
 * validation to ensure that the input text is a valid guid value.
 *
 * Can be used in, for example, forms which have an unique identifier/guid input.
 * @param {UniqueIdentifierFieldProps} props Input props.
 * @returns {JSX.Element} Unique Identifier Input.
 */
export function UniqueIdentifierField(props: UniqueIdentifierFieldProps): JSX.Element {
    const {
        defaultValue,
        label,
        value,
        readOnly = false,
        required = false,
        disabled = false,
        placeholder = "XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX",
        styles,
        onChange,
        onValidationStateChange = noop,
        validationRules = [],
        validationErrorMessageProvider = (error) => error.message,
        ...restProps
    } = props;

    const [valueState, setValueState] = useControllableValue(value, defaultValue, onChange);

    const rules = useMemo(() => getValidationRules({ validationRules, required }), [validationRules, required]);

    const { validationErrors, validationErrorMessages } = useValidation({
        value: valueState,
        rules,
        onValidationStateChange,
        validationErrorMessageProvider,
    });

    const validationErrorToDisplay: string | undefined = validationErrorMessages[0];
    const displayValidationError: boolean = getDisplayValidationError({ validationErrors, readOnly });

    return (
        <TextField
            {...restProps}
            disabled={disabled}
            label={label}
            readOnly={readOnly}
            borderless={readOnly}
            value={valueState}
            errorMessage={displayValidationError ? validationErrorToDisplay : undefined}
            placeholder={placeholder}
            styles={styles}
            onChange={(event, value) => setValueState(value, event)}
        />
    );
}
