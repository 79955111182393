import { systemColors } from "iserver365-styleguide";

/**
 * TopBar Application loader used by SingleSpa Layout engine to render a placeholder.
 *
 * Using a parcel instead is too costly and the loader does not transition nicely.
 * @returns {string} Static Html to represent the application loader.
 */
function loader(): string {
    return `<div style='height: 100%; background: linear-gradient(90deg, ${systemColors.brandPrimary} 0%, ${systemColors.brandSecondary} 100%)'></div>`;
}

export { loader };
