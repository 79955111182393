import React, { createRef, useState } from "react";
import {
    IBasePicker,
    ITag as IObject,
    IBasePickerSuggestionsProps,
    ValidationState,
    TagPicker,
    IInputProps,
} from "@fluentui/react";
import "./ObjectPicker.styles.css";

export type Labels = {
    searchObjects: string;
    noResult: string;
};

export type ObjectPickerProps = {
    /** The list of the selected objects. */
    selectedObjects?: IObject[];

    /** The labels. */
    labels: Labels;

    /** The callback function trigger on object selection change. */
    onChange: (items: IObject[] | undefined) => void;

    /** The callback function supplying component with object suggestions */
    getSuggestions: (filter: string) => Promise<IObject[]>;
};

/**
 * This component select existing objects.
 * @param {ObjectPickerProps} props The component props.
 * @returns {JSX.Element} The react element.
 */
export function ObjectPicker({
    selectedObjects,
    labels: { noResult, searchObjects },
    onChange,
    getSuggestions,
}: ObjectPickerProps): JSX.Element {
    const inputProps: IInputProps = {
        style: { margin: 0 },
        placeholder: searchObjects,
    };

    const objectPickerRef = createRef<IBasePicker<IObject>>();

    const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
        noResultsFoundText: noResult,
        onRenderNoResultFound: undefined,
        suggestionsClassName: "ms-Suggestions-container-custom",
    };

    const [isSuggestionsLoading, setSuggestionsLoading] = useState<boolean>(false);

    function isObjectPresent(object: IObject, objects?: IObject[]): boolean {
        return objects?.some((selectedObject) => selectedObject.key === object.key) === true;
    }

    const onResolveSuggestions = async (filterText: string, selectedItems?: IObject[]): Promise<IObject[]> => {
        const trimmedText = filterText.trimStart();
        if (trimmedText.length > 0) {
            try {
                setSuggestionsLoading(true);
                const suggestions = await getSuggestions(trimmedText);
                return suggestions.filter((object: IObject) => !isObjectPresent(object, selectedItems));
            } finally {
                setSuggestionsLoading(false);
            }
        }
        return [];
    };

    const onValidateInput = (input: string) => (input !== undefined ? ValidationState.valid : ValidationState.invalid);

    return (
        <>
            <TagPicker
                componentRef={objectPickerRef}
                inputProps={inputProps}
                aria-label="Values"
                selectedItems={selectedObjects}
                createGenericItem={(input: string) => ({ key: "", name: input } as IObject)}
                getTextFromItem={(item: IObject) => item.name}
                pickerSuggestionsProps={pickerSuggestionsProps}
                onChange={onChange}
                onResolveSuggestions={onResolveSuggestions}
                onValidateInput={onValidateInput}
                resolveDelay={300}
                onItemSelected={(selectedItem?: IObject | undefined) => {
                    if (isSuggestionsLoading || selectedItem?.key === "") {
                        return null;
                    }
                    return selectedItem ?? null;
                }}
            />
        </>
    );
}
