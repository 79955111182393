import { useLocation } from "react-router-dom";

/**
 * A react hook which returns url search params.
 * Returns 'undefined' values for absent search params .
 * @param {string[]} names Names of search params.
 * @returns {(string | undefined)[]} Values for search params.
 */
export function useSearchParams(...names: string[]): (string | undefined)[] {
    const { search } = useLocation();
    const params = new URLSearchParams(search);

    return names.map((name) => params.get(name) ?? undefined);
}
