import {
    ConstrainMode,
    ContextualMenu,
    ShimmeredDetailsList,
    DetailsListLayoutMode,
    DirectionalHint,
    IColumn,
    IContextualMenuItem,
    IContextualMenuProps,
    IDetailsHeaderProps,
    IDetailsList,
    IRenderFunction,
    ScrollablePane,
    Selection,
    SelectionMode,
    Sticky,
    IDetailsListProps,
    IGroup,
    IScrollablePaneStyles,
    CheckboxVisibility,
    StickyPositionType,
} from "@fluentui/react";
import { isObject } from "lodash";
import React, { useEffect, useRef, useState, Dispatch, SetStateAction } from "react";

/**
 * Component props for {@link Grid}.
 * @typedef T The item type which represents a row in the grid.
 */
export interface GridProps<T = Record<string, string>> {
    /**
     * you should pass the same amount of items as on server even if you have data for only one page.
     * Just fill others with null. Use setMissingPageNumber to get notified about next portion of data needed.
     */
    items: (T | null)[] | undefined;
    /**
     *  columns config. Use this param to pass column configuration including, but not limited
     *   to: key, name, isSorted, minWidth... Please check fluentui documentation on them.
     *   Note that you should use onRender option of column to customize cell format, which allows us to do inline editing.
     *   Default renderer simply outputs value as string
     */
    columns: IColumn[];
    /** if you have grouped data, path groups config with this option */
    groups?: IGroup[];
    /** number of items per virtual page. */
    pageSize: number;
    /**
     * additional context menu items - this allows us to add additional
     *  features for selected items e.g. show chart for selected
     */
    contextMenuItems?: IContextualMenuItem[];
    /**
     *  you should listen to this callback which tells you pageNumber of data
     *   which you should populate.
     *   Parameters items and setMissingPageNumber both serve the purpose of virtual data loading.
     */
    onPageChange(pageNumber: number): void;
    /**
     * as name suggests is optional function which accepts column and can return
     *  list of context items for column header. This is our preferred way to implement sorting, grouping ...
     */
    getColumnHeaderContextMenuItems?(column: IColumn): IContextualMenuItem[] | undefined;
    /** called when you double click item or press enter on selected item */
    onItemInvoked?: IDetailsListProps["onItemInvoked"];
    /** callback to listen to selection updates */
    onSelectionChanged?(items: T[]): void;
    /** Controls how selection is managed */
    selectionMode?: SelectionMode;
    /**
     * Class name to set for the grid.
     */
    className?: string;

    /** Styles for scrollable pane */
    scrollablePaneStyles?: Partial<IScrollablePaneStyles>;

    setHaveColumnsUnsavedChanges?: Dispatch<SetStateAction<boolean>>;

    setViewConfigurationCallback?: (cols: IColumn[]) => void;

    dragAndDropEnabled?: boolean;

    enableShimmer?: boolean;
}

/**
 * Grid is a component which displays data in list format. It is built on top of fluentui DetailsList
 * It has following additional features:
 * - virtual scrolling - all invisible items removed from DOM
 * - copy data using selection and context menu button (format is suitable for Excel)
 * Check props documentation to get more information on Grid usages.
 * @param {GridProps} props The component props.
 * @returns {JSX.Element} The grid react element.
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
export function Grid<T = Record<string, string>>({
    items,
    columns,
    groups,
    pageSize = 100,
    onPageChange,
    getColumnHeaderContextMenuItems,
    onItemInvoked,
    onSelectionChanged,
    contextMenuItems,
    selectionMode = SelectionMode.multiple,
    className,
    scrollablePaneStyles,
    setHaveColumnsUnsavedChanges,
    setViewConfigurationCallback,
    dragAndDropEnabled = false,
    enableShimmer = items === undefined,
}: GridProps<T>): JSX.Element {
    const [showContextualMenu, setShowContextualMenu] = useState(false);
    const [columnContextMenuProps, setColumnContextMenuProps] = useState<IContextualMenuProps | null>(null);
    const pageRequestsInAction = useRef<Record<number, boolean>>({});
    const listRef = useRef<IDetailsList>();

    const [selection] = useState(
        new Selection({
            onSelectionChanged: () => {
                listRef.current?.forceUpdate();
                if (onSelectionChanged !== undefined) {
                    onSelectionChanged(selection.getSelection() as T[]);
                }
            },
        })
    );
    const [contextMenuClickEvent, setContextMenuClickEvent] = useState<Event | undefined>(undefined);

    useEffect(() => {
        if (items === undefined) {
            pageRequestsInAction.current = {};
        }
    }, [items]);

    const menuItems: IContextualMenuItem[] = [...(contextMenuItems ?? [])];

    const isContextualMenuEnabled = menuItems.length > 0;

    const handleColumnReorder = (draggedIndex: number, targetIndex: number) => {
        const draggedItems = columns[draggedIndex];
        const newColumns = [...columns];

        newColumns.splice(draggedIndex, 1);
        newColumns.splice(targetIndex, 0, draggedItems);

        if (setHaveColumnsUnsavedChanges !== undefined) {
            setHaveColumnsUnsavedChanges(true);
        }

        if (setViewConfigurationCallback !== undefined) {
            setViewConfigurationCallback(newColumns);
        }
    };

    const getColumnReorderOptions = () => {
        return {
            handleColumnReorder,
        };
    };

    return (
        <div className={className} style={{ height: "100%", position: "relative" }}>
            {isContextualMenuEnabled && (
                <ContextualMenu
                    items={menuItems}
                    hidden={!showContextualMenu}
                    target={contextMenuClickEvent as MouseEvent}
                    onDismiss={() => {
                        setShowContextualMenu(false);
                    }}
                />
            )}
            {isObject(columnContextMenuProps) && <ContextualMenu {...columnContextMenuProps} />}
            <ScrollablePane styles={scrollablePaneStyles}>
                <ShimmeredDetailsList
                    componentRef={(ref) => (listRef.current = ref ?? undefined)}
                    usePageCache={true}
                    items={items ?? []}
                    groups={groups?.map((group) => ({ ...group }))}
                    columns={columns}
                    enableShimmer={enableShimmer}
                    getKey={(item, index) => {
                        if (item === null || item === undefined) {
                            return index;
                        }
                        return item.key;
                    }}
                    onRenderCustomPlaceholder={(props, index, defaultRender) => {
                        if (index === undefined) {
                            return null;
                        }
                        const pageNumber = Math.floor(index / pageSize);
                        if (!pageRequestsInAction.current[pageNumber]) {
                            pageRequestsInAction.current[pageNumber] = true;

                            // we must use setTimeout here. otherwise we get react warning that we can't
                            // update state while rendering components
                            setTimeout(() => {
                                onPageChange(pageNumber);
                            }, 0);
                        }

                        if (defaultRender !== undefined) {
                            return defaultRender(props);
                        }

                        return null;
                    }}
                    setKey="multiple"
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionMode={selectionMode}
                    constrainMode={ConstrainMode.unconstrained}
                    selection={selection}
                    selectionPreservedOnEmptyClick={true}
                    enterModalSelectionOnTouch={true}
                    ariaLabelForSelectionColumn="Toggle selection"
                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                    checkButtonAriaLabel="Row checkbox"
                    checkboxVisibility={CheckboxVisibility.always}
                    onItemContextMenu={
                        isContextualMenuEnabled
                            ? (item, i, event) => {
                                  setContextMenuClickEvent(event);
                                  setShowContextualMenu(true);
                              }
                            : undefined
                    }
                    onRenderDetailsHeader={(
                        detailsHeaderProps?: IDetailsHeaderProps,
                        defaultRender?: IRenderFunction<IDetailsHeaderProps>
                    ) => {
                        if (defaultRender === undefined) {
                            return null;
                        }
                        // stickyPosition has to be set explicitly to fix issue with Header jumping from the bottom to the top when Grid visibility is toggled
                        return (
                            <Sticky stickyPosition={StickyPositionType.Header}>
                                {defaultRender(detailsHeaderProps)}
                            </Sticky>
                        );
                    }}
                    onItemInvoked={onItemInvoked}
                    onRenderItemColumn={(item: Record<string, string>, _?: number, column?: IColumn) => {
                        if (column === undefined) {
                            return null;
                        }

                        return <>{item[column.key]}</>;
                    }}
                    onColumnHeaderClick={(
                        e: React.MouseEvent<HTMLElement, MouseEvent> | undefined,
                        column?: IColumn
                    ) => {
                        if (column === undefined || e === undefined) {
                            return;
                        }

                        const items = getColumnHeaderContextMenuItems?.(column);
                        if (items === undefined) {
                            return;
                        }

                        const contextualMenuProps: IContextualMenuProps = {
                            items,
                            directionalHint: DirectionalHint.bottomLeftEdge,
                            gapSpace: 10,
                            isBeakVisible: true,
                            target: e.target as HTMLElement,
                            onDismiss: () => setColumnContextMenuProps(null),
                        };

                        setColumnContextMenuProps(contextualMenuProps);
                    }}
                    columnReorderOptions={dragAndDropEnabled ? getColumnReorderOptions() : undefined}
                />
            </ScrollablePane>
        </div>
    );
}
