import { Stack, StackItem, TooltipHost, TooltipOverflowMode } from "@fluentui/react";
import { SystemColorKey } from "iserver365-styleguide";
import React from "react";
import { useArrowStyles } from "./Arrow.styles";

export type ArrowProps = {
    title: string;
    color: SystemColorKey;
    direction?: "directionless" | "right" | "left";
};

/**
 * Renders a visual representation of a relationship between two items.
 * @param {ArrowProps} props - The props for the ItemConnector component.
 * @returns {JSX.Element} The rendered ItemConnector component.
 */
export const Arrow = ({ title, color, direction = "directionless" }: ArrowProps): JSX.Element => {
    const styles = useArrowStyles(color);
    return (
        <Stack>
            <StackItem styles={styles.connector}>
                <StackItem styles={styles.title}>
                    <TooltipHost overflowMode={TooltipOverflowMode.Parent} content={title}>
                        {title}
                    </TooltipHost>
                </StackItem>
                {direction === "right" && <div style={styles.rightArrow}></div>}
                {direction === "left" && <div style={styles.leftArrow}></div>}
            </StackItem>
        </Stack>
    );
};
