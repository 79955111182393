import React from "react";
import { Shimmer, ShimmerElementType } from "@fluentui/react";
import { useStyles } from "./ShimmeredWidget.styles";

/**
 * Shimmered Widget component to show until widget data is loaded
 * @returns {JSX.Element} return react component
 */
export function ShimmeredWidget(): JSX.Element {
    const styles = useStyles();

    return <Shimmer shimmerElements={[{ type: ShimmerElementType.line }]} styles={styles.shimmer} />;
}
