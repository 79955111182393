import { initializeIcons } from "@fluentui/react";

import { registerAllIcons } from "./registerIcons";
import { getCurrentTheme } from "./themeModule";
import { convertHexToRGBA } from "./utils";
import { systemColors, SystemColorKey } from "./systemColors";

/**
 * Initialize the iserver365 styleguide by
 * - initializing and registering the icons.
 */
function initializeStyleguide(): void {
    initializeIcons();
    registerAllIcons();
}

export type { SystemColorKey };
export { getCurrentTheme, initializeStyleguide, convertHexToRGBA, systemColors };
