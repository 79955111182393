import { IStackStyles, useTheme } from "@fluentui/react";

export interface WizardStepStyles {
    stackStyles: Partial<IStackStyles>;
}

/** @returns {WizardStepStyles} WizardStep styles. */
export function useStyles(): WizardStepStyles {
    const { spacing } = useTheme();

    return {
        stackStyles: {
            root: {
                paddingTop: spacing.l1,
            },
        },
    };
}
