import React from "react";

/**
 * Hive Icon.
 * @returns {JSX.Element} Hive Icon.
 */
const HiveELearningIcon = (): JSX.Element => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.63 23.8316L19.7814 19.7034C17.0748 21.2616 13.6233 20.3366 12.0589 17.63C11.0595 15.8919 11.0471 13.7564 12.0403 12.012L12.0155 11.9934L11.9844 12.012C10.4077 9.31785 6.94372 8.41152 4.24955 9.9945C2.51758 11.0064 1.45605 12.8687 1.45605 14.8738V16.2147V17.3817C1.45605 17.4997 1.47468 17.6238 1.51192 17.7418V17.748C1.52434 17.779 1.53055 17.8101 1.54296 17.8411C1.54296 17.8473 1.54917 17.8597 1.55538 17.8659C1.56779 17.897 1.58021 17.9218 1.59263 17.9529C1.59883 17.9653 1.60504 17.9715 1.61125 17.9839C1.62987 18.0149 1.6485 18.046 1.66712 18.077C1.68574 18.108 1.70437 18.1329 1.7292 18.1639C1.7354 18.1701 1.74782 18.1825 1.75403 18.1887C1.77265 18.2136 1.79748 18.2384 1.82231 18.2632L1.84714 18.2819C1.87197 18.3067 1.90301 18.3315 1.92784 18.3502L1.94026 18.3564C1.98371 18.3936 2.03338 18.4246 2.08304 18.4557L11.4009 23.8378C11.7672 24.0551 12.2451 24.0551 12.63 23.8316Z"
                fill="url(#paint0_linear_3002_25598)"
            />
            <path
                d="M21.9479 5.53733L14.7965 1.41537C17.4969 2.97973 18.4281 6.43746 16.8637 9.13783C15.8581 10.876 14.0143 11.95 12.0092 11.9624L12.003 11.9934L12.0341 12.012C10.4883 14.7248 11.4381 18.1763 14.1509 19.7221C15.8953 20.7153 18.037 20.7091 19.7752 19.7034L20.936 19.033L21.9417 18.4495C22.0472 18.3874 22.1403 18.3129 22.221 18.226L22.2272 18.2198C22.252 18.195 22.2707 18.1701 22.2955 18.1453L22.3079 18.1267C22.3265 18.1018 22.3452 18.077 22.3638 18.046C22.37 18.0398 22.3762 18.0274 22.3824 18.0211C22.401 17.9901 22.4197 17.9591 22.4321 17.928C22.4507 17.897 22.4631 17.866 22.4755 17.8349C22.4817 17.8225 22.4817 17.8101 22.4879 17.8039C22.5004 17.7728 22.5128 17.7418 22.519 17.7108L22.5252 17.6797C22.5314 17.6425 22.5438 17.6114 22.5438 17.5742V17.5618V17.5556C22.55 17.4997 22.5562 17.4438 22.5562 17.388V6.61748C22.5624 6.17052 22.3265 5.76081 21.9479 5.53733Z"
                fill="url(#paint1_linear_3002_25598)"
            />
            <path
                d="M1.4436 6.61748V14.8738C1.4436 11.7513 3.97637 9.22474 7.09888 9.22474C9.10398 9.22474 10.9539 10.2863 11.972 12.012L12.003 11.9996V11.9624C15.1255 11.9437 17.6397 9.39856 17.6272 6.27605C17.6148 4.26473 16.5409 2.41482 14.7965 1.40916L13.6357 0.738724L12.63 0.155194C12.5245 0.0931165 12.4127 0.0496621 12.2948 0.0248311H12.2886C12.2575 0.0186233 12.2203 0.0124155 12.1892 0.00620776H12.1644C12.1334 0 12.1023 0 12.0713 0C12.0589 0 12.0465 0 12.0403 0C12.003 0 11.972 0 11.9347 0C11.8975 0 11.8664 0.00620776 11.8292 0.0124155L11.7919 0.0186233C11.7609 0.0248311 11.7237 0.0310388 11.6926 0.0372466L11.6616 0.0434544C11.6243 0.0558699 11.5933 0.0620776 11.5623 0.0744932H11.5498H11.5436C11.4878 0.0931165 11.4381 0.117948 11.3884 0.148986L2.07059 5.53733C1.68571 5.7546 1.4436 6.17052 1.4436 6.61748Z"
                fill="url(#paint2_linear_3002_25598)"
            />
            <path
                d="M14.2502 6.31329V10.9132H9.73714V6.31329H7.08643V17.8039H9.73714V13.1232H14.2502V17.8039H16.9071V6.31329H14.2502Z"
                fill="white"
            />
            <path d="M18.7198 9.82068V17.8039H21.1285V9.82068H18.7198Z" fill="white" />
            <path
                d="M19.9241 6.2326C19.1606 6.2326 18.546 6.84717 18.546 7.61072C18.546 8.37428 19.1606 8.98884 19.9241 8.98884C20.6877 8.98884 21.3023 8.37428 21.3023 7.61072C21.3023 6.85337 20.6877 6.2326 19.9241 6.2326ZM20.1725 8.83986C19.5827 8.83986 19.1109 8.36186 19.1109 7.77833C19.1109 7.18859 19.5889 6.7168 20.1725 6.7168C20.7622 6.7168 21.234 7.1948 21.234 7.77833C21.234 8.36807 20.7622 8.83986 20.1725 8.83986C20.1725 8.84607 20.1725 8.84607 20.1725 8.83986Z"
                fill="white"
            />
            <g opacity="0.3">
                <path
                    d="M19.1977 7.61698C19.1977 7.21348 19.5205 6.89067 19.924 6.89067C20.3275 6.89067 20.6503 7.21348 20.6503 7.61698C20.6503 8.02049 20.3275 8.34329 19.924 8.34329C19.5205 8.34329 19.1977 8.01428 19.1977 7.61698ZM19.1046 7.78459C19.1046 8.37433 19.5826 8.84612 20.1661 8.84612C20.7559 8.84612 21.2277 8.36812 21.2277 7.78459C21.2277 7.20106 20.7497 6.72306 20.1661 6.72306C19.5826 6.72306 19.1046 7.19485 19.1046 7.78459Z"
                    fill="white"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_3002_25598"
                    x1="5.24926"
                    y1="9.41526"
                    x2="13.3371"
                    y2="23.4232"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#8F9ACC" />
                    <stop offset="0.25" stopColor="#3A4EA6" />
                    <stop offset="1" stopColor="#151E47" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_3002_25598"
                    x1="13.151"
                    y1="19.1356"
                    x2="21.2392"
                    y2="5.1254"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#8F9ACC" />
                    <stop offset="0.25" stopColor="#3A4EA6" />
                    <stop offset="1" stopColor="#151E47" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_3002_25598"
                    x1="17.6068"
                    y1="7.42961"
                    x2="1.4274"
                    y2="7.42965"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#8F9ACC" />
                    <stop offset="0.25" stopColor="#3A4EA6" />
                    <stop offset="1" stopColor="#151E47" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export { HiveELearningIcon };
