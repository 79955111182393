import { isNil, isEmpty } from "lodash";
import React from "react";

import { Icon } from "../types";

interface OnClickParams {
    disabled: boolean;
    onClickProp: (value: string) => void;
    value?: string;
}

interface GetContentParams {
    altText?: string;
    children?: React.ReactChild;
    icon?: Icon;
}

/**
 * @param {OnClickParams} params onClick params.
 * @returns {undefined}
 */
export function onClick({ disabled, onClickProp, value = "" }: OnClickParams): void {
    if (!disabled) {
        onClickProp(value);
    }
}

/**
 * @param {GetContentParams} params getContent params.
 * @returns {React.ReactChild | null} The content to render.
 */
export function getContent({ altText = "Icon", children, icon }: GetContentParams): React.ReactChild | null {
    if (!isNil(children) && !isEmpty(children)) {
        return children;
    } else if (typeof icon === "string") {
        return <img alt={altText} src={icon} />;
    } else if (React.isValidElement(icon)) {
        return icon;
    }
    return null;
}
