import React from "react";
import { Layer, MessageBar, MessageBarType, Stack } from "@fluentui/react";
import { useStyles } from "./ToastStack.styles";
import { useToastMessages } from "./hooks/useToastMessages";
import { ToastMessage } from "./types/ToastMessage";

function getMessageType(message: ToastMessage) {
    return message.type === "error"
        ? MessageBarType.error
        : message.type === "warning"
        ? MessageBarType.warning
        : message.type === "success"
        ? MessageBarType.success
        : MessageBarType.info;
}

/**
 * The props for the {@link ToastStack} component.
 */
export interface ToastStackProps {
    /** The toast message. */
    message: ToastMessage | undefined;
    /** Timeout for the toast message to be hidden. */
    timeout?: number;
    /** Container where to display the toasts. */
    hostId?: string | undefined;
    /** Dismiss button area label. */
    dismissButtonAriaLabel?: string | undefined;
    /** Interval for the toast message to be hidden when timeout is run. */
    clearMessageInterval?: number;
}

/**
 * ToastStack component displays messages in a list automatically hiding the messages after
 * the timeout.
 * @param {ToastStackProps} props The component props.
 * @returns {JSX.Element} The react element.
 */
export function ToastStack(props: ToastStackProps): JSX.Element {
    const { message, timeout = 5000, hostId, dismissButtonAriaLabel, clearMessageInterval = 1000 } = props;
    const { container } = useStyles();
    const { messages, removeToast } = useToastMessages(message, timeout, clearMessageInterval);

    return (
        <>
            {messages.length > 0 && (
                <Layer hostId={hostId}>
                    <Stack>
                        {messages.map((message) => (
                            <Stack key={message.key} styles={container}>
                                <MessageBar
                                    messageBarType={getMessageType(message)}
                                    onDismiss={() => removeToast(message)}
                                    dismissButtonAriaLabel={dismissButtonAriaLabel}
                                    isMultiline={true}
                                >
                                    {message.text}
                                </MessageBar>
                            </Stack>
                        ))}
                    </Stack>
                </Layer>
            )}
        </>
    );
}
