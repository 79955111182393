import {
    PublicClientApplication,
    AuthenticationResult,
    Configuration,
    AccountInfo,
    RedirectRequest,
    EndSessionRequest,
    SilentRequest,
    InteractionRequiredAuthError,
    BrowserAuthError,
    PopupRequest,
} from "@azure/msal-browser";
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import { inIframe } from "iserver365-infrastructure-utility";
import { trackException, trackMessage } from "./appInsights";

type TokenScopes = { scopes: Array<string> };

const loginRequest: TokenScopes = {
    scopes: ["openid", "profile"],
};
// TODO: This area requires more work and thought in latter stories
// we need to be able to support redirecting to any page in iServer365. This may require the login service.
const redirectUri = window.location.origin;

const defaultSharepointScope = `https://microsoft.sharepoint-df.com/AllSites.Read`;

export type AuthModuleConfiguration = {
    msal: Configuration;
    sharepointScope: string | null | undefined;
};

/**
 * @class
 * The authentication module class. Acts as a wrapper around the msal {@link PublicClientApplication}.
 */
export class AuthModule {
    private client: PublicClientApplication;
    private account: AccountInfo | null;
    private loginRedirectRequest: RedirectRequest;
    private loginPopupRequest: PopupRequest;
    private tokenSilentRequest: SilentRequest;

    /**
     * Constructor for {@link AuthModule} that accepts a {@link configuration} for the msal authentication options.
     * @param {AuthModuleConfiguration} configuration The configuration options.
     */
    constructor(configuration: AuthModuleConfiguration) {
        configuration.msal.auth.redirectUri = redirectUri;

        this.client = new PublicClientApplication(configuration.msal);
        this.account = null;

        const tokenRequest: TokenScopes = {
            scopes: [`${configuration.msal.auth.clientId}/user_impersonation`],
        };

        const sharepointScope = configuration.sharepointScope ?? defaultSharepointScope;

        const extraScopesToConsent = [sharepointScope];

        this.loginRedirectRequest = {
            ...loginRequest,
            redirectUri,
            extraScopesToConsent,
            scopes: [...loginRequest.scopes, ...tokenRequest.scopes],
        };

        this.loginPopupRequest = {
            ...loginRequest,
            redirectUri,
            extraScopesToConsent,
            scopes: [...loginRequest.scopes, ...tokenRequest.scopes],
        };

        this.tokenSilentRequest = {
            ...tokenRequest,
            redirectUri,
        };
    }

    /**
     * Returns the {@link AccountInfo} for the current user.
     * This requires a user to be logged in first, by calling the {@link AuthModule.login} function.
     * @returns {AccountInfo | undefined} The {@link AccountInfo} for the current user, or {@link undefined} if not found.
     */
    getCurrentAccount(): AccountInfo | undefined {
        return this.account === null ? undefined : this.account;
    }

    /**
     * Checks whether we are in the middle of a redirect and handles state accordingly.
     * See [here](https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/initialization.md#redirect-apis)
     * for documentation on the redirect apis.
     * @returns {Promise<void>} a {@link Promise} for the load module operation.
     */
    async loadAuthModule(): Promise<void> {
        const resp = await this.client.handleRedirectPromise();
        return this.handleResponse(resp);
    }

    /**
     * Handles the response from a popup or redirect. If response is null, will check if we have any accounts and attempt to sign in.
     * @param {AuthenticationResult} response The response to handle, or {@link null} if response was not obtained.
     */
    private handleResponse(response: AuthenticationResult | null) {
        this.account = response !== null ? response.account : this.getAccount();
    }

    /**
     * Calls loginRedirect to log the user in.
     * @returns {Promise<void>} a {@link Promise} for the login operation.
     */
    login(): Promise<void> {
        return this.client.loginRedirect(this.loginRedirectRequest);
    }

    /**
     * Logs out of current account.
     * @param {string} redirectUrl The redirect Url.
     * @returns {Promise<void>} a {@link Promise} for the logout operation.
     */
    async logout(redirectUrl?: string): Promise<void> {
        if (this.account === null) {
            const message = "No current account to logout from";
            trackMessage(message, SeverityLevel.Warning);
            return Promise.reject(message);
        }

        const { getSettings } = await import("iserver365-settings-utility");
        const userSettings = await getSettings();

        if ((userSettings?.sessionExpirationMinutes ?? 0) > 0) {
            const logOutRequest: EndSessionRequest = {
                account: this.account,
                postLogoutRedirectUri: redirectUrl,
            };

            return this.client.logoutRedirect(logOutRequest);
        }

        const logOutRequest: EndSessionRequest = {
            account: {
                ...this.account,
                idTokenClaims: {
                    ...this.account.idTokenClaims,
                    login_hint: undefined, // keep old behavior where account selection page is shown
                },
            },
            postLogoutRedirectUri: redirectUrl,
        };

        return this.client.logout(logOutRequest);
    }

    /**
     * Gets a token for iServer365 silently, or falls back to interactive redirect if silent retrieval of token fails.
     * @returns {Promise<string>} The access token string.
     */
    async getAccessToken(): Promise<string> {
        if (this.account !== null) {
            this.tokenSilentRequest.account = this.account;
        }

        const accessToken = await this.acquireTokenSilent(
            this.tokenSilentRequest,
            this.loginRedirectRequest,
            this.loginPopupRequest
        );

        // it is ok to add a non-null assertion here, since the token can
        // never be undefined. If there is no token, or the token is invalid,
        // a full redirect will occur to get a new refresh token.
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return accessToken!;
    }

    /**
     * Gets a token silently, or falls back to interactive redirect.
     * @param {SilentRequest} silentRequest The silent request for the acquire token silent call.
     * @param {RedirectRequest} interactiveRequest The interactive request to use in case the silent call fails.
     * @param {PopupRequest} popupRequest The popup request to use in case the silent call fails.
     * @returns {Promise} The access token, or {@link undefined} if unsuccessful.
     */
    private async acquireTokenSilent(
        silentRequest: SilentRequest,
        interactiveRequest: RedirectRequest,
        popupRequest: PopupRequest
    ): Promise<string | undefined> {
        try {
            const response = await this.client.acquireTokenSilent(silentRequest);
            return response.accessToken;
        } catch (e) {
            trackMessage("silent token acquisition fails.", SeverityLevel.Warning);

            // Force popup login if application is in iframe.
            // Currently we allow embedding only of shared dashboards having '/embed/<id>' url.
            if (window.location.pathname.includes("embed") && inIframe()) {
                const silentLoginErrors = ["no_account_error", "monitor_window_timeout"];
                const popupInteractionRequired =
                    e instanceof BrowserAuthError && silentLoginErrors.includes(e.errorCode);
                if (popupInteractionRequired) {
                    const response = await this.client.acquireTokenPopup(popupRequest);
                    this.handleResponse(response);
                    return response.accessToken;
                }
            } else if (e instanceof InteractionRequiredAuthError) {
                await this.client.acquireTokenRedirect(interactiveRequest).catch(trackException);
                return undefined;
            }

            throw e;
        }
    }

    /**
     * Calls getAllAccounts and determines the correct account to sign into, currently defaults to first account found in cache.
     *
     * See [here](https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md) for
     * documentation on accounts.
     * @returns {AccountInfo | null} The {@link AccountInfo} for the current user, or {@link null} if account was not found.
     */
    private getAccount(): AccountInfo | null {
        const currentAccounts = this.client.getAllAccounts();
        if (currentAccounts === null || currentAccounts.length === 0) {
            trackMessage("No accounts detected", SeverityLevel.Warning);
            return null;
        }

        if (currentAccounts.length > 1) {
            // Add choose account code here
            trackMessage("Multiple accounts detected, need to add choose account code.", SeverityLevel.Warning);
        }

        return currentAccounts[0];
    }
}
