import { ValidationError } from "./types";
import { useState, useMemo, useCallback } from "react";

export interface FieldValidationResult {
    isValid: boolean;
    errors: ValidationError[];
}

export interface AggregatedValidationResult<T extends Record<string, unknown>> {
    /**
     * The validation result. True if all fields are valid.
     */
    isValid: boolean;
    /**
     * The object containing validation state of all fields.
     */
    validationState: Partial<Record<keyof T, FieldValidationResult>>;
    /**
     * Sets the validation errors for the field.
     * If the list of errors is empty field will be marked as valid.
     * @param fieldName The field name.
     * @param errors The list of validation errors.
     */
    setValidationErrors<K extends keyof T>(fieldName: K, errors: ValidationError[] | undefined): void;
    /**
     * Sets the validation result for the field.
     * @param fieldName The field name.
     * @param validationResult The validation result.
     */
    setValidationResult<K extends keyof T>(fieldName: K, validationResult: FieldValidationResult): void;
}

/**
 * The React hook for aggregating and managing validation results of form fields.
 * @template T A type that extends Record<string, unknown> representing the structure of the form fields.
 * @returns {AggregatedValidationResult} An object containing aggregated validation result.
 */
export function useAggregatedValidationResult<T extends Record<string, unknown>>(): AggregatedValidationResult<T> {
    const [validationState, setValidationState] = useState<Partial<Record<keyof T, FieldValidationResult>>>({});

    const setValidationErrors = useCallback(
        <K extends keyof T>(field: K, errors: ValidationError[] | undefined) => {
            setValidationState((prevState) => {
                return {
                    ...prevState,
                    [field]: {
                        isValid: errors === undefined || errors.length === 0,
                        errors: errors ?? [],
                    },
                };
            });
        },
        [setValidationState]
    );

    const setValidationResult = useCallback(
        <K extends keyof T>(field: K, validationResult: FieldValidationResult) => {
            setValidationState((prevState) => {
                return {
                    ...prevState,
                    [field]: {
                        isValid: validationResult.isValid,
                        errors: validationResult.errors,
                    },
                };
            });
        },
        [setValidationState]
    );

    const isValid = useMemo(() => {
        return Object.values(validationState).every((v) => v?.isValid === true);
    }, [validationState]);

    return {
        isValid,
        validationState,
        setValidationErrors,
        setValidationResult,
    };
}
