import React from "react";

/**
 * Community Icon.
 * @returns {JSX.Element} Community Icon.
 */
const CommunityIcon = (): JSX.Element => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3002_25612)">
                <path
                    d="M14.1443 4.14732C9.07286 4.14732 4.98657 8.27423 4.98657 13.3142C4.98657 18.3906 9.10935 22.481 14.1443 22.481C19.2157 22.481 23.3019 18.3541 23.3019 13.3142C23.3019 8.23768 19.1792 4.14732 14.1443 4.14732ZM11.9917 18.1715C8.5256 18.1715 5.75275 15.3594 5.75275 11.9264C5.75275 8.45681 8.56209 5.6812 11.9917 5.6812C15.4212 5.6812 18.2306 8.49336 18.2306 11.9264C18.2306 15.3594 15.4212 18.1715 11.9917 18.1715Z"
                    fill="#939BC1"
                />
                <path
                    d="M12 0C5.45804 0 0.151855 5.3115 0.151855 11.86C0.151855 18.4084 5.42171 23.7563 12 23.7563C18.5418 23.7563 23.8481 18.4448 23.8481 11.8963C23.8481 5.3479 18.5418 0 12 0ZM14.1442 22.4466C9.09243 22.4466 5.02194 18.3356 5.02194 13.3152C5.02194 8.25831 9.1288 4.18372 14.1442 4.18372C19.196 4.18372 23.2666 8.2947 23.2666 13.3152C23.2666 18.3356 19.1597 22.4466 14.1442 22.4466Z"
                    fill="url(#paint0_linear_3002_25612)"
                />
                <path
                    d="M20.8101 22.1772C22.1524 22.1772 23.2405 21.0891 23.2405 19.7468C23.2405 18.4046 22.1524 17.3165 20.8101 17.3165C19.4679 17.3165 18.3798 18.4046 18.3798 19.7468C18.3798 21.0891 19.4679 22.1772 20.8101 22.1772Z"
                    fill="url(#paint1_linear_3002_25612)"
                />
                <path
                    d="M3.49361 6.37975C4.83587 6.37975 5.92399 5.29163 5.92399 3.94937C5.92399 2.60711 4.83587 1.51899 3.49361 1.51899C2.15135 1.51899 1.06323 2.60711 1.06323 3.94937C1.06323 5.29163 2.15135 6.37975 3.49361 6.37975Z"
                    fill="#939BC1"
                />
                <path
                    d="M14.7767 8.81013H9.3755C9.14714 8.81013 8.96204 8.99523 8.96204 9.22359C8.96204 9.45196 9.14714 9.63706 9.3755 9.63706H14.7767C15.0051 9.63706 15.1902 9.45196 15.1902 9.22359C15.1902 8.99523 15.0051 8.81013 14.7767 8.81013Z"
                    fill="white"
                />
                <path
                    d="M14.7767 8.81013H9.3755C9.14714 8.81013 8.96204 8.99523 8.96204 9.22359C8.96204 9.45196 9.14714 9.63706 9.3755 9.63706H14.7767C15.0051 9.63706 15.1902 9.45196 15.1902 9.22359C15.1902 8.99523 15.0051 8.81013 14.7767 8.81013Z"
                    fill="#939BC1"
                />
                <path
                    d="M13.3571 10.2579H10.5907C10.3623 10.2579 10.1772 10.443 10.1772 10.6714C10.1772 10.8998 10.3623 11.0849 10.5907 11.0849H13.3571C13.5855 11.0849 13.7706 10.8998 13.7706 10.6714C13.7706 10.443 13.5855 10.2579 13.3571 10.2579Z"
                    fill="white"
                />
                <path
                    d="M13.3571 10.2579H10.5907C10.3623 10.2579 10.1772 10.443 10.1772 10.6714C10.1772 10.8998 10.3623 11.0849 10.5907 11.0849H13.3571C13.5855 11.0849 13.7706 10.8998 13.7706 10.6714C13.7706 10.443 13.5855 10.2579 13.3571 10.2579Z"
                    fill="#939BC1"
                />
                <path
                    d="M14.7767 12.4557H9.3755C9.14714 12.4557 8.96204 12.6408 8.96204 12.8692C8.96204 13.0975 9.14714 13.2826 9.3755 13.2826H14.7767C15.0051 13.2826 15.1902 13.0975 15.1902 12.8692C15.1902 12.6408 15.0051 12.4557 14.7767 12.4557Z"
                    fill="white"
                />
                <path
                    d="M14.7767 12.4557H9.3755C9.14714 12.4557 8.96204 12.6408 8.96204 12.8692C8.96204 13.0975 9.14714 13.2826 9.3755 13.2826H14.7767C15.0051 13.2826 15.1902 13.0975 15.1902 12.8692C15.1902 12.6408 15.0051 12.4557 14.7767 12.4557Z"
                    fill="#939BC1"
                />
                <path
                    d="M13.3571 13.9035H10.5907C10.3623 13.9035 10.1772 14.0886 10.1772 14.317C10.1772 14.5453 10.3623 14.7304 10.5907 14.7304H13.3571C13.5855 14.7304 13.7706 14.5453 13.7706 14.317C13.7706 14.0886 13.5855 13.9035 13.3571 13.9035Z"
                    fill="white"
                />
                <path
                    d="M13.3571 13.9035H10.5907C10.3623 13.9035 10.1772 14.0886 10.1772 14.317C10.1772 14.5453 10.3623 14.7304 10.5907 14.7304H13.3571C13.5855 14.7304 13.7706 14.5453 13.7706 14.317C13.7706 14.0886 13.5855 13.9035 13.3571 13.9035Z"
                    fill="#939BC1"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_3002_25612"
                    x1="11.7706"
                    y1="23.5748"
                    x2="11.7706"
                    y2="-0.258015"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#151E47" />
                    <stop offset="1" stopColor="#1B355C" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_3002_25612"
                    x1="20.7631"
                    y1="22.1401"
                    x2="20.7631"
                    y2="17.2637"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#151E47" />
                    <stop offset="1" stopColor="#1B355C" />
                </linearGradient>
                <clipPath id="clip0_3002_25612">
                    <rect width="23.6962" height="24" fill="white" transform="translate(0.151855)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export { CommunityIcon };
