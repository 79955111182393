import { ApolloError, ApolloLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { MessageResponse, Message, MessageCategory, EventTypes } from "./types";
import { raiseEvent } from "iserver365-infrastructure-utility";
import { isWebSocketError } from "./isWebSocketError";

/**
 * This link handles the bff {@link MessageResponse} and raises a {@link EventTypes.BffResponseMessage} event sending the message data.
 *
 * In addition it handles all the Apollo unhandled errors and raises same even as with an error message data.
 */
export const messageHandlerLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((result) => {
        if (result.data === undefined) {
            return result;
        }

        for (const key in result.data) {
            const value = result.data[key] as MessageResponse | MessageResponse[];

            if (Array.isArray(value)) {
                value.forEach((messageResponse) => {
                    messageResponse?.messages?.forEach((message) => raiseEvent(EventTypes.BffResponseMessage, message));
                });
                continue;
            }

            if (value?.messages === undefined || value?.messages === null) {
                continue;
            }

            value.messages.forEach((message: Message) => {
                raiseEvent(EventTypes.BffResponseMessage, message);
            });
        }

        return result;
    });
}).concat(
    onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors !== undefined) {
            graphQLErrors.forEach(({ message }) =>
                raiseEvent(EventTypes.BffResponseMessage, {
                    messageCategory: MessageCategory.Error,
                    messageCode: 0,
                    messageString: message,
                })
            );
        }

        if (networkError !== undefined && !isWebSocketError(networkError as ApolloError)) {
            raiseEvent(EventTypes.BffResponseMessage, {
                messageCategory: MessageCategory.Error,
                messageCode: 0,
                messageString: networkError?.message,
            });
        }
    })
);
