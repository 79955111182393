import React, { useState } from "react";
import {
    CurrencyListItemField,
    DateTimeListItemField,
    FavouriteListItemField,
    HyperlinkListItemField,
    ListItemField,
    NumberListItemField,
    PersonListItemField,
    StateListItemField,
    TagsListItemField,
    TextListItemField,
    ValueType,
} from "../types";
import { CellProps } from "./CellProps";
import { CurrencyCell } from "./CurrencyCell";
import { DateTimeCell } from "./DateTimeCell";
import { FavouriteCell } from "./FavouriteCell";
import { PersonCell } from "./PersonCell";
import { StateCell } from "./StateCell";
import { SVGIcon } from "../../SVGIcon";
import { TagsCell } from "./TagsCell";
import { TextCell } from "./TextCell";
import { useStyles } from "./EditableCell.styles";
import { Stack } from "@fluentui/react";
import { NumberCell } from "./NumberCell";
import { HyperlinkCell } from "./HyperlinkCell";

type CellContentLookup = Partial<Record<ValueType, (props: CellProps<ListItemField>) => JSX.Element>>;
const cellContentLookup: CellContentLookup = {
    [ValueType.Currency]: (props) => <CurrencyCell {...(props as CellProps<CurrencyListItemField>)} />,
    [ValueType.Number]: (props) => <NumberCell {...(props as CellProps<NumberListItemField>)} />,
    [ValueType.Url]: (props) => <HyperlinkCell {...(props as CellProps<HyperlinkListItemField>)} />,
    [ValueType.Text]: (props) => <TextCell {...(props as CellProps<TextListItemField>)} />,
    [ValueType.Tags]: (props) => <TagsCell {...(props as CellProps<TagsListItemField>)} />,
    [ValueType.State]: (props) => <StateCell {...(props as CellProps<StateListItemField>)} />,
    [ValueType.DateTime]: (props) => <DateTimeCell {...(props as CellProps<DateTimeListItemField>)} />,
    [ValueType.Person]: (props) => <PersonCell {...(props as CellProps<PersonListItemField>)} />,
    [ValueType.Favourite]: (props) => <FavouriteCell {...(props as CellProps<FavouriteListItemField>)} />,
    // TODO implement other ValueType widgets. remaining are DateTimeRelative, Protected
};
const defaultCellContentLookup = () => null;

export interface EditableCellProps {
    field: ListItemField;
    inlineEditing: boolean;
    options: CellProps<ListItemField>["options"];
}

/**
 * Editable-cell for the list view. This is being used as the entry-cell, which wraps the underlying
 * type-cells via the {@link field: ListItemField} and adds support for
 * - cell updating
 * @param {EditableCellProps} param The cell props.
 * @returns {React.FC<EditableCellProps>} The react component.
 */
export const EditableCell: React.FC<EditableCellProps> = ({ field, options }: EditableCellProps) => {
    const styles = useStyles(field);

    const [currentField, setCurrentField] = useState<ListItemField>(field);
    const [editMode, setEditMode] = useState(false);

    const baseProps = {
        onStopEditing() {
            // TODO send data to server
            setEditMode(false);
        },
        editMode,
        field: currentField,
        onValueChange(newField: ListItemField) {
            setCurrentField(newField);
        },
        options,
    };

    const cellContents = (cellContentLookup[baseProps.field.valueType] ?? defaultCellContentLookup)(baseProps);

    return (
        // TODO: handle editing with respect to accessibility requirements
        <Stack horizontal disableShrink styles={styles.cell}>
            {field.icon !== null && (
                <Stack horizontalAlign="center" verticalAlign="center" disableShrink styles={styles.iconContainer}>
                    <SVGIcon icon={field.icon} />
                </Stack>
            )}
            <Stack horizontal disableShrink styles={styles.contentContainer}>
                {cellContents}
            </Stack>
        </Stack>
    );
};
