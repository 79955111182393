import React from "react";
import { IPersonaProps, ITag as IObject } from "@fluentui/react";
import { TextField } from "../../TextField";
import { convertDateToString, DatePicker } from "../../DatePicker";
import { CategoryFilter, ValuesFilter, ValuesType } from "../useQuickFilterReducer";
import { FilterValuesErrorHandler, FilterValuesHandler } from "../QuickFilter.interfaces";
import {
    convertEventToString,
    getValueDate,
    getValueString,
    handleError,
    convertPersonsToKeys,
} from "./utilsFilterValues";

export type FilterValuesProps = {
    /** Field of current row */
    values: ValuesFilter;
    category: CategoryFilter;

    /** Handler for current values */
    valuesHandler: FilterValuesHandler;

    /** Handler for values errors*/
    valuesErrorHandler: FilterValuesErrorHandler;

    onRenderFilterChoice: ({
        attributeId,
        selectedChoices,
        onChange,
    }: {
        attributeId: string | null | number;
        selectedChoices: string[];
        onChange: (selectedKeys: string[]) => void;
    }) => JSX.Element;

    onRenderFilterPeopleOrGroup: ({
        attributeId,
        selectedPeople,
        onChange,
    }: {
        attributeId: string | null | number;
        selectedPeople: string[];
        onChange: (selectedPeople: IPersonaProps[] | undefined) => void;
    }) => JSX.Element;

    onRenderFilterCurrency: ({
        attributeId,
        value,
        onChange,
        onValidationStateChange,
    }: {
        attributeId: string | null | number;
        value: number;
        onChange: (value: number) => void;
        onValidationStateChange: (errors: Error[]) => void;
    }) => JSX.Element;

    onRenderFilterNumber: ({
        attributeId,
        value,
        onChange,
        onValidationStateChange,
    }: {
        attributeId: string | null | number;
        value: number;
        onChange: (value: number) => void;
        onValidationStateChange: (errors: Error[]) => void;
    }) => JSX.Element;

    onRenderRelatedTo: ({
        selectedObjects,
        onChange,
    }: {
        selectedObjects: string[] | IObject[];
        onChange: (items: IObject[] | undefined) => void;
    }) => JSX.Element;
};

/**
 * FilterValues component
 * @param {FilterValuesProps} props for category
 * @returns {JSX.Element} React element
 */
export function FilterValues({
    category,
    values,
    valuesHandler,
    valuesErrorHandler,
    onRenderFilterChoice,
    onRenderFilterPeopleOrGroup,
    onRenderFilterCurrency,
    onRenderFilterNumber,
    onRenderRelatedTo,
}: FilterValuesProps): JSX.Element | null {
    switch (values.type) {
        case ValuesType.Hidden:
            return null;
        case ValuesType.UniqueIdentifier:
        case ValuesType.Text:
            return (
                <TextField
                    ariaLabel="Values"
                    onValidationStateChange={(error) => handleError(values, error, valuesErrorHandler)}
                    style={{ width: "182px" }}
                    value={getValueString(values.value)}
                    onChange={(event) => {
                        valuesHandler(convertEventToString(event));
                    }}
                />
            );
        case ValuesType.Choice:
            return onRenderFilterChoice({
                attributeId: category.value,
                selectedChoices: (Array.isArray(values.value) ? values.value : []) as string[],
                onChange: (selectedKeys: string[]) => valuesHandler(selectedKeys),
            });
        case ValuesType.PersonOrGroup:
            return onRenderFilterPeopleOrGroup({
                attributeId: category.value,
                selectedPeople: (Array.isArray(values.value) ? values.value : []) as string[],
                onChange: (selectedPeople) => valuesHandler(convertPersonsToKeys(selectedPeople ?? [])),
            });
        case ValuesType.Number:
            return onRenderFilterNumber({
                attributeId: category.value,
                value: values.value as number,
                onChange: (value: number) => valuesHandler(value),
                onValidationStateChange: (error) => handleError(values, error, valuesErrorHandler),
            });
        case ValuesType.DateTime:
            return (
                <DatePicker
                    ariaLabel="Values"
                    value={getValueDate(values.value)}
                    onChange={(date) => {
                        valuesHandler(convertDateToString(date) ?? null);
                    }}
                />
            );
        case ValuesType.Currency:
            return onRenderFilterCurrency({
                attributeId: category.value,
                value: values.value as number,
                onChange: (value: number) => valuesHandler(value),
                onValidationStateChange: (error) => handleError(values, error, valuesErrorHandler),
            });
        case ValuesType.RelatedTo:
            return onRenderRelatedTo({
                selectedObjects: (Array.isArray(values.value) ? values.value : []) as string[],
                onChange: (selectedObjects) => {
                    valuesHandler(selectedObjects ?? []);
                },
            });
        case null:
        default:
            return (
                <TextField
                    ariaLabel="Values"
                    value=""
                    disabled
                    textFieldWrapperStyles={{
                        root: {
                            width: "auto",
                        },
                    }}
                />
            );
    }
}
