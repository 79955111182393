import { isNil } from "lodash";
import { ValidationRule, ValidationRuleProps } from "../../";

type Value = number | null | undefined;

/**
 * RequiredNumberError validation error class
 */
export class RequiredNumberError extends Error {
    message = "Field is required";
}

/**
 * RequiredNumber validation rule class
 */
export class RequiredNumber extends ValidationRule<Value> {
    static Error = RequiredNumberError;
    constructor(props: ValidationRuleProps<Value> = {}) {
        super(props);
    }
    validate(value: Value): RequiredNumberError | null {
        if (isNil(value)) {
            return new RequiredNumberError();
        }
        return null;
    }
}
