import { AnimationClassNames, makeStyles } from "@fluentui/react";

export const useStyles = makeStyles((theme) => ({
    errorMessage: [
        AnimationClassNames.slideDownIn20,
        theme.fonts.small,
        {
            color: theme.semanticColors.errorText,
            margin: 0,
            paddingTop: theme.spacing.s1,
            display: "flex",
            alignItems: "center",
        },
    ],
    toggle: {
        label: { wordBreak: "break-word" },
    },
}));
