import { TextField } from "@fluentui/react";
import React from "react";
import { NumberListItemField } from "../types";
import { CellProps } from "./CellProps";

/**
 * Cell for the list view which outputs a formatted Number string.
 * @param {CellProps<NumberListItemField>} param The cell props.
 * @returns {React.FC<CellProps<NumberListItemField>>} The react component.
 */
export const NumberCell: React.FC<CellProps<NumberListItemField>> = ({
    editMode,
    field,
    onStopEditing,
    onValueChange,
    options,
}: CellProps<NumberListItemField>) => {
    if (editMode) {
        return (
            <TextField
                ariaLabel="Edit"
                value={field.value?.toString() ?? ""}
                onChange={(_, newValue) => onValueChange({ ...field, value: Number(newValue) ?? NaN })}
                onBlur={onStopEditing}
            />
        );
    }
    let formattedValue = field.value?.toString();
    if (field.value !== null && field.decimalPlaces !== null) {
        formattedValue = options.formatting.number(field.value, field.decimalPlaces);
    }
    return <>{formattedValue}</>;
};
