import { IDropdownStyles, IStackItemStyles, IStackStyles, IStyle, ITextStyles, useTheme } from "@fluentui/react";
import { SystemColorKey, systemColors } from "iserver365-styleguide";

type UseTicketStyles = {
    label: ITextStyles;
    container: IStackStyles;
    iconContainer: IStackItemStyles;
    contentContainer: IStackStyles;
    subTitleContainer: IStackStyles;
    title: ITextStyles;
    subTitle: ITextStyles;
    description: IStackItemStyles;
    subTitleDropdownPicker: Partial<IDropdownStyles>;
};

/**
 * Hook for generating styles for the Ticket component.
 * @param {SystemColorKey} accentColor - The system color to be used as an accent.
 * @returns {UseTicketStyles} The generated styles.
 */
export const useTicketStyles = (accentColor: SystemColorKey): UseTicketStyles => {
    const { palette, fonts, spacing } = useTheme();

    const subTitle: IStyle = {
        fontWeight: 400,
        fontSize: fonts.small.fontSize,
        color: palette.neutralPrimary,
        opacity: 0.6,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    };

    return {
        label: {
            root: {
                ...fonts.small,
                color: palette.neutralPrimary,
                fontWeight: 400,
            },
        },
        container: {
            root: {
                boxShadow: "0px 1.600000023841858px 3.5999999046325684px 0px #00000021;",
            },
        },
        iconContainer: {
            root: {
                backgroundColor: systemColors[accentColor],
                borderRadius: "2px 0 0 2px",
                padding: `calc(${spacing.s1} + ${spacing.s2}) ${spacing.s2} ${spacing.s2} ${spacing.s2}`,
            },
        },
        contentContainer: {
            root: {
                padding: spacing.s2,
                borderWidth: "1px 1px 1px 0",
                borderStyle: "solid",
                borderColor: palette.neutralLight,
                borderRadius: "0 2px 2px 0",
                justifyContent: "space-between",
                width: "265px",
                minHeight: "136px",
            },
        },
        subTitleContainer: {
            root: {
                paddingBottom: spacing.s1,
            },
        },
        title: {
            root: {
                fontWeight: 600,
                overflow: "hidden",
                textOverflow: "ellipsis",
                wordWrap: "break-word",
                display: "-webkit-box",
                "-webkit-line-clamp": "2",
                "-webkit-box-orient": "vertical",
            },
        },
        subTitle: {
            root: {
                ...subTitle,
            },
        },
        description: { root: { ...subTitle, flexGrow: 2, display: "flex", alignItems: "flex-end" } },
        subTitleDropdownPicker: {
            root: {
                paddingBottom: spacing.s1,
            },
        },
    };
};
