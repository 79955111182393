import { IButtonStyles, IIconStyles, IShimmerStyles, IStackStyles, ITextStyles, useTheme } from "@fluentui/react";
import { SystemColorKey, systemColors } from "../SystemColor";

interface PageTitleStyles {
    /** Back button styles */
    backButton: IButtonStyles;

    /** Title container styles */
    titleContainer: IStackStyles;

    /** Icon container styles */
    iconContainer: IStackStyles;

    /** Icon styles */
    icon: IIconStyles;

    /** Title styles */
    title: ITextStyles;

    /** Subtitle styles */
    subtitle: ITextStyles;

    /** Tag styles */
    tag: IStackStyles;

    /** Header styles */
    header: IStackStyles;

    /** Shimmer styles */
    shimmer: IShimmerStyles;
}

interface UsePageTitleStylesProps {
    iconColor: SystemColorKey;
    backButtonVisible: boolean;
    truncateTitle?: boolean;
}

/**
 * Hook that returns the styles object used for the PageTitle.
 * @param {UsePageTitleStylesProps} iconColor props for styling.
 * @returns {PageTitleStyles} The styles object.
 */
function usePageTitleStyles({
    iconColor,
    backButtonVisible,
    truncateTitle = false,
}: UsePageTitleStylesProps): PageTitleStyles {
    const theme = useTheme();

    return {
        backButton: {
            root: {
                marginRight: theme.spacing.s1,
            },
        },
        titleContainer: {
            root: { overflow: "hidden" },
        },
        iconContainer: {
            root: {
                height: "2rem",
                width: "2rem",
                minWidth: "2rem", // adjusting flexShrink is disabled for Stack component children, we need to specify minWidth to avoid shrinkage
                backgroundColor: systemColors[iconColor],
                borderRadius: "2px",
                marginRight: theme.spacing.s2,
            },
        },
        icon: {
            root: {
                fontSize: theme.fonts.xLargePlus.fontSize,
                color: "white",
            },
        },
        title: {
            root: {
                marginTop: 0,
                marginBottom: 0,
                marginRight: theme.spacing.l1,
                fontSize: theme.fonts.xxLarge.fontSize,
                fontWeight: theme.fonts.xxLarge.fontWeight,
                wordBreak: "break-all",
                overflow: "hidden",
                ...(truncateTitle
                    ? {
                          textOverflow: "ellipsis",
                          textWrap: "nowrap",
                      }
                    : {}),
            },
        },
        subtitle: {
            root: {
                marginTop: 0,
                marginBottom: 0,
                marginRight: theme.spacing.l1,
                wordBreak: "break-all",
                overflow: "hidden",
                ...(truncateTitle
                    ? {
                          textOverflow: "ellipsis",
                          textWrap: "nowrap",
                      }
                    : {}),
            },
        },
        tag: {
            root: {
                padding: theme.spacing.s2,
                marginRight: theme.spacing.s2,
                marginTop: theme.spacing.s2,
                background: theme.palette.neutralLighter,
            },
        },
        header: {
            root: {
                padding: `0 ${backButtonVisible ? theme.spacing.l1 : theme.spacing.s2}`,
                minHeight: "40px",
                overflow: "hidden",
            },
        },
        shimmer: {
            root: { overflow: "hidden" },
        },
    };
}

export { usePageTitleStyles };
