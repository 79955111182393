import { AccountInfo } from "@azure/msal-browser";
import { settings } from "./settings";
import { createConfiguration } from "./authConfig";
import { AuthModule } from "./authModule";
import { legacyLogout } from "iserver365-legacy-utility";
import { createAuthenticationModule } from "./authModuleFactory";

/**
 * Logs the user in by redirecting and returns the {@link AccountInfo} for the logged-in user.
 *
 * If the user is not already logged in, then this function returns {@link undefined}. However, as the browser will have
 * already redirected to the login page before returning from this function, the return value can be ignored.
 *
 * If the user is already logged in, then the {@link AccountInfo} is returned and no redirect occurs.
 * @returns {AccountInfo | undefined} The {@link AccountInfo} for the logged-in user, or {@link undefined} if not found.
 */
async function login(): Promise<AccountInfo | undefined> {
    const module = await initializeAuthenticationModule();

    if (module === undefined) {
        return undefined;
    }

    const currentAccount = module.getCurrentAccount();

    if (currentAccount === undefined) {
        await module.login();
        return undefined;
    }

    return currentAccount;
}

/**
 * Logs the user out of iServer365. Once logout is complete, user is redirected to the user selection login page.
 *
 * This method will also attempt to log the user out of the legacy webapp. If logout of the legacy app fails,
 * an error will be raised.
 * @param {string} redirectUrl The redirect Url.
 * @returns {Promise} The promise for the logout operation.
 */
async function logout(redirectUrl?: string): Promise<void> {
    const legacyLogoutResponse = await legacyLogout();

    if (!legacyLogoutResponse) {
        throw new Error("Legacy app logout failed!");
    }

    return getAuthModule().logout(redirectUrl);
}

/**
 * Returns the {@link AccountInfo} for the current user.
 *
 * This requires a user to be logged in first, by calling the {@link login} function.
 * @returns {AccountInfo | undefined} The {@link AccountInfo} for the current user, or {@link undefined} if not found.
 */
function getCurrentAccount(): AccountInfo | undefined {
    return getAuthModule().getCurrentAccount();
}

/**
 * Gets a token for iServer365 silently, or falls back to interactive redirect if silent retrieval of token fails.
 * @returns {Promise<string>} The access token string.
 */
function getAccessToken(): Promise<string> {
    return getAuthModule().getAccessToken();
}

function getAuthModule() {
    if (authModule === undefined) {
        throw Error("User is required to login first");
    }

    return authModule;
}

let authModule: AuthModule | undefined;

async function initializeAuthenticationModule() {
    // if we are in a live environment, we need to determine the challenge url from the current url,
    // as all api url's in iServer365 follow a naming convention.
    const challengeUrl = settings.challengeUrl;

    const config = await createConfiguration(challengeUrl);

    authModule = await createAuthenticationModule(config);

    return authModule;
}

export { login, logout, getCurrentAccount, getAccessToken };
