import React, { ReactElement, useMemo } from "react";
import { useControllableValue } from "@fluentui/react-hooks";
import { Toggle } from "@fluentui/react";
import { useStyles } from "./TrueFalseField.styles";
import { ComponentValidationProps, useValidation } from "../utils/validation";
import { getDisplayValidationError, getValidationRules, OnChangeHandler, Value } from "./TrueFalseField.service";
import { isNil, mergeWith } from "lodash";
import { TextField } from "../TextField";
import { FieldLabel } from "../FieldLabel";
import { ILabeledFieldProps } from "../types";

/**
 * {@link TrueFalseField} props The component props.
 */
export interface TrueFalseFieldProps extends ILabeledFieldProps, ComponentValidationProps<Value> {
    /** Text for the component labels */
    text?: {
        /** Text for the label with true value*/
        labelTrue: string;
        /** Text for the label with false value*/
        labelFalse: string;
    };
    /** Value of the component */
    value?: boolean;
    /** The initial value. Use to set initial value in uncontrolled mode. */
    defaultValue?: boolean;
    /** Is value required for the input. */
    isRequired?: boolean;
    /** Enables readOnly mode. */
    readOnly?: boolean;
    /** Makes the field disabled. */
    disabled?: boolean;
    /** Handler for value change */
    onChange?: OnChangeHandler;
}

/**
 * Renders the three state toggle component.
 * In readOnly mode displays the text value that can be customized with props.
 * @param {TrueFalseFieldProps} props The component props.
 * @returns {JSX.Element} the react element.
 */
export function TrueFalseField(props: TrueFalseFieldProps): ReactElement {
    const {
        text,
        value,
        defaultValue,
        readOnly = false,
        isRequired = false,
        disabled = false,
        onChange,
        onValidationStateChange,
        validationRules = [],
        validationErrorMessageProvider,
        label,
        onRenderLabel,
        id,
    } = props;

    const { errorMessage, toggle } = useStyles();

    const [valueState, setValueState] = useControllableValue(value, defaultValue, onChange);

    const rules = useMemo(() => getValidationRules(validationRules, isRequired), [validationRules, isRequired]);

    const textOrDefault = useMemo(() => {
        const defaultText = {
            labelTrue: "Yes",
            labelFalse: "No",
        };
        return mergeWith(defaultText, text);
    }, [text]);

    const { validationErrors, validationErrorMessages } = useValidation({
        value: valueState,
        rules,
        onValidationStateChange,
        validationErrorMessageProvider,
    });

    const validationErrorToDisplay: string | undefined = validationErrorMessages[0];
    const displayValidationError: boolean = getDisplayValidationError({ validationErrors, readOnly });

    const displayValue =
        valueState === true ? textOrDefault.labelTrue : valueState === false ? textOrDefault.labelFalse : "-";
    const toggleLabel = () => {
        return !isNil(onRenderLabel) ? onRenderLabel() ?? undefined : <FieldLabel label={label} />;
    };

    return readOnly ? (
        <TextField readOnly borderless value={displayValue} label={label} onRenderLabel={onRenderLabel} id={id} />
    ) : (
        <>
            <Toggle
                onText={textOrDefault.labelTrue}
                offText={textOrDefault.labelFalse}
                defaultChecked={defaultValue}
                checked={value}
                disabled={disabled}
                label={toggleLabel()}
                className={toggle}
                onChange={(e, checked) => setValueState(checked, e)}
            />
            {displayValidationError && <p className={errorMessage}>{validationErrorToDisplay}</p>}
        </>
    );
}
