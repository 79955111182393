import { registerApplication, start, addErrorHandler } from "single-spa";
import { constructApplications, constructLayoutEngine, constructRoutes } from "single-spa-layout";
import { importMFE } from "./mfeImportMap";
import { resolveMFEProps } from "./mfePropsResolver";
import { initializeStyleguide } from "iserver365-styleguide";
import { authenticateSession } from "iserver365-authorization-utility";
import { handleMFEError } from "./mfeErrorHandler";
import { addEventHandlers } from "./eventHandlers";
import { initLegacyApp } from "iserver365-legacy-app/app";

import "./layout.css";
import "iserver365-styleguide/css";
import { logout } from "iserver365-authentication-utility";
import { resolveMFEActiveWhen } from "./mfeActivationResolver";
import { loader as topbarLoader } from "iserver365-topbar/loader";
import { initializeAmplitude } from "iserver365-amplitude-utility";

const logoutPath = "/authorize/logout";

// login user
await authenticateSession();

// Logout user if path is "/authorize/logout". This is called from legacy UI (configure area), and allows
// the user to logout from both legacy UI (by removing the cookie calling "/legacyLogout") and new UI (by
// redirecting the user to the Microsoft sign out page).
// It needs to go after "authenticateSession", otherwise the authentication module doesn't find any user
// to logout.
if (window.location.pathname === logoutPath) {
    await logout(window.location.origin);
}

initializeStyleguide();

addErrorHandler(handleMFEError);
addEventHandlers();

const template = document.querySelector("#single-spa-layout") as HTMLTemplateElement;
const routes = constructRoutes(template, {
    props: {},
    loaders: {
        topbarLoader: topbarLoader(),
    },
});

const applications = constructApplications({
    routes,
    // getting an error when assigning importMFE to loadApp directly, this is because they seem to be using
    // different TS types but it does work. This seems to be a bug in single-spa.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    loadApp: importMFE as any,
});
const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach((app) => {
    registerApplication(app.name, app.app, resolveMFEActiveWhen(app.name, app.activeWhen), resolveMFEProps(app.name));
});

layoutEngine.activate();

// Initialization for legacy app to reduce its preload time on mount
initLegacyApp();

start();

// initialize amplitude once the singleSPA is ready to improve startup speed, also ignoring the await to load in the background.
initializeAmplitude();
