import { isNil, isEmpty } from "lodash";
import { convertToRaw, ContentState, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";

import { ValidationRule, ValidationError } from "../utils/validation";
import { MaxStringLength, MinStringLength, MinMaxStringLength, RequiredString } from "../utils/validation/rules";
import { removeLineBreaks, removeHtmlTags } from "../utils/validation/escapers";

const escapeValue = [removeHtmlTags, removeLineBreaks];
const editorEmptyValue = editorStateToHtml(EditorState.createEmpty());

const isEmptyOrDefault = (htmlValue?: string): boolean => isNil(htmlValue) || htmlValue === editorEmptyValue;

export type Value = string | undefined;

/**
 * Helper class to make {@link EditorState} easier to consume.
 */
export class RichTextFieldState {
    constructor(public editorState: EditorState) {}
    /**
     * @returns {string} RichTextState converted to HTML string
     */
    public toHtml = (): string => editorStateToHtml(this.editorState);

    public static DefaultHtmlValue = editorEmptyValue;
}

/**
 * @param {string} html HTML to convert to an EditorState instance
 * @returns {EditorState} Resulting EditorState
 */
export function htmlToEditorState(html: string): EditorState {
    const { contentBlocks, entityMap } = htmlToDraft(html);
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

    return EditorState.createWithContent(contentState);
}

/**
 * @param {EditorState} editorState editor state to convert to HTML
 * @returns {string} HTML derived from editor state
 */
export function editorStateToHtml(editorState: EditorState): string {
    return draftToHtml(convertToRaw(editorState.getCurrentContent())).replaceAll("\n", "").trim();
}

/**
 *  @param {object} params Parameter object
 * @param {string} params.html HTML to convert to RichTextState object
 * @param {boolean} params.moveSelectionToEnd Whether the selection must be moved to the end
 * @returns {RichTextFieldState} RichTextState created from HTML
 */
export function htmlToRichTextState({
    html,
    moveSelectionToEnd,
}: { html?: string; moveSelectionToEnd?: boolean } = {}): RichTextFieldState {
    if (isNil(html)) {
        return new RichTextFieldState(EditorState.createEmpty());
    }

    const editor = htmlToEditorState(html);
    const editorWithSelection = moveSelectionToEnd === true ? EditorState.moveSelectionToEnd(editor) : editor;

    return new RichTextFieldState(editorWithSelection);
}

/**
 * @param {string} value Draft's js RTE value
 * @returns {string} Value that is compatible for legacy's Rich Text Editor
 */
export function getLegacyCompatibleRteValue(value = ""): string {
    return value
        .replace(/(<ins)/gim, "<u")
        .replace(/<\/ins>/gim, "</u>")
        .replace(/(<strong)/gim, "<b")
        .replace(/<\/strong>/gim, "</b>")
        .replace(/(<em)/gim, "<i")
        .replace(/<\/em>/gim, "</i>");
}

/**
 * @param {string} value Legacy's Rich Text Editor value
 * @returns {string} Value that is compatible for Draft's js RTE
 */
export function getRteCompatibleLegacyValue(value = ""): string {
    return value
        .replace(/(<u>)/g, "<ins>")
        .replace(/(<\/u>)/g, "</ins>")
        .replace(/(<b>)/g, "<strong>")
        .replace(/(<\/b>)/g, "</strong>")
        .replace(/(<i>)/g, "<em>")
        .replace(/(<\/i>)/g, "</em>");
}

/**
 * @param {object} params Parameter object
 * @param {ValidationRule[]} params.validationRules Validation rules to apply to input
 * @param {boolean} params.isRequired Whether the input is required
 * @param {number} [params.minLength] Minimum required length for the input
 * @param {number} [params.maxLength] Maximum allowed length for the input
 * @returns {ValidationRule[]} Array of validation rules to apply
 */
export function getValidationRules({
    validationRules,
    isRequired,
    minLength,
    maxLength,
}: {
    validationRules: ValidationRule<Value>[];
    isRequired: boolean;
    minLength?: number;
    maxLength?: number;
}): ValidationRule<Value>[] {
    const builtinRules = [];
    if (isRequired) {
        builtinRules.push(new RequiredString({ escapeValue }));
    }
    if (!isNil(minLength) && !isNil(maxLength)) {
        builtinRules.push(new MinMaxStringLength({ minLength, maxLength, escapeValue }));
    }
    if (!isNil(minLength)) {
        builtinRules.push(new MinStringLength({ minLength, escapeValue }));
    }
    if (!isNil(maxLength)) {
        builtinRules.push(new MaxStringLength({ maxLength, escapeValue }));
    }
    return [...builtinRules, ...validationRules];
}

/**
 * @param {object} params Parameter object
 * @param {ValidationError[]} params.validationErrors List of current validation errors for the RichText component
 * @param {boolean} params.disabled Whether or not the RichText component is currently disabled
 * @param {boolean} params.hasChanged Whether or not the value was changed
 * @returns {boolean} Whether or not to display a validation error
 */
export function getDisplayValidationError({
    validationErrors,
    disabled,
    hasChanged,
}: {
    validationErrors: ValidationError[];
    disabled: boolean;
    hasChanged: boolean;
}): boolean {
    return !isEmpty(validationErrors) && !disabled && hasChanged;
}

/**
 * Checks whether the value has changed
 * @param {RichTextFieldState} prevValue The previous value
 * @param {RichTextFieldState} nextValue The next value
 * @returns {boolean} True if the value has changed
 */
export function fieldValueHasChanged(
    prevValue?: RichTextFieldState | null,
    nextValue?: RichTextFieldState | null
): boolean {
    const prevHtmlValue = prevValue?.toHtml();
    const nextHtmlValue = nextValue?.toHtml();

    return prevHtmlValue !== nextHtmlValue && !(isEmptyOrDefault(prevHtmlValue) && isEmptyOrDefault(nextHtmlValue));
}
