import { IStackItemStyles, IStackStyles, useTheme } from "@fluentui/react";

export interface BadgeStyles {
    badgeStyles?: IStackItemStyles;
    badgeContainerStyles?: IStackStyles;
}

/**
 * Returns default styles for the Badge component.
 * @returns {string} {@link BadgeStyles} component default styles.
 */
export function useBadgeStyles(): BadgeStyles {
    const { spacing, palette, fonts } = useTheme();

    return {
        badgeStyles: {
            root: {
                width: spacing.l2,
                padding: spacing.s1,
                height: spacing.m,
                borderRadius: spacing.s2,
                backgroundColor: palette.themePrimary,
                color: palette.white,
                ...fonts.tiny,
                lineHeight: spacing.m,
                textAlign: "center",
                position: "absolute",
                top: spacing.s1,
                right: spacing.s1,
                pointerEvents: "none",
            },
        },
        badgeContainerStyles: {
            root: {
                position: "relative",
                ":hover > :last-child": {
                    background: palette.themeDark,
                },
            },
        },
    };
}
