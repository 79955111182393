import { AuthModule, AuthModuleConfiguration } from "./authModule";

/**
 * Factory method for {@link AuthModule }
 * Factory method for {@link AuthModule}. Creates an loads an {@link AuthModule} using the passed
 * in {@link config}.
 * @param {AuthModuleConfiguration} config The configuration for the {@link AuthModule}.
 * @returns {Promise<AuthModule>} A promise containing the {@link AuthModule}, which is resolved
 * once the auth module has loaded.
 */
async function createAuthenticationModule(config: AuthModuleConfiguration): Promise<AuthModule> {
    const authModule = new AuthModule(config);

    await authModule.loadAuthModule();

    return authModule;
}

export { createAuthenticationModule };
