import { useTheme } from "@fluentui/react";
import { NotificationStatus, StatusInfo } from "./types";

/**
 * Returns the {@link StatusInfo} based on passed {@link status}.
 * @param {NotificationStatus} status The notification status.
 * @param {number} progress The notification progress.
 * @returns {StatusInfo} The status information based on passed {@link status}.
 */
export function useStatusInfo(status: NotificationStatus, progress?: number): StatusInfo {
    const { palette } = useTheme();

    const statusInfo: Record<NotificationStatus, StatusInfo> = {
        [NotificationStatus.Info]: {
            icon: "ProgressRingDots",
            color: palette.blue,
        },
        [NotificationStatus.Success]: {
            icon: "CheckMark",
            color: palette.green,
        },
        [NotificationStatus.Pending]: {
            icon: "ReminderTime",
            color: palette.neutralSecondaryAlt,
        },
        [NotificationStatus.Warning]: {
            icon: "Warning",
            color: palette.orangeLighter,
        },
        [NotificationStatus.Error]: {
            icon: "Info",
            color: palette.redDark,
        },
    };

    let info = statusInfo[status];
    // For the moment notification card determines which status info to show depending on the notification status...
    if (progress !== undefined) {
        info = { ...info, icon: "ProgressRingDots" };
    }

    return info;
}
