const theme = {
    themePrimary: "#151E47",
    themeLighterAlt: "#D5D9E9",
    themeLighter: "#B3B9D5",
    themeLight: "#939BC1",
    themeTertiary: "#7680AC",
    themeSecondary: "#5d6798",
    themeDarkAlt: "#465184",
    themeDark: "#333E70",
    themeDarker: "#232D5C",
};

export { theme };
