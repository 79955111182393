import { NavigationStates, registerStates } from "iserver365-navigator/api";
import { ReactAppOrParcel } from "single-spa-react";
import { states as legacyStates } from "iserver365-legacy-app/states";
import { states as viewsStates } from "iserver365-views/states";
import { states as dashboardStates } from "iserver365-dashboard/states";
import { states as dashboardsStates } from "iserver365-dashboards/states";
import { states as contentManagementStates } from "iserver365-content-management/states";
import { states as drawStates } from "iserver365-draw/states";
import { legacyApp } from "iserver365-legacy-app/app";
import { MFENames } from "./mfeNames";
import { AppProps } from "single-spa";

function registerModuleStates(...statesArray: NavigationStates[]) {
    statesArray.forEach((states) => registerStates(states));
}

// __IMPORTANT!__ Register states of the MFEs
registerModuleStates(legacyStates, viewsStates, dashboardStates, dashboardsStates, contentManagementStates, drawStates);

/**
 * Imports and returns the microfrontend app specified by the {@link name}.
 *
 * __IMPORTANT!__ This module is designed to temporarily imitate SystemJS import maps.
 * After we move from mono -> multi repository per MFE we will start using SystemJS or native
 * modules + module import maps. At that point we should use mapping in this function as
 * base for real import maps.
 * @param { {name: string} } param The object containing the name of MFE to import.
 * @returns {ReactAppOrParcel} The react app corresponding to the MFE.
 */
export function importMFE({ name }: { name: string }): Promise<ReactAppOrParcel<AppProps>> {
    switch (name) {
        case MFENames.Sidebar:
            return import(/* webpackChunkName: "sidebar" */ "iserver365-sidebar/app");
        case MFENames.Topbar:
            return import(/* webpackChunkName: "topbar" */ "iserver365-topbar/app");
        case MFENames.LegacyApp:
            return Promise.resolve(legacyApp);
        case MFENames.Views:
            return import(/* webpackChunkName: "views" */ "iserver365-views/app");
        case MFENames.Dashboard:
            return import(/* webpackChunkName: "navigator" */ "iserver365-dashboard/app");
        case MFENames.Dashboards:
            return import(/* webpackChunkName: "dashboards" */ "iserver365-dashboards/app");
        case MFENames.Navigator:
            return import(/* webpackChunkName: "navigator" */ "iserver365-navigator/app");
        case MFENames.NoAccess:
            return import(/* webpackPrefetch: true */ /* webpackChunkName: "no-access" */ "iserver365-no-access/app");
        case MFENames.Share:
            return import(/* webpackChunkName: "share" */ "iserver365-share/app");
        case MFENames.SomethingWentWrong:
            return import(
                /* webpackPrefetch: true */ /*webpackChunkName: "something-went-wrong" */ "iserver365-something-went-wrong/app"
            );
        case MFENames.Toaster:
            return import(/* webpackChunkName: "toaster" */ "iserver365-toaster/app");
        case MFENames.ContentManagement:
            return import(/* webpackChunkName: "content-management" */ "iserver365-content-management/app");
        case MFENames.Draw:
            return import(/* webpackChunkName: "draw" */ "iserver365-draw/app");
    }

    throw new Error(`Unsupported app name ${name}`);
}
