import { get } from "lodash";
import React from "react";
import classNames from "classnames";

import { CurrentState, OptionKeys, Options, ToolbarOptions } from "../types";
import { Option } from "../Option";

interface GetOptionsProps {
    config: Options;
    currentState: CurrentState;
    disabled: boolean;
    onChange: (value: string | undefined) => void;
    styles: OptionKeys[];
    translations: Record<string, string>;
}

const alignOptions = [OptionKeys.left, OptionKeys.center, OptionKeys.right, OptionKeys.justify];
const listOptions = [OptionKeys.unordered, OptionKeys.ordered, OptionKeys.indent, OptionKeys.outdent];

/**
 * @param {GetOptionsProps} props Function props.
 * @returns {JSX.Element[]} An array of {@link Option} elements.
 */
export function getOptions({
    config,
    currentState,
    disabled,
    onChange,
    styles,
    translations,
}: GetOptionsProps): JSX.Element[] {
    const controlOption = getControlOption(styles);
    return styles.map((style): JSX.Element => {
        return (
            <Option
                key={style}
                value={style}
                onClick={onChange}
                className={classNames(get(config, style)?.className)}
                active={getCurrentStateValue(currentState, style)}
                disabled={disabled}
                {...config[style]}
                title={get(config, style)?.title ?? translations[`components.controls.${controlOption}.${style}`]}
            />
        );
    });
}

/**
 * @param {CurrentState} currentState The current editor state.
 * @param {OptionKeys} style The style option to check.
 * @returns {boolean} Whether the style option is active in the current state.
 */ export function getCurrentStateValue(currentState: CurrentState, style: OptionKeys): boolean {
    // Check if currentState is empty
    if (Object.keys(currentState).length === 0) {
        return false;
    }

    if (alignOptions.includes(style)) {
        return currentState.textAlignment === style;
    }

    if (listOptions.includes(style)) {
        return currentState.listType === style;
    }

    return currentState[style] === true;
}

/**
 * @param {OptionKeys[]} options An array of toolbar options.
 * @returns {string} Control option needed for toolbar tooltip.
 */
export function getControlOption(options: OptionKeys[]): string {
    let controlOption = "";

    const inlineOptions = [
        OptionKeys.bold,
        OptionKeys.italic,
        OptionKeys.underline,
        OptionKeys.strikethrough,
        OptionKeys.monospace,
        OptionKeys.superscript,
        OptionKeys.subscript,
    ];

    const firstOption = options[0];

    if (inlineOptions.includes(firstOption)) {
        controlOption = ToolbarOptions.inline;
    }

    if (alignOptions.includes(firstOption)) {
        controlOption = ToolbarOptions.textAlign;
    }

    if (listOptions.includes(firstOption)) {
        controlOption = ToolbarOptions.list;
    }

    return controlOption.toLowerCase();
}
