/* eslint-disable jsdoc/no-undefined-types */
import { isEmpty, isNil } from "lodash";
import { ValidationError, ValidationRule } from "../utils/validation";
import { RequiredDate } from "../utils/validation/rules/RequiredDate";
import { MinMaxDate } from "../utils/validation/rules/MinMaxDate";
import { MinDate } from "../utils/validation/rules/MinDate";
import { MaxDate } from "../utils/validation/rules/MaxDate";

type Value = Date | undefined;

/**
 * Function which convert date to ISO string
 * @param {Date | null | undefined} date received from datePicker input
 * @returns {string | undefined} return date ISO string
 */
function convertDateToString(date: Date | null | undefined): string | undefined {
    return isNil(date) ? undefined : new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
}

/**
 * Function which convert ISO string to date
 * @param {string | undefined} date received from API
 * @param {boolean} ignoreTimezone specifies whether timezone info should be ignored and if date should be treated as in local timezone
 * @returns {Date | undefined} return date
 */
function convertStringToDate(date: string | undefined, ignoreTimezone = false): Date | undefined {
    if (isNil(date) || date === "") {
        return undefined;
    }

    if (ignoreTimezone) {
        const dateObj = new Date(date);
        return new Date(dateObj.getTime() + dateObj.getTimezoneOffset() * 60000);
    }

    return new Date(date);
}

interface GetValidationRuleProps {
    validationRules: ValidationRule<Value>[];
    isRequired: boolean;
    min?: Date;
    max?: Date;
}

/**
 * Gets the validation rules for DatePicker component.
 * @param {GetValidationRuleProps} params Validation specification object
 * @returns {ValidationRule[]} Validation rules to apply to {@link DatePicker}
 */
function getValidationRules({
    validationRules,
    isRequired,
    min,
    max,
}: GetValidationRuleProps): ValidationRule<Value>[] {
    const builtinRules = [];
    if (isRequired) {
        builtinRules.push(new RequiredDate());
    }
    if (!isNil(min) && !isNil(max)) {
        builtinRules.push(new MinMaxDate({ minDate: min, maxDate: max }));
    } else if (!isNil(min)) {
        builtinRules.push(new MinDate({ minDate: min }));
    } else if (!isNil(max)) {
        builtinRules.push(new MaxDate({ maxDate: max }));
    }

    return [...builtinRules, ...validationRules];
}

type GetDisplayValidationErrorProps = {
    // List of current validation errors for the DatePicker component
    validationErrors: ValidationError[];
    // Whether or not the DatePicker component is currently readOnly
    readOnly: boolean;
    // Whether or not the DatePicker component value equals to default
    isDefaultValue: boolean;
    // List of current validation error messages for the DatePicker component
    validationErrorMessages: string[];
    /**
     * Specifies if the user's manual input in DatePicker component is invalid
     * Provides error message to display in case of invalid user input. Otherwise, undefined.
     */
    invalidUserInputErrorMessage: string | undefined;
};

/**
 * @param {GetDisplayValidationErrorProps} params Parameter object
 * @returns {[boolean, string | undefined]} Whether or not to display a validation error and validation error message to display.
 */
function getDisplayValidationError({
    validationErrors,
    readOnly,
    isDefaultValue,
    validationErrorMessages = [],
    invalidUserInputErrorMessage,
}: GetDisplayValidationErrorProps): [boolean, string | undefined] {
    const shouldDisplayError =
        (!isEmpty(validationErrors) || !isEmpty(invalidUserInputErrorMessage)) && !readOnly && !isDefaultValue;
    const errorMessages = shouldDisplayError
        ? [invalidUserInputErrorMessage, validationErrorMessages[0]].filter((message) => !isEmpty(message))
        : [];
    const errorMessage = isEmpty(errorMessages) ? undefined : errorMessages.join(" ");
    return [shouldDisplayError, errorMessage];
}

export type { Value, GetDisplayValidationErrorProps, GetValidationRuleProps };
export { convertDateToString, convertStringToDate, getValidationRules, getDisplayValidationError };
