import { listenEvent } from "iserver365-infrastructure-utility";
import { isNil } from "lodash";

export const lastUserActionTimestamp = "Orbus-LastUserActionTimestamp";

/**
 * Initializes automatic logout if it is configured for the tenant.
 */
export async function initializeAutomaticLogout(): Promise<void> {
    const { getSettings } = await import("iserver365-settings-utility");
    const { logout } = await import("iserver365-authentication-utility");

    const userSettings = await getSettings();
    const isLoggingOut = window.location.pathname === "/authorize/logout";

    if (
        isNil(userSettings?.sessionExpirationMinutes) ||
        (userSettings?.sessionExpirationMinutes ?? 0) <= 0 ||
        isLoggingOut
    ) {
        return;
    }

    const getCurrentTime = () => new Date().getTime();
    const setLastActionTimestamp = () =>
        window.localStorage.setItem(lastUserActionTimestamp, getCurrentTime().toString());

    setLastActionTimestamp();

    listenEvent("click", () => {
        setLastActionTimestamp();
    });

    setInterval(() => {
        if (isLoggingOut) {
            return;
        }
        const lastActionDate = parseInt(window.localStorage.getItem(lastUserActionTimestamp) ?? "");

        const limit = getCurrentTime() - (userSettings?.sessionExpirationMinutes ?? 0) * 60 * 1000;

        if (isNaN(lastActionDate) || lastActionDate < limit) {
            window.localStorage.removeItem(lastUserActionTimestamp);
            logout();
        }
    }, 1000);
}
