import { isNil } from "lodash";
import { ValidationError, ValidationRule, ValidationRuleProps } from "../..";

type Value = number | null | undefined;

export interface MaxNumberValueErrorProps {
    /**
        Maximum number value allowed for the input.
     */
    max: number;
    /**
        Actual value received for the input.
     */
    actual: number;
}

export interface MaxNumberValueProps extends ValidationRuleProps<Value> {
    /**
        Maximum number value allowed for the input.
     */
    max: number;
}

/**
 * MaxNumberValueError validation error class
 */
export class MaxNumberValueError implements ValidationError {
    constructor(props: MaxNumberValueErrorProps) {
        this.name = "MaxNumberValueError";
        this.max = props.max;
        this.actual = props.actual;
        this.message = `This value must be lower than or equal to ${props.max}.`;
    }
    name: string;
    max: number;
    actual: number;
    message: string;
}

/**
 * MaxNumberValue validation rule class
 */
export class MaxNumberValue extends ValidationRule<Value> {
    static Error = MaxNumberValueError;
    constructor(props: MaxNumberValueProps) {
        super(props);
        this.max = props.max;
    }

    max: number;

    validate(value: Value): MaxNumberValueError | null {
        if (!isNil(value) && value > this.max) {
            return new MaxNumberValueError({ max: this.max, actual: value });
        }

        return null;
    }
}
