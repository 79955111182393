import { isNil } from "lodash";
import { ValidationError, ValidationRule, ValidationRuleProps } from "../..";

type Value = string | undefined;

export interface MaxStringLengthErrorProps {
    /**
        Maximum length allowed for the input.
     */
    maxLength: number;
    /**
        Actual length received for the input.
     */
    actualLength: number;
}

export interface MaxStringLengthProps extends ValidationRuleProps<Value> {
    /**
        Maximum length allowed for the input.
     */
    maxLength: number;
}

/**
 * MaxStringLengthError validation error class
 */
export class MaxStringLengthError implements ValidationError {
    constructor(props: MaxStringLengthErrorProps) {
        this.name = "MaxStringLengthError";
        this.maxLength = props.maxLength;
        this.actualLength = props.actualLength;
        this.message = `The number of characters must not exceed ${props.maxLength}.`;
    }
    name: string;
    maxLength: number;
    actualLength: number;
    message: string;
}

/**
 * MaxStringLength validation rule class
 */
export class MaxStringLength extends ValidationRule<Value> {
    static Error = MaxStringLengthError;
    constructor(props: MaxStringLengthProps) {
        super(props);
        this.maxLength = props.maxLength;
    }

    maxLength: number;

    validate(value: Value): MaxStringLengthError | null {
        if (isNil(value) || value === "") {
            return null;
        }

        if (value.length > this.maxLength) {
            return new MaxStringLengthError({ maxLength: this.maxLength, actualLength: value.length });
        }

        return null;
    }
}
