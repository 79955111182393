import { isNil } from "lodash";
import { ValidationError, ValidationRule, ValidationRuleProps } from "../..";

type Value = number | null | undefined;

export interface MinMaxNumberValueErrorProps {
    /**
     Minimum number value required for the input.
     */
    min: number;
    /**
     Maximum number value required for the input.
     */
    max: number;
}

export interface MinMaxNumberValueProps extends ValidationRuleProps<Value> {
    /**
     Minimum number value required for the input.
     */
    min: number;
    /**
     Maximum number value required for the input.
     */
    max: number;
}

/**
 * MinMaxNumberValueError validation error class
 */
export class MinMaxNumberValueError implements ValidationError {
    constructor(props: MinMaxNumberValueErrorProps) {
        this.name = "MinMaxNumberValueError";
        this.min = props.min;
        this.max = props.max;
        this.message = `This value must be between ${props.min} and ${props.max}.`;
    }
    name: string;
    min: number;
    max: number;
    message: string;
}

/**
 * MinMaxNumberValue validation rule class
 */
export class MinMaxNumberValue extends ValidationRule<Value> {
    static Error = MinMaxNumberValueError;
    constructor(props: MinMaxNumberValueProps) {
        super(props);
        this.min = props.min;
        this.max = props.max;
    }

    min: number;
    max: number;

    validate(value: Value): MinMaxNumberValueError | null {
        if (!isNil(value) && (value < this.min || value > this.max)) {
            return new MinMaxNumberValueError({ min: this.min, max: this.max });
        }

        return null;
    }
}
