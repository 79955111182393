import React from "react";
import { Text } from "@fluentui/react";
import { useBreadcrumbTitleStyles } from "./BreadcrumbTitle.styles";
import { BreadcrumbItemDefinition } from "../types";

type BreadcrumbTitleProps = {
    text: string;
    isCurrentItem?: boolean;
    /** Specifies the HTML tag to render title as. Default set to h4. */
    as?: BreadcrumbItemDefinition["as"];
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
};

/**
 * Renders the BreadcrumbTitle component.
 * @param {BreadcrumbTitleProps} props - The component's props.
 * @returns {JSX.Element} The rendered BreadcrumbTitle component.
 */
function BreadcrumbTitle({ text, as, isCurrentItem }: BreadcrumbTitleProps): JSX.Element {
    const { title } = useBreadcrumbTitleStyles(isCurrentItem);

    return (
        <Text as={as ?? "h4"} styles={title}>
            {text}
        </Text>
    );
}

export { BreadcrumbTitle };
export type { BreadcrumbTitleProps };
