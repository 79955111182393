import { TextField } from "@fluentui/react";
import React from "react";
import { TextListItemField } from "../types";
import { CellProps } from "./CellProps";

/**
 * Cell for the list view which outputs a text string.
 * @param {CellProps<TextListItemField>} param The cell props.
 * @returns {React.FC<CellProps<TextListItemField>>} The react component.
 */
export const TextCell: React.FC<CellProps<TextListItemField>> = ({
    editMode,
    field,
    onStopEditing,
    onValueChange,
}: CellProps<TextListItemField>) => {
    if (editMode) {
        return (
            <TextField
                componentRef={(input) => {
                    input?.focus();
                }}
                value={field.text ?? ""}
                onChange={(_, newValue) => onValueChange({ ...field, text: newValue ?? "" })}
                onBlur={onStopEditing}
            />
        );
    }
    return <>{field.text}</>;
};
