import React from "react";
import { IListProps, Shimmer, List, ShimmerElementsGroup, ShimmerElementType, Stack } from "@fluentui/react";
import { range, merge } from "lodash";
import { ShimmeredListStyles, useShimmeredListStyles } from "./ShimmeredList.styles";

const DEFAULT_NUMBER_OF_SHIMMERS = 20;
export interface ShimmeredListProps extends IListProps {
    /** Controls when the shimmer is swapped with actual data through an animated transition. */
    isLoading: boolean;
    /** Elements to render in one group of the Shimmer. */
    onShimmerItemRender?: () => JSX.Element;
    /**
     * The number of shimmer item to be rendered. The value must be greater than 0.
     * @default DEFAULT_NUMBER_OF_SHIMMERS
     */
    shimmerItemCount?: number;

    /** Gap size in pixels between the shimmer items.  */
    shimmerGap?: number | undefined;

    /** Custom List styles. */
    styles?: Partial<ShimmeredListStyles>;
}

/**
 * When loading data it renders a Shimmer component with a passed or default shimmer layout.
 * After loading the data, the List component is rendered.
 * @param {ShimmeredListProps} props The component props
 * @returns {JSX.Element} return react component
 */
export function ShimmeredList(props: ShimmeredListProps): JSX.Element {
    const { isLoading, onShimmerItemRender, shimmerItemCount = DEFAULT_NUMBER_OF_SHIMMERS, styles, shimmerGap } = props;
    const { shimmerStyles, shimmerContainerStyles, listContainerStyles } = merge(useShimmeredListStyles(), styles);

    const getShimmerLayout = (): JSX.Element => {
        if (onShimmerItemRender !== undefined) {
            return onShimmerItemRender();
        }

        return <ShimmerElementsGroup shimmerElements={[{ type: ShimmerElementType.line, height: 8 }]} />;
    };

    const getShimmerItemCount = (): number => {
        return shimmerItemCount > 0 ? shimmerItemCount : DEFAULT_NUMBER_OF_SHIMMERS;
    };

    if (!isLoading) {
        return (
            <Stack styles={listContainerStyles}>
                <List {...props}></List>
            </Stack>
        );
    }

    return (
        <Stack tokens={{ childrenGap: shimmerGap }} styles={shimmerContainerStyles}>
            {range(0, getShimmerItemCount()).map((e) => {
                return <Shimmer key={e} styles={shimmerStyles} customElementsGroup={getShimmerLayout()}></Shimmer>;
            })}
        </Stack>
    );
}
