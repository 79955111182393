import { IStackTokens, ITextStyles, useTheme } from "@fluentui/react";

export type UseTagsStylesOutput = {
    containerTokens: IStackTokens;
    tagItem: ITextStyles;
};

/**
 * Hook for styling Tags component.
 * @returns {UseTagsStylesOutput} The styles object.
 */
export const useTagsStyles = (): UseTagsStylesOutput => {
    const { spacing, palette } = useTheme();
    return {
        containerTokens: {
            childrenGap: spacing.s2,
        },
        tagItem: {
            root: {
                background: palette.neutralLighter,
                paddingTop: spacing.s1,
                paddingBottom: spacing.s1,
                paddingRight: spacing.s2,
                paddingLeft: spacing.s2,
                borderRadius: "2px",
                display: "flex",
                alignItems: "center",
                [":hover"]: {
                    textDecoration: "none",
                },
            },
        },
    };
};
