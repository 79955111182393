import { isNil } from "lodash";
import { ValidationError, ValidationRule, ValidationRuleProps } from "../../types";

type Value = Date | undefined;

export interface MaxDateErrorProps extends ValidationRuleProps<Value> {
    /** Maximum Date allowed for the input. */
    maxDate: Date;
}

/**
 * MinMaxDateError validation error class.
 */
export class MaxDateError implements ValidationError {
    constructor(props: MaxDateErrorProps) {
        this.name = "MaxDateError";
        this.maxDate = props.maxDate;
        this.message = `The date must be before ${props.maxDate.toDateString()}.`;
    }
    name: string;
    maxDate: Date;
    message: string;
}

/**
 * MaxDate validation rule class.
 */
export class MaxDate extends ValidationRule<Value> {
    static Error = MaxDateError;
    constructor(props: MaxDateErrorProps) {
        super(props);
        this.maxDate = props.maxDate;
    }

    maxDate: Date;

    validate(value: Value): MaxDateError | null {
        if (isNil(value)) {
            return null;
        }

        if (value > this.maxDate) {
            return new MaxDateError({ maxDate: this.maxDate });
        }

        return null;
    }
}
