import { makeStyles } from "@fluentui/react";

export const useStyles = makeStyles({
    hiddenChevron: {
        visibility: "hidden",
    },
    expanded: {
        fontWeight: 600,
    },
});
