import React from "react";
import { BreadcrumbItem } from "./BreadcrumbItem";
import { BreadcrumbItemDefinition } from "./types";
import { useBreadcrumbItemStyles } from "./BreadcrumbItem/BreadcrumbItem.styles";
import { isNil } from "lodash";
import { IBreadcrumbProps } from "@fluentui/react";

type BreadcrumbItemRendererProps = BreadcrumbItemDefinition & Pick<IBreadcrumbProps, "onRenderItem">;

/**
 * Helper component that facilitates rendering of {@link BreadcrumbItem}.
 * @param {BreadcrumbItemDefinition} props - The breadcrumb item definition.
 * @returns {JSX.Element} The JSX element representing the BreadcrumbItem or empty component when BreadcrumbItemDefinition is undefined.
 */
export function BreadcrumbItemRenderer(props: BreadcrumbItemRendererProps | undefined): JSX.Element | null {
    const { link } = useBreadcrumbItemStyles({});

    if (isNil(props)) {
        return <></>;
    }

    if (!isNil(props?.onRenderItem)) {
        return props.onRenderItem(props);
    }

    return <BreadcrumbItem {...props} target={props.target} linkStyles={link} />;
}
