import { TagItem } from "@fluentui/react";
import { without } from "lodash";
import React from "react";
import { TagsListItemField } from "../types";
import { CellProps } from "./CellProps";

/**
 * Cell for the list view which outputs a (list of) tags using the
 * underlying fluent {@link TagItem}.
 * @param {CellProps<TagsListItemField>} param The cell props.
 * @returns {React.FC<CellProps<TagsListItemField>>} The react component.
 */
export const TagsCell: React.FC<CellProps<TagsListItemField>> = ({
    editMode,
    field,
    onValueChange,
}: CellProps<TagsListItemField>) => {
    return (
        <div role="list">
            {field.tags?.map((tag, i) => (
                <TagItem
                    key={tag}
                    item={{ name: tag, key: tag }}
                    index={i}
                    onRemoveItem={() => {
                        onValueChange({ ...field, tags: without(field.tags, tag) });
                    }}
                    styles={{ close: { display: editMode ? "inline" : "none" }, root: { display: "inline" } }}
                >
                    {tag}
                </TagItem>
            ))}
        </div>
    );
};
