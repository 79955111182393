import { IStackStyles, ILinkStyles, useTheme } from "@fluentui/react";

interface HyperlinkCellStyles {
    /** Styles for container */
    container: IStackStyles;
    /** Styles for link */
    link: ILinkStyles;
}

/**
 * Hook that returns the styles object used for the EditableCell.
 * @returns {HyperlinkCellStyles} The styles object.
 */
export function useStyles(): HyperlinkCellStyles {
    const { spacing } = useTheme();

    return {
        container: {
            root: {
                marginRight: spacing.s1,
            },
        },
        link: {
            root: {
                color: "inherit", // inherit styles set up in list view configurations
                lineHeight: spacing.l1, // stabilize height
                ":hover, :active, :focus, :hover:active": {
                    color: "inherit", // inherit styles set up in list view configurations
                },
            },
        },
    };
}
