import { defaultDatePickerStrings } from "@fluentui/react";
import { formatDate } from "../../../../iserver365-globalization-utility/src";
import { isNil } from "lodash";
import { useLocalization } from "../../Localization.context";
import React, { useEffect, useMemo, useState } from "react";
import { MinMaxDateError } from "../../utils/validation/rules/MinMaxDate";
import { MaxDateError } from "../../utils/validation/rules/MaxDate";
import { MinDateError } from "../../utils/validation/rules/MinDate";
import { usePrevious } from "../../utils";
import { parseDate } from "../DatePicker.utils";

type UseDatePickerOutput = {
    invalidValueErrorMessage: string | undefined;
    datePickerStrings: typeof defaultDatePickerStrings;
    handleParseString: (date: string) => Date | null;
    handleSelectDate: (date: Date | null | undefined) => void;
    handleBlur: (event: React.FocusEvent<HTMLElement>) => void;
    validationErrorMessageProvider: (error: Error) => string;
    resetInvalidValueMessage: () => void;
};

type UseDatePickerProps = {
    value?: Date | undefined;
    minDate?: Date;
    maxDate?: Date;
    onChange: (date: Date | undefined) => void;
};

/**
 * Hook encapsulating the logic for the DatePicker.
 * @param {UseDatePickerProps} param - The hook props.
 * @returns {UseDatePickerOutput} The hook output.
 */
export const useDatePicker = ({ value, minDate, maxDate, onChange }: UseDatePickerProps): UseDatePickerOutput => {
    const [invalidValueErrorMessage, setInvalidValueMessage] = useState<string | undefined>(undefined);
    const previousValue = usePrevious(value);

    const { formatString, ...translation } = useLocalization();

    const validationErrorMessageProvider = (error: Error): string => {
        const minDateString = formatDate(minDate);
        const maxDateString = formatDate(maxDate);

        if (error.name === MinMaxDateError.name && !isNil(minDateString) && !isNil(maxDateString)) {
            return formatString(translation.dateOutOfBoundsErrorMessage, minDateString, maxDateString);
        } else if (error.name === MaxDateError.name && !isNil(maxDateString)) {
            return formatString(translation.dateIsOutOfMaximumRangeErrorMessage, maxDateString);
        } else if (error.name === MinDateError.name && !isNil(minDateString)) {
            return formatString(translation.dateIsOutOfMinimumRangeErrorMessage, minDateString);
        }
        return error.message;
    };

    const datePickerStrings = useMemo(
        () => ({
            // There's no other way to disable default validation for manual input in DatePicker, and as we provide our own validation we need to disable the default one.
            ...defaultDatePickerStrings,
            isOutOfBoundsErrorMessage: "",
            isResetStatusMessage: "",
            invalidInputErrorMessage: "",
        }),
        []
    );

    useEffect(() => {
        if (value?.getTime() !== previousValue?.getTime()) {
            setInvalidValueMessage(undefined);
        }
    }, [value, previousValue]);

    const handleBlur = (event: React.FocusEvent<HTMLElement>) => {
        const inputValue = (event.target as HTMLInputElement).value;
        const newDate = parseDate(inputValue);
        if (!isNil(newDate)) {
            // There's an issue with DatePicker component that it doesn't update the value when it's out of min/max range if the date is typed manually.
            // It leads to a situation when the controlled value (not updated) is different than the displayed value.
            // To fix this we need to call onChange with the parsed date (it should be parsed the same way as displayed value).
            onChange(newDate);
        }
    };

    const handleParseString = (date: string) => {
        const parsedDate = parseDate(date);
        setInvalidValueMessage(
            isNil(parsedDate)
                ? formatString(translation.dateFormatIsInvalidErrorMessage, date, formatDate(value) ?? "")
                : undefined
        );

        // if not parsable date is provided, input state is reset to the previous value
        // (in some circumstances DatePicker fails to revert to the previous value),
        // that's why we have to set previous value forcefully if the parsed date is invalid
        const newValue = parsedDate ?? value;
        if (isNil(newValue)) {
            return null;
        }

        return newValue;
    };

    const handleSelectDate = (date: Date | null | undefined) => {
        onChange(date === null ? undefined : date);
    };

    const resetInvalidValueMessage = () => setInvalidValueMessage(undefined);

    return {
        datePickerStrings,
        invalidValueErrorMessage,
        validationErrorMessageProvider,
        resetInvalidValueMessage,
        handleBlur,
        handleParseString,
        handleSelectDate,
    };
};

export type { UseDatePickerProps };
