import { ApolloError } from "@apollo/client/errors";
import { isNil } from "lodash";

// Known terminal errors for GraphQL on WS.
// Borrowed from https://github.com/enisdenjo/graphql-ws/blob/45d1649474a7e95b0b1f6530e2f3d3cb3d734db8/src/client.ts#L325
const terminalGraphWebsocketErrors = ["4500", "4005", "4400", "4004", "4401", "4406", "4409", "4429"];

/**
 * Checks if the apollo error is a terminal WebSocket error.
 * @param {ApolloError} error The network error.
 * @returns {boolean} True or False if it is a  terminal websocket error.
 */
export function isWebSocketError(error: ApolloError): boolean {
    const socketClosedMatches = error?.graphQLErrors?.map((error) =>
        error?.message?.match(/Socket closed with event (\d{4})/)
    );

    if (
        socketClosedMatches !== undefined &&
        socketClosedMatches !== null &&
        socketClosedMatches.some(
            (socketClosedMatch) =>
                !isNil(socketClosedMatch) && terminalGraphWebsocketErrors.includes(socketClosedMatch[1])
        )
    ) {
        return true;
    }

    return error?.graphQLErrors?.some((error) => {
        const event = error as unknown as Event;

        return event?.type === "error" && (event?.target as WebSocket)?.url.startsWith("wss://");
    });
}
