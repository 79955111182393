import { ITextStyles } from "@fluentui/react";

type UseBreadcrumbTitleStylesOutput = {
    title: ITextStyles;
};

/**
 * Returns the styles for the BreadcrumbTitle component.
 * @param {boolean} isCurrentItem - Indicates if the breadcrumb item is the current navigation item.
 * @returns {UseBreadcrumbTitleStylesOutput} The styles for the BreadcrumbTitle.
 */
export function useBreadcrumbTitleStyles(isCurrentItem = false): UseBreadcrumbTitleStylesOutput {
    return {
        title: {
            root: {
                fontWeight: isCurrentItem ? "600" : "normal",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
            },
        },
    };
}
