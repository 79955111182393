import { ILinkStyles, IStackTokens, useTheme } from "@fluentui/react";
import { CSSProperties } from "react";

interface HyperlinkStyles {
    hyperlinkReadOnlyToken: IStackTokens;
    link: ILinkStyles;
    hyperlinkRowContainerTokens: IStackTokens;
    fontIcon: CSSProperties;
    hyperlinkRow: CSSProperties;
    hyperlinksContainer: CSSProperties;
    textError: CSSProperties;
    addButtonContainer: CSSProperties;
    deleteButton: CSSProperties;
}
/**
 *  Returns the styles for the Hyperlink component.
 * @returns {HyperlinkStyles} The styles for the Hyperlink component.
 */
export function useHyperlinkStyles(): HyperlinkStyles {
    const { spacing, palette, fonts } = useTheme();

    return {
        hyperlinkReadOnlyToken: {
            childrenGap: spacing.s2,
        },
        link: {
            root: {
                [":hover"]: {
                    textDecoration: "none",
                },
            },
        },
        hyperlinkRowContainerTokens: {
            childrenGap: spacing.m,
        },
        fontIcon: {
            marginRight: spacing.s1,
        },
        hyperlinkRow: {
            display: "inline-flex",
        },
        hyperlinksContainer: {
            gap: spacing.l1,
            marginBottom: spacing.s2,
        },
        textError: {
            fontSize: fonts.medium.fontSize,
            color: palette.redDark,
        },
        addButtonContainer: {
            height: spacing.l2,
        },
        deleteButton: {
            marginTop: "29px",
        },
    };
}
