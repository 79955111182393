import React from "react";
import { DatePicker as DatePickerComponent, Text } from "@fluentui/react";
import { formatDate } from "../../../iserver365-globalization-utility/src";
import { TextField } from "../TextField";
import { useDatePicker } from "./hooks/useDatePicker";
import { ComponentValidationProps, useValidation } from "../utils/validation";
import { getDisplayValidationError, getValidationRules } from "./DatePicker.service";
import { useDatePickerStyles } from "./DatePicker.styles";
import { isNil } from "lodash";
import { FieldLabel } from "../FieldLabel";
import { ILabeledFieldProps } from "../types";

export interface DatePickerProps extends ILabeledFieldProps, ComponentValidationProps<Date | undefined> {
    /** The aria label accessibility attribute value. */
    ariaLabel: string;
    /** The date value. */
    value: Date | undefined;
    /** Indicates whether the component is in read only mode. */
    readOnly?: boolean;
    /** Indicates whether the component should be presented as disabled. */
    disabled?: boolean;
    /** Specifies the placeholder to display when no date provided. */
    placeholder?: string;
    /** Specifies minimum allowed date. */
    minDate?: Date;
    /** Specifies maximum allowed date. */
    maxDate?: Date;
    /** Turn on manual date type */
    allowTextInput?: boolean;
    /** The onChange event handler. Executed when date has been modified. Required only for edit mode. */
    onChange: (date: Date | undefined) => void;
}

/**
 * Renders the DatePicker component.
 * In readOnly mode display the date value in browser locale format.
 * @param {DatePickerProps} props The component props.
 * @returns {JSX.Element} The React component.
 */
export function DatePicker({
    ariaLabel,
    value,
    readOnly = false,
    label,
    disabled = false,
    placeholder,
    minDate,
    maxDate,
    allowTextInput = false,
    validationErrorMessageProvider,
    onChange,
    onValidationStateChange,
    onRenderLabel,
    id,
}: DatePickerProps): JSX.Element {
    const {
        datePickerStrings,
        invalidValueErrorMessage,
        handleBlur,
        handleParseString,
        handleSelectDate,
        resetInvalidValueMessage: hideInvalidValueResetMessage,
        validationErrorMessageProvider: defaultValidationErrorMessageProvider,
    } = useDatePicker({
        minDate,
        maxDate,
        value,
        onChange,
    });
    const { validationErrors, validationErrorMessages } = useValidation({
        value,
        rules: getValidationRules({ isRequired: false, min: minDate, max: maxDate, validationRules: [] }),
        onValidationStateChange,
        validationErrorMessageProvider: defaultValidationErrorMessageProvider ?? validationErrorMessageProvider,
    });

    const [displayValidationError, validationErrorToDisplay] = getDisplayValidationError({
        validationErrors,
        readOnly,
        isDefaultValue: false,
        validationErrorMessages,
        invalidUserInputErrorMessage: invalidValueErrorMessage,
    });
    const { datePicker, errorMessage } = useDatePickerStyles(displayValidationError);

    return readOnly ? (
        <TextField
            readOnly
            placeholder={placeholder}
            disabled={disabled}
            value={formatDate(value)}
            ariaLabel={ariaLabel}
            label={label}
            onRenderLabel={onRenderLabel}
            id={id}
        />
    ) : (
        <>
            {!isNil(onRenderLabel) ? onRenderLabel() : <FieldLabel label={label} />}
            <DatePickerComponent
                ariaLabel={ariaLabel}
                calloutProps={{
                    directionalHint: 2,
                }}
                placeholder={placeholder}
                minDate={minDate}
                maxDate={maxDate}
                disabled={disabled}
                allowTextInput={allowTextInput}
                value={value}
                strings={datePickerStrings}
                styles={datePicker}
                formatDate={(date) => formatDate(date) ?? ""}
                parseDateFromString={handleParseString}
                onSelectDate={handleSelectDate}
                onBlur={handleBlur}
                onClick={hideInvalidValueResetMessage}
                onAfterMenuDismiss={hideInvalidValueResetMessage}
                id={id}
            />
            {displayValidationError && <Text styles={errorMessage}>{validationErrorToDisplay}</Text>}
        </>
    );
}
