import React from "react";
import { Stack, Image, Text } from "@fluentui/react";
import { useStyles } from "./EmptyContent.styles";

/** The EmptyContent component props. */
export interface EmptyContentProps {
    /** The image src. */
    image?: string;
    /** The title of the component. */
    title: string;
    /** The description of the component. */
    description: string | JSX.Element;
    /** The child components passed into EmptyContent. */
    children?: React.ReactNode;
}

/**
 * Renders the basic structure of the card with custom component inside.
 * @param {EmptyContentProps} props The component props.
 * @returns {JSX.Element} The React component.
 */
export function EmptyContent(props: EmptyContentProps): JSX.Element {
    const { image, title, description, children } = props;
    const styles = useStyles();

    return (
        <Stack
            verticalAlign={"center"}
            horizontalAlign={"center"}
            tokens={{ childrenGap: 8 }}
            styles={styles.container}
        >
            {image !== undefined && <Image src={image} alt={title} loading="lazy" />}
            <Text styles={styles.title}>{title}</Text>
            <Text styles={styles.description}>{description}</Text>
            {children}
        </Stack>
    );
}
