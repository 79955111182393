import { createClient, ClientOptions } from "graphql-ws";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { RetryLink } from "@apollo/client/link/retry";
import { ApolloLink } from "@apollo/client";
import { createLazyLoadableLaikaLink } from "@zendesk/laika";
import { settings } from "./settings";

const laikaLink = settings.isDevelopment
    ? createLazyLoadableLaikaLink({
          clientName: "topbar",
      })
    : null;

/**
 * The Web Socket Link.
 * @param {ClientOptions} options The web socket options.
 * @returns {ApolloLink} The Web Socket Link.
 */
export const getWebSocketLink = (options: ClientOptions): ApolloLink => {
    // retry socket connection for an average of 24h
    // up to maximum of 36h
    const webSocketRetryOptions = {
        attempts: {
            max: 43200,
        },
        delay: {
            initial: 2000,
            max: 3000,
            jitter: true,
        },
    };

    const retryLink = new RetryLink(webSocketRetryOptions);
    const webSocketRetryLink = laikaLink?.concat(retryLink) ?? retryLink;

    return webSocketRetryLink.concat(new GraphQLWsLink(createClient(options)));
};
