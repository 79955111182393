import React, { createRef } from "react";
import {
    IBasePicker,
    ITag,
    TagPicker,
    CommandBarButton,
    IBasePickerSuggestionsProps,
    ValidationState,
    Label,
} from "@fluentui/react";
import { addIcon, useStyles } from "./TagPickerCustom.styles";

export type Labels = {
    tagPicker: string;
    addNewTag?: string;
    existingTags: string;
    searchTags: string;
    noResult: string;
    remove: string;
};

export type TagPickerCustomProps = {
    /** The list of the tags. */
    tags: ITag[];

    /** The list of the selected tags. */
    selectedTags?: ITag[];

    /** The labels. */
    labels: Labels;

    /** The flag indicating whether adding of the new tags is possible.*/
    addNewTagDisabled?: boolean;

    /** The `data-test-id` attribute is used for tests.*/
    dataTestId?: string;

    /** The callback function trigger on tag selection change. */
    onChange: (items: ITag[] | undefined) => void;
};

/**
 * This component select existing tag or add new.
 * @param {TagPickerCustomProps} props The component props.
 * @returns {JSX.Element} The react element.
 */
export function TagPickerCustom({
    tags,
    selectedTags,
    addNewTagDisabled,
    labels: { tagPicker, existingTags, noResult, addNewTag, searchTags, remove },
    dataTestId,
    onChange,
}: TagPickerCustomProps): JSX.Element {
    const styles = useStyles();

    const inputProps = {
        style: { margin: 0 },
        placeholder: searchTags,
        "data-test-id": dataTestId ?? "tag-picker",
    };

    const tagPickerRef = createRef<IBasePicker<ITag>>();

    const renderAddButton = () => (
        <CommandBarButton
            iconProps={addIcon}
            styles={styles.button}
            text={addNewTag}
            onClick={() => tagPickerRef.current?.completeSuggestion(true)}
        />
    );

    const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
        suggestionsHeaderText: existingTags,
        noResultsFoundText: noResult,
        onRenderNoResultFound: addNewTagDisabled === true ? undefined : renderAddButton,
    };

    function isTagPresent(tag: ITag, tags?: ITag[]): boolean {
        return tags?.some((selectedTag) => selectedTag.key === tag.key) === true;
    }

    function isTagMatch(filterText: string, tag: ITag): boolean {
        return tag.name.toLowerCase().includes(filterText.toLowerCase());
    }

    const onResolveSuggestions = (filterText: string, selectedItems?: ITag[]): ITag[] => {
        return tags.filter((tag: ITag) => isTagMatch(filterText, tag) && !isTagPresent(tag, selectedItems));
    };

    const onValidateInput = (input: string) => (input !== undefined ? ValidationState.valid : ValidationState.invalid);

    const getExistingTag = (selectedItem: ITag | undefined): ITag | null => {
        if (selectedItem !== undefined && isTagPresent(selectedItem, tags)) {
            return selectedItem;
        }

        return null;
    };

    return (
        <>
            <Label>{tagPicker}</Label>
            <TagPicker
                componentRef={tagPickerRef}
                inputProps={inputProps}
                styles={styles.toggle}
                selectedItems={selectedTags}
                removeButtonAriaLabel={remove}
                createGenericItem={(input: string) => ({ key: "", name: input } as ITag)}
                getTextFromItem={(item: ITag) => item.name}
                pickerSuggestionsProps={pickerSuggestionsProps}
                onChange={onChange}
                onResolveSuggestions={onResolveSuggestions}
                onValidateInput={onValidateInput}
                onItemSelected={addNewTagDisabled === true ? getExistingTag : undefined}
            />
        </>
    );
}
