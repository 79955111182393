import { ITag } from "@fluentui/react";
import { CategoryType, OperatorValueType } from "../QuickFilter.interfaces";

function getCategoryType(categoryType: CategoryType | null): CategoryType {
    if (categoryType !== null) {
        return categoryType;
    }

    return CategoryType.AttributeNumber;
}

/**
 * Returns {@link ValuesType} or 'null' for to be consistent with an option when value not needed.
 * @param {CategoryType | null} categoryType - type of the category
 * @returns {OperatorType | null} the type of the value or 'null'
 */
function getValueTypeByConditionCategory(categoryType: CategoryType | null): ValuesType | null {
    if (categoryType === CategoryType.AttributeTrueFalse) {
        return ValuesType.Hidden;
    }

    return null;
}

/**
 * Returns {@link OperatorType} or `null` using {@link categoryType}.
 * @param {CategoryType | null} categoryType - type of the category
 * @returns {OperatorType | null} the type of the operator or `null`
 */
export function getOperatorTypeByConditionCategory(categoryType: CategoryType | null): OperatorType | null {
    if (categoryType === null) {
        return null;
    }

    const operatorTypeByConditionCategory: Record<string, OperatorType> = {
        [CategoryType.AttributeNumber]: OperatorType.Numerical,
        [CategoryType.AttributeText]: OperatorType.Text,
        [CategoryType.AttributeTrueFalse]: OperatorType.Boolean,
        [CategoryType.AttributeChoice]: OperatorType.Choice,
        [CategoryType.AttributeDateTime]: OperatorType.DateTime,
        [CategoryType.AttributeCurrency]: OperatorType.Currency,
        [CategoryType.AttributePersonOrGroup]: OperatorType.PersonOrGroup,
        [CategoryType.AttributeHyperlink]: OperatorType.Text,
        [CategoryType.AttributeUniqueIdentifier]: OperatorType.UniqueIdentifier,
        [CategoryType.Relationship]: OperatorType.RelatedTo,
    };

    return operatorTypeByConditionCategory[categoryType] ?? null;
}

export type Filter = {
    category: CategoryFilter;
    operator: OperatorFilter;
    values: ValuesFilter;
};

export enum OperatorType {
    Numerical = "numerical",
    Text = "text",
    Choice = "choice",
    Boolean = "boolean",
    DateTime = "dateTime",
    Currency = "currency",
    PersonOrGroup = "personOrGroup",
    UniqueIdentifier = "uniqueIdentifier",
    RelatedTo = "relatedTo",
}

export enum ValuesType {
    Hidden = "hidden",
    Number = "number",
    Choice = "choice",
    Text = "text",
    DateTime = "dateTime",
    Currency = "currency",
    PersonOrGroup = "personOrGroup",
    Hyperlink = "hyperlink",
    UniqueIdentifier = "uniqueIdentifier",
    RelatedTo = "relatedTo",
}

export type CategoryFilter = {
    type: CategoryType | null;
    value: number | string | null;
    error?: null | string;
};

export type OperatorFilter = {
    type: OperatorType | null;
    value: OperatorValueType | null;
    error: null | string;
};

export interface ValuesDateTime {
    unit: string | null;
    value: string;
}

export type ValuesFilter = {
    type: ValuesType | null;
    value: string | string[] | number | ValuesDateTime | ValuesDateTime[] | ITag[] | null;
    error: null | string;
};

export type Action =
    | { type: "clearFilter" }
    | { type: "savedFilters"; payload: State }
    | { type: "addRow" }
    | { type: "deleteRow"; payload: number }
    | { type: "clearRow"; payload: number }
    | {
          type: "changeCategory";
          payload: { index: number; value: number | string | null; category: CategoryType | null };
      }
    | { type: "changeOperator"; payload: { index: number; value: OperatorValueType } }
    | { type: "changeValues"; payload: { index: number; value: number | string | null | string[] | ITag[] } }
    | { type: "errorValues"; payload: { index: number; error: string | null } };

export const initialRow: Filter = {
    category: {
        type: null,
        value: null,
        error: null,
    },
    operator: {
        type: null,
        value: null,
        error: null,
    },
    values: {
        type: null,
        value: null,
        error: null,
    },
};

export const initialState: State = [initialRow];

/**
 * Returns {@link ValuesType} using {@link operatorType} and {@link operatorValue}.
 * @param {OperatorType | null} operatorType - type of the operator
 * @param {OperatorValueType | null} operatorValue - value of the operator
 * @returns {ValuesType} the type of the value or `null`
 */
export function getValueTypeByOperator(
    operatorType: OperatorType | null,
    operatorValue: OperatorValueType | null
): ValuesType | null {
    const valueTypeByOperatorValue: Record<string, ValuesType> = {
        [OperatorValueType.NotNull]: ValuesType.Hidden,
        [OperatorValueType.Null]: ValuesType.Hidden,
    };

    const valueTypeByOperatorType: Record<string, ValuesType> = {
        [OperatorType.Numerical]: ValuesType.Number,
        [OperatorType.Text]: ValuesType.Text,
        [OperatorType.Boolean]: ValuesType.Hidden,
        [OperatorType.DateTime]: ValuesType.DateTime,
        [OperatorType.Choice]: ValuesType.Choice,
        [OperatorType.Currency]: ValuesType.Currency,
        [OperatorType.PersonOrGroup]: ValuesType.PersonOrGroup,
        [OperatorType.UniqueIdentifier]: ValuesType.UniqueIdentifier,
        [OperatorType.RelatedTo]: ValuesType.RelatedTo,
    };

    if (operatorType === null) {
        return null;
    }

    if (operatorValue === null) {
        return valueTypeByOperatorType[operatorType] ?? null;
    }

    return valueTypeByOperatorValue[operatorValue] ?? valueTypeByOperatorType[operatorType] ?? null;
}

/**
 * Reducer, which is used in `useQuickFilterReducer`.
 * @param {State} state - state
 * @param {Action} action - action
 * @returns {State} new state
 */
export function reducer(state: State, action: Action): State {
    if (action.type === "clearFilter") {
        return initialState;
    }

    if (action.type === "savedFilters") {
        return action.payload;
    }

    if (action.type === "addRow") {
        return state.concat(initialRow);
    }

    if (action.type === "deleteRow") {
        return state.filter((row, index) => index !== action.payload);
    }

    if (action.type === "clearRow") {
        return state.map((row, index) => (index === action.payload ? initialRow : row));
    }

    if (action.type === "changeCategory") {
        const { index, value, category } = action.payload;

        const newState = state.slice();
        if (category === CategoryType.Relationship) {
            newState[index] = {
                category: {
                    type: getCategoryType(category),
                    value,
                    error: null,
                },
                operator: {
                    type: OperatorType.RelatedTo,
                    value: OperatorValueType.Relates,
                    error: null,
                },
                values: {
                    type: ValuesType.RelatedTo,
                    value: null,
                    error: null,
                },
            };
        } else {
            newState[index] = {
                category: {
                    type: getCategoryType(category),
                    value,
                    error: null,
                },
                operator: {
                    type: getOperatorTypeByConditionCategory(category),
                    value: null,
                    error: null,
                },
                values: {
                    type: getValueTypeByConditionCategory(category),
                    value: null,
                    error: null,
                },
            };
        }

        return newState;
    }

    if (action.type === "changeOperator") {
        const { index, value } = action.payload;

        const newState = state.slice();
        const rowUnderUpdate = newState[index];
        const updatedRow = {
            category: rowUnderUpdate.category,
            operator: {
                type: rowUnderUpdate.operator.type,
                value,
                error: null,
            },
            values: {
                type: getValueTypeByOperator(rowUnderUpdate.operator.type, value),
                value: null,
                error: null,
            },
        };

        newState[index] = updatedRow;

        return newState;
    }

    if (action.type === "changeValues") {
        const { index, value } = action.payload;

        const newState = state.slice();
        const rowUnderUpdate = newState[index];
        const updatedRow = {
            ...rowUnderUpdate,
            values: {
                type: rowUnderUpdate.values.type,
                value,
                error: null,
            },
        };

        newState[index] = updatedRow;

        return newState;
    }

    if (action.type === "errorValues") {
        const { index, error } = action.payload;

        const newState = state.slice();
        const rowUnderUpdate = newState[index];
        const updatedRow = {
            ...rowUnderUpdate,
            values: {
                type: rowUnderUpdate.values.type,
                value: rowUnderUpdate.values.value,
                error,
            },
        };

        newState[index] = updatedRow;

        return newState;
    }

    return state;
}

export type State = Filter[];
