import React from "react";
import { Config } from "dompurify";
import { sanitizer } from "../utils/security";

/**
 * {@link HTMLContent} component props.
 */
export interface HTMLContentProps {
    /** The HTML string. */
    value: string;
    /** The sanitization config. */
    sanitizeConfig?: Config;
    /** The HTML result wrapper styles. */
    style?: React.CSSProperties;
}

/**
 * The component sanitizes and renders HTML content.
 * @param {HTMLContentProps} props The component props.
 * @returns {JSX.Element} The rendered HTML content.
 */
export function HTMLContent({ value: value, sanitizeConfig, style }: HTMLContentProps): JSX.Element {
    return (
        <div
            style={style}
            dangerouslySetInnerHTML={{
                __html: sanitizer.sanitize(value, sanitizeConfig as Config) as string,
            }}
        ></div>
    );
}
