import { IIconStyles, IStackStyles, ITextStyles, useTheme } from "@fluentui/react";

export interface SomethingWentWrongStyles {
    /** Header styles */
    header: IStackStyles;

    /** Alert icon styles */
    alertIcon: IIconStyles;

    /** Title styles */
    title: ITextStyles;

    /** Content styles */
    content: ITextStyles;
}

/**
 * Hook that returns the styles object used for the SomethingWentWrong.
 * @returns {SomethingWentWrongStyles} The styles object.
 */
export function useSomethingWentWrongStyles(): SomethingWentWrongStyles {
    const theme = useTheme();

    return {
        header: {
            root: {
                padding: `0 ${theme.spacing.l2}`,
                minHeight: "40px",
            },
        },
        alertIcon: {
            root: {
                fontSize: theme.fonts.xxLargePlus.fontSize,
                color: theme.palette.red,
                marginBottom: theme.spacing.m,
            },
        },
        title: {
            root: {
                fontSize: theme.fonts.xLarge.fontSize,
                fontWeight: theme.fonts.xxLarge.fontWeight,
                marginBottom: theme.spacing.s2,
            },
        },
        content: {
            root: {
                fontSize: theme.fonts.medium.fontSize,
                fontWeight: theme.fonts.medium.fontWeight,
                textAlign: "center",
                width: "340px",
                marginBottom: theme.spacing.l2,
            },
        },
    };
}
