import { useTheme } from "@fluentui/react/lib/Theme";
import { IIconStyles } from "@fluentui/react/lib/components/Icon";

type UseBreadcrumbIconStylesOutput = {
    textIcon: IIconStyles;
};

/**
 * Hook for styling the BreadcrumbIcon component.
 * @returns {UseBreadcrumbIconStylesOutput} The styles.
 */
export const useBreadcrumbIconStyles = (): UseBreadcrumbIconStylesOutput => {
    const { fonts, palette } = useTheme();

    return {
        textIcon: {
            root: {
                fontSize: fonts.large.fontSize,
                color: palette.neutralDark,
            },
        },
    };
};
