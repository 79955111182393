import { IStackStyles, useTheme } from "@fluentui/react";
import { ListItemField } from "../types";
import { systemColors } from "../../SystemColor";

interface EditableCellStyles {
    /** Styles for container */
    cell: IStackStyles;

    /** Styles for cell content wrapper */
    contentContainer: IStackStyles;

    /** Styles for icon container */
    iconContainer: IStackStyles;
}

/**
 * Hook that returns the styles object used for the EditableCell.
 * @param {ListItemField} field List item field.
 * @returns {EditableCellStyles} The styles object.
 */
export function useStyles(field: ListItemField): EditableCellStyles {
    const theme = useTheme();

    const backgroundColor = field.fieldColor === null ? "" : systemColors[field.fieldColor];
    const color = field.fieldColor === null ? "" : "white";

    const icon = field.icon;
    const iconColor = icon?.color === undefined ? "" : systemColors[icon.color];

    return {
        cell: {
            root: {
                backgroundColor,
                color,
                borderRadius: "5px",
                lineHeight: theme.spacing.l1, // stabilize height
                width: "100%",
            },
        },
        contentContainer: {
            root: {
                paddingLeft: theme.spacing.s1,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                display: "block",
                width: `calc(100% - 1.5rem - ${theme.spacing.s1})`,
            },
        },
        iconContainer: {
            root: {
                backgroundColor: iconColor,
                width: "1.5rem",
                height: "1.5rem",
                marginRight: theme.spacing.s2,
                borderRadius: "2px",
            },
        },
    };
}
