import { ApolloLink } from "@apollo/client";
import { onError, ErrorResponse } from "@apollo/client/link/error";
import { raiseEvent } from "iserver365-infrastructure-utility";
import { EventTypes } from "./eventTypes";

/**
 * Redirects to No Access page if "FORBIDDEN" error code found.
 * @returns {ApolloLink} ApolloLink function.
 */
const authorizationGuardLink: ApolloLink = onError((error: ErrorResponse): void => {
    const { graphQLErrors } = error;

    const hasAuthorizationErrors =
        graphQLErrors !== undefined && graphQLErrors.some((e) => e.extensions?.code === "FORBIDDEN");

    if (hasAuthorizationErrors) {
        raiseEvent(EventTypes.forbiddenError, null);
    }
});

export { authorizationGuardLink };
