import React from "react";

/**
 * Latest Updates Icon.
 * @returns {JSX.Element} Latest Updates Icon.
 */
const LatestUpdatesIcon = (): JSX.Element => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_b_3002_25661)">
                <path
                    d="M7.88984 22H5.4223C4.66794 22 3.92768 21.3569 3.76553 20.5694L2.03119 13.4306C1.86904 12.6431 2.35548 12 3.10985 12H5.57738C6.33175 12 7.07201 12.6431 7.23416 13.4306L8.96849 20.5694C9.13065 21.3569 8.65125 22 7.88984 22Z"
                    fill="#939BC1"
                />
            </g>
            <path
                d="M17.5808 3H16.7119C16.024 3 15.4592 3.49675 15.3216 4.15662L6.58195 7.33735H2.18675C0.977528 7.33735 0 8.33828 0 9.57646V12.4161C0 13.6543 0.977528 14.6552 2.18675 14.6552H6.58195L15.2927 17.4208V17.5468C15.2927 18.3475 15.9299 19 16.7119 19H17.5808C18.3628 19 19 18.3475 19 17.5468V4.4532C18.9928 3.65245 18.3628 3 17.5808 3Z"
                fill="url(#paint0_linear_3002_25661)"
            />
            <path
                d="M15 14.4689L15 7.5311C15 7.23776 14.7762 7 14.5 7C14.2238 7 14 7.23776 14 7.5311L14 14.4689C14 14.7622 14.2238 15 14.5 15C14.7762 15 15 14.7622 15 14.4689Z"
                fill="white"
            />
            <path
                d="M20.2656 12L23.7345 12C23.8811 12 24 11.7762 24 11.5C24 11.2238 23.8811 11 23.7345 11L20.2656 11C20.1189 11 20 11.2238 20 11.5C20 11.7762 20.1189 12 20.2656 12Z"
                fill="#939BC1"
            />
            <path
                d="M20.373 9.31963L22.886 8.64627C22.9922 8.6178 23.0204 8.37851 22.949 8.11176C22.8775 7.84502 22.7334 7.65188 22.6272 7.68035L20.1142 8.35371C20.0079 8.38218 19.9797 8.62147 20.0512 8.88822C20.1227 9.15497 20.2667 9.3481 20.373 9.31963Z"
                fill="#939BC1"
            />
            <path
                d="M20.1235 14.2826L22.3766 15.5834C22.4719 15.6384 22.661 15.4891 22.7991 15.25C22.9372 15.0108 22.9719 14.7724 22.8766 14.7174L20.6235 13.4165C20.5282 13.3615 20.3391 13.5108 20.201 13.75C20.0629 13.9891 20.0282 14.2276 20.1235 14.2826Z"
                fill="#939BC1"
            />
            <defs>
                <filter
                    id="filter0_b_3002_25661"
                    x="0.551558"
                    y="10.5516"
                    width="9.89688"
                    height="12.8969"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="0.724221" />
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_3002_25661" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_3002_25661" result="shape" />
                </filter>
                <linearGradient
                    id="paint0_linear_3002_25661"
                    x1="9.5"
                    y1="3"
                    x2="9.5"
                    y2="19"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#1B335B" />
                    <stop offset="1" stopColor="#161F48" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export { LatestUpdatesIcon };
