import { isNil } from "lodash";
import { ValidationError, ValidationRule, ValidationRuleProps } from "../..";

type Value = string | undefined;
export interface MinStringLengthErrorProps {
    /**
        Minimum length required for the input.
     */
    minLength: number;
    /**
        Actual length received for the input.
     */
    actualLength: number;
}

export interface MinStringLengthProps extends ValidationRuleProps<Value> {
    /**
        Minimum length required for the input.
     */
    minLength: number;
}

/**
 * MinStringLengthError validation error class
 */
export class MinStringLengthError implements ValidationError {
    constructor(props: MinStringLengthErrorProps) {
        this.name = "MinStringLengthError";
        this.minLength = props.minLength;
        this.actualLength = props.actualLength;
        this.message = `The number of characters must be at least ${props.minLength}.`;
    }
    name: string;
    minLength: number;
    actualLength: number;
    message: string;
}

/**
 * MinStringLength validation rule class
 */
export class MinStringLength extends ValidationRule<Value> {
    static Error = MinStringLengthError;
    constructor(props: MinStringLengthProps) {
        super(props);
        this.minLength = props.minLength;
    }

    minLength: number;

    validate(value: Value): MinStringLengthError | null {
        if (isNil(value) || value === "") {
            return null;
        }

        if (value.length < this.minLength) {
            return new MinStringLengthError({ minLength: this.minLength, actualLength: value.length });
        }

        return null;
    }
}
