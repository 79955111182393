import { getCurrentLocale } from "../../../iserver365-globalization-utility/src";
import moment from "moment";

/**
 * Parses a string date into a Date object using the browser's locale. Raw string to parse should follow local date format.
 * Date parser is very forgiving and will create a valid date object striping out invalid characters.
 * @param {string} date - The string date to parse.
 * @returns {Date | undefined} The parsed Date object, or undefined if the date is invalid.
 */
export function parseDate(date: string): Date | undefined {
    const localDate = moment(date, "L", getCurrentLocale());
    if (!localDate.isValid()) {
        return undefined;
    }

    return localDate.toDate();
}
