import { IButtonStyles, IStackItemStyles, IStackStyles, IStackTokens, useTheme } from "@fluentui/react";

export interface GroupSectionStyles {
    groupContainer: IStackStyles;
    tokens: IStackTokens;
    groupItem: IStackItemStyles;
    expandCollapseButton: IButtonStyles;
}

/**
 * Returns the styles for the GroupSection component.
 * @returns {GroupSectionStyles} The styles for the GroupSection component.
 */
export function useGroupSectionStyles(): GroupSectionStyles {
    const { palette, spacing } = useTheme();

    return {
        groupContainer: {
            root: {
                borderBottom: `1px solid ${palette.neutralLight}`,
                paddingBottom: spacing.l1,
                paddingTop: spacing.l1,
            },
        },
        tokens: {
            childrenGap: spacing.m,
        },
        groupItem: {
            root: {
                width: "200px",
                flexShrink: 0,
            },
        },
        expandCollapseButton: {
            root: {
                width: "100%",
            },
            textContainer: {
                overflow: "hidden",
            },
            label: {
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                lineHeight: "normal",
            },
        },
    };
}
