import React, { useEffect } from "react";
import { ActionButton, IPersonaProps, PrimaryButton, Stack, StackItem, ITag as IObject } from "@fluentui/react";

import { FilterCategory } from "./components/FilterCategory";
import { FilterOperator } from "./components/FilterOperator";
import { FilterValues } from "./components/FilterValues";

import { Filter, State as FilterState, useQuickFilterReducer } from "./useQuickFilterReducer";
import { QuickFilterStyle, useQuickFilterStyles } from "./QuickFilter.styles";
import { CategoryType, Condition, FilterValuesType, OperatorValueType } from "./QuickFilter.interfaces";
import { areFiltersEqual, isConditionValid } from "./utils";

function getStyleForValues(filter: Filter, styles: QuickFilterStyle) {
    return filter.category.type === CategoryType.Relationship ? styles.relatedToWrapper : styles.valuesWrapper;
}
export interface QuickFilterProps {
    /** Condition options */
    conditions: Condition[];

    /** Saved filters */
    savedFilters: Filter[];

    /**
     * Maintain both action bar positions until product manager
     * decides which is the most suitable place for it.
     */
    actionBarPosition?: "top" | "bottom";

    /**
     * Will be used to form Amplitude event name
     */
    viewType?: string;

    /** "Apply" button click handler */
    onApply: (filterState: FilterState) => void;

    /** Amplitude track event */
    trackEvent: (eventName: string) => void;

    // eslint-disable-next-line spellcheck/spell-checker
    /**
     *  WORKAROUND!
     *  Render props pattern is employed to allow GraphQL query in the component,
     *  which is placed in the components-library micro-frontend.
     *
     *  Otherwise, when the FilterChoice component is placed in the components-library
     *  micro-frontend, something throws an error during page render.
     */
    onRenderFilterChoice: ({
        attributeId,
        selectedChoices,
        onChange,
    }: {
        attributeId: string | null | number;
        selectedChoices: string[];
        onChange: (selectedKeys: string[]) => void;
    }) => JSX.Element;

    // eslint-disable-next-line spellcheck/spell-checker
    /**
     *  WORKAROUND!
     *  Render props pattern is employed to allow GraphQL query in the component,
     *  which is placed in the components-library micro-frontend.
     *
     *  Otherwise, when the FilterChoice component is placed in the components-library
     *  micro-frontend, something throws an error during page render.
     */
    onRenderFilterCurrency: ({
        attributeId,
        value,
        onChange,
    }: {
        attributeId: string | null | number;
        value: number;
        onChange: (value: number) => void;
        onValidationStateChange: (errors: Error[]) => void;
    }) => JSX.Element;

    onRenderFilterNumber: ({
        attributeId,
        value,
        onChange,
    }: {
        attributeId: string | null | number;
        value: number;
        onChange: (value: number) => void;
        onValidationStateChange: (errors: Error[]) => void;
    }) => JSX.Element;

    onRenderFilterPeopleOrGroup: ({
        attributeId,
        selectedPeople,
        onChange,
    }: {
        attributeId: string | null | number;
        selectedPeople: string[];
        onChange: (selectedKeys: IPersonaProps[] | undefined) => void;
    }) => JSX.Element;

    onRenderRelatedTo: ({
        selectedObjects,
        onChange,
    }: {
        selectedObjects: string[] | IObject[];
        onChange: (items: IObject[] | undefined) => void;
    }) => JSX.Element;
}

/**
 * Quick Filter component
 * @param {QuickFilterProps} props Component props
 * @returns {JSX.Element} QuickFiler component
 */
export function QuickFilter({
    conditions,
    savedFilters,
    actionBarPosition = "bottom",
    viewType,
    trackEvent,
    onApply,
    onRenderFilterChoice,
    onRenderFilterCurrency,
    onRenderFilterNumber,
    onRenderFilterPeopleOrGroup,
    onRenderRelatedTo,
}: QuickFilterProps): JSX.Element {
    const styles = useQuickFilterStyles();
    const [filters, dispatch] = useQuickFilterReducer();

    useEffect(() => {
        if (savedFilters !== undefined && savedFilters.length > 0) {
            dispatch({ type: "savedFilters", payload: savedFilters });
        }
    }, [dispatch, savedFilters]);

    const isFormValid = filters.every((filter) => isConditionValid(filter));

    function renderActionBar() {
        return (
            <Stack
                horizontal
                verticalAlign="center"
                horizontalAlign="space-between"
                styles={actionBarPosition === "top" ? styles.actionBarTop : styles.actionBarBottom}
            >
                <StackItem>
                    <ActionButton
                        ariaLabel="Add"
                        iconProps={{ iconName: "Add" }}
                        onClick={() => {
                            trackEvent(`${viewType} Filter Row Added`);
                            dispatch({ type: "addRow" });
                        }}
                    >
                        Add
                    </ActionButton>
                </StackItem>

                <StackItem>
                    <Stack horizontal verticalAlign={"center"}>
                        {savedFilters.length > 0 ? (
                            <ActionButton
                                ariaLabel="Clear filter"
                                iconProps={{ iconName: "ClearFilter" }}
                                styles={styles.clearFilterButton}
                                onClick={() => {
                                    trackEvent(`${viewType} Filter Cleared`);
                                    dispatch({ type: "clearFilter" });
                                    onApply([]);
                                }}
                            >
                                Remove
                            </ActionButton>
                        ) : null}

                        <PrimaryButton
                            disabled={!isFormValid || areFiltersEqual(filters, savedFilters)}
                            text="Apply"
                            onClick={() => {
                                trackEvent(`${viewType} Filters Applied`);
                                onApply(filters);
                            }}
                        />
                    </Stack>
                </StackItem>
            </Stack>
        );
    }

    return (
        <Stack styles={styles.container}>
            {actionBarPosition === "top" ? renderActionBar() : null}

            {filters.map((filter, index) => {
                return (
                    <Stack
                        key={index}
                        horizontal
                        horizontalAlign="stretch"
                        verticalAlign="start"
                        styles={index < filters.length - 1 ? styles.rowBottomSpacing : undefined}
                        data-test-id="filterRow"
                    >
                        <StackItem shrink={false} styles={styles.categoryWrapper}>
                            <FilterCategory
                                category={filter.category}
                                conditions={conditions}
                                categoryHandler={(
                                    value: number | string | null,
                                    category: CategoryType | null
                                ): void => {
                                    dispatch({ type: "changeCategory", payload: { index, value, category } });
                                }}
                            />
                        </StackItem>
                        {filter.category.type !== CategoryType.Relationship && (
                            <StackItem shrink={false} styles={styles.operatorWrapper}>
                                <FilterOperator
                                    operator={filter.operator}
                                    operatorHandler={(value: OperatorValueType) => {
                                        dispatch({ type: "changeOperator", payload: { index, value } });
                                    }}
                                />
                            </StackItem>
                        )}
                        <StackItem styles={getStyleForValues(filter, styles)}>
                            <FilterValues
                                category={filter.category}
                                values={filter.values}
                                valuesHandler={(value: FilterValuesType) => {
                                    dispatch({ type: "changeValues", payload: { index, value } });
                                }}
                                valuesErrorHandler={(error: string | null) => {
                                    dispatch({ type: "errorValues", payload: { index, error } });
                                }}
                                onRenderFilterChoice={onRenderFilterChoice}
                                onRenderFilterCurrency={onRenderFilterCurrency}
                                onRenderFilterNumber={onRenderFilterNumber}
                                onRenderFilterPeopleOrGroup={onRenderFilterPeopleOrGroup}
                                onRenderRelatedTo={onRenderRelatedTo}
                            />
                        </StackItem>

                        <StackItem>
                            <ActionButton
                                disabled={filter.category.value === null}
                                title="Clear"
                                ariaLabel="Clear"
                                iconProps={{ iconName: "EraseTool" }}
                                styles={styles.actionButton}
                                onClick={() => {
                                    trackEvent(`${viewType} Filter Row Cleared`);
                                    dispatch({ type: "clearRow", payload: index });
                                }}
                            />
                        </StackItem>

                        <StackItem>
                            <ActionButton
                                disabled={filters.length === 1 && index === 0}
                                title="Delete"
                                ariaLabel="Delete"
                                iconProps={{ iconName: "Delete" }}
                                styles={styles.actionButton}
                                onClick={() => {
                                    trackEvent(`${viewType} Filter Row Deleted`);
                                    dispatch({ type: "deleteRow", payload: index });
                                }}
                            />
                        </StackItem>
                    </Stack>
                );
            })}

            {actionBarPosition === "bottom" ? renderActionBar() : null}
        </Stack>
    );
}
