import { IStackStyles, ITextStyles, useTheme } from "@fluentui/react";

type PeoplePickerStyles = {
    container: IStackStyles;
    placeholder: ITextStyles;
};

/**
 * Returns styles for the PeoplePicker component.
 * @returns {PeoplePickerStyles} PeoplePicker component styles.
 */
function usePeoplePickerStyles(): PeoplePickerStyles {
    const { spacing } = useTheme();

    return {
        container: {
            root: {
                paddingTop: spacing.s1,
                paddingBottom: spacing.s1,
            },
        },
        placeholder: {
            root: {
                paddingTop: spacing.s1,
                paddingBottom: 1,
            },
        },
    };
}

export { usePeoplePickerStyles };
