import { ITextFieldStyleProps, ITextFieldStyles } from "@fluentui/react";

/**
 * Returns the styles for the Number component combined with the theme.
 * @param {boolean} readOnly Is component in read only mode.
 * @returns {Partial<ITextFieldStyles>} The styles for the Number component.
 */
export function getStylesFunction(readOnly: boolean): (props: ITextFieldStyleProps) => Partial<ITextFieldStyles> {
    return (_: ITextFieldStyleProps): Partial<ITextFieldStyles> => {
        return {
            fieldGroup: readOnly && {
                backgroundColor: "inherit",
                input: {
                    padding: 0,
                },
            },
        };
    };
}
