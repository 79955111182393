import React, { useRef } from "react";
import { FontIcon, IconButton, Stack, Text, useTheme, Callout } from "@fluentui/react";
import { NotificationCardSharedProps } from "./types";
import { useNotificationCardHeaderStyles } from "./NotificationCardHeader.styles";
import { isFunction } from "lodash";

/**
 * The props for the {@link NotificationCardHeader} component.
 */
export interface NotificationCardHeaderProps extends NotificationCardSharedProps {
    notificationId: number;
    /** The notification status color. */
    statusColor: string;
    /** The notification status icon. */
    statusIcon: string;

    setIsCalloutVisible: (isCalloutVisible: boolean, notificationId: number) => void;
    isOpen: boolean;
}

/**
 * Renders the notification card header content.
 * @param {NotificationCardHeaderProps} props The component props.
 * @returns {JSX.Element} The React component.
 */
export function NotificationCardHeader(props: NotificationCardHeaderProps): JSX.Element {
    const {
        statusColor,
        statusIcon,
        statusText,
        notificationType,
        startDate,
        endDate,
        messages,
        labels,
        deleteButtonProps,
        setIsCalloutVisible,
        notificationId,
        isOpen,
    } = props;

    const { notificationCardHeader, statusContainer, notificationTypeContainer, detailsContainer } =
        useNotificationCardHeaderStyles();
    const { spacing } = useTheme();
    const detailsButtonRef = useRef(null);

    const showMessages = messages !== undefined && messages?.length > 0;
    const showDates = startDate !== undefined;
    const showDetailsButton = showMessages || showDates;

    const onDeleteClick = Boolean(deleteButtonProps?.onClick) && deleteButtonProps?.onClick;

    const showDeleteNotificationButton = onDeleteClick !== undefined && isFunction(onDeleteClick);

    const notificationDetails = (
        <Stack className={detailsContainer} tokens={{ childrenGap: spacing.l1 }}>
            {showMessages && (
                <Stack>
                    {messages.map((m, i) => (
                        <span key={i}>{m}</span>
                    ))}
                </Stack>
            )}
            {showDates && (
                <Stack horizontal={false}>
                    <span>{`${labels.startDate}: ${startDate}`}</span>
                    {endDate !== undefined && <span>{`${labels.endDate}: ${endDate}`}</span>}
                </Stack>
            )}
        </Stack>
    );
    return (
        <Stack
            horizontal
            horizontalAlign="space-between"
            className={notificationCardHeader}
            tokens={{ childrenGap: spacing.s1 }}
        >
            <Text block nowrap>
                <span className={notificationTypeContainer}>{notificationType}</span>
                <Text block nowrap style={{ color: statusColor }} className={statusContainer}>
                    <FontIcon style={{ backgroundColor: statusColor }} aria-label={statusIcon} iconName={statusIcon} />
                    <span>{statusText}</span>
                </Text>
            </Text>
            {isOpen && (
                <Callout
                    data-test-id="notification-details-callout"
                    target={detailsButtonRef}
                    onDismiss={() => setIsCalloutVisible(false, notificationId)}
                    calloutWidth={300}
                >
                    {notificationDetails}
                </Callout>
            )}
            <Stack horizontal tokens={{ childrenGap: spacing.s1 }}>
                {showDetailsButton && (
                    <IconButton
                        elementRef={detailsButtonRef}
                        iconProps={{ iconName: "Info" }}
                        ariaLabel={labels.ariaLabelForNotificationDetails}
                        onClick={() => setIsCalloutVisible(!isOpen, notificationId)}
                    />
                )}
                {showDeleteNotificationButton && (
                    <IconButton
                        iconProps={{ iconName: "Delete" }}
                        ariaLabel={labels.ariaLabelForDeleteNotification}
                        title={
                            deleteButtonProps?.disabled === true
                                ? labels.disabledDeleteNotification
                                : labels.deleteNotification
                        }
                        {...deleteButtonProps}
                    />
                )}
            </Stack>
        </Stack>
    );
}
