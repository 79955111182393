import { isNil } from "lodash";
import { ValidationRule, ValidationRuleProps } from "../../";

type Value = Date | undefined;

/**
 * RequiredDateError validation error class
 */
export class RequiredDateError extends Error {
    message = "Field is required";
}

/**
 * RequiredString validation rule class
 */
export class RequiredDate extends ValidationRule<Value> {
    static Error = RequiredDateError;
    constructor(props: ValidationRuleProps<Value> = {}) {
        super(props);
    }
    validate(value: Value): RequiredDateError | null {
        if (isNil(value)) {
            return new RequiredDateError();
        }
        return null;
    }
}
