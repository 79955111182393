import { getCurrentLocale } from "./localizationModule";

const currencySymbolToNumberFormattersMap: Record<string, Record<string, Intl.NumberFormat>> = {};

/**
 * Formats the number {@link value} to a localized value.
 *
 * __Note__: This should be called once a locale for the current user has been determined.
 * @param {number} value The value to format.
 * @param {number} decimalPlaces Optional decimal places to use when formatting a decimal number value.
 * @returns {string} The formatted number value.
 */
function formatNumber(value: number, decimalPlaces?: number): string {
    return Intl.NumberFormat(getCurrentLocale(), {
        minimumFractionDigits: decimalPlaces ?? 0,
        maximumFractionDigits: decimalPlaces ?? 5,
    }).format(value);
}

/**
 * Formats the {@link value} to a localized currency value using the {@link isoCurrencySymbol}.
 *
 * __Note__: This should be called once a locale for the current user has been determined.
 * @param {number} value The value to format.
 * @param {string} isoCurrencySymbol The three-character ISO 4217 currency symbol to use for formatting.
 * @param {string | null} symbol The currency symbol to use for formatting.
 * @param {number | null} decimalPlaces The decimal places to use for formatting.
 * @returns {string} The formatted currency value.
 */
function formatCurrency(
    value: number,
    isoCurrencySymbol: string,
    symbol?: string | null,
    decimalPlaces?: number | null
): string {
    const locale = getCurrentLocale();

    if (currencySymbolToNumberFormattersMap[locale] === undefined) {
        currencySymbolToNumberFormattersMap[locale] = {};
    }

    const currencyFormats = currencySymbolToNumberFormattersMap[locale];
    let localeCurrencyFormat = currencyFormats[isoCurrencySymbol];
    const currencyDecimalPlaces =
        decimalPlaces !== undefined && decimalPlaces !== null && decimalPlaces > 0 ? decimalPlaces : 2;

    if (localeCurrencyFormat === undefined) {
        localeCurrencyFormat = new Intl.NumberFormat(locale, {
            style: "currency",
            currency: isoCurrencySymbol,
            minimumFractionDigits: currencyDecimalPlaces,
            maximumFractionDigits: currencyDecimalPlaces,
        });

        currencyFormats[isoCurrencySymbol] = localeCurrencyFormat;
    }

    let result = localeCurrencyFormat.format(value);

    if (result.includes(isoCurrencySymbol) && symbol !== null && symbol !== undefined) {
        result = `${result.replace(isoCurrencySymbol, "").trim()} ${symbol}`;
    }
    return result;
}

/**
 * Formats the datetime string {@link value} to a localized datetime value.
 *
 * __Note__: This should be called once a locale for the current user has been determined.
 * @param {string} value The value to format.
 * @param {boolean} includeTime A value indicating whether to include the time.
 * @param {Intl.DateTimeFormatOptions} options The options to format the date and time.
 * @returns {string | null} The formatted value, or {@link null} if {@link value} could not be formatted.
 */
function formatDate(
    value: string | Date | null | undefined,
    includeTime?: boolean,
    options?: Intl.DateTimeFormatOptions
): string | undefined {
    if (value === null || value === undefined || value === "") {
        return undefined;
    }

    const dateObject = new Date(value);
    const locale = getCurrentLocale();

    if (includeTime ?? false) {
        if (options === null || options === undefined) {
            return new Intl.DateTimeFormat(locale, {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
            }).format(dateObject);
        }
        if (options.timeStyle === null || options.timeStyle === undefined) {
            options.timeStyle = options.dateStyle;
        }
    }

    return new Intl.DateTimeFormat(locale, options).format(dateObject);
}

/**
 * Formats the datetime string {@link value} to a localized date value in 'short' format.
 *
 * __Note__: This should be called once a locale for the current user has been determined.
 * @param {string} value The value to format.
 * @returns {string | undefined} The formatted value, or {@link null} if {@link value} could not be formatted.
 */
function formatShortDate(value: string | Date | null | undefined): string | undefined {
    return formatDate(value, false, { dateStyle: "short" });
}

export { formatNumber, formatCurrency, formatDate, formatShortDate };
