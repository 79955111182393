import React from "react";
import { DirectionalHint, FontIcon, Shimmer, Text, Toggle, TooltipHost } from "@fluentui/react";
import { useStyles } from "./BetaToggle.styles";

/**
 * The props for the {@link BetaToggle} component.
 */
export type BetaToggleProps = {
    toggleLabel: string;

    /** The callback function trigger on toggle state change. */
    onChange: (e: React.MouseEvent<HTMLElement>, checked?: boolean | undefined) => void;

    tooltipContent: string;

    /** Whether to render loading state or data from props */
    loading?: boolean;
};

/**
 * Beta Toggle component
 * @param {BetaToggleProps} props The component props.
 * @returns {JSX.Element} The react element.
 */
export function BetaToggle({ toggleLabel, onChange, tooltipContent, loading = false }: BetaToggleProps): JSX.Element {
    const styles = useStyles();

    return (
        <Shimmer width="20vw" isDataLoaded={!loading} style={styles.shimmerStyle}>
            <div style={styles.betaToggleDivStyle}>
                <Toggle
                    styles={styles.toggleStyles}
                    defaultChecked={true}
                    label={toggleLabel}
                    inlineLabel
                    onChange={onChange}
                />
                <Text style={styles.betaTextStyle}> Beta </Text>
                <TooltipHost content={tooltipContent} directionalHint={DirectionalHint.bottomCenter} role="tooltip">
                    <FontIcon iconName="Info" style={styles.iTextStyle} />
                </TooltipHost>
            </div>
        </Shimmer>
    );
}
