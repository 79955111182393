import { Message } from "./Message";

export enum EntityType {
    Object = 1,
}

export enum OperationType {
    Create = 0,
    Update = 1,
    Delete = 2,
}

export type OperationResponse = {
    entityTypes: EntityType;
    operationType: OperationType;
    messages: Message[];
    success: boolean;
    successMessage: Message;
};
