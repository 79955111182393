import React, { useEffect } from "react";
import { CommandButton, PrimaryButton, Stack } from "@fluentui/react";
import { useWizardContext } from "./Wizard.context";
import { useStyles } from "./WizardStep.styles";

/**
 * The {@link WizardStepProps} Props.
 */
export interface WizardStepProps {
    /**
     * Callback that will be invoked on each WizardStep render.
     * Use this function when you need to do some logic for each step separately.
     */
    onInit?: () => void;
    /** Callback to pass children into WizardStep component. */
    content?: () => JSX.Element;
    /** Callback to invoke when moving to the prev step */
    onPrev?: () => void;
    /** Callback to invoke when moving to the next step */
    onNext?: () => void;
    /** Defines visibility state of all buttons. */
    hideButtons?: boolean;
    /** Defines visibility state of the Prev button. */
    hidePrev?: boolean;
    /** Defines visibility state of the Next button. */
    hideNext?: boolean;
    /** Defines whether the Prev button is disabled. */
    isPrevDisabled?: boolean;
    /** Defines whether the Next button is disabled. */
    isNextDisabled?: boolean;
    /** The component labels. */
    labels?: {
        prev?: string;
        next?: string;
    };
}

/**
 * WizardStep component renders the passed in children and wizard buttons.
 * @param {WizardStepProps} props The WizardStep component properties.
 * @returns {JSX.Element} The WizardStep component.
 */
export function WizardStep(props: WizardStepProps): JSX.Element {
    const {
        content,
        onInit,
        onPrev,
        onNext,
        hideButtons,
        hidePrev,
        hideNext,
        labels = {
            next: "Next",
            prev: "Back",
        },
        isPrevDisabled = false,
        isNextDisabled = false,
    } = props;
    const wizardContext = useWizardContext();
    const { stackStyles } = useStyles();

    useEffect(() => {
        if (onInit !== undefined) {
            onInit();
        }
    });

    const handlePrev = () => {
        wizardContext.prev();

        if (onPrev !== undefined) {
            onPrev();
        }
    };
    const handleNext = () => {
        wizardContext.next();

        if (onNext !== undefined) {
            onNext();
        }
    };

    return (
        <>
            {content !== undefined && content()}
            {hideButtons !== true && (
                <Stack horizontal horizontalAlign="space-between" verticalAlign="center" styles={stackStyles}>
                    {hidePrev !== true && (
                        <CommandButton text={labels.prev} disabled={isPrevDisabled} onClick={handlePrev} />
                    )}
                    {hideNext !== true && (
                        <PrimaryButton text={labels.next} disabled={isNextDisabled} onClick={handleNext} />
                    )}
                </Stack>
            )}
        </>
    );
}
