import { IDatePickerStyles, ITextStyles, useTheme } from "@fluentui/react";

type UseDatePickerStylesOutput = {
    datePicker: Partial<IDatePickerStyles>;
    errorMessage: ITextStyles;
};

/**
 * Hook for styling the DatePicker component.
 * @param {boolean} displayError - Whether to display an error style.
 * @returns {UseDatePickerStylesOutput} Object containing styles for the DatePicker component.
 */
export const useDatePickerStyles = (displayError = false): UseDatePickerStylesOutput => {
    const { fonts, palette } = useTheme();
    return {
        datePicker: {
            textField: {
                "div > div": {
                    ...(displayError ? { borderColor: palette.redDark } : { borderColor: palette.neutralDark }),
                },
            },
        },
        errorMessage: {
            root: {
                color: palette.redDark,
                fontSize: fonts.small.fontSize,
            },
        },
    };
};
