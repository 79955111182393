import React from "react";

/**
 * Orbus SVG Icon.
 * @returns {JSX.Element} Orbus SVG Icon.
 */
const OrbusIcon = (): JSX.Element => {
    return (
        <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 1C5.92638 1 1 5.92638 1 12C1 18.0736 5.92638 23 12 23C18.0736 23 23 18.0736 23 12C23 5.92638 18.1074 1 12 1ZM12 17.8037C8.79448 17.8037 6.23006 15.2055 6.23006 12.0337C6.23006 8.82822 8.82822 6.2638 12 6.2638C15.2055 6.2638 17.7699 8.86196 17.7699 12.0337C17.8037 15.2055 15.2055 17.8037 12 17.8037Z"
                fill="url(#paint0_linear)"
            />
            <path
                d="M13.9908 4.84665C9.30063 4.84665 5.52148 8.65953 5.52148 13.316C5.52148 18.0062 9.33437 21.7853 13.9908 21.7853C18.681 21.7853 22.4601 17.9724 22.4601 13.316C22.4939 8.65953 18.681 4.84665 13.9908 4.84665ZM12 17.8037C8.79449 17.8037 6.23007 15.2055 6.23007 12.0338C6.23007 8.82824 8.82823 6.26383 12 6.26383C15.2055 6.26383 17.7699 8.86199 17.7699 12.0338C17.8037 15.2055 15.2055 17.8037 12 17.8037Z"
                fill="url(#paint1_linear)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1="20.3257"
                    y1="19.2391"
                    x2="3.70138"
                    y2="4.78788"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.0703125" stopColor="#222952" />
                    <stop offset="0.196" stopColor="#2B4372" />
                    <stop offset="0.4084" stopColor="#396CA3" />
                    <stop offset="0.5705" stopColor="#4285C1" />
                    <stop offset="0.6618" stopColor="#458ECC" />
                    <stop offset="1" stopColor="#44AB59" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear"
                    x1="20.0007"
                    y1="19.3291"
                    x2="8.00738"
                    y2="7.33582"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D9B953" />
                    <stop offset="0.6383" stopColor="#EA6F6E" />
                    <stop offset="0.708" stopColor="#D85B5A" />
                    <stop offset="0.795" stopColor="#C84A49" />
                    <stop offset="0.8886" stopColor="#BF3F3F" />
                    <stop offset="1" stopColor="#BC3C3C" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export { OrbusIcon };
