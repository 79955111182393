import { CSSProperties } from "react";
import { IButtonStyles, IStackStyles, IStackTokens, useTheme } from "@fluentui/react";

export interface ShareStyles {
    share: CSSProperties;
    content: IStackStyles;
    tokens: IStackTokens;
    button: IButtonStyles;
}

/**
 * Returns styles for the Share component.
 * @returns {ShareStyles} Share component styles.
 */
export function useStyles(): ShareStyles {
    const { spacing, palette } = useTheme();

    return {
        share: {
            fontSize: spacing.m,
        },
        content: {
            root: {
                margin: spacing.l1,
            },
        },
        tokens: {
            childrenGap: spacing.m,
        },
        button: {
            root: {
                ":hover": {
                    background: palette.neutralLighter,
                },
            },
        },
    };
}
