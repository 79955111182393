import { isNil } from "lodash";
import { ValidationRule, ValidationRuleProps } from "../..";

type Value = boolean | undefined;

/**
 * RequiredBooleanError validation error class
 */
export class RequiredBooleanError extends Error {
    message = "Field is required";
}

/**
 * RequiredBoolean validation rule class
 */
export class RequiredBoolean extends ValidationRule<Value> {
    static Error = RequiredBooleanError;
    constructor(props: ValidationRuleProps<Value> = {}) {
        super(props);
    }
    validate(value: Value): RequiredBooleanError | null {
        if (isNil(value)) {
            return new RequiredBooleanError();
        }
        return null;
    }
}
