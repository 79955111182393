import { Component, ReactChild } from "react";
import { LinkTargetOption } from "../../../types";

type CurrentStatePartial = {
    [key in OptionKeys]?: key extends "link" ? Link : boolean | string;
};

export type Link = {
    target: string | undefined;
    targetOption?: string;
    title?: string;
};

export type Icon = string | JSX.Element;

export enum ToolbarOptions {
    inline = "inline",
    blockType = "blockType",
    fontSize = "fontSize",
    fontFamily = "fontFamily",
    list = "list",
    textAlign = "textAlign",
    colorPicker = "colorPicker",
    link = "link",
    unlink = "unlink",
    embedded = "embedded",
    emoji = "emoji",
    image = "image",
    remove = "remove",
}

export enum OptionKeys {
    bold = "bold",
    italic = "italic",
    underline = "underline",
    strikethrough = "strikethrough",
    monospace = "monospace",
    indent = "indent",
    outdent = "outdent",
    ordered = "ordered",
    unordered = "unordered",
    left = "left",
    center = "center",
    right = "right",
    justify = "justify",
    color = "color",
    eraser = "eraser",
    link = "link",
    unlink = "unlink",
    emoji = "emoji",
    embedded = "embedded",
    image = "image",
    undo = "undo",
    redo = "redo",
    subscript = "subscript",
    superscript = "superscript",
}

export enum CurrentStyle {
    color,
    bgcolor,
}

export type Options = {
    [key in OptionKeys]?: OptionProps;
};

export interface CurrentState extends CurrentStatePartial {
    selectionText?: string;
    color?: string;
    bgColor?: string;
    textAlignment?: string;
    listType?: string;
}

export interface OptionProps {
    icon?: Icon;
    onClick: (value: string | undefined) => void;
    colors?: string[];
    children?: ReactChild;
    value?: string;
    className?: string;
    activeClassName?: string;
    active: boolean;
    disabled: boolean;
    title?: string;
    popupClassName?: string;
    target?: string;
    targetOption?: LinkTargetOption;
}

export interface Config extends Options {
    colors?: string[];
    component?: Component;
    className?: string;
    dropdownClassName?: string;
    popupClassName?: string;
    inDropdown: boolean;
    options: OptionKeys[];
    icon?: Icon;
    title?: string;
}

export interface TopLevelOptionProps {
    expanded?: boolean;
    doExpand?: () => void;
    doCollapse?: () => void;
    onExpandEvent?: () => void;
    config: Config;
    onChange(value: string | undefined): void;
    onChange(value: string | undefined, inputValue: string | undefined): void;
    onChange(value: "color" | "bgcolor", color: string | undefined): void;
    onChange(value: "link" | "unlink", linkTitle: string, linkTarget: string, linkTargetOption: LinkTargetOption): void;
    currentState: CurrentState;
    translations: Record<string, string>;
}
