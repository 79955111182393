import React, { useCallback } from "react";
import { IPersonaProps, Persona, PersonaSize } from "@fluentui/react/lib/Persona";
import { IBasePickerSuggestionsProps, CompactPeoplePicker } from "@fluentui/react/lib/Pickers";
import { IInputProps, Shimmer, ShimmerElementType, Stack, Text } from "@fluentui/react";
import { usePeoplePickerStyles } from "./PeoplePicker.styles";
import { isNil } from "lodash";
import { FieldLabel } from "../FieldLabel";
import { ILabeledFieldProps } from "../types";

function removeSelectedPeople(people: IPersonaProps[], selectedPeople: IPersonaProps[]) {
    if (selectedPeople.length === 0) {
        return people;
    }

    return people.filter((person) => !isPersonInPeople(person, selectedPeople));
}

function isPersonInPeople(person: IPersonaProps, people: IPersonaProps[]) {
    return people.some((_person) => _person.key === person.key);
}

function doesSomeNameStartWithFilterText(name: string, filterText: string): boolean {
    const personNames = name.toLowerCase().split(" ");
    return personNames.some((name) => name.startsWith(filterText.toLowerCase()));
}

function useResolveSuggestions(
    people: IPersonaProps[],
    suggestSelectedPeople: boolean
): (filterText: string, selectedPeople: IPersonaProps[] | undefined) => IPersonaProps[] {
    return useCallback(
        (filterText: string, selectedPeople: IPersonaProps[] | undefined): IPersonaProps[] => {
            if (filterText.length === 0) {
                return [];
            }

            const filteredPeople: IPersonaProps[] = people.filter((person) => {
                if (person.text === undefined) {
                    return false;
                }

                return doesSomeNameStartWithFilterText(person.text, filterText);
            });

            if (!suggestSelectedPeople && selectedPeople !== undefined) {
                return removeSelectedPeople(filteredPeople, selectedPeople);
            }

            return filteredPeople;
        },
        [suggestSelectedPeople, people]
    );
}

function useEmptyResolveSuggestions(
    people: IPersonaProps[],
    suggestSelectedPeople: boolean
): (selectedPeople: IPersonaProps[] | undefined) => IPersonaProps[] {
    return useCallback(
        (selectedPeople: IPersonaProps[] | undefined): IPersonaProps[] => {
            if (!suggestSelectedPeople && selectedPeople !== undefined) {
                return removeSelectedPeople(people, selectedPeople);
            }

            return people;
        },
        [suggestSelectedPeople, people]
    );
}

const TEXT_FIELD_HEIGHT = 32;

export interface PeoplePickerProps extends ILabeledFieldProps {
    isLoading: boolean;
    required?: boolean;
    /** Displays component as disabled (only in edit mode). */
    disabled?: boolean;
    allowMultipleValues: boolean;
    people: IPersonaProps[];
    suggestSelectedPeople?: boolean;
    selectedPeople: IPersonaProps[];
    onSelectedPeopleChange: (people?: IPersonaProps[] | undefined) => void;
    suggestionProps: IBasePickerSuggestionsProps;
    inputProps: IInputProps;
    selectionAriaLabel: string;
    removeButtonAriaLabel: string;
    /** Enable readOnly mode. */
    readOnly?: boolean;
    /** Defines a placeholder when the value is empty. Applicable in readOnly mode. */
    readOnlyPlaceholder?: string;
}

/**
 * People and groups picker.
 * Supports only local filtering.
 * In readOnly mode, displays the formatted people and groups selection.
 * @param {PeoplePickerProps} props - component props
 * @returns {JSX.Element} react element
 */
export function PeoplePicker({
    people,
    selectedPeople,
    onSelectedPeopleChange,
    suggestionProps,
    inputProps,
    selectionAriaLabel,
    removeButtonAriaLabel,

    allowMultipleValues,
    isLoading = false,
    disabled = false,
    suggestSelectedPeople = false,
    readOnly = false,
    label,
    readOnlyPlaceholder,
    onRenderLabel,
}: PeoplePickerProps): JSX.Element {
    const styles = usePeoplePickerStyles();
    const onResolveSuggestions = useResolveSuggestions(people, suggestSelectedPeople);
    const onEmptyResolveSuggestions = useEmptyResolveSuggestions(people, suggestSelectedPeople);

    const renderEditMode = () => {
        return (
            <CompactPeoplePicker
                itemLimit={allowMultipleValues ? undefined : 1}
                className={"ms-PeoplePicker"}
                disabled={disabled}
                selectionAriaLabel={selectionAriaLabel}
                removeButtonAriaLabel={removeButtonAriaLabel}
                inputProps={inputProps}
                pickerSuggestionsProps={suggestionProps}
                selectedItems={selectedPeople}
                onChange={onSelectedPeopleChange}
                onResolveSuggestions={onResolveSuggestions}
                onEmptyResolveSuggestions={onEmptyResolveSuggestions}
            />
        );
    };

    const renderReadMode = () => {
        return (
            <>
                <Stack horizontal styles={styles.container}>
                    {selectedPeople.map((person) => (
                        <Persona key={person.key} text={person.text} size={PersonaSize.size24} />
                    ))}
                    {selectedPeople.length === 0 && <Text styles={styles.placeholder}>{readOnlyPlaceholder}</Text>}
                </Stack>
            </>
        );
    };

    return (
        <>
            {isLoading ? (
                <Shimmer
                    isDataLoaded={!isLoading}
                    shimmerElements={[{ type: ShimmerElementType.line, height: TEXT_FIELD_HEIGHT }]}
                />
            ) : (
                <>
                    {!isNil(onRenderLabel) ? onRenderLabel() : <FieldLabel label={label} />}
                    {readOnly ? renderReadMode() : renderEditMode()}
                </>
            )}
        </>
    );
}
