import { IButtonStyles, IDialogStyles, useTheme } from "@fluentui/react";

export interface DialogBoxStyles {
    dialog: Partial<IDialogStyles>;
    closeButton: Partial<IButtonStyles>;
}

/**
 * Returns styles for the DialogBox component.
 * @returns {DialogBoxStyles} DialogBoxStyles component styles.
 */
export function useStyles(): DialogBoxStyles {
    const { semanticColors } = useTheme();

    return {
        dialog: {
            main: {
                minHeight: 156,
            },
        },
        closeButton: {
            root: {
                color: semanticColors.buttonText,
            },
            rootPressed: {
                color: semanticColors.buttonText,
            },
            rootHovered: {
                color: semanticColors.buttonText,
            },
        },
    };
}
