import { settings } from "./settings";

/**
 * Calls the legacy-logout endpoint on the authorize controller in the legacy webapp.
 *
 * __Note__: This is used by the logout method in authentication-utility to ensure that when a user logs out of
 * the webapp, their session is also terminated on the legacy webapp too.
 * @returns {boolean} {@link true} if the legacy-logout response was ok (200), {@link false} otherwise.
 */
async function legacyLogout(): Promise<boolean> {
    const logoutUrl = createApiUrl("authorize/legacylogout");

    // Legacy logout is invalidating the authentication cookie for the legacy App (via set-cookie header).
    // We need to specify "credentials: include" so that the header is not ignored when doing fetch.
    const res = await fetch(logoutUrl, {
        credentials: "include",
    });

    return res.status === 200;
}

function createApiUrl(relativeUrl: string) {
    // if we are in a live environment, we need to determine the challenge url from the current url,
    // as all api url's in iServer365 follow a naming convention.
    if (settings.isDevelopment) {
        const apiUrl = settings.apiUrl.endsWith("/") ? settings.apiUrl : `${settings.apiUrl}/`;
        return apiUrl + relativeUrl;
    }

    return `/${relativeUrl}`;
}

export { legacyLogout };
