import { useEffect, useState } from "react";
import { ModelItem } from "../types/ModelItem";
import { ModelItemPickerDefinition } from "../types/ModelItemPickerDefinition";
import { isNil } from "lodash";
import { Model } from "../types/Model";

type UseModelItemPickerStateProps = {
    /** Initially selected model.*/
    model: Model | undefined;
    /** Initially selected ModelItem. */
    modelItem: ModelItem | undefined;
    /** Flag that indicates whether state can be edited. */
    isEditable: boolean;
    /** Event executed when new source ModelItem has been selected. */
    onModelItemSelected?: (modelItemId: string, metamodelItemId: string) => void;
};

type ModelItemState = {
    metamodelItemId: string | undefined;
    modelItemId: string | undefined;
};

const mapToModelItemState = (modelItem: ModelItem | undefined): ModelItemState => ({
    metamodelItemId: modelItem?.itemType.id,
    modelItemId: modelItem?.id,
});

type ModelItemPickerStateOutput = Pick<
    ModelItemPickerDefinition,
    | "selectedMetamodelItemId"
    | "selectedModelItemId"
    | "changeModelItem"
    | "changeMetamodelItem"
    | "isEditable"
    | "modelName"
>;

/**
 * Hook for managing the state of a model item picker.
 * @param {UseModelItemPickerStateProps} props - The hook props.
 * @returns {ModelItemPickerStateOutput} The model item picker state.
 */
export const useModelItemPickerState = ({
    model,
    modelItem,
    isEditable,
    onModelItemSelected,
}: UseModelItemPickerStateProps): ModelItemPickerStateOutput => {
    const [modelItemState, setModelItemState] = useState<ModelItemState>(mapToModelItemState(modelItem));

    useEffect(() => {
        const newModelItemState = mapToModelItemState(modelItem);
        setModelItemState(newModelItemState);
    }, [modelItem, isEditable]);

    const updateModelItem = (modelItemId: string | undefined): void => {
        const metamodelItemId = modelItemState.metamodelItemId;
        if (
            !isNil(onModelItemSelected) &&
            !isNil(modelItemId) &&
            !isNil(metamodelItemId) &&
            modelItemId !== modelItemState.modelItemId
        ) {
            onModelItemSelected(modelItemId, metamodelItemId);
        }

        setModelItemState((currentState) => ({ ...currentState, modelItemId }));
    };

    return {
        selectedMetamodelItemId: modelItemState.metamodelItemId,
        selectedModelItemId: modelItemState.modelItemId,
        isEditable: isEditable || isNil(modelItem),
        modelName: modelItem?.model?.name ?? model?.name ?? "",
        changeModelItem: updateModelItem,
        changeMetamodelItem: (metamodelItemId: string | undefined) =>
            setModelItemState(() => ({ metamodelItemId, modelItemId: undefined })),
    };
};

export type { UseModelItemPickerStateProps, ModelItemPickerStateOutput };
