import { useQuery } from "@apollo/client";
import { raiseEvent } from "iserver365-infrastructure-utility";
import { useMemo } from "react";
import { mapSettings } from "./settings.utils";
import { apolloClient } from "./state/apolloClient";
import { setBusinessModeEnabled } from "./state/cache";
import { GET_SETTINGS } from "./state/getSettings";
import { EventTypes, SettingsData, SettingsDataResponse } from "./types";

export type Settings = {
    settings: SettingsData | undefined;
    onBusinessModeChange: (value: boolean) => void;
    isLoading: boolean;
};

/**
 * A react hook to retrieve and modify application settings.
 * @returns {Settings} The application settings.
 */
export function useSettings(): Settings {
    const { data, loading } = useQuery<{ settingsData: SettingsDataResponse }>(GET_SETTINGS, {
        fetchPolicy: "cache-first",
        client: apolloClient,
    });

    const settings = useMemo(() => mapSettings(data?.settingsData), [data]);

    const onBusinessModeChange = (value: boolean): void => {
        setBusinessModeEnabled(value);
        raiseEvent(EventTypes.viewModeChanged);
    };

    return { settings, onBusinessModeChange, isLoading: loading };
}
