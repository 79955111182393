/**
 * Raises the event of type {@link type} with the event payload data set to {@link data}.
 * @typedef T The event data type.
 * @param {string} type The event type.
 * @param {T} data The event data.
 */
function raiseEvent<T>(type: string, data?: T): void {
    window.dispatchEvent(new CustomEvent(type, { detail: data }));
}

/**
 * Listens for events of type {@link type} and fires the {@link callback} with the event data.
 *
 * This should be used in conjunction with {@link raiseEvent}.
 *
 * When invoked this function returns a disposer function, which, when called, unbinds the listening of the
 * event. This disposer function should be called when it is no longer required to listen to the event.
 * @param {string} type The event type.
 * @param {Function} callback The callback to fire when the event is raised.
 * @returns {Function} A disposer function to unbind the listening of the event.
 */
function listenEvent<T = Record<string, unknown>>(type: string, callback: (data: T) => void): () => void {
    const eventListener = ((e: CustomEvent<T>) => {
        callback(e.detail);
    }) as EventListener;
    window.addEventListener(type, eventListener);

    return () => window.removeEventListener(type, eventListener);
}

export { raiseEvent, listenEvent };
