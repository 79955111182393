import { noop } from "lodash";
import { TextField, Stack, IStackStyles, ITextFieldStyles } from "@fluentui/react";
import React, { FormEvent } from "react";
import { useControllableValue } from "@fluentui/react-hooks";

import { useValidation, ComponentValidationProps } from "../utils/validation";

import { getStylesFunction } from "./NumberField.styles";
import {
    Value,
    getValidationRules,
    getFixedStringValue,
    getDisplayValidationError,
    blockInvalidChar,
    pasteHandlerToBlockE,
    checkMaxLength,
    getDisplayValue,
    getSuffix,
} from "./NumberField.service";
import { ILabeledFieldProps } from "../types";

/**
 * The props for the {@link Number} component.
 */
export interface NumberFieldProps extends ILabeledFieldProps, ComponentValidationProps<Value> {
    /** The aria label of the input. */
    ariaLabel?: string;
    /** The name of the input. */
    name?: string;
    /** The default value of the input. Used to set initial value in uncontrolled mode. */
    defaultValue?: number;
    /**
     * The value of the input. Used to control the value in controlled mode.
     * If it's controlled input but initial value is unknown at the first render, use `null` instead of `undefined`.
     */
    value?: number | null;
    /** Should the input be displayed in readOnly mode. */
    readOnly?: boolean;
    /** Decimal places for the value. */
    decimalPlaces?: number;
    /** Suffix of the value. */
    suffix?: string;
    /** Input value change event. */
    onChange?: (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: Value) => void;
    /** Whether the value is required */
    isRequired?: boolean;
    /** Minimum allowed value */
    min?: number;
    /** Maximum allowed value */
    max?: number;
    /** Styles for field's Stack wrapper. */
    fieldWrapperStyles?: IStackStyles;
    /** Styles for field component. */
    styles?: Partial<ITextFieldStyles>;
    /** Whether the filed is disabled. */
    disabled?: boolean;
    maxLength?: number;
    /** Specifies a placeholder to display when there's no value provided. */
    placeholder?: string;
    /** A value indicating whether the attribute is used to display currency values */
    isCurrency?: boolean;
}

/**
 * This is a NumberField component.
 * @param {NumberFieldProps} props The {@link NumberFieldProps}.
 * @returns {JSX.Element} The react element.
 */
export function NumberField(props: NumberFieldProps): JSX.Element {
    const {
        label,
        ariaLabel,
        name,
        defaultValue,
        value,
        readOnly = false,
        decimalPlaces = 0,
        suffix,
        isRequired = false,
        min,
        max,
        maxLength = 15,
        onChange,
        onValidationStateChange = noop,
        validationErrorMessageProvider = (error) => error.message,
        validationRules = [],
        fieldWrapperStyles,
        styles,
        disabled,
        placeholder,
        isCurrency = false,
        ...restProps
    } = props;

    const [valueState, setValueState] = useControllableValue(value, defaultValue, onChange);

    const { validationErrors, validationErrorMessages } = useValidation({
        value: valueState,
        rules: getValidationRules({ validationRules, isRequired, min, max }),
        onValidationStateChange,
        validationErrorMessageProvider,
    });

    const validationErrorToDisplay: string | undefined = validationErrorMessages[0];
    const displayValidationError: boolean = getDisplayValidationError({ validationErrors, readOnly });
    const displayValue = getDisplayValue(valueState, readOnly, isCurrency, suffix, decimalPlaces);
    const displaySuffix = getSuffix(suffix, readOnly, isCurrency);

    return (
        <Stack styles={{ ...fieldWrapperStyles }}>
            <TextField
                {...restProps}
                type={readOnly ? "text" : "number"}
                maxLength={maxLength}
                name={name}
                title={readOnly ? value?.toString() : ""}
                label={label}
                ariaLabel={ariaLabel}
                suffix={displaySuffix}
                disabled={disabled}
                readOnly={readOnly}
                required={isRequired && !readOnly}
                borderless={readOnly}
                value={displayValue}
                placeholder={placeholder}
                errorMessage={displayValidationError ? validationErrorToDisplay : undefined}
                onKeyDown={blockInvalidChar}
                onPaste={pasteHandlerToBlockE}
                onChange={(event, newValue) => {
                    let stringValue = getFixedStringValue(newValue, decimalPlaces);
                    stringValue = checkMaxLength(stringValue, maxLength);
                    const parsedFloatNumber = parseFloat(stringValue);

                    setValueState(parsedFloatNumber, event);
                }}
                styles={styles ?? getStylesFunction(readOnly)}
            />
        </Stack>
    );
}
