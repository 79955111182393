// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./fonts/oa-icons.eot";
import ___CSS_LOADER_URL_IMPORT_1___ from "./fonts/oa-icons.svg";
import ___CSS_LOADER_URL_IMPORT_2___ from "./fonts/oa-icons.woff";
import ___CSS_LOADER_URL_IMPORT_3___ from "./fonts/oa-icons.ttf";
import ___CSS_LOADER_URL_IMPORT_4___ from "./fonts/FabricMDL2Assets.eot";
import ___CSS_LOADER_URL_IMPORT_5___ from "./fonts/FabricMDL2Assets.woff";
import ___CSS_LOADER_URL_IMPORT_6___ from "./fonts/FabricMDL2Assets.ttf";
import ___CSS_LOADER_URL_IMPORT_7___ from "./fonts/FabricMDL2Assets.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___, { hash: "#OA-Icons" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___, { hash: "#FabricMDL2Assets" });
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n    font-family: \"OA-Icons\";\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"svg\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff\"),\n        url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"truetype\");\n    font-weight: normal;\n    font-style: normal;\n    font-display: swap;\n}\n\n@font-face {\n    font-family: \"FabricMDL2Icons\";\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"woff\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format(\"truetype\"),\n        url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") format(\"svg\");\n    font-weight: normal;\n    font-style: normal;\n    font-display: swap;\n}\n", "",{"version":3,"sources":["webpack://./src/iserver365-styleguide/src/fonts.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,4CAAgC;IAChC;kEACkD;IAClD,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,8BAA8B;IAC9B,4CAAwC;IACxC;6DACsE;IACtE,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;AACtB","sourcesContent":["@font-face {\n    font-family: \"OA-Icons\";\n    src: url(\"./fonts/oa-icons.eot\");\n    src: url(\"./fonts/oa-icons.svg#OA-Icons\") format(\"svg\"), url(\"./fonts/oa-icons.woff\") format(\"woff\"),\n        url(\"./fonts/oa-icons.ttf\") format(\"truetype\");\n    font-weight: normal;\n    font-style: normal;\n    font-display: swap;\n}\n\n@font-face {\n    font-family: \"FabricMDL2Icons\";\n    src: url(\"./fonts/FabricMDL2Assets.eot\");\n    src: url(\"./fonts/FabricMDL2Assets.woff\") format(\"woff\"), url(\"./fonts/FabricMDL2Assets.ttf\") format(\"truetype\"),\n        url(\"./fonts/FabricMDL2Assets.svg#FabricMDL2Assets\") format(\"svg\");\n    font-weight: normal;\n    font-style: normal;\n    font-display: swap;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
