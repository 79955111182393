import { IColumn, IContextualMenuItem } from "@fluentui/react";
import produce from "immer";

/**
 * Helper function for the ListView which returns the context menu items
 * for the column headers.
 * @param {IColumn} column The column for which to get the context menu items.
 * @param {IColumn[]} columns All the columns being used for the ListView.
 * @param {Function} onColumnUpdate The function to call when {@link column} is updated.
 * @returns {IContextualMenuItem[] | undefined} The context menu items.
 */
export const getColumnHeaderContextMenuItems = (
    column: IColumn,
    columns: IColumn[],
    onColumnUpdate: (columns: IColumn[]) => void
): IContextualMenuItem[] | undefined => {
    return [
        {
            key: "asc",
            // TODO replace with better name based on value type, e.g. A to Z, Older to newer
            name: "Asc",
            iconProps: { iconName: "SortUp" },
            canCheck: true,
            checked: column.isSorted === true && column.isSortedDescending === false,
            onClick: () => {
                const nextColumns = produce(columns, (draftColumns) => {
                    draftColumns.forEach((c) => {
                        if (c.key === column.key) {
                            c.isSorted = true;
                            c.isSortedDescending = false;
                        } else {
                            c.isSorted = undefined;
                            c.isSortedDescending = undefined;
                        }
                    });
                });

                onColumnUpdate(nextColumns);
            },
        },
        {
            key: "desc",
            name: "Desc",
            iconProps: { iconName: "SortDown" },
            canCheck: true,
            checked: column.isSorted === true && column.isSortedDescending === true,
            onClick: () => {
                const nextColumns = produce(columns, (draftColumns) => {
                    draftColumns.forEach((c) => {
                        if (c.key === column.key) {
                            c.isSorted = true;
                            c.isSortedDescending = true;
                        } else {
                            c.isSorted = undefined;
                            c.isSortedDescending = undefined;
                        }
                    });
                });
                onColumnUpdate(nextColumns);
            },
        },
    ];
};
