/* eslint-disable jsdoc/no-undefined-types */
import { isEmpty } from "lodash";
import { ValidationRule, ValidationError } from "../utils/validation";
import { RequiredString } from "../utils/validation/rules";

export type Value = string | undefined;

/**
 * Gets the list of validation rules to apply to {@link SearchableDropdown}.
 * @param {object} params The parameter object.
 * @param {ValidationRule[]} params.validationRules The list of validation rules to apply to the value.
 * @param {boolean} params.required Whether the value is required.
 * @returns {ValidationRule[]} The array of validation rules to apply.
 */
export function getValidationRules({
    validationRules,
    required,
}: {
    validationRules: ValidationRule<Value>[];
    required: boolean;
}): ValidationRule<Value>[] {
    const builtinRules = [];
    if (required) {
        builtinRules.push(new RequiredString());
    }
    return [...builtinRules, ...validationRules];
}

/**
 * Whether or not to display {@link SearchableDropdown} component validation error.
 * @param {object} params The parameter object.
 * @param {ValidationError[]} params.validationErrors The list of validation errors for the component.
 * @param {boolean} params.disabled Whether or not the dropdown component selection is disabled.
 * @param {boolean} params.hasChanged Whether or not the dropdown component value is equals to default.
 * @returns {boolean} True if validation error must be displayed.
 */
export function getDisplayValidationError({
    validationErrors,
    disabled,
    hasChanged,
}: {
    validationErrors: ValidationError[];
    disabled: boolean;
    hasChanged: boolean;
}): boolean {
    return !isEmpty(validationErrors) && !disabled && hasChanged;
}

/**
 * Checks whether the value has changed.
 * @param {Value}prevValue The previous value.
 * @param {Value}nextValue The next value.
 * @returns {boolean} True if the value has changed.
 */
export function fieldValueHasChanged(prevValue: Value, nextValue: Value): boolean {
    return prevValue !== nextValue && !(isEmpty(prevValue) && isEmpty(nextValue));
}
