import { inIframe } from "./iframe";

/**
 * Gets the storage item with key {@link key}, or {@link undefined} if not found.
 * @typedef T The item type.
 * @param {string} key The key of the item in storage.
 * @returns {T | undefined} The item, or {@link undefined} if not found.
 */
function getStorageItem<T>(key: string): T | undefined {
    if (inIframe()) {
        // When the app is rendered inside an iframe browsers do not allow to access local storage.
        // In such case we will not use cache.
        return undefined;
    }

    const data = window.localStorage.getItem(key);
    if (data === null) {
        return undefined;
    }

    return <T>JSON.parse(data);
}

/**
 * Stores the item with the {@link data} in storage using the specified {@link key}.
 * @typedef T The item type.
 * @param {string} key The key to use for the item.
 * @param {T} data The data to store in storage.
 */
function setStorageItem<T>(key: string, data: T): void {
    if (inIframe()) {
        // When the app is rendered inside an iframe browsers do not allow to access local storage.
        // In such case we will not use cache.
        return;
    }
    const dataString = JSON.stringify(data);
    window.localStorage.setItem(key, dataString);
}

export { getStorageItem, setStorageItem };
