import { neutral } from "./colors/neutral";
import { theme } from "./colors/theme";
import { blackAndWhite } from "./colors/blackAndWhite";
import { defaultSpacing } from "./defaultSpacing";

const defaultColors = {
    ...neutral,
    ...theme,
    ...blackAndWhite,
};

export { defaultColors, defaultSpacing };
