import { isNil } from "lodash";
import { ValidationError, ValidationRule, ValidationRuleProps } from "../..";

type Value = string | undefined;

export interface MinMaxStringLengthErrorProps {
    /**
     Maximum length allowed for the input.
     */
    maxLength: number;
    /**
     Minimum length allowed for the input.
     */
    minLength: number;
}

export interface MinMaxStringLengthProps extends ValidationRuleProps<Value> {
    /**
     Maximum length allowed for the input.
     */
    maxLength: number;
    /**
     Minimum length allowed for the input.
     */
    minLength: number;
}

/**
 * MinMaxStringLengthError validation error class
 */
export class MinMaxStringLengthError implements ValidationError {
    constructor(props: MinMaxStringLengthErrorProps) {
        this.name = "MinMaxStringLengthError";
        this.maxLength = props.maxLength;
        this.minLength = props.minLength;
        this.message = `The number of characters must be between ${props.minLength} and ${props.maxLength}.`;
    }
    name: string;
    maxLength: number;
    minLength: number;
    message: string;
}

/**
 * MinMaxStringLength validation rule class
 */
export class MinMaxStringLength extends ValidationRule<Value> {
    static Error = MinMaxStringLengthError;
    constructor(props: MinMaxStringLengthProps) {
        super(props);
        this.maxLength = props.maxLength;
        this.minLength = props.minLength;
    }

    maxLength: number;
    minLength: number;

    validate(value: Value): MinMaxStringLengthError | null {
        if (isNil(value) || value === "") {
            return null;
        }

        if (value.length > this.maxLength || value.length < this.minLength) {
            return new MinMaxStringLengthError({ maxLength: this.maxLength, minLength: this.minLength });
        }

        return null;
    }
}
