import { Icon, ILinkProps, Link, Stack } from "@fluentui/react";
import React from "react";
import { systemColors } from "../SystemColor";
import { useListItemStyles } from "./ListItem.styles";
import { Item } from "./ListItem.types";

export type ListItemProps = Item;

/**
 * Renders an item which is used in list with an icon and a clickable name.
 * @param {ListItemProps} props The component props.
 * @returns {JSX.Element} The React component.
 */
export function ListItem(props: ListItemProps): JSX.Element {
    const classes = useListItemStyles();

    const linkProps: ILinkProps = {
        "data-is365-id": props["data-is365-id"],
        href: props.href,
        className: classes.title,
    };

    return (
        <Stack className={classes.container} horizontal verticalAlign="center">
            {props.icon !== undefined && (
                <div className={classes.iconContainer} style={{ backgroundColor: systemColors[props.icon.color] }}>
                    <Icon iconName={props.icon.name} />
                </div>
            )}
            <Link
                title={props.name}
                onClick={(event) => {
                    if (props.onClick !== undefined) {
                        props.onClick(event, props);
                    }
                }}
                {...linkProps}
            >
                {props.name}
            </Link>
        </Stack>
    );
}
