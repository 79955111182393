import { IShimmerStyles, IStackStyles, useTheme } from "@fluentui/react";

export interface ShimmeredListStyles {
    shimmerStyles?: IShimmerStyles;
    shimmerContainerStyles?: IStackStyles;
    listContainerStyles?: IStackStyles;
}

/**
 * Returns styles for the Shimmered component.
 * @returns {string} {@link ShimmeredListStyles} component styles.
 */
export function useShimmeredListStyles(): ShimmeredListStyles {
    const { spacing, palette } = useTheme();

    return {
        shimmerStyles: {
            root: {
                padding: `${spacing.s2} ${spacing.l1}`,
            },
        },
        shimmerContainerStyles: {
            root: {
                overflow: "hidden",
                position: "relative",
                "::after": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    backgroundImage: `linear-gradient(to bottom, transparent 30%, ${palette.whiteTranslucent40} 50%,${palette.white} 100%)`,
                },
            },
        },
        listContainerStyles: {},
    };
}
