import React from "react";
import { ActionButton, IButtonStyles } from "@fluentui/react";
import cx from "classnames";
import { useStyles } from "./ExpandCollapse.styles";

const collapsedIcon = "ChevronRight";
const expandedIcon = "ChevronDown";

/**
 * {@link IExpandCollapseProps} component props.
 */
export interface IExpandCollapseProps {
    /**
     * Changes the value of {@link isExpanded: boolean}.
     * @param {boolean} expanded the new value to be set.
     */
    setIsExpanded: (expanded: boolean) => void;
    /** Defines expanded state of the button. */
    isExpanded: boolean;
    /** Defines disabled state of the button. */
    isDisabled?: boolean;
    /** Defines the additional className for the btn */
    className?: string;
    /** Styles for the expand collapse button */
    styles?: IButtonStyles;
    /** Defines the style of the button if it has children. */
    hasChildren: boolean;
    /** The text of the button. */
    text?: string;
}

/**
 * This component accepts `isExpanded`, `hasChildren`, `setIsExpanded`.
 * When a user clicks on the button, it calls `setIsExpanded` to change the value of the `isExpanded` property.
 * Then `isExpanded` property can be used to manage the visibility of some page content.
 * @param {IExpandCollapseProps} props The component props.
 * @returns {JSX.Element} The react element.
 */
export function ExpandCollapse(props: IExpandCollapseProps): JSX.Element {
    const { isExpanded, isDisabled, hasChildren, className, styles, setIsExpanded, text } = props;

    const { hiddenChevron, expanded } = useStyles();

    return (
        <ActionButton
            styles={styles}
            onClick={() => {
                setIsExpanded(!isExpanded);
            }}
            disabled={isDisabled}
            data-test-id={`expandCollapse ${isExpanded ? "expanded" : "collapsed"}`}
            className={cx(className, {
                [hiddenChevron]: !hasChildren,
                [expanded]: isExpanded,
            })}
            iconProps={{ iconName: isExpanded ? expandedIcon : collapsedIcon }}
            ariaLabel={isExpanded ? "Collapse" : "Expand"}
        >
            {text}
        </ActionButton>
    );
}
