import { isNil } from "lodash";
import { useLayoutEffect, RefObject, useState, useCallback } from "react";
import { useBoolean } from "@fluentui/react-hooks";

/**
 * Checks whether displayed text is truncated by 'overflow: hidden' CSS property or it's displayed in multiple lines.
 * @param {HTMLDivElement | null} element The element which text needs to be checked.
 * @param {number} textInitialHeight The initial height of the text. It is used for checking if text is displayed in multiple lines.
 * @returns {boolean} whether or not the text is truncated.
 */
function isTextTruncated(element: HTMLElement | null, textInitialHeight: number): boolean {
    return !isNil(element) && (element.offsetWidth < element.scrollWidth || element?.offsetHeight > textInitialHeight);
}

/**
 * Monitors whether text is truncated and 'Show more' button needs to be displayed
 * @param {RefObject<HTMLElement>} ref Text element ref
 * @returns {boolean} whether to display 'Show All'/'Show less' buttons
 */
export const useTruncatedText = (ref: RefObject<HTMLElement>): boolean => {
    const [textInitialHeight, setTextInitialHeight] = useState(0);
    const [isShowMoreButtonDisplayed, { setTrue: displayShowMoreButton, setFalse: hideShowMoreButton }] =
        useBoolean(false);

    const resizeCallback = useCallback(() => {
        if (isTextTruncated(ref.current, textInitialHeight)) {
            displayShowMoreButton();
        } else {
            hideShowMoreButton();
        }
    }, [displayShowMoreButton, hideShowMoreButton, ref, textInitialHeight]);

    useLayoutEffect(() => {
        if (ref.current === null) {
            return undefined;
        }

        setTextInitialHeight(ref.current?.offsetHeight ?? 0);

        const observer = new ResizeObserver(() => {
            resizeCallback();
        });
        observer.observe(ref.current);

        return () => {
            observer.disconnect();
        };
    }, [resizeCallback, ref]);

    return isShowMoreButtonDisplayed;
};
