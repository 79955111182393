import { isEmpty } from "lodash";

import { ValidationError, ValidationRule } from "../../";

type Value = string | undefined;
const guidLength = 36;
const regexp = /^{?([0-9a-fA-F]){8}(-([0-9a-fA-F]){4}){3}-([0-9a-fA-F]){12}}?$/;

/**
 * IsValidGuid validation error class
 */
export class IsValidGuidError implements ValidationError {
    message = "Value is not a valid guid";
    name = "WrongGuidFormat";
}

/**
 * IsValidGuid validation rule class
 */
export class IsValidGuid extends ValidationRule<Value> {
    static Error = IsValidGuidError;
    constructor(props = {}) {
        super(props);
    }

    validate(value: Value): IsValidGuidError | null {
        if (isEmpty(value)) {
            return null;
        }

        if (value?.length !== guidLength || !regexp.test(value)) {
            return new IsValidGuidError();
        }

        return null;
    }
}
