import { useRef, useEffect } from "react";

/**
 * Returns the previous version of the provided value.
 * @template T The type of the value.
 * @param {T} value The value.
 * @param {T} [initialValue] The optional initial value to set as the previous value on the first render.
 * @returns {T | undefined} The previous version of the provided value.
 */
export function usePrevious<T>(value: T, initialValue?: T): T | undefined {
    const ref = useRef<T | undefined>(initialValue);

    useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref.current;
}
