import { createContext, useContext } from "react";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function navigateToUrl(path: string): void {
    throw new Error("Not implemented");
}

const navigationProps = {
    navigateToUrl,
};

type NavigationProps = typeof navigationProps;

export const NavigationContext = createContext<NavigationProps>(navigationProps);

/**
 * The react navigation context.
 * @returns {NavigationProps} Navigation properties.
 */
export function useNavigationContext(): NavigationProps {
    return useContext(NavigationContext);
}
