import { ApplicationInsights, ITelemetryItem } from "@microsoft/applicationinsights-web";
import { ClickAnalyticsPlugin } from "@microsoft/applicationinsights-clickanalytics-js";
import { settings } from "./settings";
import { AppInsightConfig } from "./types";
import { isArray, mergeWith } from "lodash";

function arrayMergeCustomizer(objValue: string | unknown[], srcValue: unknown) {
    if (isArray(objValue)) {
        return objValue.concat(srcValue);
    }

    return undefined;
}

/**
 * Creates the {@link ApplicationInsights} instance using an optional {@link extendConfig} which can be used for MFE
 * telemetry, error and performance tracking.
 *
 * This is low-level functionality and is intended to be used in conjunction with higher-level, framework specific configuration.
 * For example a ReactPlugin. Which is then provided to the react AppInsightsContext.
 * @param {AppInsightConfig} extendConfig Extra configuration options to specify which extend the default configuration.
 * @returns {ApplicationInsights} The application insights instance.
 */
function createAppInsight(extendConfig?: AppInsightConfig): ApplicationInsights {
    const clickPluginInstance = new ClickAnalyticsPlugin();

    const clickPluginConfig = {
        autoCapture: true,
        dataTags: {
            customDataPrefix: "data-is365-",
            useDefaultContentNameOrId: true,
        },
    };

    const configObject: AppInsightConfig = {
        connectionString: settings.connectionString,
        extensions: [clickPluginInstance],
        extensionConfig: {
            [clickPluginInstance.identifier]: clickPluginConfig,
        },
        disableFetchTracking: false,
        // eslint-disable-next-line spellcheck/spell-checker
        enableCorsCorrelation: true,
        correlationHeaderExcludedDomains: ["*login.microsoftonline.com", "*eu.amplitude.com", "*prismatic.io"],
    };

    const mergedConfig =
        extendConfig !== null ? mergeWith(configObject, extendConfig, arrayMergeCustomizer) : configObject;

    const appInsights = new ApplicationInsights({
        config: mergedConfig,
    });

    appInsights.loadAppInsights();

    appInsights.addTelemetryInitializer(getInstanceIdTelemetryInitializer());

    return appInsights;
}

// This TelemetryInitializer set up custom property "instanceId" with value, depends on a tenant.
function getInstanceIdTelemetryInitializer() {
    return (envelope: ITelemetryItem) => {
        if (envelope.data !== undefined) {
            envelope.data.instanceId = window.location.hostname?.split(".")[0] ?? "undefined";
        }
    };
}

export { createAppInsight };
