import { FormEvent, useMemo, useState } from "react";
import { useControllableValue } from "@fluentui/react-hooks";
import { noop } from "lodash";
import { useValidation } from "../../utils/validation";
import {
    Value,
    getDisplayValidationError,
    getValidationRules,
    fieldValueHasChanged,
} from "../SearchableDropdown.service";
import { useHasChanged } from "../../utils/hooks/useHasChanged";
import { SearchableDropdownBaseProps } from "../types/SearchableDropdownBaseProps";

/** The UseDropdown hook response. */
export type UseDropdownOutput = {
    searchText: string;
    setSearchText: (searchText: string) => void;
    selectedValue: string | null;
    setSelectedValue: (value: Value, event?: FormEvent<HTMLElement> | undefined) => void;
    validationError: string | undefined;
};

/**
 * A react hook to get searchable dropdown state.
 * @param {SearchableDropdownBaseProps} props The hook props.
 * @returns {UseDropdownOutput} The hook output.
 */
export function useDropdown({
    selectedKey,
    initialValue = "",
    validationRules = [],
    required = false,
    disabled = false,
    onChange,
    onValidationStateChange = noop,
    validationErrorMessageProvider = (error) => error.message,
}: SearchableDropdownBaseProps): UseDropdownOutput {
    const [searchText, setSearchText] = useState<string>("");
    const [selectedValue, setSelectedValue] = useControllableValue(selectedKey ?? "", initialValue, onChange);
    const hasChanged = useHasChanged(selectedValue, fieldValueHasChanged);

    const rules = useMemo(() => getValidationRules({ validationRules, required }), [validationRules, required]);

    const { validationErrors, validationErrorMessages } = useValidation({
        value: selectedValue,
        rules,
        onValidationStateChange,
        validationErrorMessageProvider,
    });

    const displayValidationError = getDisplayValidationError({
        validationErrors,
        disabled,
        hasChanged,
    });

    return {
        searchText,
        setSearchText,
        selectedValue: selectedValue ?? null,
        setSelectedValue,
        validationError: displayValidationError ? validationErrorMessages[0] : undefined,
    };
}
