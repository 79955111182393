import { getDisplayValueErrorMessage, getValueErrorMessage } from "../HyperlinkField/HyperlinkField.validation";
import { ValidationMessage, ValidationResult } from "../validation.service";
import { HyperLinkFieldValue } from "./Hyperlink.interface";

/**
 * Validates if any Hyperlink value is valid.
 * @param {HyperLinkFieldValue[]} values The values to validate.
 * @param {(messageCode: string, params?: Record<string, string>) => string} messageProvider Message formatter to format messages based on message code.
 * @param {boolean} required A value indicating whether it is required.
 * @param {boolean} displayValueRequired A value indicating whether displayValue is required.
 * @returns {ValidationResult} Result if the value is valid.
 */
function isAnyUrlValid(
    values: HyperLinkFieldValue[],
    messageProvider: (messageCode: string, params?: Record<string, string>) => string,
    required: boolean,
    displayValueRequired: boolean
): ValidationResult {
    const isAnyUrlValid = values.some((item) => {
        const displayValueErrorMessage = getDisplayValueErrorMessage(
            item.displayValue,
            displayValueRequired,
            messageProvider
        );

        const valueErrorMessage = getValueErrorMessage(item.value, displayValueRequired, required, messageProvider);

        return displayValueErrorMessage.length === 0 && valueErrorMessage.length === 0;
    });

    if (required && !isAnyUrlValid) {
        return {
            isValid: false,
            validationMessage: messageProvider(ValidationMessage.oneEntryNeedsToBeValid),
        };
    }

    return {
        isValid: true,
        validationMessage: "",
    };
}

/**
 * Validates if all Hyperlink values are valid.
 * @param {HyperLinkFieldValue[]} values The values to validate.
 * @returns {boolean} Result if the values are valid.
 */
export const isAllValuesValid = (values: HyperLinkFieldValue[]): boolean =>
    values.every(({ isDisplayValueValid, isValueValid }) => isDisplayValueValid === true && isValueValid === true);

export { isAnyUrlValid };
