/* eslint-disable spellcheck/spell-checker */

const systemColors = {
    word: "#295391",
    excel: "#207347",
    visio: "#35509D",
    powerpoint: "#d24625",
    pdf: "#d9534f",
    default: "#151E47",
    lochmara: "#0072C6",
    indigo: "#3F51B5",
    malibu: "#64B5F6",
    mineShaft: "#333333",
    doveGray: "#666666",
    pottersClay: "#8C6239",
    kabul: "#5D4037",
    thatch: "#BCAAA4",
    persianGreen: "#00A99D",
    tropicalRainForest: "#00695C",
    monteCarlo: "#80CBC4",
    fruitSalad: "#43A047",
    parsley: "#1B5E20",
    mossGreen: "#A5D6A7",
    california: "#FF9900",
    clementine: "#EF6C00",
    chardonnay: "#FFCC80",
    pomegranate: "#F44336",
    tallPoppy: "#C62828",
    sunglo: "#EE9999",
    silverChalice: "#999999",
    defaultVis: "#b3b3b3",
    lightIndigo: "#B3A5F2",
    lightMalibu: "#ADE8FF",
    darkMalibu: "#538BE5",
    betaBadge: "#FF8469",
    brandPrimary: "#151E47",
    brandSecondary: "#264A70",
};

type SystemColorKey = keyof typeof systemColors;

export type { SystemColorKey };
export { systemColors };
