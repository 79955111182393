import { Dispatch, useReducer } from "react";
import { State, Action, reducer, initialState } from "./reducer";

type UseQuickFilterReducer = [State, Dispatch<Action>];

/**
 * @returns {UseQuickFilterReducer} - result of `useReducer` hook
 */
export function useQuickFilterReducer(): UseQuickFilterReducer {
    const [state, dispatch] = useReducer(reducer, initialState);

    return [state, dispatch];
}
