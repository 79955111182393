// eslint-disable-next-line spellcheck/spell-checker
/**
 * Parses raw string containing Object and returns parsed Object.
 * @template T
 * @param {string | undefined} rawObject String that contains object to parse.
 * @param {(keyof T)[]} requiredProperties Properties of desired type that are required to consider parsed object valid.
 * @returns {T | undefined} Parsed Object or undefined if string cannot be parsed to an Object or parsed Object does not contain all required properties.
 */
function parseObject<T extends Record<string, unknown>>(
    rawObject: string | undefined,
    requiredProperties: (keyof T)[] = []
): T | undefined {
    if (rawObject === undefined || rawObject === "") {
        return undefined;
    }

    const parsedObject = JSON.parse(rawObject) as T;
    if (parsedObject === undefined || parsedObject === null || typeof parsedObject !== "object") {
        return undefined;
    }

    for (const property of requiredProperties) {
        const parsedProperty = parsedObject[property];
        if (parsedProperty === null || parsedProperty === undefined) {
            return undefined;
        }
    }

    return parsedObject;
}

export { parseObject };
