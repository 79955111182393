import { settings } from "./settings";

let initialized = false;
let legacyAppContainerEl: HTMLElement | null = null;
const legacyAppIframe = document.createElement("iframe");
const errorPageContainer = document.createElement("div");
const legacyAppContainerSelector = ".legacyAppIframeContainer";
const legacyIframeURL = new URL(getIframeOriginUrl());

const iframeUrlDefaultPath = "modellingcontent";
const iframeUrlQuery = "containerMode=true";

function getIframeOriginUrl() {
    if (settings.legacyIframeUrl?.startsWith("http")) {
        return settings.legacyIframeUrl;
    }

    return window.origin + settings.legacyIframeUrl;
}

/**
 * Gets the src to use on the iframe for the legacy app.
 * @param {boolean} appendPathToHashbang Whether to append the current path and query to the hashbang.
 * @returns {string} The legacy iframe src.
 */
function getIframeSrc(appendPathToHashbang = false): string {
    let src = `${settings.legacyIframeUrl}/${iframeUrlDefaultPath}?${iframeUrlQuery}#!`;
    if (appendPathToHashbang) {
        src += window.location.pathname + window.location.search;
    }

    return src;
}

/**
 * Gets the {@link HTMLIFrameElement} for the legacy app.
 * @returns {HTMLIFrameElement} The iframe element.
 */
function getIframe(): HTMLIFrameElement {
    return legacyAppIframe;
}

/**
 * Creates container for error page to be appended.
 * @returns {void}
 */
function createErrorPageContainer(): void {
    errorPageContainer.className = "errorPage";
    document?.querySelector(legacyAppContainerSelector)?.appendChild(errorPageContainer);
}

/**
 * Gets the {@link HTMLElement} for the error container and hides the iframe.
 * @returns {HTMLElement} The div element.
 */
function getErrorPageContainer(): HTMLElement {
    return errorPageContainer;
}

/**
 * Initializes the iframe. This should be called when the MFE is being bootstrapped.
 */
function initializeIframe(): void {
    const src = getIframeSrc(true);
    legacyAppIframe.src = src;
    legacyAppIframe.height = "100%";
    legacyAppIframe.width = "100%";
    legacyAppIframe.style.border = "0";

    legacyAppContainerEl = document.querySelector(legacyAppContainerSelector);
    createErrorPageContainer();
    if (legacyAppContainerEl !== null) {
        legacyAppContainerEl.appendChild(legacyAppIframe);
        initialized = true;
    }
}

/**
 * Provides information if the iframe has been successfully initialized via {@link initializeIframe}.
 * @returns {boolean} True if the iframe has been initialized successfully, false otherwise.
 */
function isInitialized(): boolean {
    return initialized;
}

/**
 * Shows the iframe by updating the display style. This should only be called once iframe has
 * been initialized via {@link initializeIframe}.
 */
function showIframeContainer(): void {
    if (legacyAppContainerEl === null) {
        throw new Error("Iframe can`t be shown, because we can`t find host element");
    }

    legacyAppContainerEl.style.display = "block";
    syncIframeUrl();
}

/**
 * Hides the iframe container by updating the display style.
 */
function hideIframeContainer(): void {
    if (legacyAppContainerEl !== null) {
        legacyAppContainerEl.style.display = "none";
    }
}

/**
 * Sets the url of the iframe.
 * @returns {void}
 */
function syncIframeUrl(): void {
    /**
     * There is a bug in angular js where it breaks the back navigation if
     * any of the following characters are encoded in the url. So we have to make sure
     * they are decoded before changing the url.
     *
     * In addition we have to decode the `'` as `encodeUriComponent` does not encode it,
     * while the `new URL` does.
     */
    const angularSearch: string = window.location.search
        .replace(/%40/gi, "@")
        .replace(/%3A/gi, ":")
        .replace(/%24/g, "$")
        .replace(/%2C/gi, ",")
        .replace(/%3B/gi, ";")
        .replace(/%27/gi, "'");

    // get hash location without hash itself, as hash is already present in getIframeSrc(false) and gets stripped by browser
    // So, that it gets lost and Visio URLs (like View) are not working properly
    const hashLocation = window.location.hash.replace("#!", "");

    const newPath = getIframeSrc(false) + window.location.pathname + hashLocation + angularSearch;
    // if (legacyAppIframe.contentWindow?.location.href === window.location.origin + newPath) {
    //     if (!newPath.includes("/views/")) {
    //         sendEventToIframe("reload");
    //     }
    // } else
    legacyAppIframe.contentWindow?.location.replace(newPath);
}

/**
 * Sends event to the iframe from web app.
 * @param {string} type Event type
 * @typedef T The choice data entry type.
 * @param {T} data Data that will be sent to legacy app
 * @returns {void}
 */
function sendEventToIframe<T>(type: string, data?: T): void {
    const messageEvent = { type, data };
    const serializedEvent = JSON.stringify(messageEvent);

    legacyAppIframe.contentWindow?.postMessage(serializedEvent, legacyIframeURL.origin);
}

export {
    initializeIframe,
    isInitialized,
    getIframe,
    showIframeContainer,
    hideIframeContainer,
    syncIframeUrl,
    getIframeSrc,
    legacyIframeURL,
    getErrorPageContainer,
    sendEventToIframe,
};
