import { MFENames } from "./mfeNames";

const HEADER_HEIGHT_CSS_VARIABLE = "--header-height";

/**
 * Resolves custom props for each MFE.
 * @param {string} name MFE name.
 * @returns {Record<string, string>} The MFE props.
 */
export function resolveMFEProps(name: string): Record<string, string> {
    if (
        name !== MFENames.Topbar &&
        name !== MFENames.Sidebar &&
        name !== MFENames.Views &&
        name !== MFENames.Dashboard &&
        name !== MFENames.ContentManagement &&
        name !== MFENames.Draw
    ) {
        return {};
    }

    const computedStyles = getComputedStyle(document.documentElement);

    const headerHeight = computedStyles.getPropertyValue(HEADER_HEIGHT_CSS_VARIABLE);

    if (headerHeight.length === 0) {
        throw new Error(`${HEADER_HEIGHT_CSS_VARIABLE} variable not found`);
    }

    return { headerHeight };
}
