import { Stack, IColumn, IContextualMenuItem, HoverCard, HoverCardType, IPlainCardProps } from "@fluentui/react";
import React, { SetStateAction, useCallback, useEffect, useState, useImperativeHandle } from "react";
import { Grid } from "../Grid";
import { CellOptions, EditableCell } from "./components";
import { getColumnHeaderContextMenuItems } from "./getColumnHeaderContextMenuItems";
import {
    ListViewProps,
    ListViewOptions,
    ListItem,
    ListViewConfiguration,
    ValueType,
    ListItemField,
    ListViewConfigurationColumn,
} from "./types";
import { toCellOptions } from "./listViewOptionsHelper";
import { useListViewStyles } from "./ListView.styles";
import { fetchDataCallback } from "./fetchDataCallback";
import { NavigationContext } from "./Navigation.context";
import { isEmpty } from "lodash";
import { ListViewRefProps } from "./types/ListViewRef";

const pageSize = 50;

function enableShimmerCondition(
    enableShimmer: ((items: (ListItem | null)[] | undefined) => boolean) | undefined,
    items: (ListItem | null)[] | undefined
): boolean | undefined {
    return enableShimmer !== undefined ? enableShimmer(items) : undefined;
}

/**
 * The list view component. This serves as the higher-level component wrapping an underlying {@link Grid},
 * to add additional functionality as in
 * - data loading
 * - data (view) configuration loading
 * - cell formatting options
 * @param {ListViewProps} param The list view component props.
 * @returns {React.FC<ListViewProps>} The listview react component.
 */
export const ListView: React.FC<ListViewProps> = React.forwardRef<ListViewRefProps, React.PropsWithRef<ListViewProps>>(
    (
        {
            viewDataRetriever: dataRetriever,
            viewConfigurationRetriever: getViewConfiguration,
            defaultViewConfiguration,
            options,
            navigateToUrl,
            selectionMode,
            setHaveColumnsUnsavedChanges,
            setViewDataCallback,
            dragAndDropEnabled,
            searchQuery,
            itemsFoundCallback: itemsFoundCallback,
            enableShimmer: enableShimmer,
            style,
            setSelectionChanged,
        }: ListViewProps,
        ref
    ) => {
        const styles = useListViewStyles();

        const [viewConfiguration, setViewConfiguration] = useState<ListViewConfiguration | undefined>(undefined);
        const [items, setItems] = useState<(ListItem | null)[] | undefined>(undefined);
        const [missingPage, setMissingPage] = useState<number | undefined>(undefined);

        const fetchData = useCallback(fetchDataCallback, []);

        const resetList = useCallback(() => {
            setItems(undefined);
        }, []);

        // this hook populates resetList function to the component's ref. This allows the parent component to call resetList.
        useImperativeHandle(
            ref,
            () => ({
                resetList,
            }),
            [resetList]
        );

        // this effect ensures that if there are changes for the getData callback, it re-fetches the items
        useEffect(() => {
            resetList();
        }, [dataRetriever, resetList]);

        // this hook loads data on first render (when items is undefined)
        useEffect(() => {
            if (items === undefined && viewConfiguration !== undefined && searchQuery === undefined) {
                fetchData(0, pageSize, viewConfiguration.columns, items, setItems, dataRetriever, searchQuery);
            }
        }, [fetchData, items, viewConfiguration, setItems, dataRetriever, searchQuery]);

        // this hook loads data on search query change
        useEffect(() => {
            const onSetItems = (items: SetStateAction<(ListItem | null)[] | undefined>) => {
                setItems(items);
                if (itemsFoundCallback !== undefined && items !== undefined) {
                    itemsFoundCallback(items.length);
                }
            };
            if (!isEmpty(searchQuery) && viewConfiguration !== undefined) {
                fetchData(0, pageSize, viewConfiguration.columns, items, onSetItems, dataRetriever, searchQuery);
            }
            setMissingPage(undefined);
        }, [searchQuery, itemsFoundCallback]); // eslint-disable-line react-hooks/exhaustive-deps

        //this effect calls the callback function when items are loaded
        useEffect(() => {
            if (items !== undefined && itemsFoundCallback !== undefined) {
                itemsFoundCallback(items.length);
            }
        }, [items, itemsFoundCallback]);

        // this hook loads data when Grid component needs additional page of data
        useEffect(() => {
            if (missingPage !== undefined && viewConfiguration !== undefined) {
                fetchData(
                    missingPage * pageSize,
                    pageSize,
                    viewConfiguration.columns,
                    items,
                    setItems,
                    dataRetriever,
                    searchQuery
                );
            }
            setMissingPage(undefined);
        }, [fetchData, missingPage, viewConfiguration, items, setItems, dataRetriever, searchQuery]);

        useEffect(() => {
            if (defaultViewConfiguration !== undefined) {
                setViewConfiguration(defaultViewConfiguration);
            } else if (getViewConfiguration !== undefined) {
                getViewConfiguration().then(setViewConfiguration);
            }
        }, [defaultViewConfiguration, getViewConfiguration]);

        const getTooltipText = (item: ListItemField, cellOptions: CellOptions): string => {
            const maxTextLength = 1000;
            switch (item.valueType) {
                case ValueType.Currency:
                    return item.value !== null && item.isoCurrencySymbol !== null
                        ? cellOptions.formatting.currency(
                              item.value,
                              item.isoCurrencySymbol,
                              item.currencySymbol,
                              item.decimalPlaces
                          )
                        : "";
                case ValueType.Number:
                    return item.value !== null && item.decimalPlaces !== null
                        ? cellOptions.formatting.number(item.value, item.decimalPlaces)
                        : "";
                case ValueType.Tags:
                    return item.tags.join(", ");
                case ValueType.Url:
                    return item.hyperlinks.map((l) => l.text ?? l.url).join("\n");
                case ValueType.DateTime:
                    return cellOptions.formatting.dateTime(item.text);
                default:
                    return item.text !== null
                        ? item.text.length > maxTextLength
                            ? `${item.text.slice(0, maxTextLength)}...`
                            : item.text
                        : "";
            }
        };

        function getProcessedColumns(
            viewConfiguration: ListViewConfiguration | undefined,
            options: ListViewOptions | undefined
        ): IColumn[] {
            if (viewConfiguration === undefined || viewConfiguration?.columns === undefined) {
                return [];
            }

            const cellOptions = toCellOptions(options);

            const onRenderPlainCard = (item: ListItemField): JSX.Element => {
                return <div style={styles.hoverCard}>{getTooltipText(item, cellOptions)}</div>;
            };

            return viewConfiguration.columns.map((column) => ({
                ...column,
                onRender(item: ListItem): JSX.Element | null {
                    const field = item.fields.find((f) => f.columnId === column.key);
                    if (field === undefined) {
                        return null;
                    }
                    const plainCardProps: IPlainCardProps = {
                        onRenderPlainCard,
                        renderData: field,
                    };
                    const cellContent =
                        column.onRender !== undefined ? (
                            column.onRender(item)
                        ) : (
                            <EditableCell field={field} options={cellOptions} inlineEditing />
                        );
                    return column.disableTooltip === true ? (
                        cellContent
                    ) : (
                        <HoverCard type={HoverCardType.plain} plainCardProps={plainCardProps} instantOpenOnClick={true}>
                            {cellContent}
                        </HoverCard>
                    );
                },
            }));
        }

        const getColumnHeaderContextMenuItemsCallback = useCallback(
            (column: ListViewConfigurationColumn): IContextualMenuItem[] | undefined => {
                if (viewConfiguration === undefined || column.disableSorting === true) {
                    return undefined;
                }

                return getColumnHeaderContextMenuItems(
                    column,
                    viewConfiguration.columns,
                    function onColumnsChange(nextColumns) {
                        setViewConfiguration({
                            ...viewConfiguration,
                            columns: nextColumns,
                        });
                        resetList();
                    }
                );
            },
            [viewConfiguration, resetList]
        );

        return (
            <NavigationContext.Provider value={{ navigateToUrl }}>
                <Stack styles={styles.container} style={style}>
                    <Grid<ListItem>
                        items={items}
                        columns={getProcessedColumns(viewConfiguration, options)}
                        pageSize={pageSize}
                        onPageChange={setMissingPage}
                        getColumnHeaderContextMenuItems={getColumnHeaderContextMenuItemsCallback}
                        selectionMode={selectionMode}
                        setHaveColumnsUnsavedChanges={setHaveColumnsUnsavedChanges}
                        setViewConfigurationCallback={(cols: IColumn[]) => {
                            setViewConfiguration({
                                ...(viewConfiguration as ListViewConfiguration),
                                columns: cols,
                            });
                            if (setViewDataCallback !== undefined) {
                                setViewDataCallback(cols);
                            }
                        }}
                        dragAndDropEnabled={dragAndDropEnabled}
                        enableShimmer={enableShimmerCondition(enableShimmer, items)}
                        onSelectionChanged={setSelectionChanged}
                    />
                </Stack>
            </NavigationContext.Provider>
        );
    }
);
