export enum MFENames {
    Sidebar = "@iserver365/sidebar",
    Topbar = "@iserver365/topbar",
    LegacyApp = "@iserver365/legacy-app",
    Views = "@iserver365/views",
    Dashboard = "@iserver365/dashboard",
    Dashboards = "@iserver365/dashboards",
    BusinessConsole = "@iserver365/business-console",
    Navigator = "@iserver365/navigator",
    NoAccess = "@iserver365/no-access",
    Share = "@iserver365/share",
    SomethingWentWrong = "@iserver365/something-went-wrong",
    Toaster = "@iserver365/toaster",
    ContentManagement = "@iserver365/content-management",
    Draw = "@iserver365/draw",
}
