import { useCallback, useEffect, useState } from "react";
import { ToastMessage } from "../types/ToastMessage";

interface ToastMessageInternal extends ToastMessage {
    time: number;
}

/**
 * Providing a unique message will return a list of current active messages
 * that will be removed after the timeout.
 *
 * Additionally, outputs a function to remove a message from the active list manually.
 * @param {ToastMessage} message Message to display.
 * @param {number} timeout Timeout for when to automatically hide the message.
 * @param {number} interval Interval that checks for time when message was added and removes it after timeout is run.
 * @returns {{ messages: Array<ToastMessage>, removeToast: (message) => void }} List of active messages and a function to remove the message.
 */
export function useToastMessages(
    message: ToastMessage | undefined,
    timeout: number,
    interval: number
): {
    messages: Array<ToastMessage>;
    removeToast: (message: ToastMessage) => void;
} {
    const [messages, setMessages] = useState<Array<ToastMessageInternal>>([]);

    const removeToast = useCallback(
        (message?: ToastMessage | undefined) => {
            setMessages((prevState: Array<ToastMessageInternal>) => {
                const hideMessageTime = Date.now() - timeout;
                return prevState.filter((item) => {
                    if (message !== undefined) {
                        return item !== message;
                    }

                    return item.time > hideMessageTime;
                });
            });
        },
        [setMessages, timeout]
    );

    const addToast = useCallback(
        // Remove last if more than 5
        (message: ToastMessage) => {
            setMessages((prevState: Array<ToastMessageInternal>) => {
                return [
                    ...prevState,
                    {
                        ...message,
                        time: Date.now(),
                    },
                ];
            });
        },
        [setMessages]
    );

    useEffect(() => {
        let intervalId: ReturnType<typeof setInterval>;

        if (message !== undefined) {
            addToast(message);
            intervalId = setInterval(() => {
                removeToast();
                // run timeout every second and check whether message is removable
            }, interval);
        }

        return () => {
            if (intervalId !== undefined) {
                clearInterval(intervalId);
            }
        };
    }, [addToast, removeToast, message, interval]);

    return {
        messages,
        removeToast,
    };
}
