import { startsWith } from "lodash";

/**
 * Returns redirect url for `draw` when the feature is disabled.
 * @param {URL} url Source url.
 * @returns {string | undefined} Redirect url.
 */
export function getDisabledDrawRedirectUrl(url: URL): string | undefined {
    if (startsWith(url.pathname, "/draw")) {
        return "/";
    }

    return undefined;
}
