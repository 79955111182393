import { IDropdownStyles, ISearchBoxStyles, useTheme } from "@fluentui/react";

export interface SearchableDropdownStyles {
    dropdownStyles: Partial<IDropdownStyles>;
    searchBoxStyles?: Partial<ISearchBoxStyles>;
}

/**
 * Returns styles for the Searchable Dropdown component.
 * @returns {string} {@link SearchableDropdown: SearchableDropdownStyles} component styles.
 */
export function useSearchableDropdownStyles(): SearchableDropdownStyles {
    const { spacing, palette, fonts } = useTheme();

    return {
        dropdownStyles: {
            dropdownItemHeader: {
                color: palette.themeTertiary,
                fontSize: fonts.small.fontSize,
            },
        },
        searchBoxStyles: {
            root: {
                marginTop: spacing.s2,
            },
        },
    };
}
