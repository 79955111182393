import { noop } from "lodash";
import React, { useState } from "react";
import classNames from "classnames";
import { useDisabledContext } from "../../context";
import { Option } from "../Option";
import { CurrentStyle, TopLevelOptionProps } from "../types";
import { ColorPickerOptionModal } from "./ColorPickerOption.modal";

/**
 * Adapted from: https://github.com/jpuri/react-draft-wysiwyg/blob/705f518fa516f2839394d706e8b11bfd99734abd/src/controls/ColorPicker/Component/index.js
 * @param {TopLevelOptionProps} props {@link ColorPickerOption} props.
 * @returns {JSX.Element} The react element.
 */
export function ColorPickerOption({
    currentState,
    config,
    expanded = false,
    onExpandEvent = noop,
    translations,
    onChange,
}: TopLevelOptionProps): JSX.Element {
    const [currentStyle, setCurrentStyle] = useState<keyof typeof CurrentStyle>("color");
    const disabled = useDisabledContext();

    const { icon, className, title } = config;
    let content = null;
    if (expanded) {
        content = (
            <ColorPickerOptionModal
                currentState={currentState}
                currentStyle={currentStyle}
                setCurrentStyle={setCurrentStyle}
                onChange={onChange}
                config={config}
                translations={translations}
            />
        );
    }

    return (
        <div
            className="rdw-colorpicker-wrapper"
            aria-haspopup="true"
            aria-expanded={expanded}
            aria-label="Color Picker"
            title={title ?? translations["components.controls.colorpicker.colorpicker"]}
        >
            <Option
                title="Color Picker"
                icon={icon}
                active={false}
                disabled={disabled}
                onClick={onExpandEvent}
                className={classNames(className)}
            />
            {content}
        </div>
    );
}
