import { Activity, ActivityFn } from "single-spa";
import { MFENames } from "./mfeNames";

const workspacesPath = "/workspaces";
const mergeRequestsPath = "/merge-requests";

/**
 * Resolves activeWhen for each MFE.
 * @param {string} name MFE name.
 * @param {Activity} defaultActiveWhen default activeWhen for MFE.
 * @returns {ActivityFn} The MFE activeWhen.
 */
export function resolveMFEActiveWhen(name: string, defaultActiveWhen: Activity): ActivityFn {
    switch (name) {
        case MFENames.Sidebar:
            return (location) => location.pathname !== workspacesPath;
        case MFENames.ContentManagement:
            return (location) =>
                location.pathname.startsWith(workspacesPath) || location.pathname.startsWith(mergeRequestsPath);
        default:
            return (defaultActiveWhen as ActivityFn[])[0];
    }
}
