export enum ValueType {
    DateTime = "DateTime",
    DateTimeRelative = "DateTimeRelative",
    Currency = "Currency",
    Favourite = "Favourite",
    Tags = "Tags",
    Protected = "Protected",
    Text = "Text",
    State = "State",
    Url = "Url",
    Person = "Person",
    Number = "Number",
    ApprovalStatus = "ApprovalStatus",
    Custom = "Custom",
}
