import { MouseEvent } from "react";
import { isEmpty } from "lodash";

import { RequiredBoolean } from "../utils/validation/rules";
import { ValidationRule, ValidationError } from "../utils/validation";

export type Value = boolean | undefined;

export type OnChangeHandler = (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>, value?: boolean) => void;

/**
 * @param {object} params Parameter object
 * @param {ValidationError[]} params.validationErrors List of current validation errors for the TrueFalse component
 * @param {boolean} params.readOnly Whether or not the TrueFalse component is currently read only
 * @returns {boolean} Whether or not to display a validation error
 */
export function getDisplayValidationError({
    validationErrors,
    readOnly,
}: {
    validationErrors: ValidationError[];
    readOnly: boolean;
}): boolean {
    return !readOnly && !isEmpty(validationErrors);
}

/**
 * @param {ValidationRule[]} validationRules Validation rules to apply to input
 * @param {boolean} isRequired Whether the input is required
 * @returns {ValidationRule[]} Array of validation rules to apply
 */
export function getValidationRules(
    validationRules: ValidationRule<Value>[],
    isRequired: boolean
): ValidationRule<Value>[] {
    const builtinRules = [];
    if (isRequired) {
        builtinRules.push(new RequiredBoolean());
    }
    return [...builtinRules, ...validationRules];
}
