import { CellOptions, CellFormatterType, CellNumberFormatterType, CellCurrencyFormatterType } from "./components";
import { ListViewOptions, FormatterType, NumberFormatterType, CurrencyFormatterType } from "./types";

const defaultFormatter: FormatterType = (s) => s ?? undefined;
const defaultNumberFormatter: NumberFormatterType = (s) => s?.toString() ?? undefined;
const defaultCurrencyFormatter: CurrencyFormatterType = (s) => s?.toString() ?? undefined;

function normalize(fn: FormatterType): CellFormatterType {
    return (text) => fn(text) ?? "";
}

function normalizeNumber(fn: NumberFormatterType): CellNumberFormatterType {
    return (v, d) => fn(v, d) ?? "";
}

function normalizeCurrency(fn: CurrencyFormatterType): CellCurrencyFormatterType {
    return (v, isoSymbol, symbol, decimalPlaces) => fn(v, isoSymbol, symbol, decimalPlaces) ?? "";
}

/**
 * Creates a {@link CellOptions} from the {@link options} passed into the list view.
 *
 * This will also add default values for missing entries in {@link options}.
 * @param {ListViewOptions} options The list view options.
 * @returns {CellOptions} The cell options.
 */
function toCellOptions(options: ListViewOptions | undefined): CellOptions {
    return {
        formatting: {
            dateTime: normalize(options?.formatting?.dateTime ?? defaultFormatter),
            number: normalizeNumber(options?.formatting?.number ?? defaultNumberFormatter),
            currency: normalizeCurrency(options?.formatting?.currency ?? defaultCurrencyFormatter),
        },
    };
}

export { toCellOptions };
