import { IComboBoxOption } from "@fluentui/react";
import { OperatorType, OperatorFilter } from "../useQuickFilterReducer";
import { OperatorValueType } from "../QuickFilter.interfaces";

/**
 * Function which form operator options
 * @param {OperatorFilter} operator type of operator
 * @returns {IComboBoxOption[]} return operator options
 */
export function getOperatorOptions(operator: OperatorFilter): IComboBoxOption[] {
    const HAS_A_VALUE = "Has a Value";
    const HAS_NO_VALUE = "Has no Value";
    const EQUALS = "Equals";

    if (operator.type === OperatorType.Numerical) {
        return [
            {
                key: OperatorValueType.GreaterThan,
                text: ">",
            },
            {
                key: OperatorValueType.GreaterThanOrEqualTo,
                text: ">=",
            },
            {
                key: OperatorValueType.LessThan,
                text: "<",
            },
            {
                key: OperatorValueType.LessThanOrEqualTo,
                text: "<=",
            },
            {
                key: OperatorValueType.Equal,
                text: EQUALS,
            },
            {
                key: OperatorValueType.NotNull,
                text: HAS_A_VALUE,
            },
            {
                key: OperatorValueType.Null,
                text: HAS_NO_VALUE,
            },
        ];
    }

    if (operator.type === OperatorType.Currency) {
        return [
            {
                key: OperatorValueType.GreaterThan,
                text: ">",
            },
            {
                key: OperatorValueType.GreaterThanOrEqualTo,
                text: ">=",
            },
            {
                key: OperatorValueType.LessThan,
                text: "<",
            },
            {
                key: OperatorValueType.LessThanOrEqualTo,
                text: "<=",
            },
            {
                key: OperatorValueType.Equal,
                text: EQUALS,
            },
            {
                key: OperatorValueType.NotNull,
                text: HAS_A_VALUE,
            },
            {
                key: OperatorValueType.Null,
                text: HAS_NO_VALUE,
            },
        ];
    }

    if (operator.type === OperatorType.Text) {
        return [
            {
                key: OperatorValueType.Contains,
                text: "Contains",
            },
            {
                key: OperatorValueType.EndsWith,
                text: "Ends with",
            },
            {
                key: OperatorValueType.Equal,
                text: EQUALS,
            },
            {
                key: OperatorValueType.NotNull,
                text: HAS_A_VALUE,
            },
            {
                key: OperatorValueType.StartsWith,
                text: "Starts with",
            },
            {
                key: OperatorValueType.Null,
                text: HAS_NO_VALUE,
            },
        ];
    }
    if (operator.type === OperatorType.Boolean) {
        return [
            {
                key: OperatorValueType.IsFalse,
                text: "Is False",
            },
            {
                key: OperatorValueType.IsTrue,
                text: "Is True",
            },
        ];
    }

    if (operator.type === OperatorType.Choice) {
        return [
            {
                key: OperatorValueType.NotNull,
                text: HAS_A_VALUE,
            },
            {
                key: OperatorValueType.Contains,
                text: "Contains",
            },
            {
                key: OperatorValueType.Null,
                text: HAS_NO_VALUE,
            },
        ];
    }

    if (operator.type === OperatorType.PersonOrGroup) {
        return [
            {
                key: OperatorValueType.NotNull,
                text: HAS_A_VALUE,
            },
            {
                key: OperatorValueType.Null,
                text: HAS_NO_VALUE,
            },
            {
                key: OperatorValueType.Equal,
                text: EQUALS,
            },
        ];
    }

    if (operator.type === OperatorType.DateTime) {
        return [
            {
                key: OperatorValueType.After,
                text: "After",
            },
            {
                key: OperatorValueType.AfterOrIn,
                text: "After or In",
            },
            {
                key: OperatorValueType.Before,
                text: "Before",
            },
            {
                key: OperatorValueType.BeforeOrIn,
                text: "Before or In",
            },
            {
                key: OperatorValueType.Equal,
                text: EQUALS,
            },
            {
                key: OperatorValueType.NotNull,
                text: HAS_A_VALUE,
            },
            {
                key: OperatorValueType.Null,
                text: HAS_NO_VALUE,
            },
        ];
    }

    if (operator.type === OperatorType.UniqueIdentifier) {
        return [
            {
                key: OperatorValueType.Equal,
                text: "Equals",
            },
            {
                key: OperatorValueType.NotNull,
                text: HAS_A_VALUE,
            },
            {
                key: OperatorValueType.Null,
                text: HAS_NO_VALUE,
            },
        ];
    }

    return [];
}
