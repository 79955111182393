import { TagItemDefinition } from "../Tags";
import { IChoiceDataEntry } from "../Choice/IChoiceDataEntry";
import {
    calculateSelectedChoices,
    findSelectedChoicesNames,
    getDisplayValueTags,
    updateSelectedChoicesValue,
} from "./ChoicePicker.utils";
import React, { useRef } from "react";

type UseChoicePickerProps = {
    choices: IChoiceDataEntry[];
    selectedChoices: string[];
    allowMultipleValues: boolean;
    dropdownWidthSameAsInput?: boolean;
    onChange: (selectedKeys: string[]) => void;
};

type UseChoicePickerOutput = {
    /** The width of the choices dropdown. */
    calloutWidth: number | undefined;
    displayValues: {
        tags: TagItemDefinition[];
        text: string[];
    };
    choicesState: IChoiceDataEntry[];
    /** The TextField DOM element reference. */
    textInputRef: React.RefObject<HTMLInputElement>;
    handleChoiceChange: (leafChoicesToUpdate: IChoiceDataEntry[], isSelected: boolean) => void;
};

/**
 * Hook for handling choice picker logic.
 * @param {UseChoicePickerProps} props - The hook props.
 * @returns {UseChoicePickerOutput} The display values of the selected choices.
 */
export const useChoicePicker = ({
    choices,
    selectedChoices,
    allowMultipleValues,
    dropdownWidthSameAsInput = false,
    onChange,
}: UseChoicePickerProps): UseChoicePickerOutput => {
    const textInputRef = useRef<HTMLInputElement>(null);
    const calloutWidth = dropdownWidthSameAsInput ? textInputRef.current?.offsetWidth ?? 250 : undefined;

    const choicesState = updateSelectedChoicesValue(choices, selectedChoices, true);

    const displayValuesSimple = findSelectedChoicesNames(choicesState);

    const displayValuesTags = getDisplayValueTags(displayValuesSimple);

    const handleChoiceChange = (leafChoicesToUpdate: IChoiceDataEntry[], isSelected: boolean) => {
        const currentSelectedChoices = calculateSelectedChoices(
            selectedChoices,
            leafChoicesToUpdate,
            isSelected,
            allowMultipleValues
        );

        onChange(currentSelectedChoices);
    };

    return {
        calloutWidth,
        displayValues: {
            text: displayValuesSimple,
            tags: displayValuesTags,
        },
        choicesState,
        textInputRef,
        handleChoiceChange,
    };
};

export type { UseChoicePickerProps };
