import { isNil } from "lodash";
import { ValidationError, ValidationRule, ValidationRuleProps } from "../../";

type Value = string | undefined;

export interface InvalidCharacterErrorProps {
    /**
        InvalidCharacters.
     */
    invalidChars: string[];
}

/**
 * InvalidCharacterError validation error class
 */
export class InvalidCharacterError implements ValidationError {
    constructor(props: InvalidCharacterErrorProps) {
        this.name = "InvalidCharacterError";
        this.invalidChars = props.invalidChars;
        this.message = `Can not contain ${props.invalidChars.join(", ")}.`;
    }
    name: string;
    invalidChars: string[];
    message: string;
}

export interface InvalidCharactersProps extends ValidationRuleProps<Value> {
    /**
        Array of invalid characters.
     */
    invalidChars: string[];
}

/**
 * InvalidCharacters validation rule class
 */
export class InvalidCharacters extends ValidationRule<Value> {
    static Error = InvalidCharacterError;
    constructor(props: InvalidCharactersProps) {
        super(props);
        this.invalidChars = props.invalidChars;
    }

    invalidChars: string[];

    validate(value: Value): InvalidCharacterError | null {
        if (isNil(value) || value === "") {
            return null;
        }

        if (this.invalidChars.some((char) => value.includes(char))) {
            return new InvalidCharacterError({ invalidChars: this.invalidChars });
        }
        return null;
    }
}
