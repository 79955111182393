import React from "react";
import { registerIcons } from "@fluentui/react/lib/Styling";
import { OrbusIcon } from "./icons/OrbusIcon";
import { SolutionsHubIcon } from "./icons/SolutionsHubIcon";
import { ContactSupportIcon } from "./icons/ContactSupportIcon";
import { IServer365RoadmapIcon } from "./icons/IServer365RoadmapIcon";
import { HiveELearningIcon } from "./icons/HiveELeearningIcon";
import { KnowledgeBaseIcon } from "./icons/KnowledgeBaseIcon";
import { EndPointSolidIcon } from "@fluentui/react-icons-mdl2";
import { OrbusInfinityIcon } from "./icons/OrbusInfinityIcon";
import { CommunityIcon } from "./icons/CommunityIcon";
import { LatestUpdatesIcon } from "./icons/LatestUpdatesIcon";
import { FlowIcon } from "./icons/FlowIcon";

/**
 * Register the extended fabric MDL2 icons with fluent ui.
 */
function registerAllIcons(): void {
    registerIcons({
        fontFace: {
            fontFamily: "FabricMDL2Icons",
        },
        // Avoid registering the icons if they are already available as part of Fluentui:
        // https://developer.microsoft.com/en-us/fluentui#/styles/web/icons
        icons: {
            ContentFeed: "\uE428",
        },
    });

    registerIcons({
        fontFace: {
            fontFamily: "OA-Icons",
        },
        icons: {
            MatrixView: "\ue600",
            HierarchyView: "\ue601",
            ListView: "\ue602",
            ListViewVisualization: "\ue621",
            ListViewCards: "\ue604",
            ListViewTiles: "\ue620",
            LandscapeView: "\ue92d",
        },
    });

    registerIcons({
        icons: {
            SharepointBrand: (
                <img
                    src="https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/svg/sharepoint_32x1.svg"
                    width="32"
                    height="32"
                    alt="Sharepoint product icon"
                />
            ),
            VisioBrand: (
                <img
                    src="https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/svg/visio_32x1.svg"
                    width="32"
                    height="32"
                    alt="Visio product icon"
                />
            ),
            Orbus: <OrbusIcon />,
            OrbusInfinity: <OrbusInfinityIcon />,
            SolutionsHub: <SolutionsHubIcon />,
            EndPointSolid: <EndPointSolidIcon />,
            ContactSupportIcon: <ContactSupportIcon />,
            IServer365RoadmapIcon: <IServer365RoadmapIcon />,
            HiveIcon: <HiveELearningIcon />,
            KnowledgeBaseIcon: <KnowledgeBaseIcon />,
            CommunityIcon: <CommunityIcon />,
            LatestUpdatesIcon: <LatestUpdatesIcon />,
            FlowIcon: <FlowIcon />,
        },
    });
}

export { registerAllIcons };
