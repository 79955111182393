import { isNil, noop } from "lodash";
import { useEffect, useMemo } from "react";

import { UseValidationProps, UseValidationResult, ValidationError } from "./types";

/**
 * @param {UseValidationProps} props The hook props.
 * @returns {UseValidationResult} Results from useValidation.
 */
export function useValidation<Value>({
    rules,
    value,
    onValidationStateChange = noop,
    validationErrorMessageProvider = (error) => error.message,
}: UseValidationProps<Value>): UseValidationResult {
    const {
        validationErrors,
        stringifiedValidationErrors,
    }: {
        validationErrors: ValidationError[];
        stringifiedValidationErrors: string;
    } = useMemo(() => {
        const validationErrors = rules.reduce((errors: ValidationError[], rule) => {
            const result = rule._validate(value);
            if (!isNil(result)) {
                return [...errors, result];
            }
            return errors;
        }, []);
        const stringifiedValidationErrors = JSON.stringify(validationErrors);
        return {
            validationErrors,
            stringifiedValidationErrors,
        };
    }, [rules, value]);

    useEffect(
        () => onValidationStateChange(validationErrors),
        /* NOTE: Disabling exhaustive-deps rule, because `stringifiedValidationErrors` represents the `validationErrors` dependency. The validation rule just doesn't realize that. */
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        [onValidationStateChange, stringifiedValidationErrors]
    );
    const validationErrorMessages = validationErrors.map(validationErrorMessageProvider);

    return {
        validationErrors,
        validationErrorMessages,
    };
}
