import { gql } from "@apollo/client";
import { appInsights } from "iserver365-logging-utility";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { mapSettings } from "../settings.utils";
import { SettingsData, SettingsDataResponse } from "../types";
import { apolloClient } from "./apolloClient";

export const GET_SETTINGS = gql`
    query SettingsData {
        settingsData {
            knowledgeBaseUrl
            version
            sharePointRootSiteUrl
            solutionsHubUrl
            visioAddInDownloadLink
            features {
                name
                value
            }
            licenseDetails {
                sharePointPermissionsEnabled
            }
            salesforceAccountId
            instanceId
            businessModeEnabled @client
            sessionExpirationMinutes
        }
    }
`;

/**
 * Gets application settings.
 * @returns {Promise<SettingsDataResponse>} The application settings.
 */
export async function getSettings(): Promise<SettingsData | undefined> {
    try {
        const result = await apolloClient.query<{ settingsData: SettingsDataResponse }>({
            query: GET_SETTINGS,
            fetchPolicy: "cache-first",
        });

        return mapSettings(result.data?.settingsData);
    } catch (err) {
        appInsights.trackException({
            exception: err as Error,
            severityLevel: SeverityLevel.Error,
        });

        return undefined;
    }
}
