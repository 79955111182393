import { SeverityLevel } from "@microsoft/applicationinsights-common";
import { appInsights } from "iserver365-logging-utility";

/**
 * Tracks an exception in AppInsights.
 * @param {Error} error The exception.
 */
function trackException(error: Error): void {
    appInsights.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
    });
}

export { trackException };
