import { Icon } from "@fluentui/react";
import React from "react";
import { FavouriteListItemField } from "../types";
import { CellProps } from "./CellProps";

/**
 * Cell for the list view which outputs the favourite icon.
 * @param {CellProps<FavouriteListItemField>} param The cell props.
 * @returns {React.FC<CellProps<FavouriteListItemField>>} The react component.
 */
export const FavouriteCell: React.FC<CellProps<FavouriteListItemField>> = ({
    field,
    onValueChange,
}: CellProps<FavouriteListItemField>) => {
    return (
        <Icon
            iconName={field.text === "Yes" ? "FavoriteStarFill" : "FavoriteStar"}
            onClick={() => onValueChange({ ...field, text: field.text === "Yes" ? "No" : "Yes" })}
        />
    );
};
