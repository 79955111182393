import { isNil } from "lodash";
import { ValidationError, ValidationRule, ValidationRuleProps } from "../..";

type Value = string | undefined;
export interface MinWordsLengthErrorProps {
    /**
        Minimum words length required for the input.
     */
    minWordsLength: number;
    /**
        Actual words length received for the input.
     */
    actualWordsLength: number;
}

export interface MinWordsLengthProps extends ValidationRuleProps<Value> {
    /**
        Minimum length required for the input.
     */
    minWordsLength: number;
}

/**
 * MinStringWordsLengthError validation error class
 */
export class MinWordsLengthError implements ValidationError {
    constructor(props: MinWordsLengthErrorProps) {
        this.name = "MinWordsLengthError";
        this.minWordsLength = props.minWordsLength;
        this.actualWordsLength = props.actualWordsLength;
        this.message = `Field must contain at least ${props.minWordsLength} words.`;
    }
    name: string;
    minWordsLength: number;
    actualWordsLength: number;
    message: string;
}

/**
 * MinWordsLength validation rule class
 */
export class MinWordsLength extends ValidationRule<Value> {
    static Error = MinWordsLengthError;
    constructor(props: MinWordsLengthProps) {
        super(props);
        this.minWordsLength = props.minWordsLength;
    }

    minWordsLength: number;

    validate(value: Value): MinWordsLengthError | null {
        if (isNil(value) || value === "") {
            return null;
        }

        const wordCount = value.trim().split(/\s+/).length;
        if (wordCount < this.minWordsLength) {
            return new MinWordsLengthError({ minWordsLength: this.minWordsLength, actualWordsLength: wordCount });
        }

        return null;
    }
}
