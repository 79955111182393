import { isNil } from "lodash";

export interface ComponentValidationProps<Value> {
    /**
        Rules to apply.
     */
    validationRules?: ValidationRule<Value>[];
    /**
        Runs every time the validation state changes.
        NOTE: this may be called again even though the count and types of errors are the same, but the error properties may have changed.
     */
    onValidationStateChange?: ValidationStateChangeHandler;
    /**
        Called for every validation error.  Use this to provide localized error messages for the component to display.
     */
    validationErrorMessageProvider?: ValidationErrorMessageProvider;
}

export interface UseValidationProps<Value> {
    rules: ValidationRule<Value>[];
    value: Value;
    onValidationStateChange: ValidationStateChangeHandler | undefined;
    validationErrorMessageProvider: ValidationErrorMessageProvider | undefined;
}

export type ValidationStateChangeHandler = (props: ValidationError[]) => void;
export type ValidationErrorMessageProvider = (props: ValidationError) => string; // We may actually want ValidationErrorMessageProvider to handle all of the validation errors at once, so we can present a message indicating how many, and which rules, are failing.

export interface UseValidationResult {
    validationErrors: ValidationError[];
    validationErrorMessages: string[];
}

export type ValidationError = Error;
export type Escaper<Value> = ({ value }: { value: Value }) => Value;

export interface ValidationRuleProps<Value> {
    /**
        `escapeValue` escapes the value before it is passed to `validate`.
     */
    escapeValue?: Escaper<Value> | Escaper<Value>[];
}

/**
 * ValidationRule class
 */
export abstract class ValidationRule<Value> {
    abstract validate(value: Value): ValidationError | null;
    constructor(props: ValidationRuleProps<Value>) {
        this.escapeValue = props.escapeValue;
    }
    _validate(value: Value): ValidationError | null {
        if (!isNil(this.escapeValue)) {
            let escapedValue;
            if (Array.isArray(this.escapeValue)) {
                escapedValue = this.escapeValue.reduce((value, escaper) => escaper({ value }), value);
            } else {
                escapedValue = this.escapeValue({ value });
            }
            return this.validate(escapedValue);
        }
        return this.validate(value);
    }
    escapeValue?: Escaper<Value> | Escaper<Value>[];
}
