import React, { CSSProperties } from "react";
import { useStyles } from "./Share.styles";
import { ShareType } from "./shareType";
import { ActionButton, Callout, DirectionalHint, IconButton, Stack } from "@fluentui/react";
import { useBoolean, useId } from "@fluentui/react-hooks";

/**
 * {@link Share} Component Props.
 */
export interface ShareProps {
    url: string;
    labels: {
        share: string;
        copyCode: string;
        copyLink: string;
    };
    style?: CSSProperties;
    onShare: (shareType: ShareType) => void;
}

/**
 * Renders the component for sharing action.
 * @param {ShareProps} props The component props.
 * @returns {JSX.Element} The React component.
 */
export function Share(props: ShareProps): JSX.Element {
    const styles = useStyles();
    const shareButtonId = useId("show-share-callout-button");
    const { url, labels, style } = props;
    const [isCalloutVisible, { toggle: toggleCallout, setTrue: showCallout }] = useBoolean(false);

    const onShare = async (content: string, shareType: ShareType) => {
        await navigator.clipboard.writeText(content);
        toggleCallout();
        props.onShare(shareType);
    };

    const generateIFrame = (src: string) => {
        return `<iframe src="${src}" height="400px" width="600px" scrolling="no" frameborder="1" allowfullscreen></iframe>`;
    };

    return (
        <>
            <IconButton
                id={shareButtonId}
                iconProps={{ iconName: "Share" }}
                title={labels.share}
                style={{ ...styles.share, ...style }}
                onClick={showCallout}
            />
            {isCalloutVisible && (
                <Callout
                    role="dialog"
                    target={`#${shareButtonId}`}
                    onDismiss={toggleCallout}
                    directionalHint={DirectionalHint.bottomRightEdge}
                >
                    <Stack tokens={styles.tokens} styles={styles.content}>
                        <Stack tokens={styles.tokens} horizontalAlign={"space-between"}>
                            <ActionButton
                                styles={styles.button}
                                iconProps={{ iconName: "Embed" }}
                                text={labels.copyCode}
                                onClick={() => {
                                    onShare(generateIFrame(url), ShareType.EmbeddedCode);
                                }}
                            />
                            <ActionButton
                                styles={styles.button}
                                iconProps={{ iconName: "Link" }}
                                text={labels.copyLink}
                                onClick={() => onShare(url, ShareType.ShareLink)}
                            />
                        </Stack>
                    </Stack>
                </Callout>
            )}
        </>
    );
}
