import React, { useRef } from "react";
import { ActionButton, IButtonProps, Stack, Text } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";

import { useStyles } from "./ShowMoreText.styles";
import { useTruncatedText } from "./useTruncatedText";

/** ShowMoreText component props. */
export interface ShowMoreTextProps {
    /** The text value to be shown when component renders. */
    value: string;
    /** The text for 'Show more' button. */
    showMoreButtonText?: string;
    /** The text for 'Show less' button. */
    showLessButtonText?: string;
}

/**
 * Shows text
 * @param {ShowMoreTextProps} props The component props.
 * @returns {JSX.Element} the react element.
 */
export function ShowMoreText({
    value,
    showMoreButtonText = "Show more",
    showLessButtonText = "Hide",
}: ShowMoreTextProps): JSX.Element {
    const displayTextRef = useRef<HTMLDivElement>(null);
    const isShowMoreButtonDisplayed = useTruncatedText(displayTextRef);
    const [isAllTextShown, { toggle: toggleShowMore }] = useBoolean(false);
    const styles = useStyles({ isAllTextShown });

    const showButtonProps: IButtonProps = {
        styles: styles.showButton,
        text: isAllTextShown ? showLessButtonText : showMoreButtonText,
        onClick: toggleShowMore,
    };

    return (
        <Stack styles={styles.stack} horizontal={!isAllTextShown}>
            <Text styles={styles.text} root={{ ref: displayTextRef }} nowrap={!isAllTextShown} block>
                {value}
            </Text>

            {isShowMoreButtonDisplayed && <ActionButton {...showButtonProps} />}
        </Stack>
    );
}
