import { IButtonStyles, IDropdownStyles, IStackStyles, ITextFieldStyles, useTheme } from "@fluentui/react";

export interface QuickFilterStyle {
    container: Partial<IStackStyles>;

    actionBarTop: Partial<IStackStyles>;
    actionBarBottom: Partial<IStackStyles>;

    rowBottomSpacing: Partial<IStackStyles>;

    categoryWrapper: Partial<IDropdownStyles>;
    operatorWrapper: Partial<IDropdownStyles>;
    valuesWrapper: Partial<ITextFieldStyles>;
    relatedToWrapper: Partial<ITextFieldStyles>;

    actionButton: Partial<IButtonStyles>;
    clearFilterButton: Partial<IButtonStyles>;
}

export const CATEGORY_FIELD_WIDTH = 200;
export const OPERATOR_FIELD_WIDTH = 128;
export const ACTION_BUTTON_HEIGHT = 32;

/**
 * Hook that returns the styles object used for the QuickFilter.
 * @returns {QuickFilterStyle} The styles object.
 */
export function useQuickFilterStyles(): QuickFilterStyle {
    const { spacing, palette } = useTheme();

    return {
        container: {
            root: {
                width: "fit-content",
                minWidth: "580px",
            },
        },

        actionBarTop: {
            root: {
                paddingBottom: spacing.s2,
                borderBottomWidth: 1,
                borderBottomStyle: "solid",
                borderBottomColor: palette.neutralTertiary,
                marginBottom: spacing.m,
            },
        },
        actionBarBottom: {
            root: {
                paddingTop: spacing.m,
            },
        },

        rowBottomSpacing: {
            root: {
                marginBottom: spacing.s2,
            },
        },

        categoryWrapper: {
            root: {
                width: CATEGORY_FIELD_WIDTH,
                marginRight: spacing.s2,
            },
        },
        operatorWrapper: {
            root: {
                width: OPERATOR_FIELD_WIDTH,
                marginRight: spacing.s2,
            },
        },
        valuesWrapper: {
            root: {
                width: 184,
                marginRight: spacing.s2,
            },
        },
        relatedToWrapper: {
            root: {
                width: 320,
                marginRight: spacing.s2,
            },
        },

        actionButton: {
            root: {
                height: ACTION_BUTTON_HEIGHT,
            },
        },

        clearFilterButton: {
            root: {
                marginRight: spacing.m,
                color: palette.red,
            },
            icon: {
                color: palette.red,
            },
            iconHovered: {
                color: palette.redDark,
            },
            rootHovered: {
                color: palette.redDark,
            },
        },
    };
}
