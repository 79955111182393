import { ApolloError } from "@apollo/client/errors";
import { getAccessToken } from "iserver365-authentication-utility";
import { EventTypes, raiseEvent } from "iserver365-infrastructure-utility";
import { navigateToUrl } from "single-spa";
import { settings } from "./settings";
import { initializeAutomaticLogout } from "./initializeAutomaticLogout";

/**
 * Logs the current user using the authentication-utility and then retrieves the current user from the
 * users-and-roles-utility. If no user is found, an error is thrown.
 * @throws An error is thrown if no user is returned from the users-and-roles-utility for the current user.
 */
async function authenticateSession(): Promise<void> {
    const { login } = await import("iserver365-authentication-utility");

    try {
        await login();
        const { getCurrentUser } = await import("iserver365-users-and-roles-utility");
        const { data } = await getCurrentUser();
        if (data.user === null || data.user === undefined) {
            throw new Error("Could not get user info from OrbusInfinity");
        }

        await getCurrentUserAuthenticationCookie();
        initializeAutomaticLogout();
    } catch (e) {
        const { trackException } = await import("./appInsights");
        if ((e as ApolloError)?.message === "Access denied") {
            navigateToUrl("/no-access");
        } else {
            raiseEvent(EventTypes.GlobalError);
        }
        trackException(e as Error);
    }
}

/**
 * Retrieves authentication cookies for legacy app
 */
async function getCurrentUserAuthenticationCookie(): Promise<void> {
    const token = await getAccessToken();
    const legacyIframeUrl = settings.legacyIframeUrl;

    await fetch(`${legacyIframeUrl}/api/user/getCurrentUserAuthenticationCookie`, {
        credentials: "include",
        headers: { Authorization: `Bearer ${token}` },
    });
}

export { authenticateSession };
