import { IToggleStyles, ICommandBarStyles, IIconProps, useTheme } from "@fluentui/react";

interface UseStyles {
    /**
     * Styles for toggle
     */
    toggle: Partial<IToggleStyles>;
    button: Partial<ICommandBarStyles>;
}

/**
 * Hook that returns the styles object used for TagPicker.
 * @returns {UseStyles} The styles object.
 */
export function useStyles(): UseStyles {
    const { spacing } = useTheme();

    return {
        toggle: {
            text: { minWidth: "100%" },
        },
        button: {
            root: {
                height: spacing.l2,
            },
        },
    };
}

export const addIcon: IIconProps = { iconName: "Add" };
