import React, { useCallback } from "react";
import { Text, IconButton, Panel, merge, Stack, IPanelProps, IStackStyles } from "@fluentui/react";
import { useStyles } from "./SidePanel.styles";
import { SidebarPanelStyles } from "./SidebarPanelStyles";
import { isString } from "lodash";

export interface SidePanelProps extends IPanelProps {
    /** Determines the margin top for the panel, so that it doesn't overlap with the topbar. */
    marginTop?: string;
    /** Side Panel Title */
    headerTitle: string | JSX.Element;
    /** Props for cross */
    crossProps: {
        /** Track event identifier for cross */
        trackEvent: string;
        /** Cross Title */
        title: string;
        /** Click handler */
        onClick: () => void;
    };
    /** Content for Side panel*/
    children: JSX.Element;
    /** Custom styles of the panel. */
    styles?: SidebarPanelStyles;
    /** Function for rendering custom action in Panel header */
    onRenderHeaderActions?: () => JSX.Element | undefined;
    /** Title tags */
    tags?: Record<string, IStackStyles>;
}

/**
 * SidePanel component.
 * @param {SidePanelProps} props The component props.
 * @returns {JSX.Element} The react element.
 */
export function SidePanel(props: SidePanelProps): JSX.Element {
    const {
        headerTitle,
        marginTop = "0",
        crossProps: { trackEvent: crossTrackEvent, title: crossTitle, onClick: onCrossClick },
        children,
        styles: customStyles,
        onRenderHeaderActions,
        tags,
    } = props;

    const defaultStyles = useStyles(marginTop);
    const styles = merge(defaultStyles, customStyles);

    const renderNavigationContent = useCallback(
        () => (
            <Stack horizontal horizontalAlign="space-between" styles={styles.header} verticalAlign="center">
                <Stack horizontal>
                    <Text styles={styles.headerText} title={isString(headerTitle) ? headerTitle : ""}>
                        {headerTitle}
                    </Text>
                    {tags !== undefined ? (
                        <>
                            {Object.entries(tags).map(([tag, tagStyles]) => (
                                <Text key={tag} styles={tagStyles}>
                                    {tag}
                                </Text>
                            ))}
                        </>
                    ) : null}
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 8 }}>
                    {onRenderHeaderActions?.()}
                    <IconButton
                        data-is365-id={crossTrackEvent}
                        ariaLabel={crossTitle}
                        title={crossTitle}
                        iconProps={{ iconName: "Cancel" }}
                        styles={styles.cross}
                        onClick={onCrossClick}
                    />
                </Stack>
            </Stack>
        ),
        [crossTrackEvent, headerTitle, crossTitle, onCrossClick, styles, onRenderHeaderActions, tags]
    );

    return (
        <Panel
            {...props}
            isBlocking={props.isBlocking ?? false}
            data-test-id="sidePanel"
            styles={styles.panel}
            layerProps={{
                eventBubblingEnabled: true,
            }}
            onRenderNavigationContent={renderNavigationContent}
        >
            {children}
        </Panel>
    );
}
