import React from "react";

/**
 * iServer365 Roadmap Icon.
 * @returns {JSX.Element} iServer365 Roadmap Icon.
 */
const IServer365RoadmapIcon = (): JSX.Element => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3002_25581)">
                <path
                    d="M3.0769 7.00219C3.0769 6.41859 3.48678 5.9152 4.05832 5.79696L19.4429 2.61394C20.2067 2.45591 20.9231 3.03917 20.9231 3.81917V18.6154H3.0769V7.00219Z"
                    fill="url(#paint0_linear_3002_25581)"
                />
                <path d="M0 18.6154H22.7692C23.449 18.6154 24 19.1664 24 19.8462V21.6923H0V18.6154Z" fill="#939BC1" />
                <path
                    d="M3.72254 20.8241C4.07912 20.8241 4.3682 20.535 4.3682 20.1785C4.3682 19.8219 4.07912 19.5328 3.72254 19.5328C3.36598 19.5328 3.0769 19.8219 3.0769 20.1785C3.0769 20.535 3.36598 20.8241 3.72254 20.8241Z"
                    fill="white"
                />
                <path
                    d="M9.26099 20.8241C9.61757 20.8241 9.90665 20.535 9.90665 20.1785C9.90665 19.8219 9.61757 19.5328 9.26099 19.5328C8.90443 19.5328 8.61536 19.8219 8.61536 20.1785C8.61536 20.535 8.90443 20.8241 9.26099 20.8241Z"
                    fill="white"
                />
                <path
                    d="M14.7994 20.8241C15.156 20.8241 15.4451 20.535 15.4451 20.1785C15.4451 19.8219 15.156 19.5328 14.7994 19.5328C14.4429 19.5328 14.1538 19.8219 14.1538 20.1785C14.1538 20.535 14.4429 20.8241 14.7994 20.8241Z"
                    fill="white"
                />
                <path
                    d="M20.3379 20.8241C20.6945 20.8241 20.9836 20.535 20.9836 20.1785C20.9836 19.8219 20.6945 19.5328 20.3379 19.5328C19.9813 19.5328 19.6923 19.8219 19.6923 20.1785C19.6923 20.535 19.9813 20.8241 20.3379 20.8241Z"
                    fill="white"
                />
                <path
                    d="M11.1303 12.1538H7.49135C7.09255 12.1538 6.76929 12.4771 6.76929 12.8759C6.76929 13.2747 7.09255 13.5979 7.49135 13.5979H11.1303C11.5291 13.5979 11.8524 13.2747 11.8524 12.8759C11.8524 12.4771 11.5291 12.1538 11.1303 12.1538Z"
                    fill="#939BC1"
                />
                <path
                    d="M16.6688 10.3077H13.0298C12.631 10.3077 12.3077 10.631 12.3077 11.0298C12.3077 11.4285 12.631 11.7518 13.0298 11.7518H16.6688C17.0676 11.7518 17.3908 11.4285 17.3908 11.0298C17.3908 10.631 17.0676 10.3077 16.6688 10.3077Z"
                    fill="#939BC1"
                />
                <path
                    d="M22.2072 8.46154H18.5683C18.1695 8.46154 17.8462 8.7848 17.8462 9.1836C17.8462 9.58237 18.1695 9.90563 18.5683 9.90563H22.2072C22.606 9.90563 22.9293 9.58237 22.9293 9.1836C22.9293 8.7848 22.606 8.46154 22.2072 8.46154Z"
                    fill="#939BC1"
                />
                <path
                    d="M5.59173 14.1251H1.95277C1.55397 14.1251 1.23071 14.4484 1.23071 14.8472C1.23071 15.2459 1.55397 15.5692 1.95277 15.5692H5.59173C5.99053 15.5692 6.31379 15.2459 6.31379 14.8472C6.31379 14.4484 5.99053 14.1251 5.59173 14.1251Z"
                    fill="#939BC1"
                />
                <path
                    d="M12.6585 7.53846H7.18806C6.95676 7.53846 6.76929 7.72594 6.76929 7.95723C6.76929 8.18852 6.95676 8.376 7.18806 8.376H12.6585C12.8898 8.376 13.0773 8.18852 13.0773 7.95723C13.0773 7.72594 12.8898 7.53846 12.6585 7.53846Z"
                    fill="white"
                />
                <path
                    d="M9.98993 9.00483H7.18806C6.95676 9.00483 6.76929 9.19231 6.76929 9.4236C6.76929 9.65489 6.95676 9.84237 7.18806 9.84237H9.98993C10.2212 9.84237 10.4087 9.65489 10.4087 9.4236C10.4087 9.19231 10.2212 9.00483 9.98993 9.00483Z"
                    fill="white"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_3002_25581"
                    x1="11.8272"
                    y1="18.4908"
                    x2="11.8272"
                    y2="2.13056"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#151E47" />
                    <stop offset="1" stopColor="#1B355C" />
                </linearGradient>
                <clipPath id="clip0_3002_25581">
                    <rect width="24" height="19.3846" fill="white" transform="translate(0 2.30769)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export { IServer365RoadmapIcon };
