import { confirmNavigation } from "iserver365-navigator/api";
import { trackException } from "./appInsights";
import { EventTypes } from "./eventTypes";
import {
    EventTypes as InfrastructureEventTypes,
    MessageCategory,
    listenEvent,
    raiseEvent,
} from "iserver365-infrastructure-utility";
import { EventTypes as AuthorizationEventTypes } from "iserver365-authorization-utility";
import { syncIframeUrl, sendEventToIframe } from "./iframeHelper";
import { mountParcelAsync } from "./parcels";

interface BaseCommunicationEvent<T = Record<string, unknown>> {
    type: EventTypes;
    data: T;
}

interface LegacyAppNavigationEvent
    extends BaseCommunicationEvent<{ url: string; reason: "linkClick" | "locationChange" }> {
    type: EventTypes.legacyAppNavigationEventType;
}

interface LegacyAppContextChangeEvent extends BaseCommunicationEvent {
    type: EventTypes.legacyAppContextChangeEventType;
}

interface LegacyAppReloadedEvent extends BaseCommunicationEvent {
    type: EventTypes.onLegacyReloaded;
}

interface LegacyAppSetUnsavedChangesEvent extends BaseCommunicationEvent<{ value: boolean }> {
    type: EventTypes.setUnsavedChangesEventType;
}

interface LegacyAppConfirmUnsavedChangesEvent extends BaseCommunicationEvent<{ title: string; message: string }> {
    type: EventTypes.confirmUnsavedChangesEventType;
}

interface LegacyAppDisplayNotificationEvent extends BaseCommunicationEvent<{ type: number; message: string }> {
    type: EventTypes.displayNotificationEventType;
}

interface LegacyAppAccessDeniedEvent extends BaseCommunicationEvent {
    type: EventTypes.legacyAppAccessDeniedEventType;
}

interface EventReceivedEvent extends BaseCommunicationEvent {
    type: EventTypes.eventReceivedEventType;
}

interface LegacyAppModalOpenedEvent extends BaseCommunicationEvent {
    type: EventTypes.modalOpenedEventType;
}

interface OpenNewWebAppFlyOut extends BaseCommunicationEvent<{ action: string; data: unknown }> {
    type: EventTypes.openNewWebAppFlyOut;
}

interface LegacyAppError extends BaseCommunicationEvent<{ action: string; data: unknown }> {
    type: EventTypes.legacyAppError;
}

type LegacyAppCommunicationEvents =
    | LegacyAppNavigationEvent
    | LegacyAppContextChangeEvent
    | LegacyAppSetUnsavedChangesEvent
    | LegacyAppConfirmUnsavedChangesEvent
    | LegacyAppDisplayNotificationEvent
    | LegacyAppAccessDeniedEvent
    | EventReceivedEvent
    | LegacyAppModalOpenedEvent
    | OpenNewWebAppFlyOut
    | LegacyAppReloadedEvent
    | LegacyAppError;

let performNavigation = false;
const eventReceivePromises: Partial<Record<EventTypes, Promise<void>>> = {};

// it will be null on the start. Then in bootstrap function we initialize it using
// navigate function from params
let onLegacyAppEventHandler: ((e: MessageEvent) => void) | null = null;
const onPopState = () => {
    if (performNavigation) {
        Promise.all(Object.values(eventReceivePromises)).then(() => syncIframeUrl());
    }

    raiseEvent(EventTypes.closeSidePanel, null);
};

function handleLegacyAppNavigation(navigator: (path: string) => void, message: LegacyAppNavigationEvent) {
    // Extract the route from the url
    const dataURL = new URL(message.data.url);
    const path = dataURL.hash.substring(2);

    // Construct the full url
    const url = new URL(window.location.origin + path);

    // Replace state when navigation occur due to state change
    // to prevent duplicate history entries
    // as legacy app adds a history entry itself
    if (message.data.reason === "locationChange") {
        window.history.replaceState(window.history.state, "", url);
    } else {
        navigator(url.pathname + url.search);
    }
}

function displayResponseMessage(message: { type: number; message: string }) {
    const messageType = message.type;

    raiseEvent(InfrastructureEventTypes.BffResponseMessage, {
        messageCategory:
            messageType === 0
                ? MessageCategory.Error
                : messageType === 1
                ? MessageCategory.Warning
                : messageType === 2
                ? MessageCategory.Information
                : MessageCategory.Success,
        messageString: message.message,
    });
}

function disableBackground(message: LegacyAppModalOpenedEvent): void {
    let overlay = document.getElementById("overlay");
    const opened = Boolean(message.data);

    if (overlay === null) {
        overlay = document.createElement("div");
        overlay.setAttribute("id", "overlay");
        overlay.style.cssText = `
                position: fixed;
                display: none;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgba(0,0,0,0.4);
                z-index: 2000001;
                cursor: default;
            `;
        document.body.appendChild(overlay);
    }

    overlay.style.display = opened ? "block" : "none";

    const frame = document.querySelector<HTMLElement>(".legacyAppIframeContainer");
    if (frame === null) {
        return;
    }

    frame.style.zIndex = opened ? "2000002" : "0";
}

async function mountErrorParcel(parsedData: Record<string, unknown>): Promise<void> {
    const data = {
        errorMessageContent:
            parsedData?.title === undefined
                ? undefined
                : {
                      headerText: parsedData.title,
                      contentText: parsedData.description,
                  },
        onNavigateToUrl: () => {
            sendEventToIframe("reload");
            sendEventUnmountParcelLegacyError();
        },
    };
    await mountParcelAsync("showLegacyAppError", data);
}

/**
 * Initialize the event handler used for communicating between the legacy webapp and the legacy MFE.
 * @param {Function} navigator A navigator function which updates the url with the specified path.
 */
function initializeEvents(navigator: (path: string) => void): void {
    onLegacyAppEventHandler = async (e: MessageEvent) => {
        if (typeof e.data === "string") {
            try {
                const parsedMessage = <LegacyAppCommunicationEvents>JSON.parse(e.data);
                if (parsedMessage.type === EventTypes.legacyAppNavigationEventType) {
                    handleLegacyAppNavigation(navigator, parsedMessage);
                } else if (parsedMessage.type === EventTypes.legacyAppContextChangeEventType) {
                    raiseEvent(EventTypes.legacyAppContextChangeEventType, parsedMessage.data);
                } else if (parsedMessage.type === EventTypes.setUnsavedChangesEventType) {
                    raiseEvent(EventTypes.setUnsavedChangesEventType, { ...parsedMessage.data, source: "legacy" });
                } else if (parsedMessage.type === EventTypes.confirmUnsavedChangesEventType) {
                    const value = await confirmNavigation();
                    sendEventToIframe(EventTypes.confirmUnsavedChangesResponseEventType, { value });
                } else if (parsedMessage.type === EventTypes.displayNotificationEventType) {
                    displayResponseMessage(parsedMessage.data);
                } else if (parsedMessage.type === EventTypes.legacyAppAccessDeniedEventType) {
                    raiseEvent(AuthorizationEventTypes.forbiddenError, parsedMessage.data);
                } else if (parsedMessage.type === EventTypes.eventReceivedEventType) {
                    raiseEvent(EventTypes.eventReceivedEventType, parsedMessage.data);
                } else if (parsedMessage.type === EventTypes.modalOpenedEventType) {
                    disableBackground(parsedMessage);
                } else if (parsedMessage.type === EventTypes.openNewWebAppFlyOut) {
                    await mountParcelAsync(parsedMessage.data.action, parsedMessage.data.data);
                } else if (parsedMessage.type === EventTypes.legacyAppError) {
                    await mountErrorParcel(parsedMessage.data as Record<string, unknown>);
                } else if (parsedMessage.type === EventTypes.onLegacyReloaded) {
                    raiseEvent(EventTypes.onLegacyReloaded, parsedMessage.data);
                }
            } catch (e) {
                trackException(e as Error);
            }
        }
    };
}

const getRedirectToIframeEventHandler =
    <T = Record<string, unknown>>(eventName: string) =>
    (data: T) =>
        sendEventToIframe(eventName, data);

/**
 * Adds all the event handlers for the events consumed by the legacy MFE.
 */
function addEventHandlers(): void {
    listenEvent("popstate", onPopState);

    listenEvent(EventTypes.setUnsavedChangesEventType, (data: { value: boolean; source: string }) => {
        if (data.source !== "legacy" && !data.value) {
            setEventReceivedPromise(EventTypes.resetUnsavedChangesEventType);
            sendEventToIframe(EventTypes.resetUnsavedChangesEventType, null);
        }
    });

    listenEvent(EventTypes.onObjectMerged, refreshLegacyAppListView);
    listenEvent(EventTypes.onObjectsEdited, refreshLegacyAppListView);

    // TODO: This should probably be on the container MFE, which is the entry point of the app.
    listenEvent(EventTypes.openNewWebAppFlyOut, async (data: { action: string; data: unknown }) => {
        await mountParcelAsync(data.action, data.data);
    });

    const eventTypesWhichRedirectToIframe = [
        EventTypes.refreshLegacyViewsEventType,
        EventTypes.roleChangeEventType,
        EventTypes.mscContextChangeEventType,
    ];

    eventTypesWhichRedirectToIframe.forEach((eventName) => {
        const eventHandler = getRedirectToIframeEventHandler(eventName);
        listenEvent(eventName, eventHandler);
    });

    if (onLegacyAppEventHandler !== null) {
        window.addEventListener("message", onLegacyAppEventHandler);
    }
}

/**
 * Notifies the legacy app to reload list view to load new data.
 */
function refreshLegacyAppListView() {
    sendEventToIframe(EventTypes.refreshLegacyAppListView);
}

function setEventReceivedPromise(eventToReceive: EventTypes) {
    eventReceivePromises[eventToReceive] = new Promise<void>((resolver) => {
        const removeListener = listenEvent(EventTypes.eventReceivedEventType, (data: { eventName: EventTypes }) => {
            if (eventToReceive !== data.eventName) {
                return;
            }

            delete eventReceivePromises[eventToReceive];

            removeListener();
            resolver();
        });
    });
}

/**
 * Notifies the app to clear parcel error after redirect from legacy to new webapp.
 * We need this if we redirect 'something went wrong' page without click on button.
 */
function sendEventUnmountParcelLegacyError(): void {
    raiseEvent(EventTypes.unmountParcelError, null);
}

/**
 * For legacy app we need to know if iframe is visible.
 * This helps to fix problem with views styles ( columns collapse after reload )
 * @param {boolean} value Flag for iframe visibility
 */
function sendLegacyCurrentVisibility(value: boolean): void {
    sendEventToIframe(EventTypes.legacyAppIsVisibleEventType, { value });
}

/**
 * Notifies the app to stop performing navigation.
 */
function stopNavigationChanges(): void {
    performNavigation = false;
}

/**
 * Notifies the app to start performing navigation.
 */
function startNavigationChanges(): void {
    performNavigation = true;
}

export {
    initializeEvents,
    addEventHandlers,
    startNavigationChanges,
    stopNavigationChanges,
    sendLegacyCurrentVisibility,
    sendEventUnmountParcelLegacyError,
};
