import React from "react";
import { ModelItem } from "./types/ModelItem";
import { RelationshipType } from "./types/RelationshipType";
import { Ticket } from "../Ticket/Ticket";
import { Arrow } from "./Arrow/Arrow";
import { ActionButton, Shimmer, Stack } from "@fluentui/react";
import { useLocalization } from "../Localization.context";
import { useRelationshipPickerStyles } from "./RelationshipPicker.styles";
import { RelationshipDirection } from "./types";
import { SearchableDropdown } from "../SearchableDropdown";
import { ModelItemType } from "./types/ModelItemType";
import { useRelationshipPicker } from "./hooks/useRelationshipPicker";
import { isNil } from "lodash";
import { Model } from "./types/Model";

type ItemChangeCallbackType = (metamodelItemId: string, modelItemId: string) => void;

export type RelationshipPickerProps = {
    /** Model of the source object. It will be overridden when 'sourceItem.model' property is provided. */
    sourceModel: Model | undefined;
    /** Model of the target object. It will be overridden when 'targetItem.model' property is provided. */
    targetModel: Model | undefined;
    /** Item to be displayed as source of the relationship. Item can be undefined initially in edit mode. */
    sourceItem: ModelItem | undefined;
    /** Item to be displayed as target of the relationship. Item can be undefined initially in edit mode. */
    targetItem: ModelItem | undefined;
    /** Relationship type describing relationship between source and target items. Value can be undefined initially in edit mode. */
    relationshipType: RelationshipType | undefined;
    /** Flag indicating whether the relationship source can be edited. */
    isSourceEditable?: boolean;
    /** Flag indicating whether the relationship target can be edited. */
    isTargetEditable?: boolean;
    /** Flag indicating whether the component content is loading. */
    loading?: boolean;
    /** Available Model Items retriever. */
    modelItemsRetriever?: (
        searchText: string,
        objectTypeId: string | undefined,
        modelId: string
    ) => Promise<ModelItem[]>;
    /** Available Metamodel Items retriever. */
    metamodelItemsRetriever?: (
        relatedMetamodelItemId: string | undefined,
        relationshipTypeId: string | undefined,
        isSource: boolean
    ) => ModelItemType[];
    /** Available relationship types retriever. */
    relationshipTypesRetriever?: (
        sourceObjectTypeId: string | undefined,
        targetObjectTypeId: string | undefined
    ) => RelationshipType[];
    /** Callback to be called when source item is clicked. */
    onSourceItemClick?: () => void;
    /** Callback to be called when target item is clicked. */
    onTargetItemClick?: () => void;
    /** Callback to be called when source item has change. */
    onSourceItemChange?: ItemChangeCallbackType;
    /** Callback to be called when target item has change. */
    onTargetItemChange?: ItemChangeCallbackType;
    /** Callback to be called when relationship type has change. */
    onRelationshipTypeChange?: (relationshipTypeId: string) => void;
    /** Name of the event to send when clicking on the clear all button. */
    clearAllEventName?: string;
};

/**
 * RelationshipPicker component presents relationship between two items.
 * Currently component supports only read mode.
 * @param {RelationshipPickerProps} props - The component props.
 * @returns {JSX.Element} The RelationshipPicker component.
 */
export const RelationshipPicker = ({
    sourceModel,
    targetModel,
    sourceItem,
    targetItem,
    relationshipType,
    isSourceEditable = false,
    isTargetEditable = false,
    loading = false,
    modelItemsRetriever,
    metamodelItemsRetriever,
    relationshipTypesRetriever,
    onSourceItemClick,
    onTargetItemClick,
    onSourceItemChange,
    onTargetItemChange,
    onRelationshipTypeChange,
    clearAllEventName,
}: RelationshipPickerProps): JSX.Element => {
    const translation = useLocalization();

    const {
        relationshipTypePicker,
        sourceModelItemPicker,
        targetModelItemPicker,
        clearPickers,
        displayClearAllOption,
    } = useRelationshipPicker({
        sourceModel,
        targetModel,
        relationshipType,
        sourceModelItem: sourceItem,
        targetModelItem: targetItem,
        isSourceEditable,
        isTargetEditable,
        modelItemsRetriever,
        relationshipTypesRetriever,
        metamodelItemsRetriever,
        onSourceModelItemSelected: onSourceItemChange,
        onTargetModelItemSelected: onTargetItemChange,
        onRelationshipTypeSelected: onRelationshipTypeChange,
    });
    const styles = useRelationshipPickerStyles(relationshipTypePicker.isEditable);

    return (
        <Stack styles={styles.shimmerContainer}>
            <Shimmer isDataLoaded={!loading} shimmerElements={styles.shimmerElements}>
                <Stack horizontal styles={styles.container}>
                    <Ticket
                        label={translation.sourceModelItemLabel}
                        readOnly={!sourceModelItemPicker.isEditable}
                        title={sourceItem?.name ?? ""}
                        subTitle={sourceItem?.itemType.name ?? ""}
                        description={sourceModelItemPicker.modelName}
                        icon={sourceModelItemPicker.icon}
                        titlePicker={{
                            placeholder: translation.relationshipPickerObjectPlaceholder,
                            selectedKey: sourceModelItemPicker.selectedModelItemId,
                            disabled: isNil(sourceModelItemPicker.selectedMetamodelItemId),
                            options: sourceModelItemPicker.getAvailableModelItems,
                            onChange: (_, key) => sourceModelItemPicker.changeModelItem(key),
                        }}
                        subTitlePicker={{
                            placeholder: translation.relationshipPickerObjectTypePlaceholder,
                            selectedKey: sourceModelItemPicker.selectedMetamodelItemId,
                            options: sourceModelItemPicker.availableMetamodelItems,
                            onChange: (_, key) => sourceModelItemPicker.changeMetamodelItem(key),
                        }}
                        onTitleClick={onSourceItemClick}
                    />
                    <Stack styles={styles.relationshipContainer}>
                        <Stack styles={styles.relationshipInnerContainer}>
                            {relationshipTypePicker.isEditable && (
                                <SearchableDropdown
                                    label={translation.relationshipTypeLabel}
                                    placeholder={translation.relationshipPickerRelationshipTypePlaceholder}
                                    styles={styles.relationshipDropdown}
                                    selectedKey={relationshipTypePicker.relationshipTypeId}
                                    options={relationshipTypePicker.availableRelationshipTypes}
                                    onChange={(_, key) => relationshipTypePicker.changeRelationshipType(key)}
                                />
                            )}
                            <Arrow
                                title={
                                    !relationshipTypePicker.isEditable && !isNil(relationshipType)
                                        ? relationshipType.directionName
                                        : ""
                                }
                                color={relationshipTypePicker.color ?? "california"}
                                direction={
                                    relationshipTypePicker.direction === RelationshipDirection.Sequential
                                        ? "right"
                                        : "directionless"
                                }
                            />
                            {displayClearAllOption && (
                                <ActionButton
                                    iconProps={{ iconName: "Cancel" }}
                                    onClick={clearPickers}
                                    data-is365-id={clearAllEventName}
                                >
                                    {translation.relationshipPickerClearAll}
                                </ActionButton>
                            )}
                        </Stack>
                    </Stack>
                    <Ticket
                        label={translation.targetModelItemLabel}
                        readOnly={!targetModelItemPicker.isEditable}
                        title={targetItem?.name ?? ""}
                        subTitle={targetItem?.itemType.name ?? ""}
                        description={targetModelItemPicker.modelName ?? ""}
                        icon={targetModelItemPicker.icon}
                        titlePicker={{
                            placeholder: translation.relationshipPickerObjectPlaceholder,
                            selectedKey: targetModelItemPicker.selectedModelItemId,
                            disabled: isNil(targetModelItemPicker.selectedMetamodelItemId),
                            options: targetModelItemPicker.getAvailableModelItems,
                            onChange: (_, key) => targetModelItemPicker.changeModelItem(key),
                        }}
                        subTitlePicker={{
                            placeholder: translation.relationshipPickerObjectTypePlaceholder,
                            selectedKey: targetModelItemPicker.selectedMetamodelItemId,
                            options: targetModelItemPicker.availableMetamodelItems,
                            onChange: (_, key) => targetModelItemPicker.changeMetamodelItem(key),
                        }}
                        onTitleClick={onTargetItemClick}
                    />
                </Stack>
            </Shimmer>
        </Stack>
    );
};
