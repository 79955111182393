export const states = {
    ["my-views"]: [
        {
            exact: true,
            url: "/visualizations/my",
        },
        {
            exact: true,
            url: "/views/personal",
        },
    ],
    ["public-views"]: [
        "/visualizations/all",
        "/views/public",
        "/views/all",
        { url: "/matrix/view", features: { hiddenMsc: true } },
        { url: "/catalog/view", features: { hiddenMsc: true } },
        { url: "/traceability/view", features: { hiddenMsc: true } },
        "/matrix/new",
        "/catalog/new/",
        "/traceability/new",
        { url: "/visualizations/landscape/new", features: { hiddenMsc: true } },
        { url: "/visualizations/landscape/view", features: { hiddenMsc: true } },
        { url: "/visualizations/listview/", features: { hiddenMsc: true } },
    ],
};
