import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { createBrowserHistory } from "history";
import { createAppInsight } from "iserver365-logging";

interface ReactAppInsights {
    reactPlugin: ReactPlugin;
    appInsights: ApplicationInsights;
}

/**
 * Extends the {@link createAppInsight} using a {@link ReactPlugin} to enable easy integration
 * with react AppInsightsContext.
 * @returns {ReactAppInsights} The react plugin which extends the base {@link createAppInsight} configuration.
 */
function createAppInsightForReact(): ReactAppInsights {
    const reactPlugin = new ReactPlugin();
    const browserHistory = createBrowserHistory({ basename: "" });

    const appInsights = createAppInsight({
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory },
        },
    });

    return { reactPlugin, appInsights };
}

export { createAppInsightForReact };
