import React from "react";

import { Icon } from "./Icon";
import { TopLevelOption } from "./TopLevelOption";
import { LinkOption } from "./LinkOption";
import { RemoveOption } from "./RemoveOption";

export const toolbarOptions = {
    options: ["inline", "textAlign", "link", "list", "remove"],
    inline: {
        component: TopLevelOption,
        options: ["bold", "italic", "underline"],
        bold: { icon: <Icon iconName="Bold" /> },
        italic: { icon: <Icon iconName="Italic" /> },
        underline: { icon: <Icon iconName="Underline" /> },
    },
    textAlign: {
        component: TopLevelOption,
        options: ["right", "center", "left"],
        right: { icon: <Icon iconName="AlignRight" /> },
        center: { icon: <Icon iconName="AlignCenter" /> },
        left: { icon: <Icon iconName="AlignLeft" /> },
    },
    link: {
        component: LinkOption,
        options: ["link"],
        link: { icon: <Icon iconName="Link" /> },
    },
    list: {
        component: TopLevelOption,
        options: ["unordered"],
        unordered: { icon: <Icon iconName="BulletedList" /> },
    },
    remove: {
        component: RemoveOption,
        icon: <Icon iconName="ClearFormatting" />,
    },
};
