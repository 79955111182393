import React from "react";

/**
 * Knowledge Base Icon.
 * @returns {JSX.Element} Knowledge Base Icon.
 */
const KnowledgeBaseIcon = (): JSX.Element => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3002_25551)">
                <path
                    d="M21.4286 4.79124C20.1143 4.79124 19.3954 4.41573 18.5444 3.98767C17.6939 3.55961 16.7092 3.07592 15.1878 3.07693C14.0255 3.07441 13.0858 3.45755 12 4.34481C10.9143 3.45807 9.97452 3.07542 8.81228 3.07693C7.29086 3.07542 6.30615 3.55909 5.45514 3.98767C4.60412 4.41622 3.88523 4.79124 2.57095 4.79124H2.00769V18.7912H2.57095C4.11381 18.7912 5.11126 18.3009 5.96381 17.8719C6.81637 17.4427 7.52298 17.0764 8.81178 17.0775C9.89135 17.0744 10.451 17.306 11.6154 18.354L11.999 18.7019L12.3827 18.354C13.547 17.3055 14.1067 17.0749 15.1863 17.0775C16.475 17.0775 17.1817 17.4427 18.0342 17.8719C18.8868 18.3009 19.8837 18.7912 21.4271 18.7912H21.9985V7.64838V4.79124H21.4271H21.4286Z"
                    fill="url(#paint0_linear_3002_25551)"
                />
                <path
                    d="M22 6.50552V7.64838V18.7912H21.4286C19.8852 18.7912 18.8883 18.3009 18.0357 17.8718C17.1832 17.4428 16.4765 17.0775 15.1878 17.0775C14.1082 17.0749 13.5485 17.306 12.3842 18.354L12.0005 18.702L11.6168 18.354C10.4526 17.3055 9.89286 17.0744 8.81326 17.0775C7.52449 17.0764 6.81785 17.4428 5.96529 17.8718C5.11277 18.3009 4.11529 18.7912 2.57246 18.7912H2.00917V4.79121H2.00102V6.50552H0V19.5604C0 20.2402 0.551046 20.7912 1.23077 20.7912H24V7.73629C24 7.05654 23.449 6.50552 22.7692 6.50552H22Z"
                    fill="#939BC1"
                />
                <path
                    d="M17.8892 7.07693H12.4188C12.1875 7.07693 12 7.26441 12 7.4957C12 7.727 12.1875 7.91447 12.4188 7.91447H17.8892C18.1205 7.91447 18.308 7.727 18.308 7.4957C18.308 7.26441 18.1205 7.07693 17.8892 7.07693Z"
                    fill="white"
                />
                <path
                    d="M15.2206 8.54453H12.4188C12.1875 8.54453 12 8.732 12 8.96329C12 9.19459 12.1875 9.38206 12.4188 9.38206H15.2206C15.4519 9.38206 15.6394 9.19459 15.6394 8.96329C15.6394 8.732 15.4519 8.54453 15.2206 8.54453Z"
                    fill="white"
                />
                <path
                    d="M17.8892 11.4506H12.4188C12.1875 11.4506 12 11.6381 12 11.8694C12 12.1007 12.1875 12.2882 12.4188 12.2882H17.8892C18.1205 12.2882 18.308 12.1007 18.308 11.8694C18.308 11.6381 18.1205 11.4506 17.8892 11.4506Z"
                    fill="white"
                />
                <path
                    d="M15.2206 12.917H12.4188C12.1875 12.917 12 13.1045 12 13.3358C12 13.5671 12.1875 13.7545 12.4188 13.7545H15.2206C15.4519 13.7545 15.6394 13.5671 15.6394 13.3358C15.6394 13.1045 15.4519 12.917 15.2206 12.917Z"
                    fill="white"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_3002_25551"
                    x1="11.8096"
                    y1="18.6711"
                    x2="11.8096"
                    y2="2.90625"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#151E47" />
                    <stop offset="1" stopColor="#1B355C" />
                </linearGradient>
                <clipPath id="clip0_3002_25551">
                    <rect width="24" height="17.8462" fill="white" transform="translate(0 3.07692)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export { KnowledgeBaseIcon };
