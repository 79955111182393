import { IStackStyles, ITextStyles, useTheme } from "@fluentui/react";

export interface NotFoundStyles {
    /** Header styles */
    container: IStackStyles;

    /** Header styles */
    header: IStackStyles;

    /** Title styles */
    title: ITextStyles;

    /** SubTitle styles */
    subTitle: ITextStyles;

    /** Content styles */
    content: ITextStyles;
}

/**
 * Hook that returns the styles object used for the NotFound.
 * @returns {NotFoundStyles} The styles object.
 */
export function useNotFoundStyles(): NotFoundStyles {
    const theme = useTheme();

    return {
        container: {
            root: {
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
                margin: "15vh 0",
            },
        },
        header: {
            root: {
                padding: `0 ${theme.spacing.l2}`,
                minHeight: theme.spacing.l2,
            },
        },
        title: {
            root: {
                fontSize: theme.fonts.xLarge.fontSize,
                fontWeight: theme.fonts.xxLarge.fontWeight,
                marginBottom: theme.spacing.s2,
            },
        },
        subTitle: {
            root: {
                fontSize: theme.fonts.xLarge.fontSize,
                color: theme.palette.red,
                marginBottom: theme.spacing.m,
            },
        },
        content: {
            root: {
                fontSize: theme.fonts.medium.fontSize,
                fontWeight: theme.fonts.medium.fontWeight,
                textAlign: "center",
                marginBottom: theme.spacing.l2,
            },
        },
    };
}
