import { TextField } from "@fluentui/react";
import React from "react";
import { PersonListItemField } from "../types";
import { CellProps } from "./CellProps";

/**
 * Cell for the list view which outputs a person record.
 * @param {CellProps<PersonListItemField>} param The cell props.
 * @returns {React.FC<CellProps<PersonListItemField>>} The react component.
 */
export const PersonCell: React.FC<CellProps<PersonListItemField>> = ({
    editMode,
    field,
    onStopEditing,
    onValueChange,
}: CellProps<PersonListItemField>) => {
    if (editMode) {
        return (
            <TextField
                componentRef={(input) => {
                    input?.focus();
                }}
                value={field.text ?? ""}
                onChange={(e, newValue) => onValueChange({ ...field, text: newValue ?? "" })}
                onBlur={onStopEditing}
                ariaLabel="Edit"
            />
        );
    }

    return <>{field.text}</>;
};
