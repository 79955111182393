import { CustomProps, ParcelConfig } from "single-spa";
import { EventTypes } from "./eventTypes";

export const ParcelDefinitions = [
    {
        actionName: "mergeObjects",
        unmountEventName: EventTypes.mergeObjectsSidePanelClosed,
        useIframe: true,
        import: (): Promise<ParcelConfig<CustomProps>> =>
            import("iserver365-views/parcels/mergeObjectsParcel.singlespa") as Promise<ParcelConfig<CustomProps>>,
    },
    {
        actionName: "showLegacyAppError",
        unmountEventName: EventTypes.unmountParcelError,
        useIframe: false,
        import: (): Promise<ParcelConfig<CustomProps>> =>
            import("iserver365-something-went-wrong/parcels/somethingWentWrong.singlespa") as Promise<
                ParcelConfig<CustomProps>
            >,
    },
    {
        actionName: "editObjects",
        unmountEventName: EventTypes.editObjectsSidePanelClosed,
        useIframe: true,
        import: (): Promise<ParcelConfig<CustomProps>> =>
            import("iserver365-views/parcels/editObjectsParcel.singlespa") as Promise<ParcelConfig<CustomProps>>,
    },
    {
        actionName: "createLandscapeViewPanel",
        unmountEventName: EventTypes.closeSidePanel,
        useIframe: true,
        import: (): Promise<ParcelConfig<CustomProps>> =>
            import("iserver365-views/parcels/landscapeViewPanelParcel.singlespa") as Promise<ParcelConfig<CustomProps>>,
    },
    {
        actionName: "modelItemDetails",
        unmountEventName: EventTypes.modelItemDetailsSidePanelClosed,
        useIframe: true,
        import: (): Promise<ParcelConfig<CustomProps>> =>
            import("iserver365-content-management/parcels/modelItemFlyoutParcel.singlespa") as Promise<
                ParcelConfig<CustomProps>
            >,
    },
    {
        actionName: "mergeModels",
        unmountEventName: EventTypes.mergeModelsSidePanelClosed,
        useIframe: true,
        import: (): Promise<ParcelConfig<CustomProps>> =>
            import("iserver365-content-management/parcels/mergeModelsFlyoutParcel.singlespa") as Promise<
                ParcelConfig<CustomProps>
            >,
    },
];
