import React from "react";
import { range } from "lodash";
import { DefaultButton, Stack, Text, useTheme } from "@fluentui/react";
import { useStyles } from "./Rating.styles";

/**
 * The {@link RatingProps} Props.
 */
export interface RatingProps {
    /** Callback to execute when select rating */
    onRatingChanged: (rating: number) => void;
    /** Labels of the component */
    labels: {
        poorLabelText: string;
        excellentLabelText: string;
    };
    /** Maximum rating number. The default value is 5 */
    max?: number;
}

/**
 * Rating component renders the rating buttons.
 * @param {RatingProps} props The Rating component properties.
 * @returns {JSX.Element} The Rating component.
 */
export function Rating(props: RatingProps): JSX.Element {
    const { onRatingChanged, labels, max = 5 } = props;
    const { stackStyles, buttonStyles, textStyles } = useStyles();
    const { spacing } = useTheme();

    return (
        <>
            <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: spacing.s2 }} styles={stackStyles}>
                {range(0, max + 1).map((rating) => {
                    return (
                        <DefaultButton key={rating} styles={buttonStyles} onClick={() => onRatingChanged(rating)}>
                            {rating}
                        </DefaultButton>
                    );
                })}
            </Stack>
            <Stack horizontal horizontalAlign="space-between">
                <Text variant="small" styles={textStyles}>
                    {labels.poorLabelText}
                </Text>
                <Text variant="small" styles={textStyles}>
                    {labels.excellentLabelText}
                </Text>
            </Stack>
        </>
    );
}
