import { TextField } from "@fluentui/react";
import React from "react";
import { CurrencyListItemField } from "../types";
import { CellProps } from "./CellProps";

/**
 * Cell for the list view which outputs a formatted currency string.
 * @param {CellProps<CurrencyListItemField>} param The cell props.
 * @returns {React.FC<CellProps<CurrencyListItemField>>} The react component.
 */
export const CurrencyCell: React.FC<CellProps<CurrencyListItemField>> = ({
    editMode,
    field,
    onStopEditing,
    onValueChange,
    options,
}: CellProps<CurrencyListItemField>) => {
    if (editMode) {
        return (
            <TextField
                ariaLabel="Edit"
                value={field.value?.toString() ?? ""}
                onChange={(_, newValue) => onValueChange({ ...field, value: Number(newValue) ?? NaN })}
                onBlur={onStopEditing}
            />
        );
    }

    let formattedValue = "";
    if (field.value !== null && field.isoCurrencySymbol !== null) {
        formattedValue = options.formatting.currency(
            field.value,
            field.isoCurrencySymbol,
            field.currencySymbol,
            field.decimalPlaces
        );
    }
    return <>{formattedValue}</>;
};
