import { IColumn } from "@fluentui/react";
import { SetStateAction } from "react";
import { ListItem, ViewDataRetriever } from "./types";

function getSortData(columns?: IColumn[]): { sortField?: string; sortOrder?: "asc" | "desc" } {
    const sortingColumn = columns?.find((c) => c.isSorted);
    if (sortingColumn === undefined) {
        return {};
    }
    return {
        sortField: sortingColumn.key,
        sortOrder: sortingColumn.isSortedDescending ?? false ? "desc" : "asc",
    };
}

/**
 * Fetch data callback. This function retrieves and sets items for list view.
 * Per each page corresponding data is loaded.
 * @param {number} index Current amount of items
 * @param {number} pageSize Amount data per page
 * @param {IColumn[]|undefined} columns List of columns configuration
 * @param {(ListItem|null)[]} items List of items
 * @param {(value: SetStateAction<(ListItem | null)[] | undefined>) => void} setItems Callback to set items
 * @param {ViewDataRetriever} dataRetriever Function to fetch data
 * @param {string|undefined} searchQuery Search query
 */
export async function fetchDataCallback(
    index: number,
    pageSize: number,
    columns: IColumn[],
    items: (ListItem | null)[] | undefined,
    setItems: (value: SetStateAction<(ListItem | null)[] | undefined>) => void,
    dataRetriever: ViewDataRetriever,
    searchQuery?: string
): Promise<void> {
    const pageNumber = Math.floor(index / pageSize);

    const rowsAlreadyLoaded =
        items !== undefined && items.slice(pageNumber * pageSize, (pageNumber + 1) * pageSize).every((r) => r !== null);

    if (rowsAlreadyLoaded && (searchQuery === undefined || searchQuery === "")) {
        return;
    }

    const { sortField, sortOrder } = getSortData(columns);
    const fetchResult = await dataRetriever(pageNumber, pageSize, sortField, sortOrder, searchQuery);

    if (fetchResult === undefined) {
        return;
    }

    setItems((prevItems) => {
        const fullResponse = prevItems === undefined ? new Array(fetchResult.totalItemCount).fill(null) : prevItems;
        fullResponse?.splice(index, pageSize, ...fetchResult.items);

        return [...fullResponse];
    });
}
