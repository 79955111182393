import { makeStyles } from "@fluentui/react";

export const useListItemStyles = makeStyles(({ fonts, spacing, palette, effects }) => ({
    container: {
        minWidth: 0,
    },
    title: {
        fontFamily: fonts.medium.fontFamily,
        fontSize: fonts.medium.fontSize,
        color: palette.themePrimary,
        ":hover, :active, :hover:active": {
            color: palette.themePrimary,
        },
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    },
    iconContainer: {
        display: "flex",
        background: palette.themePrimary,
        fontSize: fonts.mediumPlus.fontSize,
        fontWeight: fonts.mediumPlus.fontWeight,
        color: palette.white,
        borderRadius: effects.roundedCorner2,
        padding: spacing.s1,
        marginRight: spacing.s2,
    },
}));
