import React from "react";
import {
    Dropdown,
    DropdownMenuItemType,
    SearchBox,
    IDropdownOption,
    IDropdownProps,
    mergeStyleSets,
} from "@fluentui/react";
import { useSearchableDropdownStyles } from "./SearchableDropdown.styles";

/**
 * The props of the SearchableDropdown component.
 */
export interface SearchableDropdownBaseProps extends IDropdownProps {
    setSearchText: (searchText: string) => void;
    renderNormalOption?: (props?: IDropdownOption) => JSX.Element | null;
}

/**
 * SearchableDropdown is a component which displays the selected item from options. It is built on top of Fluent UI Dropdown
 * It has following additional features:
 * - filter options by text;
 * @param {SearchableDropdownBaseProps} props The component props
 * @returns {JSX.Element} return react component
 */
export function SearchableDropdownBase(props: SearchableDropdownBaseProps): JSX.Element {
    const { setSearchText, options, renderNormalOption } = props;

    const { searchBoxStyles, dropdownStyles } = useSearchableDropdownStyles();

    const renderOption = (
        option?: IDropdownOption,
        defaultRender?: (props?: IDropdownOption) => JSX.Element | null
    ): JSX.Element | null => {
        if (option === undefined) {
            return <></>;
        }

        if (option.itemType === DropdownMenuItemType.Header) {
            if (option.key === "FilterHeader") {
                return (
                    <SearchBox
                        styles={searchBoxStyles}
                        onChange={(_e, newValue) => setSearchText(newValue ?? "")}
                        placeholder="Search options"
                    />
                );
            }
            return <>{option.text}</>;
        }
        return defaultRender === undefined ? <>{option.text}</> : defaultRender(option);
    };

    return (
        <Dropdown
            {...props}
            styles={mergeStyleSets(props.styles, dropdownStyles)}
            options={[{ key: "FilterHeader", text: "-", itemType: DropdownMenuItemType.Header }, ...options]}
            onRenderOption={(optionProps) => renderOption(optionProps, renderNormalOption)}
            onDismiss={() => setSearchText("")}
            // set role to list box since the default role for dropdown is combo box which requires child text box control
            // this does not suit out case because we will not allow to add new option items to the list
            role="listbox"
        />
    );
}
