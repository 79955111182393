import { InMemoryCache, makeVar } from "@apollo/client";
import { getStorageItem, setStorageItem } from "iserver365-infrastructure-utility";

const businessModeEnabledKey = "businessModeEnabled";

const businessModeEnabled = makeVar<boolean>(getStorageItem<boolean>(businessModeEnabledKey) ?? false);

/**
 * Gets the business mode status.
 * @returns {boolean} True if business mode is enabled.
 */
export function getBusinessModeEnabled(): boolean {
    return businessModeEnabled();
}

/**
 * Sets the business mode status.
 * @param {boolean} value The value of business view mode flag.
 */
export function setBusinessModeEnabled(value: boolean): void {
    setStorageItem(businessModeEnabledKey, value);
    businessModeEnabled(value);
}

/**
 * The in-memory apollo cache to retrieve the application settings.
 */
export const cache: InMemoryCache = new InMemoryCache({
    typePolicies: {
        Settings: {
            fields: {
                businessModeEnabled: {
                    read(_) {
                        return businessModeEnabled();
                    },
                },
            },
        },
    },
});
