import { wrap } from "lodash";
import React from "react";
import classNames from "classnames";

import { keyDownA11y } from "../../../utils/accessibility";
import { OptionProps } from "../types";
import { getContent, onClick } from "./Option.service";

/**
 * Adapted from: https://github.com/jpuri/react-draft-wysiwyg/blob/705f518fa516f2839394d706e8b11bfd99734abd/src/components/Option/index.js
 * @param {OptionProps} props The component props
 * @returns {JSX.Element} The react element.
 */
export function Option(props: OptionProps): JSX.Element {
    const {
        active,
        activeClassName,
        children,
        className,
        disabled,
        icon,
        onClick: onClickProp,
        title = "Toolbar Option",
        value,
    } = props;

    const content = getContent({ children, icon });
    const inputHandler = wrap({ disabled, onClickProp, value }, onClick);
    return (
        <div
            className={classNames("rdw-option-wrapper", className, {
                [`rdw-option-active ${activeClassName ?? ""}`]: active,
                "rdw-option-disabled": disabled,
            })}
            style={{ backgroundColor: "inherit" }}
            onClick={inputHandler}
            onKeyPress={keyDownA11y({ handler: inputHandler })}
            aria-pressed={active}
            role="button"
            title={title}
            tabIndex={0}
        >
            {content}
        </div>
    );
}
