import { FormEvent } from "react";
import { isEmpty } from "lodash";

import { RequiredString, IsValidGuid } from "../utils/validation/rules";
import { ValidationRule, ValidationError } from "../utils/validation";

export type Value = string | undefined;

export type OnChangeHandler = (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => void;

/**
 * @param {object} params Parameter object
 * @param {ValidationError[]} params.validationErrors List of current validation errors for the UniqueIdentifier component
 * @param {boolean} params.readOnly Whether or not the UniqueIdentifier component is currently read only
 * @returns {boolean} Whether or not to display a validation error
 */
export function getDisplayValidationError({
    validationErrors,
    readOnly,
}: {
    validationErrors: ValidationError[];
    readOnly: boolean;
}): boolean {
    return !isEmpty(validationErrors) && !readOnly;
}

/**
 * @param {object} params Parameter object
 * @param {ValidationRule[]} params.validationRules Validation rules to apply to input
 * @param {boolean} params.required Whether the input is required
 * @returns {ValidationRule[]} Array of validation rules to apply
 */
export function getValidationRules({
    validationRules,
    required,
}: {
    validationRules: ValidationRule<Value>[];
    required: boolean;
}): ValidationRule<Value>[] {
    const builtinRules = [new IsValidGuid()];
    if (required) {
        builtinRules.push(new RequiredString());
    }
    return [...builtinRules, ...validationRules];
}
