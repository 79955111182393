import { Escaper } from "../types";

type StringValue = string | undefined;

export const removeHtmlTags: Escaper<StringValue> = function removeHtmlTags({ value }) {
    return value?.replace(/(<([^>]+)>)/gi, "");
};

export const removeLineBreaks: Escaper<StringValue> = function removeLineBreaks({ value }) {
    return value?.replace(/(\r\n|\n|\r)/gm, "");
};
