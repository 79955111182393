import { identify, Identify, init, setGroup, track, Types, add } from "@amplitude/analytics-browser";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";
import { SettingsData } from "iserver365-settings-utility";
import { User } from "iserver365-users-and-roles-utility";
import { settings } from "./settings";

/**
 * Initialize Amplitude SDK module for tracking user events.
 * @param {User} user The component props.
 * @param {SettingsData} configSettings The tenant settings.
 */
function initAmplitude(user?: User, configSettings?: SettingsData): void {
    const amplitudeApiKey = settings.amplitudeApiKey;

    if (
        amplitudeApiKey !== null &&
        amplitudeApiKey !== undefined &&
        amplitudeApiKey.length > 0 &&
        !amplitudeApiKey.includes("TURN_OFF_INTEGRATION")
    ) {
        if (settings.amplitudeSessionReplaySampleRate > 0) {
            const sessionReplayTracking = sessionReplayPlugin({
                sampleRate: settings.amplitudeSessionReplaySampleRate,
            });
            add(sessionReplayTracking);
        }

        init(amplitudeApiKey, user?.personId, {
            serverZone: Types.ServerZone.EU,
            // eslint-disable-next-line spellcheck/spell-checker
            autocapture: {
                attribution: false,
                pageViews: true,
                sessions: true,
                fileDownloads: false,
                formInteractions: true,
                elementInteractions: true,
            },
        });

        identifyUserProperties(user);
        setGroupProperties(configSettings);
    }
}

function setGroupProperties(configSettings?: SettingsData): void {
    if (configSettings === null || configSettings === undefined) {
        return;
    }

    setGroup("Account Id", configSettings.salesforceAccountId);
    setGroup("Instance Id", configSettings.instanceId);
}

/**
 * Track Amplitude Event and execute callback afterwards.
 * @param {string} eventName The tracking event name.
 * @param {() => void} callback The callback to execute after tracking event.
 * @param {Record<string, any>} properties The properties of tracking event.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function trackEvent(eventName: string, callback?: () => void, properties?: Record<string, any>): void {
    track(eventName, properties);
    if (callback !== null && callback !== undefined) {
        callback();
    }
}

/**
 * Track Amplitude Event and await async callback afterwards.
 * @param {string} buttonName The tracking event name.
 * @param {() => Promise<void>} asyncCallback The async callback to await after tracking event.
 * @param {Record<string, any>} properties The properties of tracking event.
 */
async function trackEventAsync(
    buttonName: string,
    asyncCallback?: () => Promise<void>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    properties?: Record<string, any>
): Promise<void> {
    track(buttonName, properties);
    if (asyncCallback !== null && asyncCallback !== undefined) {
        await asyncCallback();
    }
}

/**
 * Track Amplitude Event and execute callback afterwards. Returns callback result.
 * @typedef T The callback type.
 * @param {string} eventName The tracking event name.
 * @param {T} callback The callback to execute after tracking event.
 * @param {Record<string, any>} properties The properties of tracking event.
 * @returns {T} The callback result.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function trackEventAndReturnValue<T>(eventName: string, callback: () => T, properties?: Record<string, any>): T {
    track(eventName, properties);
    return callback();
}

/**
 * Identify user properties for Amplitude SDK
 * @param {User} user The user.
 */
function identifyUserProperties(user?: User): void {
    if (user === null || user === undefined) {
        return;
    }

    const identifyObj = new Identify();
    identifyObj.set(
        "Roles",
        user.roles.map((role) => role.name)
    );

    const licenseType = user.licenseTypeCategoryName;
    if (licenseType !== null && licenseType !== undefined && licenseType.length > 0) {
        identifyObj.set("License type category", licenseType);
    }

    identify(identifyObj);
}

export { trackEvent, trackEventAsync, trackEventAndReturnValue, initAmplitude };
